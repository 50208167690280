import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.p`
  font-size: 21px !important;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${defaultStyles.klassisch.bodyFont} !important;
  color: ${defaultStyles.klassisch.colors.secondary.onDefaultBackground} !important;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    font-size: 16px !important;
    margin-top: 0;
  }
`;
