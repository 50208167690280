import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import defaultStyles from '../../DefaultStyles';

export default styled.h1`
  margin: 0px;
  margin-top: -18px;
  margin-bottom: -18px;
  display: flex;
  align-items: center;
  font-family: ${defaultStyles.flower.headerFont} !important;
  color: ${defaultStyles.flower.colors.primary.base} !important;
  font-size: ${({ characters }) => `calc(360px / ${characters}) !important`};
  @media (max-width: ${breakpoints.xs}) {
    font-size: ${({ characters }) => `calc(200px / ${characters}) !important`};
  }
`;
