import axios from 'axios';

export default async (baseData, weddingId, setWeddingBaseData) => {
  const { data: savedWeddingBaseData } = await axios.patch(`wedding-update/`, {
    updateType: 'UPDATE_WEDDING_BASE_DATA',
    weddingId: weddingId,
    baseData: baseData,
  });
  setWeddingBaseData(savedWeddingBaseData);
};
