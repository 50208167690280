import i18n from 'i18next';
import k from './../../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Switch } from '@material-ui/core';

const Container = styled.div`
  margin: auto;
  display: flex;
`;

const FoodOptionsToggle = ({ foodOptionsActive, setFoodOptionsActive }) => {
  return (
    <Container>
      <FormControlLabel
        control={
          <Switch
            onChange={e => setFoodOptionsActive(e.target.checked)}
            checked={foodOptionsActive}
            value={foodOptionsActive}
          />
        }
        label={
          <span>
            {foodOptionsActive && i18n.t(k.ASK_FOR_MENU_WISHES)}
            {!foodOptionsActive && i18n.t(k.ASK_NOT_FOR_MENU_WISHES)}
          </span>
        }
      />
    </Container>
  );
};

export default FoodOptionsToggle;
