import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginContainer from '../../../../Shared/Components/LoginFlow/LoginContainer';
import { Helmet } from 'react-helmet';

const StartPage = ({ weddingId, scrollToTop }) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Start`}</title>
      </Helmet>
      <Switch>
        <Route
          exact
          path={['/:weddingId/login/enterUsername']}
          render={() => {
            return <LoginContainer weddingId={weddingId}></LoginContainer>;
          }}
        />
      </Switch>
    </>
  );
};

export default StartPage;
