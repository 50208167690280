import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';

export default styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: url(${defaultStyles.boho.background.image});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
