import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

export default styled(LinearProgress)`
  z-index: 1;
  width: 90%;
  max-width: 400px;
  margin: 12px 0;
  min-width: 250px;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
`;
