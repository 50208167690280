import styled from 'styled-components';
import StyledIconButton from './StyledIconButton';

export default styled(StyledIconButton)`
  position: fixed;
  top: 10px;
  left: 10px;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
  z-index: 2;
`;
