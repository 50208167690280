import styled from 'styled-components';

export default styled.div`
  background-color: ${({ iconColor, backgroundColor, highlighted }) => (highlighted ? backgroundColor : iconColor)};
  padding: 0 6px 0 20px;
  border: solid 2px;
  cursor: pointer;
  span {
    color: ${({ iconColor, backgroundColor, highlighted }) => (highlighted ? iconColor : backgroundColor)};
    font-size: 14px !important;
    font-weight: ${({ highlighted }) => (highlighted ? 'bold' : 'normal')};
    letter-spacing: 0.5px;
  }
`;
