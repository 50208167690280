import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../../Shared/breakpoints';

const Question = styled.h1`
  margin: 12px;
  height: 70px;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    margin: 3px;
    height: 32px;
    display: flex;
    align-items: flex-end;
    margin-top: 32px;
  }
`;

const QuestionHeader = ({ children }) => {
  return <Question>{children}</Question>;
};

export default QuestionHeader;
