import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 800px;
  width: 900px;
  background-image: url(${defaultStyles.modern.assets.element});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${({ reducedOpacity }) => (reducedOpacity ? '0.7' : '1')};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 450px;
    width: 450px;
  }
`;
