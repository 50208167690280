import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 400px;
  width: 400px;
  max-width: 20vw;
  background-image: url(${defaultStyles.vintage.assets.threeHearts});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(-800px, -500px, 0)')};
  z-index: 1;
  position: absolute;
  left: 0;
  top: 150px;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 450px;
    width: 450px;
    top: 0px;
    background-position: top;
  }
`;
