export default (baseColor, changeAmount) => {
  let usePound = false;
  if (baseColor[0] === '#') {
    baseColor = baseColor.slice(1);
    usePound = true;
  }
  let num = parseInt(baseColor, 16);
  let r = (num >> 16) + changeAmount;
  let g = ((num >> 8) & 0x00ff) + changeAmount;
  let b = (num & 0x0000ff) + changeAmount;

  r = Math.min(Math.max(0, r), 255);
  g = Math.min(Math.max(0, g), 255);
  b = Math.min(Math.max(0, b), 255);

  return (usePound ? '#' : '') + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};
