import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import boxShadows from '../../../Shared/boxShadows';
import languages from '../../../i18n/languages';
import k from '../../../i18n/keys';
import LanguageContext from '../../../Shared/Context/LanguageContext';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DefaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../Shared/WeddingStyles/GenericStyleContainer';
import LanguageSelectButton from '../../../Shared/Components/LanguageSelection/LanguageSelectButton';
import Icon from '../../../Shared/Components/LanguageSelection/Icon';
import Label from '../../../Shared/Components/LanguageSelection/Label';
import LanguagesContainer from '../../../Shared/Components/LanguageSelection/LanguagesContainer';
import WeddingContext from '../../../Shared/Context/WeddingContext';

const StyledButton = styled.button`
  position: absolute;
  right: 0;
  top: 105px;
  padding: 9px;
  width: 80px;
  background-color: #ffffffdd !important ;
  border: 0px !important;
  box-shadow: ${boxShadows.z3};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  :hover {
    background-color: #eeeeeedd !important;
  }
`;

const LanguageSelect = ({ selectedLanguagesForWedding }) => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const weddingContext = useContext(WeddingContext);
  const weddingId = weddingContext ? weddingContext.weddingId : null;
  const [showLanguageSelectionDialog, setShowLanguageSelectionDialog] = useState(false);
  let filteredLanguages = {};
  Object.entries(languages).forEach(language => {
    if (selectedLanguagesForWedding) {
      if (selectedLanguagesForWedding.includes(language[0])) {
        filteredLanguages[language[0]] = language[1];
      }
    } else {
      filteredLanguages[language[0]] = language[1];
    }
  });

  return (
    <>
      <Dialog
        open={showLanguageSelectionDialog}
        onClose={() => setShowLanguageSelectionDialog(false)}
        aria-labelledby="{messageTitle}"
        aria-describedby="{messageDescriptionText}"
        className="color-background-white"
      >
        <GenericStyleContainer weddingStyleData={DefaultStyles.eurehochzeitonline}>
          <DialogContent>
            <LanguagesContainer>
              {Object.entries(filteredLanguages).map(language => (
                <LanguageSelectButton
                  key={language[1].icon}
                  onClick={() => {
                    setCurrentLanguage(language[0], weddingId);
                  }}
                >
                  <Icon icon={language[1].icon}></Icon>
                  <Label>{language[1].label}</Label>
                </LanguageSelectButton>
              ))}
            </LanguagesContainer>
            {!selectedLanguagesForWedding && (
              <p>
                {i18n.t(k.IF_YOU_NEED_MORE_LANGUAGES)} {i18n.t(k.WE_LOOK_FORWARD_TO_IT)}{' '}
                <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
              </p>
            )}
          </DialogContent>
          <DialogActions className="color-background-white">
            <Button onClick={() => setShowLanguageSelectionDialog(false)} color="primary" variant="contained">
              {i18n.t(k.OK)}
            </Button>
          </DialogActions>
        </GenericStyleContainer>
      </Dialog>
      <StyledButton onClick={() => setShowLanguageSelectionDialog(true)}>
        <Icon icon={languages[currentLanguage].icon}></Icon>
        <Label>{languages[currentLanguage].label}</Label>
      </StyledButton>
    </>
  );
};

export default LanguageSelect;
