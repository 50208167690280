import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';
import LoadingState from '../../../../../../Shared/Components/LoadingState/LoadingState';
import breakpoints from '../../../../../../Shared/breakpoints';
import ContentCardContainer from '../../../../Components/ContentCardContainer';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 90%;
    max-width: 90%;
    min-height: 90%;
    max-height: 90%;
    .MuiDialogTitle-root {
      text-align: center;
    }
    .MuiTableRow-root {
      vertical-align: text-top;
      span,
      td,
      th {
        font-size: 14px !important;
      }
      .border-bottom-required {
        border-bottom: solid #00000025 1px;
        padding-bottom: 12px;
      }
      th {
        font-weight: bold;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .MuiTableContainer-root {
      display: none;
    }
  }
`;

const MobileViewContainer = styled.div`
  display: none;
  > div {
    max-width: 95%;
    width: 500px;
    padding: 9px;
  }
  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`;

const CardLine = styled.div`
  display: flex;
  text-align: left;
`;
const CardKey = styled.div`
  width: 150px;
`;
const CardValue = styled.div`
  margin-left: auto;
  font-weight: bold;
`;
const NoPresentsCreated = styled.p`
  text-align: center;
`;

const BackingOverviewDialog = ({ showBackingOverviewDialog, setShowBackingOverviewDialog }) => {
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const presents = weddingConfig.weddingContent.contentPages
    .find(page => page.pageType === 'PRESENT')
    .elements.find(element => element.elementType === 'presentElement').presents;
  const [presentsWithBackings, setPresentsWithBackings] = useState(presents);
  const [loadingBackingList, setLoadingBackingList] = useState(true);

  const loadAllBackings = async () => {
    setLoadingBackingList(true);
    const { data: result } = await axios.get(`/${weddingId}/wedding-backing-read/?read-all=true`);
    const newPresents = [...presentsWithBackings].map(present => {
      const backing = result.find(backing => backing.presentId === present.id);
      return {
        ...present,
        backing,
      };
    });
    setPresentsWithBackings(newPresents);
    setLoadingBackingList(false);
  };

  useEffect(() => {
    loadAllBackings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDialog open={showBackingOverviewDialog} onClose={() => setShowBackingOverviewDialog(false)}>
      <DialogTitle>{i18n.t(k.OVERVIEW_OF_ALL_PRESENTS)}</DialogTitle>
      <DialogContent>
        {loadingBackingList ? (
          <LoadingState message={i18n.t(k.LOADING_BACKINGS)}></LoadingState>
        ) : (
          <>
            {presentsWithBackings.length === 0 ? (
              <NoPresentsCreated>{i18n.t(k.NO_PRESENTS_YET)}</NoPresentsCreated>
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">#</TableCell>
                        <TableCell align="left">{i18n.t(k.PRESENT)}</TableCell>
                        <TableCell align="left">{i18n.t(k.E_MAIL_ADDRESS)}</TableCell>
                        <TableCell align="left">{i18n.t(k.DATE)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {presentsWithBackings.map((present, index) => (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align="right">{index + 1}</TableCell>
                          <TableCell align="left">{present.name}</TableCell>
                          <TableCell align="left">
                            {present.backing ? present.backing.username : i18n.t(k.NO_BACKINGS_YET)}
                          </TableCell>
                          <TableCell align="left">
                            {present.backing ? new Date(present.backing.submissionDate).toLocaleDateString() : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <MobileViewContainer>
                  {presentsWithBackings.map((present, index) => (
                    <ContentCardContainer key={index} hideEditingContainer>
                      <h3>
                        {i18n.t(k.PRESENT)} #{index + 1}
                      </h3>
                      <CardLine>
                        <CardKey>{i18n.t(k.PRESENT)}</CardKey>
                        <CardValue>{present.name}</CardValue>
                      </CardLine>
                      <CardLine>
                        <CardKey>{i18n.t(k.E_MAIL_ADDRESS)}</CardKey>
                        <CardValue>{present.backing ? present.backing.username : i18n.t(k.NO_BACKINGS_YET)}</CardValue>
                      </CardLine>
                      <CardLine>
                        <CardKey>{i18n.t(k.DATE)}</CardKey>
                        <CardValue>
                          {present.backing ? new Date(present.backing.submissionDate).toLocaleDateString() : ''}
                        </CardValue>
                      </CardLine>
                    </ContentCardContainer>
                  ))}
                </MobileViewContainer>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className="color-background-white">
        <Button variant="contained" color="primary" onClick={() => setShowBackingOverviewDialog(false)}>
          {i18n.t(k.CLOSE)}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default BackingOverviewDialog;
