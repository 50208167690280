import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 50px;
    margin: 21px;
  }
`;
