import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import styled from 'styled-components';

import IconBox from '../.../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../.../../../../Shared/Components/SelectionComponent/SelectionContainer';

import QuestionHeader from '../Components/QuestionHeader';
import OuterContainer from '../Components/OuterContainer';
import NextButton from '../Components/NextButton';
import ButtonContainer from '../Components/ButtonContainer';
import WeddingStyleSelectionDialog from '../../../Shared/Components/WeddingStyleSelectionDialog/WeddingStyleSelectionDialog';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import breakpoints from '../../../Shared/breakpoints';
import ShowAllStylesButton from '../../../Shared/Components/SelectionComponent/ShowAllStylesButton';
import { Badge, Hidden } from '@material-ui/core';

const Hint = styled.p`
  margin: -15px 6px 12px 6px;
  font-size: 13px !important;

  @media (max-width: ${breakpoints.xs}) {
    margin-top: 12px;
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 13px;
    right: 15px;
    min-width: 44px;
    font-size: 14px !important;
  }
`;

const WeddingStyle = ({ onSubmit, requestPreview, initialSelection }) => {
  const [style, setStyle] = useState(initialSelection);
  const [styleSelectionDialogOpen, setStyleSelectionDialogOpen] = useState(false);
  const [randomStyles, setRandomStyles] = useState([]);

  useEffect(() => {
    setStyle(initialSelection);
  }, [initialSelection]);

  const onSelect = async selection => {
    requestPreview(selection.value);
    setStyle(selection.value);
  };

  const submit = () => {
    ReactPixel.trackCustom('StyleFunnel', { style: style });
    onSubmit({
      value: style,
      question: 'weddingStyle',
    });
  };

  const onSelectWeddingStyle = selectedStyle => {
    if (randomStyles.indexOf(selectedStyle) === -1 && selectedStyle !== style) {
      randomStyles.pop();
      randomStyles.splice(0, 0, selectedStyle);
    }
    requestPreview(selectedStyle);
    setStyle(selectedStyle);
  };

  useEffect(() => {
    const randomized = Object.keys(defaultStyles)
      .filter(key => key !== 'eurehochzeitonline')
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);

    setRandomStyles(randomized);
  }, []);

  return (
    <OuterContainer>
      <QuestionHeader>{i18n.t(k.QUESTION_WEDDING_STYLE)}</QuestionHeader>
      <SelectionContainer>
        {randomStyles.map(key => {
          const title = i18n.t(k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_TITLE`]);
          return (
            <IconBox
              active={style === key}
              image={`/assets/wedding-styles/${key}/desktop-${key}.jpg`}
              text={title}
              question="weddingStyle"
              selectValue={key}
              onSelect={onSelect}
              key={key}
              weddingStyle={style}
              narrow
            />
          );
        })}
        <StyledBadge badgeContent={`+ ${Object.keys(defaultStyles).length - 1}`} color="primary">
          <ShowAllStylesButton
            color="secondary"
            variant="contained"
            onClick={() => setStyleSelectionDialogOpen(true)}
            weddingStyle={style}
          ></ShowAllStylesButton>
        </StyledBadge>
      </SelectionContainer>
      <Hint>
        {i18n.t(k[`NOT_SURE_ABOUT_DESIGN`])}
        <Hidden smDown>
          <br></br>
        </Hidden>
        {i18n.t(k[`OWN_PHOTOS_AFTER_REGISTRATION`])}
      </Hint>
      <ButtonContainer>
        <NextButton onClick={() => submit()} variant="contained">
          {i18n.t(k.NEXT)}
        </NextButton>
      </ButtonContainer>
      <WeddingStyleSelectionDialog
        open={styleSelectionDialogOpen}
        handleClose={() => setStyleSelectionDialogOpen(false)}
        weddingStyle={style}
        savedWeddingStyle={style}
        onSelect={style => onSelectWeddingStyle(style)}
      ></WeddingStyleSelectionDialog>
    </OuterContainer>
  );
};

export default WeddingStyle;
