import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../breakpoints';

import EditingContainerForBaseData from '../../../Pages/WeddingPage/Editing/Components/EditingContainer/EditingContainerForBaseData';
import BackgroundElementContainer from '../../Components/BackgroundElementContainer/BackgroundElementContainer';

import WeddingTitle from './Components/WeddingTitle';
import WeddingDate from './Components/WeddingDate';
import TopCenter from './Components/TopCenter';
import TopLeft from './Components/TopLeft';
import Heart from './Components/Heart';
import HorizontalLine from './Components/HorizontalLine';
import Overlay from './Components/Overlay';
import Container from './Components/Container';
import Border from './Components/Border';
import Countdown from '../Components/Countdown';
import StartPhoto from './Components/StartPhoto';

const BorderLeft = styled(Border)`
  z-index: 2000;
  left: -30px;
  transform: none;
  transition: none;
  opacity: 0;
  @media (min-width: ${breakpoints.md}) {
    opacity: 1;
  }
`;

const StyledTopLeft = styled(TopLeft)`
  left: 100px;
  @media (max-width: ${breakpoints.xs}) {
    left: 0;
    top: 50px;
  }
`;

const StyledTopCenter = styled(TopCenter)`
  margin-top: -200px !important;
  @media (max-width: ${breakpoints.xs}) {
    margin-left: 0;
  }
`;

const CustomLogo = styled.div`
  height: 250px;
  max-height: 50vh;
  width: 600px;
  max-width: 70vw;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const WeddingTitleInnerContainer = styled.div`
  position: relative;
  width: 600px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const VintageOnboardingContainer = ({ showStartPage, weddingConfig }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container visible>
      <Overlay />
      <BackgroundElementContainer>
        <BorderLeft></BorderLeft>
        <StyledTopLeft visible />
        {showStartPage && weddingConfig && (
          <StyledTopCenter visible>
            <Countdown weddingConfig={weddingConfig} weddingStyle={weddingConfig.weddingStyle}></Countdown>
            {weddingConfig.useCustomLogo ? (
              <WeddingTitleInnerContainer>
                {weddingConfig.useStartPhoto && <StartPhoto imageUrl={weddingConfig.startPhotoUrl}></StartPhoto>}
                <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
                <EditingContainerForBaseData></EditingContainerForBaseData>
              </WeddingTitleInnerContainer>
            ) : (
              <>
                <WeddingTitleInnerContainer>
                  {weddingConfig.useStartPhoto ? (
                    <StartPhoto imageUrl={weddingConfig.startPhotoUrl}></StartPhoto>
                  ) : (
                    <Heart />
                  )}
                  {nameOfBride && nameOfGroom && (
                    <>
                      <WeddingTitle>
                        {nameOfBride} &amp; {nameOfGroom}
                      </WeddingTitle>
                      <EditingContainerForBaseData>
                        <HorizontalLine />
                      </EditingContainerForBaseData>
                    </>
                  )}
                  <WeddingDate>{weddingDate}</WeddingDate>
                </WeddingTitleInnerContainer>
              </>
            )}
          </StyledTopCenter>
        )}
      </BackgroundElementContainer>
    </Container>
  );
};

export default VintageOnboardingContainer;
