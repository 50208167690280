import React, { useEffect } from 'react';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import ContentCardWithLargePhoto from '../../Components/ContentCardWithLargePhoto';
import { Helmet } from 'react-helmet';

const CouplePage = ({ content, scrollToTop, weddingId }) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Couple`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {content.elements.map((item, index) => {
          switch (item.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithTitle
                    title={item.title}
                    subtitle={item.subtitle}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'COUPLE', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'COUPLE' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.largePhotoItem.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithLargePhoto
                    title={item.title}
                    description={item.description}
                    imageUrl={item.imageUrl}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{
                      index: index,
                      contentPage: 'COUPLE',
                      elementType: elementTypes.largePhotoItem.name,
                    }}
                  ></ContentCardWithLargePhoto>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'COUPLE' }}></AddingContainer>
                </ElementContainer>
              );
            default:
              return null;
          }
        })}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default CouplePage;
