import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

export default styled.h3`
  margin: 0;
  width: 100%;
  font-size: 28px !important;
  color: #333 !important;
  @media (max-width: ${breakpoints.md}) {
    font-size: 21px !important;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: 18px !important;
  }
`;
