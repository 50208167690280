import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import boxShadows from '../../../../../Shared/boxShadows';

export default styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${({ styles }) => styles.textColor};
  box-shadow: ${boxShadows.z2};
  svg {
    transform: ${({ details }) => (details === 'true' ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    fill: ${({ styles }) => styles.backgroundColor};
    height: 15px;
    width: 15px;
  }
  :hover {
    background-color: ${({ styles }) => `${styles.textColor}dd`};
    svg {
      fill: ${({ styles }) => styles.backgroundColor};
    }
  }
`;
