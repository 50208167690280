export default [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'EG', label: 'Ägypten' },
  { value: 'AX', label: 'Ålandinseln' },
  { value: 'AL', label: 'Albanien' },
  { value: 'DZ', label: 'Algerien' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarktis' },
  { value: 'AG', label: 'Antigua und Barbuda' },
  { value: 'GQ', label: 'Äquatorialguinea' },
  { value: 'AR', label: 'Argentinien' },
  { value: 'AM', label: 'Armenien' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AC', label: 'Ascension' },
  { value: 'AZ', label: 'Aserbaidschan' },
  { value: 'ET', label: 'Äthiopien' },
  { value: 'AU', label: 'Australien' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesch' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgien' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivien' },
  { value: 'BA', label: 'Bosnien und Herzegowina' },
  { value: 'BW', label: 'Botsuana' },
  { value: 'BV', label: 'Bouvetinsel' },
  { value: 'BR', label: 'Brasilien' },
  { value: 'VG', label: 'Britische Jungferninseln' },
  { value: 'IO', label: 'Britisches Territorium im Indischen Ozean' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgarien' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CK', label: 'Cookinseln' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Côte d’Ivoire' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'DK', label: 'Dänemark' },
  { value: 'DE', label: 'Deutschland' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominikanische Republik' },
  { value: 'DJ', label: 'Dschibuti' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estland' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'FK', label: 'Falklandinseln' },
  { value: 'FO', label: 'Färöer' },
  { value: 'FJ', label: 'Fidschi' },
  { value: 'FI', label: 'Finnland' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'GF', label: 'Französisch-Guayana' },
  { value: 'PF', label: 'Französisch-Polynesien' },
  { value: 'TF', label: 'Französische Süd- und Antarktisgebiete' },
  { value: 'GA', label: 'Gabun' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgien' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GR', label: 'Griechenland' },
  { value: 'GL', label: 'Grönland' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HN', label: 'Honduras' },
  { value: 'IN', label: 'Indien' },
  { value: 'ID', label: 'Indonesien' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IE', label: 'Irland' },
  { value: 'IS', label: 'Island' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italien' },
  { value: 'JM', label: 'Jamaika' },
  { value: 'JP', label: 'Japan' },
  { value: 'YE', label: 'Jemen' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordanien' },
  { value: 'KY', label: 'Kaimaninseln' },
  { value: 'KH', label: 'Kambodscha' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CA', label: 'Kanada' },
  { value: 'BQ', label: 'Karibische Niederlande' },
  { value: 'KZ', label: 'Kasachstan' },
  { value: 'QA', label: 'Katar' },
  { value: 'KE', label: 'Kenia' },
  { value: 'KG', label: 'Kirgisistan' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'CO', label: 'Kolumbien' },
  { value: 'KM', label: 'Komoren' },
  { value: 'CG', label: 'Kongo-Brazzaville' },
  { value: 'CD', label: 'Kongo-Kinshasa' },
  { value: 'XK', label: 'Kosovo' },
  { value: 'HR', label: 'Kroatien' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'LA', label: 'Laos' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LV', label: 'Lettland' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libyen' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Litauen' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'MG', label: 'Madagaskar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Malediven' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MA', label: 'Marokko' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauretanien' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexiko' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolei' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mosambik' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NC', label: 'Neukaledonien' },
  { value: 'NZ', label: 'Neuseeland' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'MK', label: 'Nordmazedonien' },
  { value: 'NO', label: 'Norwegen' },
  { value: 'OM', label: 'Oman' },
  { value: 'AT', label: 'Österreich' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PS', label: 'Palästinensische Autonomiegebiete' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua-Neuguinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippinen' },
  { value: 'PN', label: 'Pitcairninseln' },
  { value: 'PL', label: 'Polen' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'MD', label: 'Republik Moldau' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'RO', label: 'Rumänien' },
  { value: 'RU', label: 'Russland' },
  { value: 'SB', label: 'Salomonen' },
  { value: 'ZM', label: 'Sambia' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'São Tomé und Príncipe' },
  { value: 'SA', label: 'Saudi-Arabien' },
  { value: 'SE', label: 'Schweden' },
  { value: 'CH', label: 'Schweiz' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbien' },
  { value: 'SC', label: 'Seychellen' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'ZW', label: 'Simbabwe' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SK', label: 'Slowakei' },
  { value: 'SI', label: 'Slowenien' },
  { value: 'SO', label: 'Somalia' },
  { value: 'HK', label: 'Sonderverwaltungsregion Hongkong' },
  { value: 'MO', label: 'Sonderverwaltungsregion Macau' },
  { value: 'ES', label: 'Spanien' },
  { value: 'SJ', label: 'Spitzbergen und Jan Mayen' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'BL', label: 'St. Barthélemy' },
  { value: 'SH', label: 'St. Helena' },
  { value: 'KN', label: 'St. Kitts und Nevis' },
  { value: 'LC', label: 'St. Lucia' },
  { value: 'MF', label: 'St. Martin' },
  { value: 'PM', label: 'St. Pierre und Miquelon' },
  { value: 'VC', label: 'St. Vincent und die Grenadinen' },
  { value: 'ZA', label: 'Südafrika' },
  { value: 'SD', label: 'Sudan' },
  { value: 'GS', label: 'Südgeorgien und die Südlichen Sandwichinseln' },
  { value: 'KR', label: 'Südkorea' },
  { value: 'SS', label: 'Südsudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'TJ', label: 'Tadschikistan' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tansania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad und Tobago' },
  { value: 'TA', label: 'Tristan da Cunha' },
  { value: 'TD', label: 'Tschad' },
  { value: 'CZ', label: 'Tschechien' },
  { value: 'TN', label: 'Tunesien' },
  { value: 'TR', label: 'Türkei' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks- und Caicosinseln' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'ZZ', label: 'Unbekannte Region' },
  { value: 'HU', label: 'Ungarn' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Usbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatikanstadt' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'AE', label: 'Vereinigte Arabische Emirate' },
  { value: 'US', label: 'Vereinigte Staaten' },
  { value: 'GB', label: 'Vereinigtes Königreich' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'WF', label: 'Wallis und Futuna' },
  { value: 'EH', label: 'Westsahara' },
  { value: 'CF', label: 'Zentralafrikanische Republik' },
  { value: 'CY', label: 'Zypern' },
];
