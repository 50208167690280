import i18n from 'i18next';
import k from './../../i18n/keys';
import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import WebFont from 'webfontloader';

import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import ModernOnboardingContainer from '../../Shared/WeddingStyles/Modern/ModernOnboardingContainer';
import VintageOnboardingContainer from '../../Shared/WeddingStyles/Vintage/VintageOnboardingContainer';
import FlowerOnboardingContainer from '../../Shared/WeddingStyles/Flower/FlowerOnboardingContainer';
import KlassischOnboardingContainer from '../../Shared/WeddingStyles/Klassisch/KlassischOnboardingContainer';
import getSearchParamsAsObject from '../../Shared/FunctionUtils/getSearchParamsAsObject';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import FidiraDialog from '../../Shared/Components/Dialog/FidiraDialog';
import axios from 'axios';
import breakpoints from '../../Shared/breakpoints';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import OnboardingStyleContainer from '../../Shared/Components/OnboardingStyleContainer/OnboardingStyleContainer';
import getWeddingsOfUserAsHost from '../../Shared/FunctionUtils/getWeddingsOfUserAsHost';
import UserContext from '../../Shared/Context/UserContext';
import isProduction from '../../Shared/FunctionUtils/isProduction';

import WeddingStyle from './Questions/WeddingStyle';
import Stepper from './Components/Stepper';
import UserIsBride from './Questions/UserIsBride';
import NameOfUser from './Questions/NameOfUser';
import NameOfPartner from './Questions/NameOfPartner';
import WeddingDate from './Questions/WeddingDate';
import WeddingLocation from './Questions/WeddingLocation';
import Register from './Questions/Register';
import PreOrderHint from './Components/PreOrderHint';
import EnterPasswordDialog from './Questions/EnterPasswordDialog';
import getFidiraUserByUsername from '../../Shared/FunctionUtils/getFidiraUserByUsername';
import LanguageContext from '../../Shared/Context/LanguageContext';
import BohoOnboardingContainer from '../../Shared/WeddingStyles/Boho/BohoOnboardingContainer';
import BlossomOnboardingContainer from '../../Shared/WeddingStyles/Blossom/BlossomOnboardingContainer';

const GlobalStyle = createGlobalStyle`
  #root {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
`;

const getRequiredFonts = () => {
  const fonts = [];
  Object.entries(defaultStyles).forEach(style => {
    fonts.push(style[1].headerFont);
    fonts.push(style[1].navigationFont);
    fonts.push(style[1].bodyFont);
  });
  const uniqueFonts = [...new Set(fonts)];
  return uniqueFonts;
};

const SlideContainer = styled.div`
  position: fixed;
  top: 55vh;
  max-width: 100vw;
  z-index: 1;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(0, -200vh, 0)')};
  @media (max-width: ${breakpoints.xs}), (max-height: 900px) {
    top: 37vh;
  }
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    top: 50px;
  }
`;

const OnboardingPage = ({ cookiePreference, utmMedium, utmCampaign, utmContent, utmTerm, utmSource }) => {
  const history = useHistory();
  const { search } = useLocation();
  const match = useRouteMatch('/hochzeit-erstellen/:stepNumber');
  const { fidiraUser, setAuthenticatedUser } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  const currentStepFromUrl = Number.parseInt(match?.params?.stepNumber || 1);
  const [onboardingData, setOnboardingData] = useState({
    weddingStyle: 'modern',
    userIsBride: null,
    nameOfBride: null,
    nameOfGroom: null,
    weddingDate: null,
    dateIsExact: null,
    weddingLocation: null,
    currentStep: 1,
  });
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [weddingsOfUserAsHost, setWeddingsOfUserAsHost] = useState([]);
  const [showWeddingsOfUserAsHost, setShowWeddingsOfUserAsHost] = useState(true);
  const [showEnterPasswordDialog, setShowEnterPasswordDialog] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const onSubmit = ({ question, value }) => {
    const onboardingDataUpdate = {};
    onboardingDataUpdate[question] = value;
    setOnboardingData({ ...onboardingData, ...onboardingDataUpdate });
    history.push(`/hochzeit-erstellen/${onboardingData.currentStep + 1}${search}`);
  };
  const onBack = () => {
    history.push(`/hochzeit-erstellen/${onboardingData.currentStep - 1}${search}`);
  };
  const setWeddingDate = (weddingDate, isExact) => {
    const onboardingDataUpdate = {};
    onboardingDataUpdate.weddingDate = weddingDate;
    onboardingDataUpdate.dateIsExact = isExact;
    setOnboardingData({ ...onboardingData, ...onboardingDataUpdate });
    history.push(`/hochzeit-erstellen/${onboardingData.currentStep + 1}${search}`);
  };
  const setLocation = (location, isDefined) => {
    const onboardingDataUpdate = {};
    onboardingDataUpdate.weddingLocation = location;
    onboardingDataUpdate.locationIsDefined = isDefined;
    setOnboardingData({ ...onboardingData, ...onboardingDataUpdate });
    history.push(`/hochzeit-erstellen/${onboardingData.currentStep + 1}${search}`);
  };
  const requestPreview = style => {
    setOnboardingData({ ...onboardingData, weddingStyle: style });
  };
  const goToStep = step => {
    history.push(`/hochzeit-erstellen/${step}${search}`);
  };

  const saveWedding = async (username, password) => {
    const wedding = {
      weddingStyle: onboardingData.weddingStyle,
      userIsBride: onboardingData.userIsBride,
      nameOfBride: onboardingData.nameOfBride,
      nameOfGroom: onboardingData.nameOfGroom,
      weddingDate: onboardingData.weddingDate,
      dateIsExact: onboardingData.dateIsExact,
      weddingLocation: onboardingData.weddingLocation,
      weddingLocationIsDefined: onboardingData.locationIsDefined,
      username: username.toLowerCase(),
      utmMedium,
      utmCampaign,
      utmContent,
      utmTerm,
      utmSource,
      selectedLanguagesForWedding: [currentLanguage],
    };
    setSaveInProcess(true);
    const onboardingDataUpdate = {};
    onboardingDataUpdate.username = username;
    setOnboardingData({ ...onboardingData, ...onboardingDataUpdate });
    if (!password && fidiraUser && fidiraUser.username.toLowerCase() === username.toLowerCase()) {
      setSaveInProcess(false);
      setShowEnterPasswordDialog(true);
      return;
    }
    try {
      const createdWedding = await axios.post('wedding-create', wedding);
      ReactPixel.trackCustom('LeadRegistrationFunnel');
      ReactGA.event({
        category: `EHO_Event`,
        action: `Wedding_Created`,
      });
      ReactPixel.trackCustom('Wedding', wedding);
      // Repeat the sign in if user already existed. This ensures that the new wedding is in the JWT token.
      if (password) {
        const cognitoUser = await Auth.signIn(username.toLowerCase(), password);
        const fidiraUser = await getFidiraUserByUsername(onboardingData.username.toLowerCase());
        await setAuthenticatedUser(cognitoUser, fidiraUser);
      }
      window.location.href = `/${createdWedding.data.weddingId}`;
    } catch (error) {
      setSaveInProcess(false);
      if (error.response && error.response.status === 409 && error.response.data === 'Passwort eingeben') {
        setShowEnterPasswordDialog(true);
      } else {
        setSaveError(true);
        console.error(error);
      }
    }
  };

  const onSubmitPassword = async ({ password }) => {
    setLoadingLogin(true);
    const cognitoUser = await Auth.signIn(onboardingData.username.toLowerCase(), password);
    const fidiraUser = await getFidiraUserByUsername(onboardingData.username.toLowerCase());
    await setAuthenticatedUser(cognitoUser, fidiraUser);
    setLoadingLogin(false);
    setShowEnterPasswordDialog(false);
    await saveWedding(onboardingData.username, password);
  };

  const onCancelPassword = () => {
    setShowEnterPasswordDialog(false);
  };

  const weddingDate = onboardingData.weddingDate;

  useEffect(() => {
    if (cookiePreference === 'ALL' && isProduction()) {
      hotjar.initialize(1808603, 6);
    }
  }, [cookiePreference]);

  useEffect(() => {
    setOnboardingData(onboardingData => {
      return { ...onboardingData, currentStep: currentStepFromUrl };
    });
  }, [currentStepFromUrl]);

  useEffect(() => {
    ReactGA.event({
      category: `EHO_Visit`,
      action: `Onboarding-Page-${onboardingData.currentStep}`,
    });
  }, [onboardingData.currentStep]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: getRequiredFonts(),
      },
    });
    ReactPixel.trackCustom('StartRegistration');
    if (fidiraUser) {
      const weddingsOfUserAsHost = getWeddingsOfUserAsHost(fidiraUser.weddings);
      setWeddingsOfUserAsHost(weddingsOfUserAsHost);
    }
    const searchParams = getSearchParamsAsObject(window.location.search);
    if (currentStepFromUrl > 1) {
      history.replace(`/hochzeit-erstellen/1${search}`);
    }
    if (searchParams.style) {
      setOnboardingData(onboardingData => {
        return { ...onboardingData, weddingStyle: searchParams.style };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <GenericStyleContainer weddingStyleData={defaultStyles[onboardingData.weddingStyle]}>
        <OnboardingStyleContainer>
          <Helmet>
            <title>{`EureHochzeitOnline - Wedding Creation - ${onboardingData.currentStep}`}</title>
          </Helmet>
          <ModernOnboardingContainer
            visible={onboardingData.weddingStyle === 'modern'}
            nameOfBride={onboardingData.nameOfBride}
            nameOfGroom={onboardingData.nameOfGroom}
            weddingDate={weddingDate}
          />

          <VintageOnboardingContainer
            visible={onboardingData.weddingStyle === 'vintage'}
            nameOfBride={onboardingData.nameOfBride}
            nameOfGroom={onboardingData.nameOfGroom}
            weddingDate={weddingDate}
          />

          <FlowerOnboardingContainer
            visible={onboardingData.weddingStyle === 'flower'}
            nameOfBride={onboardingData.nameOfBride}
            nameOfGroom={onboardingData.nameOfGroom}
            weddingDate={weddingDate}
          />

          <KlassischOnboardingContainer
            visible={onboardingData.weddingStyle === 'klassisch'}
            nameOfBride={onboardingData.nameOfBride}
            nameOfGroom={onboardingData.nameOfGroom}
            weddingDate={weddingDate}
          />

          <BohoOnboardingContainer
            visible={onboardingData.weddingStyle === 'boho'}
            nameOfBride={onboardingData.nameOfBride}
            nameOfGroom={onboardingData.nameOfGroom}
            weddingDate={weddingDate}
          />

          <BlossomOnboardingContainer
            visible={onboardingData.weddingStyle === 'blossom'}
            nameOfBride={onboardingData.nameOfBride}
            nameOfGroom={onboardingData.nameOfGroom}
            weddingDate={weddingDate}
          />

          {onboardingData.currentStep === 7 && (
            <PreOrderHint weddingStyle={onboardingData.weddingStyle}>
              <h2>{i18n.t(k.REGISTER_NOW)} </h2>
              <span>{i18n.t(k.YOUR_FREE_WEDDING_WEBSITE)} </span>
            </PreOrderHint>
          )}

          <SlideContainer visible={onboardingData.currentStep === 1}>
            <WeddingStyle
              onSubmit={onSubmit}
              requestPreview={requestPreview}
              initialSelection={onboardingData.weddingStyle}
              visible={onboardingData.currentStep === 1}
            />
          </SlideContainer>
          <SlideContainer visible={onboardingData.currentStep === 2}>
            <UserIsBride
              onSubmit={onSubmit}
              onBack={onBack}
              weddingStyle={onboardingData.weddingStyle}
              visible={onboardingData.currentStep === 2}
            />
          </SlideContainer>
          <SlideContainer visible={onboardingData.currentStep === 3}>
            <NameOfUser
              userIsBride={onboardingData.userIsBride}
              submitToParent={onSubmit}
              onBack={onBack}
              visible={onboardingData.currentStep === 3}
            />
          </SlideContainer>
          <SlideContainer visible={onboardingData.currentStep === 4}>
            <NameOfPartner
              userIsBride={onboardingData.userIsBride}
              nameOfGroom={onboardingData.nameOfGroom}
              nameOfBride={onboardingData.nameOfBride}
              submitToParent={onSubmit}
              onBack={onBack}
              visible={onboardingData.currentStep === 4}
            />
          </SlideContainer>
          <SlideContainer visible={onboardingData.currentStep === 5}>
            <WeddingDate
              submitWeddingDate={setWeddingDate}
              onBack={onBack}
              weddingStyle={onboardingData.weddingStyle}
              visible={onboardingData.currentStep === 5}
            />
          </SlideContainer>
          <SlideContainer visible={onboardingData.currentStep === 6}>
            <WeddingLocation
              submitLocation={setLocation}
              onBack={onBack}
              weddingStyle={onboardingData.weddingStyle}
              visible={onboardingData.currentStep === 6}
            />
          </SlideContainer>
          <SlideContainer visible={onboardingData.currentStep === 7}>
            <Register
              visible={onboardingData.currentStep === 7}
              weddingStyle={onboardingData.weddingStyle}
              saveWedding={saveWedding}
              saveInProcess={saveInProcess}
            />
          </SlideContainer>
          <Stepper currentStep={onboardingData.currentStep} goToStep={goToStep} />
          <LegalFooter fixedBottom weddingStyle={onboardingData.weddingStyle}></LegalFooter>
          <FidiraDialog
            open={saveError}
            messageTitle={i18n.t(k.ERROR_GENERAL)}
            messageDescriptionText={i18n.t(k.COULD_NOT_SAVE_WEDDING)}
            handleClose={() => setSaveError(false)}
          ></FidiraDialog>
          <EnterPasswordDialog
            show={showEnterPasswordDialog}
            weddingStyle={onboardingData.weddingStyle}
            onSubmitPassword={onSubmitPassword}
            onCancelPassword={onCancelPassword}
            username={onboardingData.username}
            displayName={onboardingData.userIsBride ? onboardingData.nameOfBride : onboardingData.nameOfGroom}
            loadingLogin={loadingLogin}
          ></EnterPasswordDialog>
          {fidiraUser && (
            <FidiraDialog
              open={showWeddingsOfUserAsHost && weddingsOfUserAsHost.length > 0}
              isError={false}
              messageTitle={i18n.t(k.ALREADY_HAVE_WEDDING)}
              buttonText={i18n.t(k.CREATE_NEW)}
              messageDescriptionJsx={
                <>
                  <span>
                    {i18n.t(k.HELLO)} {fidiraUser.displayName}! {i18n.t(k.ALREADY_CREATED_WEDDINGS)}
                  </span>
                  <br></br>
                  <br></br>
                  {weddingsOfUserAsHost.map(wedding => (
                    <span key={wedding.w}>
                      <a href={`/${wedding.w}`}>
                        {i18n.t(k.WEDDING)} : {wedding.w} ({wedding.r === 'BRIDE' ? i18n.t(k.BRIDE) : i18n.t(k.GROOM)})
                      </a>
                      <br></br>
                    </span>
                  ))}
                </>
              }
              handleClose={() => setShowWeddingsOfUserAsHost(false)}
            ></FidiraDialog>
          )}
        </OnboardingStyleContainer>
      </GenericStyleContainer>
    </Router>
  );
};

export default OnboardingPage;
