import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.h1`
  margin: 0;
  font-size: 45px !important;
  text-transform: uppercase;
  font-family: ${defaultStyles.klassisch.headerFont} !important;
  color: ${defaultStyles.klassisch.colors.primary.base} !important;
  @media (max-height: 980px) {
    font-size: 39px !important;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    font-size: 24px !important;
  }
`;
