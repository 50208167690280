import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  top: -350px;
  transform: rotate(90deg);
  height: 900px;
  width: 1100px;
  right: 200px;
  background-image: url(${defaultStyles.flower.assets.element1});
  transform: ${({ visible }) => (visible ? 'rotate(90deg)' : 'rotate(90deg) translate3d(-500px, 0, 0)')};
  z-index: 1;
  @media (max-height: 980px) {
    top: -470px;
    right: -100px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 600px;
    top: -250px;
    left: -50px;
    width: 400px;
  }
`;
