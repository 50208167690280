import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import Form from '../Components/Form';
import TextField from '../Components/TextField';
import Button from '../Components/Button';
import ButtonContainer from '../Components/ButtonContainer';
import Subheader from '../Components/Subheader';

const Username = ({ visible, onNext, onBack }) => {
  const { handleSubmit, register, errors, setValue } = useForm({});
  const inputRef = useRef(null);

  useEffect(() => {
    if (visible && inputRef.current !== document.activeElement) {
      inputRef.current.focus();
    }
  }, [visible]);

  return (
    <Form visible={visible} onSubmit={handleSubmit(onNext)}>
      <Subheader>{i18n.t(k.ENTER_DISPLAY_NAME)}</Subheader>
      <RHFInput
        as={
          <TextField
            type="text"
            variant="filled"
            error={!!errors.displayName}
            helperText={errors.displayName ? i18n.t(k.ENTER_DISPLAY_NAME_SHORT) : ''}
            label={i18n.t(k.YOUR_NAME_OR_NICKNAME)}
            inputRef={inputRef}
          />
        }
        rules={{
          required: true,
        }}
        mode="onSubmit"
        name="displayName"
        register={register}
        setValue={setValue}
      ></RHFInput>
      <ButtonContainer>
        <Button onClick={onBack} color="secondary" variant="contained">
          {i18n.t(k.BACK)}
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {i18n.t(k.NEXT)}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default Username;
