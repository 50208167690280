import styled from 'styled-components';
import defaultStyles from '../../../WeddingStyles/DefaultStyles';
import { NavLink } from 'react-router-dom';

export default styled(NavLink)`
  font-size: 14px;
  outline: none;
  margin: 6px;
  cursor: pointer;
  color: ${({ weddingstyle }) => defaultStyles[weddingstyle].colors.primary.onWhite};
`;
