import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 300px;
  width: 300px;
  transform: scale(1.3);
  background-image: url(${defaultStyles.vintage.assets.heart});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 150px;
  }
`;
