import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import SectionContainer from '../Components/SectionContainer';
import SectionTextContainer from '../Components/SectionTextContainer';
import SectionTitle from '../Components/SectionTitle';
import SectionSubTitle from '../Components/SectionSubTitle';
import boxShadows from '../../../Shared/boxShadows';
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import breakpoints from '../../../Shared/breakpoints';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 900px;
  background-color: #fbeff1;
  padding-bottom: 50px;
  box-shadow: ${boxShadows.z2};
  margin-bottom: 12px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SideImage = styled.div`
  position: sticky;
  top: 110px;
  max-height: 85vh;
  background-image: url('/assets/landing-page/hochzeits-website-fragen-antworten.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 30%;
  margin: 25px auto 25px 0;
  box-shadow: ${boxShadows.z1};
  border-radius: 0 12px 12px 0;
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const ImageText = styled.div`
  background-color: #ba5a5e66;
  width: 70%;
  margin-right: auto;
  border-radius: 0 12px 12px 0;
  box-shadow: ${boxShadows.z2};
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
  h3 {
    padding: 6px 12px;
    font-weight: bold;
    margin-bottom: -6px;
    color: #ffd2d4;
  }
  p {
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    margin-top: -6px;
  }
  a {
    margin: 0;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    color: white;
    :hover {
      background-color: transparent;
    }
  }
`;

const FAQContainer = styled.div`
  width: 55%;
  margin: 25px 0 25px auto;
  background-color: #ffffffbf;
  background: #ffffffbf;
  padding: 12px;
  border-radius: 12px 0 0 12px;
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;
const Category = styled.div`
  .MuiAccordion-root:before {
    opacity: 0;
  }
`;
const CategoryTitle = styled.h3`
  padding: 12px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 0;
    margin: 24px 3px -10px 3px;
  }
`;
const QuestionContainer = styled(Accordion)`
  margin: 16px 0;
  border-radius: 12px 0 0 12px;
  box-shadow: ${boxShadows.z1};
  @media (max-width: ${breakpoints.sm}) {
    padding: 0;
    margin: 16px 0;
  }
`;
const Question = styled(AccordionSummary)`
  font-weight: bold !important;
  margin-top: 6px;
`;
const Answer = styled(AccordionDetails)`
  margin-top: 6px;
`;

const FAQSection = ({ search }) => {
  const faq = [
    {
      category: i18n.t(k.CONTENT_AND_DESIGN),
      questions: [
        {
          q: i18n.t(k.WHAT_CONTENT_TYPICALLY),
          a: i18n.t(k.WEDDING_CONTENT_INDIVIDUAL),
        },
        {
          q: i18n.t(k.CAN_CREATE_WEBSITE_BEFORE_DETAILS),
          a: i18n.t(k.YES_FEASIBLE_ADVANTAGES),
        },
        {
          q: i18n.t(k.CAN_CUSTOMIZE_DESIGN),
          a: i18n.t(k.YES_EUREHOCHZEITONLINE_OFFERS),
        },
        {
          q: i18n.t(k.CHANGES_VISIBLE_AFTER_SAVE),

          a: i18n.t(k.INDEED_CHANGES_VISIBLE),
        },
      ],
    },
    {
      category: i18n.t(k.PHOTO_GALLERY),
      questions: [
        {
          q: i18n.t(k.PHOTO_UPLOAD_LIMIT),
          a: i18n.t(k.IN_FREE_VERSION_LIMITED_PHOTOS),
        },
        {
          q: i18n.t(k.ORIGINAL_SIZE_PHOTO_STORAGE),
          a: i18n.t(k.WE_GENERATE_DIFFERENT_SIZES),
        },
        {
          q: i18n.t(k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS),
          a: i18n.t(k.YES_GUESTS_CAN_DOWNLOAD),
        },
        {
          q: i18n.t(k.USE_PHOTO_GALLERY_FOR_SLIDESHOW),
          a: i18n.t(k.YES_FEASIBLE_SCREEN_PROJECTOR),
        },
      ],
    },
    {
      category: i18n.t(k.RSVP_CARD),
      questions: [
        {
          q: i18n.t(k.HOW_DOES_R_S_V_P_WORK),
          a: i18n.t(k.R_S_V_P_FUNCTION_SIMPLIFIES),
        },
      ],
    },
    {
      category: i18n.t(k.PLAYLIST),
      questions: [
        {
          q: i18n.t(k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST),
          a: i18n.t(k.YES_YOUR_GUESTS_CAN_SUGGEST),
        },
        {
          q: i18n.t(k.CAN_DJ_ACCESS_PLAYLIST),
          a: i18n.t(k.NATURALLY_INVITE_DJ_TO_WEBSITE),
        },
      ],
    },
    {
      category: i18n.t(k.MULTILINGUAL_OWN_DOMAIN),
      questions: [
        {
          q: i18n.t(k.POSSIBILITY_OF_MULTIPLE_LANGUAGES),
          a: i18n.t(k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES),
        },
        {
          q: i18n.t(k.OWN_DOMAIN_POSSIBILITY),
          a: i18n.t(k.YES_YOU_CAN_HAVE_OWN_DOMAIN),
        },
      ],
    },
    {
      category: i18n.t(k.DURATION_PASSWORD_PROTECTION),
      questions: [
        {
          q: i18n.t(k.HOW_LONG_ACCESS_WEDDING_WEBSITE),
          a: i18n.t(k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS),
        },
        {
          q: i18n.t(k.PROTECT_SITE_WITH_PASSWORD),
          a: i18n.t(k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED),
        },
      ],
    },
    {
      category: i18n.t(k.COST_SUPPORT),
      questions: [
        {
          q: i18n.t(k.IS_EUREHOCHZEITONLINE_FREE),
          a: i18n.t(k.YES_CREATE_WEDDING_WEBSITE_FREE),
        },
        {
          q: i18n.t(k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE),
          a: i18n.t(k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP),
        },
      ],
    },
  ];

  return (
    <Container>
      <SectionContainer alignment="left" id="faq">
        <SectionTextContainer alignment="left">
          <SectionTitle>{i18n.t(k.FAQ)} </SectionTitle>
          <SectionSubTitle>{i18n.t(k.FREQUENTLY_ASKED_QUESTIONS)}</SectionSubTitle>
          <FlexContainer>
            <SideImage>
              <ImageText>
                <h3>{i18n.t(k.ANY_MORE_QUESTIONS_OR_FEEDBACK)}</h3>
                <p>
                  {i18n.t(k.WE_LOOK_FORWARD_TO_IT)}{' '}
                  <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
                </p>
              </ImageText>
            </SideImage>
            <FAQContainer>
              {faq.map(category => (
                <Category key={category.category}>
                  <CategoryTitle>{category.category}</CategoryTitle>
                  {category.questions.map((question, i) => (
                    <QuestionContainer key={i}>
                      <Question expandIcon={<ExpandMoreIcon />}>{question.q}</Question>
                      <Answer>{question.a}</Answer>
                    </QuestionContainer>
                  ))}
                </Category>
              ))}
            </FAQContainer>
          </FlexContainer>
          <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
            <Button color="primary" variant="contained">
              {i18n.t(k.CREATE_WEDDING_WEBSITE_NOW)}
            </Button>
          </NavLink>
        </SectionTextContainer>
      </SectionContainer>
    </Container>
  );
};

export default FAQSection;
