import styled from 'styled-components';

export default styled.div`
  margin: auto;
  width: 700px;
  max-width: 90vw;
  height: 100%;
  border-left: #e87076 solid 4px;
  padding-left: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
