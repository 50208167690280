export default {
  complete: {
    price: 4900,
    label: 'Komplett',
  },
  premium: {
    price: 7900,
    label: 'Premium',
  },
  vip: {
    price: 39900,
    label: 'VIP',
  },
};
