import styled from 'styled-components';
import defaultStyles from '../../../WeddingStyles/DefaultStyles';

export default styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 7px;
  right: -35px;
  transform: rotate(-17deg);
  fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite};
`;
