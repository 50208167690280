import styled from 'styled-components';

export default styled.div`
  position: fixed;
  bottom: 90px;
  right: 10px;
  left: 10px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  z-index: ${({ visible }) => (visible ? '2' : '0')};
  align-items: center;
  justify-content: center;
`;
