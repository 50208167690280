import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

import Icon from './Components/Icon';

const StyledDialogTitle = styled(DialogTitle)`
  h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  p {
    margin-top: 0;
  }
`;

const FidiraDialog = ({
  isError = true,
  open,
  messageTitle = i18n.t(k.ERROR_GENERAL),
  messageDescriptionText,
  messageDescriptionJsx,
  buttonText = i18n.t(k.OK),
  handleClose,
  suppressDefaultErrorText,
}) => {
  const onClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={messageTitle}
      aria-describedby={messageDescriptionText}
      disableEscapeKeyDown
    >
      <StyledDialogTitle>
        <Icon isError={isError}></Icon>
        <span>{messageTitle}</span>
      </StyledDialogTitle>

      <StyledDialogContent>
        {messageDescriptionText && <p>{messageDescriptionText}</p>}
        {messageDescriptionJsx && <p>{messageDescriptionJsx}</p>}
        {!suppressDefaultErrorText && isError && (
          <p>
            {i18n.t(k.SORRY_WRITE_MAIL)} <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
          </p>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FidiraDialog;
