import styled from 'styled-components';
import breakpoints from '../../../breakpoints';

export default styled.div`
  width: 450px;
  max-width: 100%;
  min-height: 320px;
  text-align: center;
  position: relative;
  overflow: hidden;
  @media (max-width: ${breakpoints.xs}) {
    min-height: 270px;
  }
`;
