import i18n from 'i18next';
import k from './../../i18n/keys';
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { TextField, LinearProgress, Button } from '@material-ui/core';
import axios from 'axios';

import UserContext from '../../Shared/Context/UserContext';
import getSearchParamsAsObject from '../../Shared/FunctionUtils/getSearchParamsAsObject';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import LogoLarge from '../../Shared/Components/EureHochzeitOnlineLogo/LogoLarge';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import FidiraDialog from '../../Shared/Components/Dialog/FidiraDialog';
import OuterContainer from '../../Shared/Components/EureHochzeitOnline/OuterContainer';
import InnerContainer from '../../Shared/Components/EureHochzeitOnline/InnerContainer';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';

const StyledTextField = styled(TextField)`
  width: 300px;
  max-width: 70vw;
`;
const StyledLinearProgress = styled(LinearProgress)`
  width: 300px;
  max-width: 70vw;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
`;
const SavePasswordButton = styled(Button)`
  margin-top: 12px;
`;
const FormContainer = styled.form`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
  p {
    width: 300px;
    max-width: 70vw;
  }
`;

const ChangePasswordPage = () => {
  const history = useHistory();
  const { setAuthenticatedUser } = useContext(UserContext);
  const [dataFromUrl, setDataFromUrl] = useState(null);
  const [password, setPassword] = useState(null);
  const [loadingDecryptValues, setLoadingDecryptValues] = useState(true);
  const [loadingPasswordReset, setLoadingPasswordReset] = useState(false);
  const [errorDecryptValues, setErrorDecryptValues] = useState(false);
  const [errorPasswordReset, setErrorPasswordReset] = useState(false);
  const [successPasswordReset, setSuccessPasswordReset] = useState(false);
  const [specificErrorPasswordReset, setSpecificErrorPasswordReset] = useState(false);
  const { handleSubmit, register, errors, setValue } = useForm({});
  const inputRef = useRef(null);

  const onSubmit = async ({ password }) => {
    setLoadingPasswordReset(true);
    try {
      await Auth.forgotPasswordSubmit(dataFromUrl.username, dataFromUrl.key, password);
      setPassword(password);
      setLoadingPasswordReset(false);
      setSuccessPasswordReset(true);
    } catch (error) {
      if (error.code === 'LimitExceededException') {
        setSpecificErrorPasswordReset(i18n.t(k.LIMIT_EXCEEDED_PASSWORD_RESET));
      } else if (error.code === 'ExpiredCodeException') {
        setSpecificErrorPasswordReset(i18n.t(k.EXPIRED_CODE_PASSWORD_RESET));
      }
      setLoadingPasswordReset(false);
      setErrorPasswordReset(true);
    }
  };

  const handleCloseSuccessMessage = async () => {
    const cognitoUser = await Auth.signIn(dataFromUrl.username, password);
    const fidiraUser = await axios.get(`user-read/`, { headers: { 'data-username': dataFromUrl.username } });
    setAuthenticatedUser(cognitoUser, fidiraUser.data);
    if (dataFromUrl.wedding) {
      history.push(`/${dataFromUrl.wedding}/`);
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    if (!loadingDecryptValues && inputRef.current !== document.activeElement) {
      inputRef.current.focus();
    }
  }, [loadingDecryptValues]);

  useEffect(() => {
    const decryptData = async () => {
      try {
        setLoadingDecryptValues(true);
        const weddingData = getSearchParamsAsObject(window.location.search);
        const arrayOfStringsToBeDecrypted = [weddingData.name, weddingData.info];
        if (weddingData.wedding) {
          arrayOfStringsToBeDecrypted.push(weddingData.wedding);
        }
        const decryptedDataFromUrl = await axios.post(`util-decrypt/`, {
          arrayOfStringsToBeDecrypted: arrayOfStringsToBeDecrypted,
        });
        setDataFromUrl({
          name: decryptedDataFromUrl.data.decrypted[0],
          username: decryptedDataFromUrl.data.decrypted[1],
          wedding: decryptedDataFromUrl.data.decrypted[2],
          key: weddingData.ckey,
        });
        setLoadingDecryptValues(false);
      } catch (error) {
        setErrorDecryptValues(true);
        setLoadingDecryptValues(false);
      }
    };
    decryptData();
  }, []);

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <LanguageSelect></LanguageSelect>
        <InnerContainer>
          {loadingDecryptValues && <StyledLinearProgress visible={loadingDecryptValues.toString()} />}
          <FormContainer visible={(!loadingDecryptValues).toString()} onSubmit={handleSubmit(onSubmit)}>
            <LogoLarge></LogoLarge>
            <div>
              <strong>
                {i18n.t(k.HELLO)} {dataFromUrl ? dataFromUrl.name : ''}
                {i18n.t(k._)}
              </strong>
              <p>{i18n.t(k.ENTER_NEW_PASSWORD)}</p>
            </div>
            <StyledTextField
              style={{ display: 'none' }}
              type="email"
              variant="filled"
              name="username"
              label={i18n.t(k.E_MAIL_ADDRESS)}
              autoComplete="username"
            />

            <RHFInput
              as={
                <StyledTextField
                  type="password"
                  variant="filled"
                  error={!!errors.password}
                  helperText={errors.password ? i18n.t(k.MINIMUM_6_CHARACTERS) : ''}
                  label={i18n.t(k.SET_A_PASSWORD_SHORT)}
                  autoComplete="new-password"
                  inputRef={inputRef}
                />
              }
              rules={{
                required: true,
                minLength: 6,
              }}
              mode="onSubmit"
              name="password"
              register={register}
              setValue={setValue}
            ></RHFInput>
            <StyledLinearProgress visible={loadingPasswordReset.toString()} />
            <SavePasswordButton type="submit" variant="contained">
              {i18n.t(k.SAVE)}
            </SavePasswordButton>
          </FormContainer>
        </InnerContainer>
      </OuterContainer>
      <FidiraDialog
        open={errorDecryptValues}
        messageDescriptionText={i18n.t(k.COULD_NOT_LOAD_YOUR_DATA)}
        handleClose={() => {
          window.location.reload();
        }}
      ></FidiraDialog>
      <FidiraDialog
        open={errorPasswordReset}
        messageDescriptionText={
          specificErrorPasswordReset ? specificErrorPasswordReset : i18n.t(k.UNABLE_TO_RESET_PASSWORD)
        }
        handleClose={() => {
          setErrorPasswordReset(false);
          setSpecificErrorPasswordReset('');
        }}
      ></FidiraDialog>
      <FidiraDialog
        isError={false}
        open={successPasswordReset}
        messageTitle={i18n.t(k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE)}
        messageDescriptionJsx={<span>{i18n.t(k.PASSWORD_CHANGED_SUCCESSFULLY)}</span>}
        handleClose={handleCloseSuccessMessage}
      ></FidiraDialog>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default ChangePasswordPage;
