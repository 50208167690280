import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import boxShadows from '../../../../../../Shared/boxShadows';

import EmptyState from '../EmptyState/EmptyState';
import MusicSearch from '../Search/Search';
import MusicPlaylist from '../Playlist/Playlist';
import RefreshButton from '../RefreshButton';
import LoadingState from '../../../../../../Shared/Components/LoadingState/LoadingState';

const SearchArea = styled.div`
  overflow: hidden;
  background-color: #ffffffe0;
  border-radius: 0;
  border-top-left-radius: 12px;
  box-shadow: ${boxShadows.z2};
  border: none;
`;

const PlaylistContainer = styled.div`
  min-height: 250px;
  position: relative;
  box-shadow: ${boxShadows.z2};
  padding: 12px;
  border-top: solid;
  border-width: 3px;
`;
const PlaylistInnerContainer = styled.div`
  width: 500px;
  max-width: 80vw;
  position: relative;
  margin: auto;
`;

const Container = ({
  weddingId,
  playlistId,
  nameOfBride,
  nameOfGroom,
  creatorIsRegistered,
  refreshWeddingConfig,
  loadingWeddingConfig,
  weddingConfig,
}) => {
  const [spotifyAccessToken, setSpotifyAccessToken] = useState(null);
  const [tracksBeingDeleted, setTracksBeingDeleted] = useState([]);
  const [refreshingPlaylist, setRefreshingPlaylist] = useState(false);
  const [playlistData, setPlaylistData] = useState(null);

  const refreshSpotifyAccessToken = async () => {
    const getSpotifyAuthToken = async () => {
      const { data: spotifyToken } = await axios.get(`${weddingId}/spotify-auth-read/?refresh=true`);
      setSpotifyAccessToken(spotifyToken);
    };
    getSpotifyAuthToken();
  };

  const deleteTrack = async trackId => {
    setTracksBeingDeleted([...tracksBeingDeleted, trackId]);
    await axios.delete(`${weddingId}/music/${playlistId}/${trackId}/music-delete/`);
    setTracksBeingDeleted(tracksBeingDeleted.filter(track => track === trackId));
    await loadMusic();
  };

  const loadMusic = useCallback(async () => {
    if (!creatorIsRegistered) return;
    setRefreshingPlaylist(true);
    if (weddingId) {
      const { data: music } = await axios.get(`${weddingId}/music/${playlistId}/music-read/`);
      setPlaylistData(music);
    }
    setRefreshingPlaylist(false);
  }, [weddingId, playlistId, creatorIsRegistered]);

  useEffect(() => {
    const getSpotifyAuthToken = async () => {
      const { data: spotifyToken } = await axios.get(`${weddingId}/spotify-auth-read/?refresh=false`);
      setSpotifyAccessToken(spotifyToken);
    };
    getSpotifyAuthToken();
    loadMusic();
  }, [weddingId, loadMusic, playlistId]);
  return (
    <>
      <SearchArea className="color-background-white">
        <MusicSearch
          playlistId={playlistId}
          spotifyAccessToken={spotifyAccessToken}
          refreshSpotifyAccessToken={refreshSpotifyAccessToken}
          weddingId={weddingId}
          weddingConfig={weddingConfig}
          trackCount={playlistData?.tracks.length}
          triggerPlaylistRefresh={loadMusic}
          nameOfBride={nameOfBride}
          nameOfGroom={nameOfGroom}
          creatorIsRegistered={creatorIsRegistered}
          refreshWeddingConfig={refreshWeddingConfig}
        ></MusicSearch>

        <PlaylistContainer>
          <RefreshButton
            refreshing={refreshingPlaylist || loadingWeddingConfig}
            startRefresh={loadMusic}
          ></RefreshButton>
          <PlaylistInnerContainer>
            {refreshingPlaylist && !playlistData && <LoadingState message="Songs werden geladen..." />}
            {(!playlistData || (playlistData && playlistData.tracks.length === 0)) && !refreshingPlaylist && (
              <EmptyState />
            )}
            {playlistId && playlistData && playlistData.tracks.length > 0 && (
              <MusicPlaylist
                tracksBeingDeleted={tracksBeingDeleted}
                playlistId={playlistId}
                playlistData={playlistData}
                triggerPlaylistRefresh={loadMusic}
                refreshingPlaylist={refreshingPlaylist}
                deleteTrack={deleteTrack}
              ></MusicPlaylist>
            )}
          </PlaylistInnerContainer>
        </PlaylistContainer>
      </SearchArea>
    </>
  );
};

export default Container;
