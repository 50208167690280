import getRoleOfUserInWedding from './getRoleOfUserInWedding';

const hasUserWritePermissionForWedding = (user, requestedWedding) => {
  if (user && user.weddings) {
    const role = getRoleOfUserInWedding(user.weddings, requestedWedding);
    return role === 'BRIDE' || role === 'GROOM';
  } else {
    return false;
  }
};

export default hasUserWritePermissionForWedding;
