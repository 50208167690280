import styled from 'styled-components';

export default styled.div`
  width: 30%;
  min-width: 105px;
  text-align: center;
  padding-left: 12px;
  max-width: 100%;
  margin: 12px auto;
  > label {
    margin-left: -24px;
  }
`;
