import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import { ReactComponent as HeartFilledSvg } from '../../../../../../_assets/svg/heart-filled.svg';
import { ReactComponent as HeartOutlineSvg } from '../../../../../../_assets/svg/heart-outline.svg';

import LikeContainer from '../CommonComponents/LikeContainer';
import NumberOfLikes from '../CommonComponents/NumberOfLikes';
import UploaderNameContainer from '../CommonComponents/UploaderNameContainer';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 12px;
  .uploader {
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  :hover {
    .uploader {
      opacity: 1;
    }
  }
`;

const ControlContainer = styled.div`
  position: absolute;
  bottom: 3px;
  right: 3px;
  height: 60px;
  width: 60px;
`;

const StyledIconButton = styled(IconButton)`
  background-color: transparent !important;
  position: absolute;
  bottom: 0;
  right: 0;
  svg {
    height: 35px;
    width: 35px;
  }
`;

const Image = styled.div`
  background: ${({ src }) => `url(${src})`};
  position: relative;
  width: 100%;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  cursor: pointer;
  box-sizing: border-box;
`;

const ImageComponent = ({ index, photo, margin, addLike, removeLike, onFullscreen }) => {
  return (
    <Container key={index} style={{ margin }} onClick={() => onFullscreen(index)}>
      <Image alt={photo.title} src={photo.src} />
      <UploaderNameContainer className="uploader">{photo.creatorDisplayName}</UploaderNameContainer>
      <ControlContainer>
        <LikeContainer
          onClick={e => {
            if (photo.likedByUser) {
              removeLike(photo.id);
            } else {
              addLike(photo.id);
            }
            e.stopPropagation();
          }}
        >
          {photo.likedByUser && (
            <StyledIconButton>
              <HeartFilledSvg></HeartFilledSvg>
            </StyledIconButton>
          )}
          {!photo.likedByUser && (
            <StyledIconButton>
              <HeartOutlineSvg></HeartOutlineSvg>
            </StyledIconButton>
          )}
          <NumberOfLikes>{photo.numberOfLikes || ''}</NumberOfLikes>
        </LikeContainer>
      </ControlContainer>
    </Container>
  );
};

export default ImageComponent;
