import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ChurchSvg } from '../../../../../../_assets/svg/map-markers/church.svg';
import { ReactComponent as DinnerSvg } from '../../../../../../_assets/svg/map-markers/dinner.svg';
import { ReactComponent as CoupleBalloonSvg } from '../../../../../../_assets/svg/map-markers/couple-balloon.svg';
import { ReactComponent as CoupleCakeSvg } from '../../../../../../_assets/svg/map-markers/couple-cake.svg';
import { ReactComponent as CityHallSvg } from '../../../../../../_assets/svg/map-markers/city-hall.svg';
import { ReactComponent as ParkingSvg } from '../../../../../../_assets/svg/map-markers/parking.svg';
import { ReactComponent as HotelSvg } from '../../../../../../_assets/svg/map-markers/hotel.svg';
import { ReactComponent as CarRentalSvg } from '../../../../../../_assets/svg/map-markers/car-rental.svg';

const OuterContainer = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 9px auto;
`;
const Headline = styled.span`
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 9px;
`;

const IconContainer = styled.div`
  width: 10%;
  padding: 6px;
  margin-right: 12px;
  background-color: ${({ selected }) => (selected ? '#e8e8e8' : 'transparent')};
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? '#333333 !important' : 'white')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;

const IconSelector = ({ selectedIcon, onSelectIcon, contentPage }) => {
  return (
    <OuterContainer>
      <Headline>{i18n.t(k.SELECT_ICON_FOR_LOCATION)}</Headline>
      <Container>
        {contentPage === 'LOCATION' && (
          <>
            <IconContainer
              selected={selectedIcon === 'couple-balloon'}
              onClick={() => {
                onSelectIcon('couple-balloon');
              }}
            >
              <CoupleBalloonSvg></CoupleBalloonSvg>
            </IconContainer>
            <IconContainer
              selected={selectedIcon === 'dinner'}
              onClick={() => {
                onSelectIcon('dinner');
              }}
            >
              <DinnerSvg></DinnerSvg>
            </IconContainer>
            <IconContainer
              selected={selectedIcon === 'church'}
              onClick={() => {
                onSelectIcon('church');
              }}
            >
              <ChurchSvg></ChurchSvg>
            </IconContainer>
            <IconContainer
              selected={selectedIcon === 'couple-cake'}
              onClick={() => {
                onSelectIcon('couple-cake');
              }}
            >
              <CoupleCakeSvg></CoupleCakeSvg>
            </IconContainer>
            <IconContainer
              selected={selectedIcon === 'city-hall'}
              onClick={() => {
                onSelectIcon('city-hall');
              }}
            >
              <CityHallSvg></CityHallSvg>
            </IconContainer>
          </>
        )}

        {contentPage === 'HOTEL' && (
          <>
            <IconContainer
              selected={selectedIcon === 'hotel'}
              onClick={() => {
                onSelectIcon('hotel');
              }}
            >
              <HotelSvg></HotelSvg>
            </IconContainer>
            <IconContainer
              selected={selectedIcon === 'parking'}
              onClick={() => {
                onSelectIcon('parking');
              }}
            >
              <ParkingSvg></ParkingSvg>
            </IconContainer>
            <IconContainer
              selected={selectedIcon === 'car-rental'}
              onClick={() => {
                onSelectIcon('car-rental');
              }}
            >
              <CarRentalSvg></CarRentalSvg>
            </IconContainer>
          </>
        )}
      </Container>
    </OuterContainer>
  );
};

export default IconSelector;
