import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

const HeroImage = styled.div`
  position: relative;
  background-image: url('/assets/landing-page/hero-landing-page-2.jpg');
  filter: brightness(1.2);
  width: 100%;
  height: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: ${breakpoints.md}) {
    height: 750px;
  }
  @media (max-width: ${breakpoints.xs}) {
    background-size: 450%;
    background-repeat: no-repeat;
    background-position: 50% -275px;
    height: 900px;
    background-color: black;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 1500px;
  max-width: 95vw;
  margin: auto;
  z-index: 1;
  padding: 6px;
  box-sizing: border-box;
`;

const BannerText = styled.h1`
  width: 500px;
  background-color: #9a3241;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 0 12px 0 0;
  margin-bottom: 0;
  color: white !important;
  @media (max-width: ${breakpoints.md}) {
    font-size: 21px !important;
    padding: 9px;
    width: 400px;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: 16px !important;
    padding: 6px;
    width: 90vw;
    max-width: 400px;
  }
`;

const HeroText = styled.h2`
  color: white !important;
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px !important;
  }
`;

const HeroSubText = styled.ul`
  color: white !important;
  font-size: 18px !important;
  text-align: left;
  margin-top: 0;
  width: 85%;
  li {
    margin: 12px 0;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 15px !important;
  }
`;

const Gradient = styled.div`
  background-image: linear-gradient(#00000000, #000000dd);
  position: absolute;
  bottom: 0;
  height: 500px;
  left: 0;
  right: 0;
`;

const InnerTextContainer = styled.div`
  width: 500px;
  background-color: #000000aa;
  padding: 16px;
  border-radius: 0 0 12px 0;
  box-sizing: border-box;
  button {
    background-color: #9a3241;
    :hover {
      background-color: #9a3241aa;
    }
  }
  button,
  a {
    width: 300px;
    max-width: 100%;
    > span {
      color: white !important;
    }
    &.MuiButton-containedSecondary {
      background-color: #ffffff55;
      :hover {
        background-color: #ffffff22;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    width: 400px;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 90vw;
    max-width: 400px;
  }
`;

const StyledButton = styled(Button)`
  margin: 18px;
  margin-top: 0;
`;

const HeroSection = ({ search }) => {
  return (
    <HeroImage>
      <TextContainer>
        <BannerText>
          {i18n.t(k.YOUR_WEDDING_WEBSITE)} <br></br>
          {i18n.t(k.UNIQUE_LIKE_YOU)}
        </BannerText>
        <InnerTextContainer>
          <HeroText>{i18n.t(k.EASY_SETUP_CUSTOMIZED)}</HeroText>
          <HeroSubText>
            <li>{i18n.t(k.ALL_DETAILS_OF_YOUR_CELEBRATION)}</li>
            <li>{i18n.t(k.GUEST_FEEDBACK_AND_WISHES)}</li>
            <li>{i18n.t(k.YOUR_CELEBRATION_FROM_ALL_VIEWS)}</li>
            <li>{i18n.t(k.MULTILINGUAL_SUPPORT)}</li>
          </HeroSubText>
          <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
            <StyledButton color="primary" variant="contained">
              {i18n.t(k.FREE_CREATE)}
            </StyledButton>
          </NavLink>
          <NavLink exact to={{ pathname: `/demo` }}>
            <StyledButton color="secondary" variant="contained">
              {i18n.t(k.DEMO_WEBSITE)}
            </StyledButton>
          </NavLink>
        </InnerTextContainer>
      </TextContainer>
      <Gradient></Gradient>
    </HeroImage>
  );
};

export default HeroSection;
