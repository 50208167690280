import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ReactGA from 'react-ga4';

import UserContext from '../../../../../Shared/Context/UserContext';
import EditingContext from '../../../../../Shared/Context/EditingContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import boxShadows from '../../../../../Shared/boxShadows';
import { ReactComponent as PlusSvg } from '../../../../../_assets/svg/plus.svg';

import LocationSearchDialog from '../EditingDialogs/EditingDialogLocationItem/LocationSearchDialog';
import getContentPage from '../../Functions/getContentPage';
import abortDueToMissingRegistration from '../../Functions/abortDueToMissingRegistration';

import OuterContainer from './Components/OuterContainer';
import InnerContainer from './Components/InnerContainer';
import { CircularProgress, Button } from '@material-ui/core';
import elementTypes from '../../Constants/elementTypes';
import LanguageContext from '../../../../../Shared/Context/LanguageContext';

const StyledOuterContainer = styled(OuterContainer)`
  position: relative;
  bottom: -20px;
`;

const StyledButton = styled(Button)`
  margin: 4px;
  box-shadow: ${boxShadows.z1};
  max-width: 150px;
  svg {
    height: 30px;
    width: 30px;
    margin-right: 6px;
  }
`;

const ButtonContainer = styled.div`
  background-color: #ffffffc4;
  border-radius: 4px;
`;

const AddingContainer = ({ contentPageName }) => {
  const { editingModeActive, setWeddingContent, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const [showElementTypeSelection, setShowElementTypeSelection] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const { currentLanguage } = useContext(LanguageContext);

  const startAdding = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setShowElementTypeSelection(true);
  };

  const stopAdding = () => {
    setShowElementTypeSelection(false);
  };

  const saveElement = async element => {
    const elementType = elementTypes.locationItem;
    const languageProperty = {};
    elementType.i18nProperties.forEach(p => {
      languageProperty[p] = element[p];
      delete element[p];
    });
    languageProperty.lng = currentLanguage;
    const languageAdjustedElement = {
      ...element,
      i18n: [languageProperty],
    };
    setLoadingSave(true);
    setShowElementTypeSelection(false);
    const contentPage = getContentPage(weddingConfig, { contentPage: contentPageName });
    const locationMap = contentPage.elements.filter(item => item.elementType === 'locationMap')[0];
    locationMap.locations.splice(locationMap.locations.length, 0, languageAdjustedElement);
    const savedWeddingContent = await axios.patch(`wedding-update/`, {
      updateType: 'UPDATE_WEDDING_CONTENT',
      weddingId: weddingId,
      weddingContent: weddingConfig.weddingContent,
    });
    setLoadingSave(false);
    setWeddingContent(savedWeddingContent.data);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${contentPageName}`,
    });
  };

  return (
    <>
      {editingModeActive && (
        <>
          <StyledOuterContainer>
            <InnerContainer>
              <ButtonContainer className="color-background-white">
                <StyledButton disabled={loadingSave} color="primary" variant="contained" onClick={startAdding}>
                  {loadingSave ? (
                    <CircularProgress size={30}></CircularProgress>
                  ) : (
                    <>
                      <PlusSvg></PlusSvg> {contentPageName}
                    </>
                  )}
                </StyledButton>
              </ButtonContainer>
            </InnerContainer>
          </StyledOuterContainer>
          {showElementTypeSelection && (
            <LocationSearchDialog
              stopAdding={stopAdding}
              saveElement={saveElement}
              contentPage={contentPageName}
            ></LocationSearchDialog>
          )}
        </>
      )}
    </>
  );
};

export default AddingContainer;
