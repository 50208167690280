import styled from 'styled-components';

import breakpoints from '../../../../../Shared/breakpoints';
import boxShadows from '../../../../../Shared/boxShadows';

export default styled.div`
  #wedding-map {
    border-top-left-radius: 12px;
    min-height: 600px;
    width: 475px;
    box-shadow: ${() => `${boxShadows.z2}`};
    border: ${({ backgroundColor }) => `solid 2px ${backgroundColor}`};
    @media (max-width: ${breakpoints.xs}) {
      width: 99%;
      min-height: 400px;
    }
    .gm-style-pbt {
      color: white;
    }
    .gm-style-cc span,
    .gm-style-cc a {
      color: black !important;
      font-weight: normal !important;
    }
  }
`;
