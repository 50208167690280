import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../../Shared/breakpoints';

import ContentCardContainer from './ContentCardContainer';

const InfoItem = styled.div`
  padding: 12px;
  border-bottom: solid 2px;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-around;
  align-items: stretch;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 12px;
  flex: 8;
`;
const Title = styled.h2`
  max-width: ${({ imageUrl }) => (imageUrl ? '80%' : '100%')};
  margin-bottom: 0;
  @media (max-width: ${breakpoints.xs}) {
    max-width: 100%;
  }
`;
const Description = styled.p`
  max-width: ${({ imageUrl }) => (imageUrl ? '80%' : '100%')};
  white-space: pre-wrap;
  @media (max-width: ${breakpoints.xs}) {
    max-width: 100%;
  }
`;
const ImageContainer = styled.div`
  flex: 4;
  min-width: 150px;
`;
const Image = styled.div`
  min-height: 250px;
  height: 100%;
  width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const mappingImagePositionToFlexDirection = {
  left: 'row',
  right: 'row-reverse',
  top: 'column',
  bottom: 'column-reverse',
};

const ContentCardWithInfoItem = ({
  title,
  description,
  imageUrl,
  editingKeys,
  imagePosition,
  hideEditingContainer,
  substituteLanguage,
}) => {
  return (
    <ContentCardContainer
      hideEditingContainer={hideEditingContainer}
      editingKeys={editingKeys}
      substituteLanguage={substituteLanguage}
      content={{ title: title, description: description, imageUrl: imageUrl, imagePosition: imagePosition }}
    >
      <InfoItem direction={mappingImagePositionToFlexDirection[imagePosition]}>
        {imageUrl && (
          <ImageContainer>
            <Image imageUrl={imageUrl}></Image>
          </ImageContainer>
        )}
        <TextContainer>
          {title && <Title imageUrl={imageUrl}>{title}</Title>}
          {description && <Description imageUrl={imageUrl}>{description}</Description>}
        </TextContainer>
      </InfoItem>
    </ContentCardContainer>
  );
};

export default ContentCardWithInfoItem;
