import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

const PreviewContainer = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 12px auto;
  display: flex;
`;

const PreviewImage = styled.div`
  width: 15%;
  height: 60px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 12px;
`;
const Progress = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Filename = styled.span`
  margin-top: 6px;
  font-size: 14px !important;
  text-align: left;
`;

const ErrorHandlingButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorHandlingButton = styled.button`
  cursor: pointer;
  background-color: transparent !important;
  border: none !important;
  text-decoration: underline;
  font-weight: bold;
  color: inherit !important;
  line-height: 1;
  margin: 0;
  padding: 0;
  outline: none;
`;

const UploadPreview = ({ fileWithMeta }) => {
  const { meta, restart, remove } = fileWithMeta;
  const { name, percent, previewUrl, status } = meta;
  let statusText;
  let bottomLine;
  switch (status) {
    case 'getting_upload_params':
    case 'preparing':
    case 'started':
    case 'ready':
    case 'headers_received':
    case 'restarted':
      statusText = i18n.t(k.UPLOAD_PREPARING);
      bottomLine = <Filename>{Math.round(percent)}%</Filename>;
      break;
    case 'uploading':
      statusText = i18n.t(k.UPLOAD_STARTED);
      bottomLine = <Filename>{Math.round(percent)}%</Filename>;
      break;
    case 'done':
      statusText = i18n.t(k.UPLOAD_FINISHING);
      bottomLine = <Filename>{Math.round(percent)}%</Filename>;
      break;
    case 'aborted':
      statusText = i18n.t(k.UPLOAD_CANCELED);
      bottomLine = (
        <ErrorHandlingButtonContainer>
          <ErrorHandlingButton onClick={remove}>{i18n.t(k.CANCEL)}</ErrorHandlingButton>
        </ErrorHandlingButtonContainer>
      );

      break;
    case 'rejected_file_type':
    case 'error_validation':
      statusText = i18n.t(k.INVALID_FILE_TYPE);
      bottomLine = (
        <ErrorHandlingButtonContainer>
          <ErrorHandlingButton onClick={remove}>{i18n.t(k.CANCEL)}</ErrorHandlingButton>
        </ErrorHandlingButtonContainer>
      );

      break;
    case 'rejected_max_files':
      statusText = i18n.t(k.MAX_FILE_NUMBER);
      bottomLine = (
        <ErrorHandlingButtonContainer>
          <ErrorHandlingButton onClick={remove}>{i18n.t(k.CANCEL)}</ErrorHandlingButton>
        </ErrorHandlingButtonContainer>
      );

      break;
    case 'error_file_size':
      statusText = i18n.t(k.FILE_TOO_LARGE);
      bottomLine = (
        <ErrorHandlingButtonContainer>
          <ErrorHandlingButton onClick={remove}>{i18n.t(k.CANCEL)}</ErrorHandlingButton>
        </ErrorHandlingButtonContainer>
      );

      break;
    case 'error_upload_params':
    case 'exception_upload':
    case 'error_upload':
      statusText = i18n.t(k.ERROR_GENERAL);
      bottomLine = (
        <ErrorHandlingButtonContainer>
          <ErrorHandlingButton onClick={restart}>{i18n.t(k.RESTART)}</ErrorHandlingButton>
          <ErrorHandlingButton onClick={remove}>{i18n.t(k.CANCEL)}</ErrorHandlingButton>
        </ErrorHandlingButtonContainer>
      );

      break;
    default:
      statusText = '...';
      break;
  }
  return (
    <PreviewContainer className="preview-container">
      <PreviewImage imageUrl={previewUrl} className="preview-image"></PreviewImage>
      <Progress>
        <LinearProgress variant="determinate" value={Math.round(percent)}></LinearProgress>
        <Filename className="filename">
          {name}
          <br></br>
          {statusText}
          <br></br>
          {bottomLine}
        </Filename>
      </Progress>
    </PreviewContainer>
  );
};

export default UploadPreview;
