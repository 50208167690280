import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as MoreSvg } from '../../../../_assets/svg/more.svg';
import { ReactComponent as ArrowUp } from '../../../../_assets/svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../../_assets/svg/arrow-down.svg';

import StyledIconButton from './StyledIconButton';

const StyledMenuItem = styled(MenuItem)`
  svg {
    width: 20px;
    margin: 0 12px 0 3px;
  }
`;

const MoreMenu = ({ handleMove }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMoreMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const onMoveUp = () => {
    handleMove('UP');
    setAnchorEl(null);
  };

  const onMoveDown = () => {
    handleMove('DOWN');
    setAnchorEl(null);
  };

  return (
    <>
      <StyledIconButton onClick={handleOpenMoreMenu}>
        <MoreSvg></MoreSvg>
      </StyledIconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleCloseMoreMenu}>
        <StyledMenuItem key="moveUp" onClick={onMoveUp}>
          <ArrowUp></ArrowUp>
          {i18n.t(k.MOVE_UP)}
        </StyledMenuItem>
        <StyledMenuItem key="moveDown" onClick={onMoveDown}>
          <ArrowDown></ArrowDown>
          <span>{i18n.t(k.MOVE_DOWN)}</span>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default MoreMenu;
