import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React from 'react';
import { NavLink } from 'react-router-dom';
import BottomHintContainer from '../Components/BottomHintContainer';
import styled from 'styled-components';
import breakpoints from '../../../../../Shared/breakpoints';

const SytledBottomHintContainer = styled(BottomHintContainer)`
  @media (min-width: ${breakpoints.md}) {
    left: 333px;
  }
`;

const NoPackageBoughtHintBottom = ({ weddingId }) => {
  return (
    <SytledBottomHintContainer>
      <NavLink to={{ pathname: `/${weddingId}/payment` }}>{i18n.t(k.NO_PACKAGE_BOUGHT_HINT)}</NavLink>
    </SytledBottomHintContainer>
  );
};

export default NoPackageBoughtHintBottom;
