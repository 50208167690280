import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { ReactComponent as EditSvg } from '../../../../../_assets/svg/edit.svg';
import EditingContext from '../../../../../Shared/Context/EditingContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import UserContext from '../../../../../Shared/Context/UserContext';

import elementTypes from '../../Constants/elementTypes';
import EditingDialogContainer from '../EditingDialogContainer/EditingDialogContainer';
import saveWeddingBaseDataUpdates from '../../Functions/saveWeddingBaseDataUpdates';
import abortDueToMissingRegistration from '../../Functions/abortDueToMissingRegistration';

import InnerContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/InnerContainer';
import StyledIconButton from '../../../../../Shared/Components/Editing/EditingContainerComponents/StyledIconButton';
import OuterContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/OuterContainer';

const Container = styled(OuterContainer)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: auto;
  top: auto;
  width: 100px;
  height: 198px;
  align-items: flex-start;
`;

const EditingContainerForLanguage = ({ children }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const { editingModeActive, setWeddingBaseData, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const [startedEditing, setStartedEditing] = useState(false);

  const handleEdit = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setStartedEditing(true);
  };

  const stopEditing = () => {
    setStartedEditing(false);
  };

  const saveChanges = async baseData => {
    setStartedEditing(false);
    setLoadingSave(true);
    saveWeddingBaseDataUpdates(baseData, weddingId, setWeddingBaseData);
  };

  useEffect(() => {
    setLoadingSave(false);
  }, [weddingConfig, setLoadingSave]);

  return (
    <>
      {editingModeActive ? (
        <>
          <Container className="color-background-white">
            <InnerContainer loadingSave={loadingSave} numberOfButtons={1}>
              <StyledIconButton onClick={handleEdit}>
                <EditSvg></EditSvg>
              </StyledIconButton>
            </InnerContainer>
          </Container>
          {startedEditing && (
            <EditingDialogContainer
              editingKeys={{ elementType: elementTypes.languages.name }}
              stopEditing={stopEditing}
              saveChanges={saveChanges}
              content={{
                selectedLanguagesForWedding: weddingConfig.selectedLanguagesForWedding,
              }}
            ></EditingDialogContainer>
          )}
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditingContainerForLanguage;
