import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';

import isMobile from '../../../../../../Shared/FunctionUtils/isMobile';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';

const EditingDialogPageTitle = ({ title, subtitle, stopEditing, saveChanges }) => {
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentSubtitle, setCurrentSubtitle] = useState(subtitle);

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({ title: currentTitle, subtitle: currentSubtitle });
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{i18n.t(k.EDIT_PAGE_TITLE)}</DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          variant="filled"
          value={currentTitle}
          onChange={event => setCurrentTitle(event.target.value)}
          label={i18n.t(k.TITLE_FOR_THIS_PAGE)}
          autoFocus={isMobile() ? false : true}
        />

        <TextField
          type="text"
          variant="filled"
          value={currentSubtitle}
          onChange={event => setCurrentSubtitle(event.target.value)}
          label={i18n.t(k.SUBTITLE_FOR_THIS_PAGE)}
        />
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogPageTitle;
