import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > label {
    text-align: left;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .MuiIconButton-root {
    padding: 6px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  opacity: ${({ substitutelanguage }) => (substitutelanguage === 'true' ? '0.25' : '1')};
`;

const GuestFoodSelection = ({ foodOptions, currentSelection, selectFood, disabled }) => {
  return (
    <Container>
      <FormLabel>{i18n.t(k.MENU_WISH)}</FormLabel>
      <RadioGroup value={currentSelection} onChange={selectFood}>
        {foodOptions.map(option => (
          <StyledFormControlLabel
            value={option.id}
            key={option.id}
            control={<Radio disabled={disabled} />}
            label={option.option}
            substitutelanguage={option.substituteLanguage?.toString()}
          />
        ))}
      </RadioGroup>
    </Container>
  );
};

export default GuestFoodSelection;
