import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';
import styled from 'styled-components';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ReactPixel from 'react-facebook-pixel';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import packages from '../../../../../Shared/Constants/PACKAGES';
import countries from '../../../../../Shared/Constants/COUNTRIES';
import { Helmet } from 'react-helmet';
import breakpoints from '../../../../../Shared/breakpoints';

const ErrorMessage = styled.div`
  margin-top: 12px;
  p {
    font-size: 16px;
    color: red;
  }
`;

const CustomFieldWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .MuiFormControl-root,
  .MuiFormControlLabel-root {
    width: 40%;
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    .MuiFormControl-root,
    .MuiFormControlLabel-root {
      width: 90vw;
      max-width: 400px;
      margin-top: 18px;
    }
  }
`;

const CheckoutForm = ({ weddingId, closeDialog, selectedPackage, appliedVoucher }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loadingPaymentStatus, setLoadingPaymentStatus] = useState(false);
  const [privatePurchase, setPrivatePurchase] = useState(true);
  const [country, setCountry] = useState('DE');

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      if (!stripe) {
        return;
      }
      const selectedPackageFromUrl = new URLSearchParams(window.location.search).get('package');
      const selectedCountryFromUrl = new URLSearchParams(window.location.search).get('country');
      const selectedPrivatePurchaseFromUrl = new URLSearchParams(window.location.search).get('privatePurchase');
      const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
      if (!clientSecret) {
        return;
      }
      try {
        setLoadingPaymentStatus(true);
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        if (paymentIntent.status === 'succeeded') {
          ReactPixel.trackCustom('PaymentCompleted');
          ReactGA.event({
            category: `EHO_Event`,
            action: `Payment_Completed`,
          });
        } else {
          ReactPixel.trackCustom('PaymentFailed');
          ReactGA.event({
            category: `EHO_Event`,
            action: `Payment_Failed`,
          });
        }
        await axios.post(`${weddingId}/payment-service/save-payment-result`, {
          weddingId: weddingId,
          paymentIntent: paymentIntent,
          selectedPackage: selectedPackageFromUrl,
          country: selectedCountryFromUrl,
          privatePurchase: selectedPrivatePurchaseFromUrl,
        });
        setPaymentStatus(paymentIntent.status);
        setLoadingPaymentStatus(false);
      } catch (error) {
        // Handle any errors from the stripe.retrievePaymentIntent call
        console.error('Error retrieving payment intent:', error);
        setLoadingPaymentStatus(false);
      }
    };

    fetchPaymentIntent();
  }, [stripe, weddingId]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${weddingId}/payment/?package=${selectedPackage}&country=${country}&privatePurchase=${privatePurchase}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return loadingPaymentStatus ? (
    <>
      <CircularProgress size={32}></CircularProgress>
      <br></br>
      <div>{i18n.t(k.CHECKING_PAYMENT_STATUS)}</div>
      <br></br>
    </>
  ) : paymentStatus ? (
    <>
      {paymentStatus === 'succeeded' ? (
        <>
          <div>
            {i18n.t(k.PAYMENT_SUCCESSFULL)} <strong>{packages[selectedPackage].label}</strong>
          </div>
          <br></br>
          <Button
            onClick={() => {
              closeDialog();
              window.location.reload();
            }}
            color="primary"
            variant="contained"
          >
            {i18n.t(k.OK)}
          </Button>
        </>
      ) : (
        <>
          <div>
            {i18n.t(k.PAYMENT_FAILED)}
            <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
          </div>
          <br></br>
          <Button onClick={closeDialog} color="primary" variant="contained">
            {i18n.t(k.OK)}
          </Button>
        </>
      )}
    </>
  ) : (
    <form id="payment-form" className="color-background-white" onSubmit={handleSubmit}>
      {weddingId && (
        <Helmet>
          <title>{`EureHochzeitOnline: ${weddingId} | Checkout Form`}</title>
        </Helmet>
      )}

      <h2>
        {i18n.t(k.ACTIVATE_PACKAGE_NOW)} {packages[selectedPackage].label}{' '}
        {appliedVoucher?.voucherType === '%'
          ? Math.floor((((100 - appliedVoucher.voucherDiscount) / 100) * packages[selectedPackage].price) / 100)
          : packages[selectedPackage].price / 100}
        .- €
      </h2>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <CustomFieldWrapper>
        <FormControlLabel
          labelPlacement="end"
          control={
            <Switch
              checked={privatePurchase}
              onChange={() => {
                setPrivatePurchase(!privatePurchase);
              }}
            />
          }
          label={privatePurchase ? i18n.t(k.PRIVATE_PURCHASE) : i18n.t(k.COMMERCIAL_PURCHASE)}
        />
        <FormControl>
          <InputLabel id="country-label">Land des Rechnungsempfängers</InputLabel>
          <Select
            value={country}
            labelId="country-label"
            label="Land des Rechnungsempfängers"
            onChange={e => {
              setCountry(e.target.value);
            }}
          >
            {countries.map(country => (
              <MenuItem value={country.value} key={country.value}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CustomFieldWrapper>
      <Button
        type="submit"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        color="primary"
        variant="contained"
      >
        {isLoading ? <CircularProgress size={32}></CircularProgress> : i18n.t(k.PAY)}
      </Button>
      {errorMessage && (
        <ErrorMessage>
          <p>
            {i18n.t(k.ERROR_OCCURED_WITH_MESSAGE)}
            <br></br>
            {errorMessage}
            <br></br>
            {i18n.t(k.TRY_AGAIN_PLEASE)}
          </p>
          <p>
            <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
          </p>
        </ErrorMessage>
      )}
    </form>
  );
};

export default CheckoutForm;
