import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga4';

import EditingContext from '../../../../../Shared/Context/EditingContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import UserContext from '../../../../../Shared/Context/UserContext';
import { ReactComponent as PlusSvg } from '../../../../../_assets/svg/plus.svg';

import AddingDialogElementSelection from '../EditingDialogs/AddingDialogElementSelection/AddingDialogElementSelection';
import getContentPage from '../../Functions/getContentPage';
import abortDueToMissingRegistration from '../../Functions/abortDueToMissingRegistration';

import OuterContainer from './Components/OuterContainer';
import InnerContainer from './Components/InnerContainer';
import Line from './Components/Line';
import ButtonContainer from './Components/ButtonContainer';
import StyledIconButton from './Components/StyledIconButton';
import { CircularProgress } from '@material-ui/core';
import elementTypes from '../../Constants/elementTypes';
import LanguageContext from '../../../../../Shared/Context/LanguageContext';
import saveWeddingContentUpdates from '../../Functions/saveWeddingContentUpdates';

const AddingContainer = ({ editingKeys }) => {
  const { editingModeActive, setWeddingContent, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const [showElementTypeSelection, setShowElementTypeSelection] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const { currentLanguage } = useContext(LanguageContext);

  const startAdding = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setShowElementTypeSelection(true);
  };

  const stopAdding = () => {
    setShowElementTypeSelection(false);
  };

  const saveElement = async element => {
    const elementType = elementTypes[element.elementType];
    const languageProperty = {};
    elementType.i18nProperties.forEach(p => {
      languageProperty[p] = element[p];
      delete element[p];
    });
    languageProperty.lng = currentLanguage;
    const languageAdjustedElement = {
      ...element,
      i18n: [languageProperty],
    };
    setLoadingSave(true);
    setShowElementTypeSelection(false);
    const contentPage = getContentPage(weddingConfig, editingKeys);
    contentPage.elements.splice(editingKeys.index + 1, 0, languageAdjustedElement);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    setLoadingSave(false);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  return (
    <>
      {editingModeActive && (
        <>
          <OuterContainer>
            <InnerContainer>
              <Line></Line>
              <ButtonContainer className="color-background-white">
                <StyledIconButton disabled={loadingSave} color="primary" variant="contained" onClick={startAdding}>
                  {loadingSave ? <CircularProgress size={30}></CircularProgress> : <PlusSvg></PlusSvg>}
                </StyledIconButton>
              </ButtonContainer>
            </InnerContainer>
          </OuterContainer>
          {showElementTypeSelection && (
            <AddingDialogElementSelection
              stopAdding={stopAdding}
              saveElement={saveElement}
              editingKeys={editingKeys}
            ></AddingDialogElementSelection>
          )}
        </>
      )}
    </>
  );
};

export default AddingContainer;
