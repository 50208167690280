import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 9px 0;
  max-width: 210px;
`;
const Username = styled.p`
  margin: 0;
  font-size: 14px !important;
`;

const UserNameContainer = ({ fidiraUser, userRole }) => {
  return (
    <Container>
      <Username>
        {i18n.t(k.HELLO)} <strong>{fidiraUser.displayName}</strong>!{' '}
        {userRole === 'BRIDE' && i18n.t(k.YOU_ARE_LOGGED_IN_AS_BRIDE)}
        {userRole === 'GROOM' && i18n.t(k.YOU_ARE_LOGGED_IN_AS_GROOM)}
      </Username>
    </Container>
  );
};

export default UserNameContainer;
