import styled from 'styled-components';
import boxShadows from '../../../Shared/boxShadows';

export default styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  a {
    align-self: center;
    margin: 24px auto;
  }
  button {
    height: 60px;
    padding: 24px;
    box-shadow: ${boxShadows.z2};
    background-color: #9a3241;
    :hover {
      background-color: #e06e7e;
    }
    .MuiButton-label {
      color: white;
    }
  }
  width: 100%;
`;
