import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../../../../Shared/breakpoints';

import LocationBox from './LocationBox';
import AddingContainerForLocation from '../../../Editing/Components/AddingContainer/AddingContainerForLocation';

const Container = styled.div`
  width: 220px;
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
  }
`;

const Locations = ({ locations, highlightLocation, highlightedLocation, weddingStyle, contentPage }) => {
  return (
    <Container className="color-background-white">
      {locations.map((location, index) => (
        <LocationBox
          location={location}
          index={index}
          highlighted={highlightedLocation === index}
          key={location.name}
          highlightLocation={highlightLocation}
          weddingStyle={weddingStyle}
          contentPageName={contentPage}
        ></LocationBox>
      ))}
      <AddingContainerForLocation contentPageName={contentPage}></AddingContainerForLocation>
    </Container>
  );
};

export default Locations;
