import React, { useContext, useEffect } from 'react';

import UserContext from '../../../../Shared/Context/UserContext';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import RsvpElement from './Components/RsvpElement';
import ContentCardContainer from '../../Components/ContentCardContainer';
import GuestListOverview from './Components/GuestListOverview';
import getRoleOfUserInWedding from '../../../../Shared/FunctionUtils/getRoleOfUserInWedding';
import ContentCardWithInfoItem from '../../Components/ContentCardWithInfoItem';
import { Helmet } from 'react-helmet';

const RsvpPage = ({ pageData, scrollToTop, weddingId }) => {
  const { fidiraUser } = useContext(UserContext);

  const showOpenGuestList =
    getRoleOfUserInWedding(fidiraUser?.weddings, weddingId) === 'BRIDE' ||
    getRoleOfUserInWedding(fidiraUser?.weddings, weddingId) === 'GROOM';

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Rsvp`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {pageData.elements.map((item, index) => {
          switch (item.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithTitle
                    title={item.title}
                    subtitle={item.subtitle}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'RSVP', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'RSVP' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.infoItem.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithInfoItem
                    title={item.title}
                    description={item.description}
                    imageUrl={item.imageUrl}
                    imagePosition={item.imagePosition}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{
                      index: index,
                      contentPage: 'RSVP',
                      elementType: elementTypes.infoItem.name,
                    }}
                  ></ContentCardWithInfoItem>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'RSVP' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.rsvpElement.name:
              return (
                <ContentCardContainer
                  key="1"
                  editingKeys={{ index: index, contentPage: 'RSVP', elementType: elementTypes.rsvpElement.name }}
                  content={{ foodOptions: item.foodOptions, foodOptionsActive: item.foodOptionsActive }}
                  substituteLanguage={item.substituteLanguage}
                  deletionDisabled
                >
                  <RsvpElement foodOptions={item.foodOptions} foodOptionsActive={item.foodOptionsActive}></RsvpElement>
                </ContentCardContainer>
              );

            default:
              return null;
          }
        })}
        {showOpenGuestList && <GuestListOverview pageData={pageData}></GuestListOverview>}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default RsvpPage;
