import styled from 'styled-components';
import IconContainer from './IconContainer';

export default styled(IconContainer)`
  position: relative;
  margin: 3px auto auto auto;
  top: 0;
  left: 0;
  background-color: inherit;
  transform: scale(1.5);
  border: none;
`;
