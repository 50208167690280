import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

export default styled.div`
  height: 150px;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 90vw;
  margin: 0 auto;
  min-width: 300px;
  @media (min-width: ${breakpoints.xs}) {
    height: 170px;
  }
`;
