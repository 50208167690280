import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Switch } from '@material-ui/core';

const Container = styled.div`
  margin: 21px;
  display: flex;
  text-align: left;
`;

const AttendanceToggle = ({ willAttend, changeWillAttend, disabled }) => {
  return (
    <Container>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            onChange={e => changeWillAttend(e.target.checked)}
            checked={willAttend}
            value={willAttend}
          />
        }
        label={
          <span>
            {willAttend && i18n.t(k.YES_WE_WILL_ATTEND)}
            {!willAttend && i18n.t(k.NO_WE_WILL_NOT_ATTEND)}
          </span>
        }
      />
    </Container>
  );
};

export default AttendanceToggle;
