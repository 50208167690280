import i18n from 'i18next';
import k from './../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';

const Container = styled.div`
  text-align: left;
  width: 100%;
  padding: 6px;
  font-size: 14px;
  background-color: #fafafa;
  h2 {
    color: #a54c4c;
  }
  h3 {
    color: #a15757;
  }
  a {
    color: #a15757;
  }
`;
const InnerContainer = styled.div`
  margin: auto;
  width: 700px;
  max-width: 99vw;
`;

const PrivacyPage = () => {
  return (
    <Container>
      <LanguageSelect></LanguageSelect>
      <InnerContainer>
        <h2>{i18n.t(k.PRIVACY_00)}</h2>
        <div>
          <h3>{i18n.t(k.PRIVACY_01)}</h3>
          <p>
            <strong>{i18n.t(k.PRIVACY_02)}</strong>
            {i18n.t(k.PRIVACY_03)}
          </p>
          <p>
            {i18n.t(k.PRIVACY_04)} <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
            {i18n.t(k.PRIVACY_05)}
          </p>
          <h3>{i18n.t(k.PRIVACY_06)}</h3>
          <p>
            {i18n.t(k.PRIVACY_07)}

            <a href="/f/impressum/">{i18n.t(k.IMPRINT_00)}</a>
            {i18n.t(k._DOT)}
          </p>
          <p>{i18n.t(k.PRIVACY_08)}</p>
          <h3>{i18n.t(k.PRIVACY_09)}</h3>
          <p>{i18n.t(k.PRIVACY_10)}</p>
          <h3>{i18n.t(k.PRIVACY_11)}</h3>
          <p>{i18n.t(k.PRIVACY_12)}</p>
          <h3>{i18n.t(k.PRIVACY_13)}</h3>
          <p>{i18n.t(k.PRIVACY_14)}</p>
          <p>
            {i18n.t(k.PRIVACY_15)}

            <strong>{i18n.t(k.PRIVACY_16)}</strong>
          </p>
          <h3>{i18n.t(k.PRIVACY_17)}</h3>
          <p>{i18n.t(k.PRIVACY_18)}</p>
          <p>{i18n.t(k.PRIVACY_19)}</p>
          <h3>{i18n.t(k.PRIVACY_20)}</h3>
          <p>
            <strong>{i18n.t(k.PRIVACY_21)}</strong>
          </p>
          <p>
            {i18n.t(k.PRIVACY_22)}{' '}
            <a href="https://www.google.com/intl/de_de/help/terms_maps.html" target="_blank" rel="noopener noreferrer">
              {i18n.t(k.PRIVACY_23)}
            </a>
            {i18n.t(k._DOT)}
          </p>
          <p>
            <strong>{i18n.t(k.PRIVACY_24)}</strong>
          </p>
          <p>
            {i18n.t(k.PRIVACY_25)}{' '}
            <a href="https://www.hotjar.com/privacy/do-not-track/" target="_blank" rel="noopener noreferrer">
              {i18n.t(k.PRIVACY_26)}
            </a>
          </p>
          <p>
            <strong>{i18n.t(k.PRIVACY_27)}</strong>
          </p>
          <p>{i18n.t(k.PRIVACY_28)}</p>
          <p>{i18n.t(k.PRIVACY_29)}</p>
          <p>{i18n.t(k.PRIVACY_30)}</p>

          <p>{i18n.t(k.PRIVACY_31)}</p>
          <p>
            {i18n.t(k.PRIVACY_32)}{' '}
            <a
              href="https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t(k.PRIVACY_33)}
            </a>
          </p>
          <p>{i18n.t(k.PRIVACY_34)}</p>
          <p>{i18n.t(k.PRIVACY_35)}</p>
          <p>
            {i18n.t(k.PRIVACY_36)}{' '}
            <a href="https://mailchimp.com/legal/data-processing-addendum/" target="_blank" rel="noopener noreferrer">
              {i18n.t(k.PRIVACY_37)}
            </a>
          </p>

          <p>
            <strong>{i18n.t(k.PRIVACY_38)}</strong>
          </p>
          <p>
            {i18n.t(k.PRIVACY_39)}

            <a href="https://www.spotify.com/de/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">
              {i18n.t(k.PRIVACY_40)}
            </a>
          </p>
          <p>
            <strong>{i18n.t(k.PRIVACY_41)}</strong>
          </p>
          <p>
            {i18n.t(k.PRIVACY_42)}{' '}
            <a target="_blank" href="https://www.facebook.com/policy.php" rel="noopener noreferrer">
              {i18n.t(k.PRIVACY_43)}
            </a>
            {i18n.t(k.PRIVACY_44)}{' '}
            <a target="_blank" href="https://www.facebook.com/policy.php" rel="noopener noreferrer">
              {i18n.t(k.PRIVACY_45)}
            </a>
            {i18n.t(k._DOT)}
          </p>
          <h3>{i18n.t(k.PRIVACY_46)}</h3>
          <p>
            {i18n.t(k.PRIVACY_47)} <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>{' '}
            {i18n.t(k.PRIVACY_48)}
          </p>
          <h3>{i18n.t(k.PRIVACY_49)}</h3>
          <p>{i18n.t(k.PRIVACY_50)}</p>
          <div></div>
          <p>{i18n.t(k.PRIVACY_51)}</p>
        </div>
      </InnerContainer>
    </Container>
  );
};

export default PrivacyPage;
