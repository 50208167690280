import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export default styled(IconButton)`
  z-index: 1;
  svg {
    fill: white;
    width: 40px;
    height: 40px;
  }
`;
