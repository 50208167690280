import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import defaultStyles from '../DefaultStyles';
import breakpoints from '../../breakpoints';

import WeddingDate from './Components/WeddingDate';
import Heart from './Components/Heart';

const Container = styled.div`
  background-image: url(${defaultStyles.vintage.background.image});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  @media (min-width: ${breakpoints.md}) {
    background-color: transparent;
  }
  a {
    font-family: ${defaultStyles.vintage.bodyFont};
    font-weight: bold;
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-color: ${defaultStyles.vintage.background.overlay};
`;

const WeddingTitle = styled.div`
  text-align: center;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 40px !important;
  font-family: ${defaultStyles.vintage.headerFont} !important;
  color: ${defaultStyles.vintage.colors.primary.onDefaultBackground} !important;
  font-weight: normal;
  line-height: 1.25;
  @media (max-height: 980px) {
    font-size: 30px !important;
  }
`;

const CustomLogo = styled.div`
  height: 100px;
  width: 300px;
  margin: 3px auto;
  max-width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledWeddingDate = styled(WeddingDate)`
  font-size: 15px !important;
  text-align: center;
  max-width: 100%;
  margin-top: 15px !important;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 21px;
  position: relative;
  max-width: 90%;
`;

const StyledHeart = styled(Heart)`
  height: 100px !important;
  width: 100px;
  transform: scale(1.2) rotate(-28deg);
  margin-right: -18px;
  margin-left: 18px;
`;

const ChildrenContainer = styled.div`
  position: relative;
`;

const VintageMenuContainer = ({ weddingId, weddingConfig, closeDrawer, children }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container>
      <Overlay>
        <StyledNavLink to={{ pathname: `/${weddingId}/` }} onClick={() => closeDrawer()}>
          {weddingConfig.useCustomLogo ? (
            <>
              <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
            </>
          ) : (
            <>
              {weddingConfig && nameOfGroom && nameOfBride && (
                <>
                  <StyledHeart></StyledHeart>
                  <div>
                    <WeddingTitle>
                      {nameOfBride} &amp; {nameOfGroom}
                    </WeddingTitle>
                    <StyledWeddingDate>{weddingDate}</StyledWeddingDate>
                  </div>
                </>
              )}
            </>
          )}
        </StyledNavLink>

        <ChildrenContainer>{children}</ChildrenContainer>
      </Overlay>
    </Container>
  );
};

export default VintageMenuContainer;
