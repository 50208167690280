import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import WeddingContext from '../../Context/WeddingContext';
import GenericStyleContainer from '../../WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../WeddingStyles/DefaultStyles';

const StyledDialogTitle = styled(DialogTitle)`
  h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  p {
    margin-top: 0;
  }
  max-width: 400px;
`;
const StyledDialogActions = styled(DialogActions)`
  button {
    width: 150px;
  }
`;

const AlertDialog = ({
  open,
  messageTitle = i18n.t(k.ALERT_DIALOG_TITLE),
  messageDescriptionText,
  confirmButtonText = i18n.t(k.OK),
  handleConfirm,
  loadingConfirm = false,
  children,
}) => {
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';
  return (
    <Dialog
      open={open}
      onClose={handleConfirm}
      aria-labelledby={messageTitle}
      aria-describedby={messageDescriptionText}
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
        <StyledDialogTitle className="color-background-white">{messageTitle}</StyledDialogTitle>
        <StyledDialogContent className="color-background-white">
          <p>{messageDescriptionText || children}</p>
        </StyledDialogContent>
        <StyledDialogActions className="color-background-white">
          <Button disabled={loadingConfirm} onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            {loadingConfirm ? <CircularProgress size={30}></CircularProgress> : <>{confirmButtonText}</>}
          </Button>
        </StyledDialogActions>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default AlertDialog;
