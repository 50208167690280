import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../DefaultStyles';
import TopCenter from './Components/TopCenter';
import BottomLeft from './Components/BottomLeft';
import BottomRight from './Components/BottomRight';
import WeddingTitle from './Components/WeddingTitle';

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.flower.background.colors[1]}, ${defaultStyles.flower.background.colors[0]})`};
`;

const FlowerOnboardingContainer = ({ visible, nameOfBride, nameOfGroom, weddingDate }) => {
  return (
    <Container visible={visible}>
      <TopCenter visible={visible} />
      {nameOfBride && nameOfGroom && (
        <WeddingTitle
          visible={visible}
          nameOfBride={nameOfBride}
          nameOfGroom={nameOfGroom}
          weddingDate={weddingDate}
        ></WeddingTitle>
      )}
      <BottomLeft visible={visible} />
      <BottomRight visible={visible} />
    </Container>
  );
};

export default FlowerOnboardingContainer;
