import styled from 'styled-components';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import boxShadows from '../../../Shared/boxShadows';
import breakpoints from '../../../Shared/breakpoints';

export default styled.div`
  position: fixed;
  top: 10vh;
  right: 5vw;
  transform: rotate(20deg);
  background-color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.navigation.inverseColor} !important;
  padding: 6px;
  z-index: 1;
  border-radius: 12px;
  box-shadow: ${() => `${boxShadows.z2}`};
  @media (max-width: ${breakpoints.xs}) {
    top: 20px;
    right: -45px;
    transform: rotate(20deg) scale(0.75);
  }
  h2 {
    margin: 3px;
    color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.navigation.textColor} !important;
  }
  span {
    color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.navigation.textColor} !important;
  }
`;
