import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import removeAccents from 'remove-accents';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Button, CircularProgress } from '@material-ui/core';
import ReactGA from 'react-ga4';

import boxShadows from '../../../../../../Shared/boxShadows';
import breakpoints from '../../../../../../Shared/breakpoints';
import CreatorNotRegisteredHintDialog from '../../../../Components/WeddingStatusHints/CreatorNotRegisteredHint/CreatorNotRegisteredHintDialog';
import NoPackageBoughtHintDialog from '../../../../Components/WeddingStatusHints/NoPackageBoughtHint/NoPackageBoughtHintDialog';
import isDemoWedding from '../../../../../../Shared/FunctionUtils/isDemoWedding';
import DemoWeddingHintDialog from '../../../../Components/WeddingStatusHints/DemoWeddingHint/DemoWeddingHintDialog';

const Container = styled.div`
  .MuiAutocomplete-noOptions {
    display: ${({ searchQueryEmpty }) => (searchQueryEmpty ? 'none !important' : 'block')};
  }
  .MuiAutocomplete-popper {
    margin-top: 112px;
  }
  height: ${({ expand }) => (expand ? '300px' : '190px;')};
  transition: height 500ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StyledTextField = styled(TextField)`
  width: 500px;
  max-width: 80vw;
  margin: 50px auto 0px auto;
`;

const SearchResultContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const TextContainer = styled.div`
  width: 80%;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;
const Line = styled.div`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: -3px 0;
`;
const Name = styled.span``;
const ArtistOrAlbum = styled.span`
  font-size: 14px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  width: 100%;
`;
const Image = styled.div`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const PlayContainer = styled.div`
  iframe {
    width: 500px;
    max-width: 80vw;
  }
`;
const OuterPlayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  box-shadow: ${boxShadows.z2};
  @media (max-width: ${breakpoints.xs}) {
    min-width: 100px;
  }
`;

const Search = ({
  spotifyAccessToken,
  refreshSpotifyAccessToken,
  playlistId,
  weddingId,
  weddingConfig,
  trackCount,
  triggerPlaylistRefresh,
  nameOfGroom,
  nameOfBride,
  creatorIsRegistered,
  refreshWeddingConfig,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState(['']);
  const [selectedTrack, setSelectedTrack] = useState([null]);
  const [loading, setLoading] = useState(false);
  const [loadingAddTrack, setLoadingAddTrack] = useState(false);
  const [showCreatorNotRegisteredDialog, setShowCreatorNotRegisteredDialog] = useState(false);
  const [showNoPackageBoughtDialog, setShowNoPackageBoughtDialog] = useState(false);
  const [showDemoWeddingHintDialog, setShowDemoWeddingHintDialog] = useState(false);
  const [randomKey, setRandomKey] = useState(null);

  const axiosInstance = axios.create({ headers: {} });
  delete axiosInstance.defaults.headers.common['X-Fidira-Test-Request'];

  const onSelect = (event, value) => {
    setSelectedTrack(value);
  };

  const searchTrack = value => {
    if (!spotifyAccessToken) return;
    if (!value) return;
    let active = true;
    setSearchQuery(value);
    setLoading(true);
    (async () => {
      let response;
      try {
        response = await axiosInstance.get(`https://api.spotify.com/v1/search/?q=${value}&type=track`, {
          headers: {
            Authorization: `Bearer ${spotifyAccessToken}`,
          },
        });
      } catch (error) {
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message === 'The access token expired'
        ) {
          await refreshSpotifyAccessToken();
          response = await axios.get(`https://api.spotify.com/v1/search/?q=${value}&type=track`, {
            headers: {
              Authorization: `Bearer ${spotifyAccessToken}`,
            },
          });
          setLoading(false);
        } else {
        }
      }
      if (response) {
        const tracks = response.data.tracks.items;
        if (active) {
          setOptions(
            tracks.map(track => {
              return {
                ...track,
                artist:
                  track.artists.length === 1
                    ? track.artists[0].name
                    : track.artists.reduce((f, s) => {
                        const first = f.name ? f.name : f;
                        const second = s.name;
                        return first + ' - ' + second;
                      }),
                albumName: track.album.name,
                imageUrl: track.album.images.filter(image => image.height === 64)[0].url || track.album.images[0].url,
              };
            })
          );
        }

        setLoading(false);
      }
    })();
  };

  const addTrack = async () => {
    if (isDemoWedding(weddingId)) {
      setShowDemoWeddingHintDialog(true);
    } else if (!creatorIsRegistered) {
      setShowCreatorNotRegisteredDialog(true);
    } else if (weddingConfig.selectedPackage === 'basic' && trackCount > 4) {
      setShowNoPackageBoughtDialog(true);
    } else {
      setLoadingAddTrack(true);
      await axios.post(`music-create/`, {
        weddingId: weddingId,
        playlistId: playlistId,
        nameOfBride: nameOfBride,
        nameOfGroom: nameOfGroom,
        track: {
          trackId: selectedTrack.id,
          trackUri: selectedTrack.uri,
          duration_ms: selectedTrack.duration_ms,
          href: selectedTrack.href,
          name: selectedTrack.name,
          popularity: selectedTrack.popularity,
          track_number: selectedTrack.track_number,
          type: selectedTrack.type,
          externalIds: {
            isrc: selectedTrack.externalIds ? selectedTrack.externalIds.isrc : '',
          },
          artists: selectedTrack.artists.map(artist => {
            return {
              name: artist.name,
              id: artist.id,
              uri: artist.uri,
              href: artist.href,
            };
          }),
          album: {
            id: selectedTrack.album ? selectedTrack.album.id : '',
            uri: selectedTrack.album ? selectedTrack.album.uri : '',
            imageSmall: selectedTrack.album
              ? selectedTrack.album.images.filter(image => image.height === 64)[0].url ||
                selectedTrack.album.images[0].url
              : '',
            imageMedium: selectedTrack.album
              ? selectedTrack.album.images.filter(image => image.height === 640)[0].url ||
                selectedTrack.album.images[0].url
              : '',
            name: selectedTrack.album ? selectedTrack.album.name : '',
            release_date: selectedTrack.album ? selectedTrack.album.release_date : '',
            total_tracks: selectedTrack.album ? selectedTrack.album.total_tracks : '',
            type: selectedTrack.album ? selectedTrack.album.type : '',
          },
        },
      });
      setSelectedTrack(null);
      setLoadingAddTrack(false);
      if (!playlistId) {
        refreshWeddingConfig();
      }
      triggerPlaylistRefresh();
      // clears the autocomplete after saving the track
      setRandomKey(Date.now());
      ReactGA.event({
        category: `EHO_Event`,
        action: `Wedding-${weddingId}-Music-Added`,
      });
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filterOptions = createFilterOptions({
    stringify: option => {
      return `${removeAccents(option.name)} ${removeAccents(option.artist)} ${removeAccents(option.name)}`;
    },
    trim: true,
  });

  return (
    <Container
      searchQueryEmpty={searchQuery.length === 0}
      expand={selectedTrack && selectedTrack.id}
      className="color-background-white"
    >
      <CreatorNotRegisteredHintDialog
        setShow={setShowCreatorNotRegisteredDialog}
        show={showCreatorNotRegisteredDialog}
      ></CreatorNotRegisteredHintDialog>
      <NoPackageBoughtHintDialog
        setShow={setShowNoPackageBoughtDialog}
        show={showNoPackageBoughtDialog}
        restriction="music"
      ></NoPackageBoughtHintDialog>
      <DemoWeddingHintDialog
        show={showDemoWeddingHintDialog}
        setShow={setShowDemoWeddingHintDialog}
      ></DemoWeddingHintDialog>
      <Autocomplete
        key={randomKey}
        forcePopupIcon={false}
        id="asynchronous-demo"
        open={open}
        debug
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={options}
        loading={loading}
        loadingText={i18n.t(k.LOADING)}
        clearOnBlur
        disablePortal
        onChange={onSelect}
        clearOnEscape
        filterOptions={filterOptions}
        renderInput={params => (
          <StyledTextField
            {...params}
            label={i18n.t(k.ENTER_SONG_WISH)}
            variant="filled"
            value={searchQuery}
            onChange={event => searchTrack(event.target.value)}
            onBlur={() => setSearchQuery('')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        noOptionsText={<span>{i18n.t(k.NO_RESULT_FOUND)}</span>}
        autoComplete
        renderOption={(option, { inputValue }) => {
          const matchesName = match(option.name, inputValue);
          const partsName = parse(option.name, matchesName);
          const matchesArtist = match(option.artist, inputValue);
          const partsArtist = parse(option.artist, matchesArtist);
          const matchesAlbum = match(option.albumName, inputValue);
          const partsAlbum = parse(option.albumName, matchesAlbum);

          return (
            <SearchResultContainer>
              <Image imageUrl={option.imageUrl}></Image>
              <TextContainer>
                <Line>
                  {partsName.map((part, index) => (
                    <Name key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </Name>
                  ))}
                </Line>
                <Line>
                  {partsArtist.map((part, index) => (
                    <ArtistOrAlbum key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </ArtistOrAlbum>
                  ))}
                </Line>
                <Line>
                  {partsAlbum.map((part, index) => (
                    <ArtistOrAlbum key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </ArtistOrAlbum>
                  ))}
                </Line>
              </TextContainer>
            </SearchResultContainer>
          );
        }}
      />

      <OuterPlayContainer expand={selectedTrack && selectedTrack.id}>
        {selectedTrack && selectedTrack.id && (
          <>
            <PlayContainer>
              <iframe
                title={selectedTrack.id}
                src={`https://open.spotify.com/embed/track/${selectedTrack.id}`}
                height="80"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </PlayContainer>
            <ButtonContainer>
              <StyledButton
                color="secondary"
                variant="contained"
                onClick={() => {
                  setSearchQuery('');
                  setSelectedTrack(null);
                  setRandomKey(Date.now());
                }}
              >
                {i18n.t(k.CANCEL)}
              </StyledButton>
              <StyledButton onClick={addTrack} disabled={loadingAddTrack} variant="contained">
                {loadingAddTrack && <CircularProgress size={30}></CircularProgress>}
                {!loadingAddTrack && <>{i18n.t(k.ADD_SONG)}</>}
              </StyledButton>
            </ButtonContainer>
          </>
        )}
      </OuterPlayContainer>
    </Container>
  );
};

export default Search;
