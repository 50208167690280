import React, { useEffect } from 'react';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import ContentCardWithScheduleItem from '../../Components/ContentCardWithScheduleItem';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import { Helmet } from 'react-helmet';

const SchedulePage = ({ schedule, scrollToTop, weddingId }) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Schedule`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {schedule.elements.map((scheduleItem, index) => {
          switch (scheduleItem.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={scheduleItem.title}>
                  <ContentCardWithTitle
                    title={scheduleItem.title}
                    subtitle={scheduleItem.subtitle}
                    substituteLanguage={scheduleItem.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'SCHEDULE', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'SCHEDULE' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.scheduleItem.name:
              return (
                <ElementContainer key={scheduleItem.title}>
                  <ContentCardWithScheduleItem
                    time={scheduleItem.time}
                    title={scheduleItem.title}
                    description={scheduleItem.description}
                    imageUrl={scheduleItem.imageUrl}
                    substituteLanguage={scheduleItem.substituteLanguage}
                    imagePosition={scheduleItem.imagePosition || 'right'}
                    editingKeys={{ index: index, contentPage: 'SCHEDULE', elementType: elementTypes.scheduleItem.name }}
                  ></ContentCardWithScheduleItem>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'SCHEDULE' }}></AddingContainer>
                </ElementContainer>
              );
            default:
              return null;
          }
        })}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default SchedulePage;
