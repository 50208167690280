import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import ReactPixel from 'react-facebook-pixel';

import QuestionHeader from '../Components/QuestionHeader';
import BackButton from '../Components/BackButton';
import ButtonContainer from '../Components/ButtonContainer';
import NextButton from '../Components/NextButton';
import FieldContainer from '../Components/FieldContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const schema = object().shape({
  nameOfPartner: string().required(i18n.t(k.PLEASE_ENTER_NAME)),
});

const NameOfPartner = ({ submitToParent, onBack, userIsBride, nameOfGroom, nameOfBride }) => {
  const { handleSubmit, register, errors } = useForm({ validationSchema: schema });

  const onSubmit = values => {
    ReactPixel.trackCustom('NameOfPartnerFunnel', { creatorIsBride: userIsBride, nameOfCreator: values.nameOfPartner });
    submitToParent({
      value: values.nameOfPartner,
      question: userIsBride ? 'nameOfGroom' : 'nameOfBride',
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <QuestionHeader>
          {i18n.t(k.PERFECT)} {userIsBride ? nameOfBride : nameOfGroom}! {i18n.t(k.NAME_OF)}{' '}
          {userIsBride ? i18n.t(k.SPOUSE_M) : i18n.t(k.SPOUSE_F)}?
        </QuestionHeader>
        <FieldContainer>
          <TextField
            name="nameOfPartner"
            error={!!errors.nameOfPartner}
            helperText={errors.nameOfPartner ? errors.nameOfPartner.message : ''}
            type="text"
            inputRef={register}
            variant="filled"
            autoComplete="off"
          />
        </FieldContainer>
        <ButtonContainer>
          <BackButton onClick={() => onBack()} variant="contained" color="secondary">
            {i18n.t(k.BACK)}
          </BackButton>
          <NextButton type="submit" variant="contained">
            {i18n.t(k.NEXT)}
          </NextButton>
        </ButtonContainer>
      </Container>
    </form>
  );
};

export default NameOfPartner;
