import i18n from 'i18next';
import k from './../../i18n/keys';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { hotjar } from 'react-hotjar';
import styled from 'styled-components';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import WebFont from 'webfontloader';
import { LoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import WeddingContext from '../../Shared/Context/WeddingContext';
import EditingContext from '../../Shared/Context/EditingContext';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import hasUserReadPermissionForWedding from '../../Shared/FunctionUtils/hasUserReadPermissionForWedding';
import PrivateRoute from '../../Shared/Components/PrivateRoute/PrivateRoute';
import ErrorBoundary from '../../Shared/Components/ErrorBoundary/ErrorBoundary';
import ModernAppBackground from '../../Shared/WeddingStyles/Modern/ModernAppBackground';
import VintageAppBackground from '../../Shared/WeddingStyles/Vintage/VintageAppBackground';
import FlowerAppBackground from '../../Shared/WeddingStyles/Flower/FlowerAppBackground';
import KlassischAppBackground from '../../Shared/WeddingStyles/Klassisch/KlassischAppBackground';
import hasUserWritePermissionForWedding from '../../Shared/FunctionUtils/hasUserWritePermissionForWedding';
import EditingModeToggle from '../../Shared/Components/Editing/EditingModeToggle/EditingModeToggle';

import PaymentDialog from './Components/PaymentDialog/PaymentDialog';
import CreatorNotRegisteredHintBottom from './Components/WeddingStatusHints/CreatorNotRegisteredHint/CreatorNotRegisteredHintBottom';
import CreatorNotRegisteredHintDialog from './Components/WeddingStatusHints/CreatorNotRegisteredHint/CreatorNotRegisteredHintDialog';
import Menu from './Menu/Container';
import SchedulePage from './AppPages/Schedule/SchedulePage';
import LocationsPage from './AppPages/Locations/LocationsPage';
import LoginPage from './AppPages/Login/LoginPage';
import StartPage from './AppPages/Start/StartPage';
import PhotoPage from './AppPages/Photo/PhotoPage';
import MusicPage from './AppPages/Music/MusicPage';
import InfoPage from './AppPages/Info/InfoPage';
import WeddingNotFoundPage from './WeddingNotFoundPage/WeddingNotFoundPage';
import RsvpPage from './AppPages/Rsvp/RsvpPage';
import NoPackageBoughtHintBottom from './Components/WeddingStatusHints/NoPackageBoughtHint/NoPackageBoughtHintBottom';
import isDemoWedding from '../../Shared/FunctionUtils/isDemoWedding';
import DemoWeddingHintBottom from './Components/WeddingStatusHints/DemoWeddingHint/DemoWeddingHintBottom';
import getRoleOfUserInWedding from '../../Shared/FunctionUtils/getRoleOfUserInWedding';
import isProduction from '../../Shared/FunctionUtils/isProduction';
import HotelPage from './AppPages/Hotel/HotelPage';
import CouplePage from './AppPages/Couple/CouplePage';
import PresentPage from './AppPages/Present/PresentPage';
import { Helmet } from 'react-helmet';
import WeddingLanguageSelection from './Components/WeddingLanguageSelection/WeddingLanguageSelection';
import LanguageContext from '../../Shared/Context/LanguageContext';
import GuestNumberExceededHintDialog from './Components/WeddingStatusHints/GuestNumberExceededHint/GuestNumberExceededHintDialog';
import BohoAppBackground from '../../Shared/WeddingStyles/Boho/BohoAppBackground';
import BlossomAppBackground from '../../Shared/WeddingStyles/Blossom/BlossomAppBackground';

const Main = styled.div`
  @media (min-width: 1280px) {
    padding-left: 350px;
  }
`;

const getRequiredFonts = weddingStyle => {
  const fonts = [];
  fonts.push(defaultStyles[weddingStyle].headerFont);
  fonts.push(defaultStyles[weddingStyle].navigationFont);
  fonts.push(defaultStyles[weddingStyle].bodyFont);
  const uniqueFonts = [...new Set(fonts)];
  return uniqueFonts;
};

const googleMapsLibraries = ['places'];

const languageAdjustContentPages = (contentPages, language) => {
  if (Array.isArray(contentPages)) {
    contentPages.forEach(item => languageAdjustContentPages(item, language));
  } else if (typeof contentPages === 'object' && contentPages !== null) {
    if ('i18n' in contentPages && Array.isArray(contentPages.i18n)) {
      const correctItem = contentPages.i18n.find(item => item.lng === language);
      if (correctItem) {
        contentPages.substituteLanguage = false;
        for (const key in correctItem) {
          if (key !== 'lng') {
            contentPages[key] = correctItem[key];
          }
        }
      } else {
        const substituteItem = contentPages.i18n[0];
        for (const key in substituteItem) {
          if (key !== 'lng') {
            contentPages[key] = substituteItem[key];
            contentPages.substituteLanguage = true;
          }
        }
      }
    }
    for (const key in contentPages) {
      languageAdjustContentPages(contentPages[key], language);
    }
  }
};

const WeddingPage = ({ weddingId, fidiraUser, scrollToTop, cookiePreference }) => {
  const [loadingWeddingConfig, setLoadingWeddingConfig] = useState(false);
  const [editingModeActive, setEditingModeActive] = useState(false);
  const [showCreatorNotRegisteredDialog, setShowCreatorNotRegisteredDialog] = useState(false);
  const [showGuestNumberExceededHintDialog, setShowGuestNumberExceededHintDialog] = useState(false);
  const [errorWeddingConfig, setErrorWeddingConfig] = useState(false);
  const [weddingNotFound, setWeddingNotFound] = useState(false);
  const [weddingConfig, setWeddingConfig] = useState(null);
  const currentLocation = useLocation().pathname;
  const isOnStartPage = !currentLocation.split('/')[2];
  const scrollRef = useRef(false);
  const { currentLanguage } = useContext(LanguageContext);

  const setWeddingContent = weddingContent => {
    languageAdjustContentPages(weddingContent.contentPages, currentLanguage);
    setWeddingConfig({ ...weddingConfig, weddingContent: weddingContent });
  };

  const setWeddingBaseData = baseData => {
    WebFont.load({
      google: {
        families: getRequiredFonts(baseData.weddingStyle),
      },
    });
    setWeddingConfig({ ...weddingConfig, ...baseData });
  };

  const refreshWeddingConfig = async () => {
    const { data: weddingConfigInitial } = await axios.get(`${weddingId}/wedding-read/`);
    setWeddingConfig(weddingConfigInitial);
  };

  useEffect(() => {
    if (weddingConfig && fidiraUser === null) return; // in case of logout no need to load the data again
    const loadWeddingConfig = async () => {
      setLoadingWeddingConfig(true);
      if (fidiraUser && fidiraUser.weddings) {
        const roleOfUserInWedding = getRoleOfUserInWedding(fidiraUser.weddings, weddingId);
        if (roleOfUserInWedding !== 'GUEST' && cookiePreference === 'ALL' && isProduction()) {
          hotjar.initialize(1808603, 6);
        }
      }
      try {
        const { data: weddingConfigInitial } = await axios.get(`${weddingId}/wedding-read/`);
        if (weddingConfigInitial.weddingContent) {
          languageAdjustContentPages(weddingConfigInitial.weddingContent.contentPages, currentLanguage);
        }
        setWeddingConfig(weddingConfigInitial);
        if (!weddingConfigInitial.creatorIsRegistered) {
          setShowCreatorNotRegisteredDialog(true);
        }
        if (weddingConfigInitial.guestNumberExceeded) {
          setShowGuestNumberExceededHintDialog(true);
        }
        WebFont.load({
          google: {
            families: getRequiredFonts(weddingConfigInitial.weddingStyle),
          },
        });
      } catch (error) {
        console.error(error);
        setErrorWeddingConfig(true);
        if (error.response && error.response.data === 'Leider konnten wir die Hochzeit nicht finden') {
          setWeddingNotFound(true);
        }
      } finally {
        setLoadingWeddingConfig(false);
      }
    };
    loadWeddingConfig();
    // eslint-disable-next-line
  }, [weddingId, fidiraUser]);

  useEffect(() => {
    if (!weddingConfig || !weddingConfig.weddingContent) return;
    const newWeddingConfig = JSON.parse(JSON.stringify(weddingConfig));
    languageAdjustContentPages(newWeddingConfig.weddingContent.contentPages, currentLanguage);
    setWeddingConfig(newWeddingConfig);
    // eslint-disable-next-line
  }, [currentLanguage]);

  useScrollPosition(({ currPos }) => {
    window.lastScrollPosition = -currPos.y + 20;
  }, []);

  useEffect(() => {
    window.scrollTo(0, window.lastScrollPosition);
  });

  return (
    !loadingWeddingConfig && (
      <ErrorBoundary>
        <WeddingContext.Provider
          value={{
            weddingId: weddingId,
            weddingConfig: weddingConfig,
            selectedPackage: weddingConfig?.selectedPackage,
          }}
        >
          <EditingContext.Provider
            value={{
              editingModeActive: editingModeActive,
              setEditingModeActive: setEditingModeActive,
              setShowCreatorNotRegisteredDialog: setShowCreatorNotRegisteredDialog,
              setWeddingContent: setWeddingContent,
              setWeddingBaseData: setWeddingBaseData,
            }}
          >
            {errorWeddingConfig && <div>{i18n.t(k.ERROR_GENERAL)}</div>}
            {errorWeddingConfig && weddingNotFound && <WeddingNotFoundPage></WeddingNotFoundPage>}
            {!errorWeddingConfig && weddingConfig && weddingConfig.weddingStyle && (
              <GenericStyleContainer weddingStyleData={defaultStyles[weddingConfig.weddingStyle]}>
                <LoadScript
                  id="script-loader"
                  libraries={googleMapsLibraries}
                  googleMapsApiKey="AIzaSyAUO_P0hkO_78JaRqQLYEX3CLvHDVvpAkQ"
                >
                  <Helmet>
                    <title>{`EureHochzeitOnline: ${weddingId}`}</title>
                  </Helmet>
                  <Menu navigationWithShadow={defaultStyles[weddingConfig.weddingStyle].navigationWithShadow} />
                  <WeddingLanguageSelection></WeddingLanguageSelection>
                  <CreatorNotRegisteredHintDialog
                    setShow={setShowCreatorNotRegisteredDialog}
                    show={showCreatorNotRegisteredDialog}
                  ></CreatorNotRegisteredHintDialog>
                  <GuestNumberExceededHintDialog
                    setShow={setShowGuestNumberExceededHintDialog}
                    show={showGuestNumberExceededHintDialog}
                  ></GuestNumberExceededHintDialog>
                  {(hasUserWritePermissionForWedding(fidiraUser, weddingId) || !weddingConfig.creatorIsRegistered) && (
                    <EditingModeToggle></EditingModeToggle>
                  )}

                  {isDemoWedding(weddingId) && <DemoWeddingHintBottom></DemoWeddingHintBottom>}
                  {!weddingConfig.creatorIsRegistered && (
                    <CreatorNotRegisteredHintBottom></CreatorNotRegisteredHintBottom>
                  )}

                  {weddingConfig.creatorIsRegistered &&
                    (!weddingConfig.selectedPackage ||
                      (weddingConfig.selectedPackage === 'basic' && (
                        <NoPackageBoughtHintBottom weddingId={weddingId}></NoPackageBoughtHintBottom>
                      )))}
                  <Main ref={scrollRef}>
                    {weddingConfig.weddingStyle === 'modern' && (
                      <ModernAppBackground
                        showStartPage={isOnStartPage}
                        weddingConfig={weddingConfig}
                      ></ModernAppBackground>
                    )}

                    {weddingConfig.weddingStyle === 'vintage' && (
                      <VintageAppBackground
                        showStartPage={isOnStartPage}
                        weddingConfig={weddingConfig}
                      ></VintageAppBackground>
                    )}

                    {weddingConfig.weddingStyle === 'flower' && (
                      <FlowerAppBackground
                        showStartPage={isOnStartPage}
                        weddingConfig={weddingConfig}
                      ></FlowerAppBackground>
                    )}

                    {weddingConfig.weddingStyle === 'klassisch' && (
                      <KlassischAppBackground
                        showStartPage={isOnStartPage}
                        weddingConfig={weddingConfig}
                      ></KlassischAppBackground>
                    )}

                    {weddingConfig.weddingStyle === 'boho' && (
                      <BohoAppBackground
                        showStartPage={isOnStartPage}
                        weddingConfig={weddingConfig}
                      ></BohoAppBackground>
                    )}

                    {weddingConfig.weddingStyle === 'blossom' && (
                      <BlossomAppBackground
                        showStartPage={isOnStartPage}
                        weddingConfig={weddingConfig}
                      ></BlossomAppBackground>
                    )}

                    <Switch>
                      <Route
                        path={['/:weddingId/login']}
                        render={() =>
                          hasUserReadPermissionForWedding(fidiraUser, weddingId) ||
                          !weddingConfig.creatorIsRegistered ? (
                            <Redirect to={`/${weddingId}/`} />
                          ) : (
                            <LoginPage weddingId={weddingId} weddingStyle={weddingConfig.weddingStyle} />
                          )
                        }
                      />

                      <PrivateRoute requestedWedding={weddingId}>
                        <Route
                          exact
                          path={['/:weddingId/']}
                          render={() => {
                            return <StartPage scrollToTop={scrollToTop} weddingId={weddingId} />;
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/location']}
                          render={() => {
                            return (
                              <LocationsPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                locations={
                                  weddingConfig.weddingContent.contentPages.filter(
                                    page => page.pageType === 'LOCATION'
                                  )[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/hotel']}
                          render={() => {
                            return (
                              <HotelPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                hotels={
                                  weddingConfig.weddingContent.contentPages.filter(page => page.pageType === 'HOTEL')[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/ablauf']}
                          render={() => {
                            return (
                              <SchedulePage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                schedule={
                                  weddingConfig.weddingContent.contentPages.filter(
                                    page => page.pageType === 'SCHEDULE'
                                  )[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/infos']}
                          render={() => {
                            return (
                              <InfoPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                content={
                                  weddingConfig.weddingContent.contentPages.filter(page => page.pageType === 'INFO')[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/couple']}
                          render={() => {
                            return (
                              <CouplePage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                content={
                                  weddingConfig.weddingContent.contentPages.filter(
                                    page => page.pageType === 'COUPLE'
                                  )[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/fotos']}
                          render={() => {
                            return (
                              <PhotoPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                pageData={
                                  weddingConfig.weddingContent.contentPages.filter(page => page.pageType === 'PHOTO')[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/musik']}
                          render={() => {
                            return (
                              <MusicPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                pageData={
                                  weddingConfig.weddingContent.contentPages.filter(page => page.pageType === 'MUSIC')[0]
                                }
                                refreshWeddingConfig={refreshWeddingConfig}
                                loadingWeddingConfig={loadingWeddingConfig}
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/rsvp']}
                          render={() => {
                            return (
                              <RsvpPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                pageData={
                                  weddingConfig.weddingContent.contentPages.filter(page => page.pageType === 'RSVP')[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/present']}
                          render={() => {
                            return (
                              <PresentPage
                                scrollToTop={scrollToTop}
                                weddingId={weddingId}
                                weddingConfig={weddingConfig}
                                pageData={
                                  weddingConfig.weddingContent.contentPages.filter(
                                    page => page.pageType === 'PRESENT'
                                  )[0]
                                }
                              />
                            );
                          }}
                        />

                        <Route
                          exact
                          path={['/:weddingId/payment']}
                          render={() => {
                            return <PaymentDialog show={true} />;
                          }}
                        />
                      </PrivateRoute>
                    </Switch>
                  </Main>
                </LoadScript>
              </GenericStyleContainer>
            )}
          </EditingContext.Provider>
        </WeddingContext.Provider>
      </ErrorBoundary>
    )
  );
};

export default WeddingPage;
