import styled from 'styled-components';

export default styled.div`
  height: 100px;
  width: 100%;
  background-image: url(/assets/logos/eurehochzeitonline_logo_transparent_1000px.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
