import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button, Input } from '@material-ui/core';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import boxShadows from '../../../../../../Shared/boxShadows';
import { ReactComponent as DeleteSvg } from '../../../../../../_assets/svg/delete.svg';
import { ReactComponent as AddSvg } from '../../../../../../_assets/svg/plus.svg';
import FoodOptionsToggle from './Components/FoodOptionsToggle';

const LineContainer = styled.div`
  display: flex;
  margin: 12px auto;
  box-shadow: ${boxShadows.z2};
  border-radius: 4px;
  position: relative;
`;
const FoodOption = styled(Input)`
  width: 250px;
  max-width: 90%;
  padding: 6px;
  box-sizing: border-box;
`;
const RemoveOrAddButton = styled(Button)`
  padding: 6px;
  box-shadow: ${boxShadows.z1};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  svg {
    width: 21px;
  }
`;

const NewOptionContainer = styled.div`
  margin-top: 21px;
  display: flex;
  justify-content: center;

  .MuiTextField-root {
    width: 250px;
    max-width: 90%;
    margin: 0;
  }
`;

const EditingDialogRsvp = ({ foodOptionsActive, foodOptions, stopEditing, saveChanges }) => {
  const [currentFoodOptionsActive, setCurrentFoodOptionsActive] = useState(foodOptionsActive);
  const [currentFoodOptions, setCurrentFoodOptions] = useState(foodOptions);
  const [newFoodOption, setNewFoodOption] = useState('');

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      foodOptionsActive: currentFoodOptionsActive,
      foodOptions: currentFoodOptions,
    });
  };

  const addNewFoodOption = () => {
    const newFoodOptions = [...currentFoodOptions];
    newFoodOptions.push({ option: newFoodOption, id: uuidv4() });
    setCurrentFoodOptions(newFoodOptions);
    setNewFoodOption('');
  };

  const removeFoodOption = index => {
    const newFoodOptions = [...currentFoodOptions];
    newFoodOptions.splice(index, 1);
    setCurrentFoodOptions(newFoodOptions);
  };

  const renameFoodOption = (value, index) => {
    const newFoodOptions = [...currentFoodOptions];
    newFoodOptions[index].option = value;
    setCurrentFoodOptions(newFoodOptions);
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{i18n.t(k.EDIT_RSVP)}</DialogTitle>
      <DialogContent>
        <p>{i18n.t(k.MENU_WISHES_ACTIVATED)}</p>
        <FoodOptionsToggle
          foodOptionsActive={currentFoodOptionsActive}
          setFoodOptionsActive={setCurrentFoodOptionsActive}
        ></FoodOptionsToggle>
        {currentFoodOptionsActive && (
          <>
            {currentFoodOptions.map((option, index) => (
              <LineContainer key={index}>
                <FoodOption
                  value={option.option || ''}
                  onChange={e => renameFoodOption(e.target.value, index)}
                ></FoodOption>
                {currentFoodOptions.length > 2 && (
                  <RemoveOrAddButton onClick={() => removeFoodOption(index)}>
                    <DeleteSvg></DeleteSvg>
                  </RemoveOrAddButton>
                )}
              </LineContainer>
            ))}
            <NewOptionContainer>
              <TextField
                type="text"
                variant="filled"
                value={newFoodOption}
                onChange={e => setNewFoodOption(e.target.value)}
                label={i18n.t(k.NEW_FOOD_OPTION)}
              />

              <RemoveOrAddButton onClick={addNewFoodOption}>
                <AddSvg></AddSvg>
              </RemoveOrAddButton>
            </NewOptionContainer>
          </>
        )}
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogRsvp;
