import i18next from 'i18next';
import english from './english';
import german from './german';
import chinese from './chinese';
import czechRepublic from './czech-republic';
import french from './french';
import greek from './greek';
import hungarian from './hungarian';
import italian from './italian';
import japanese from './japanese';
import polish from './polish';
import portuguese from './portuguese';
import spanish from './spanish';
import turkish from './turkish';
import ukrainian from './ukrainian';
import languages from './languages';

const getMostPreferredAvailableLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  const languageWithoutRegion = language.split('-')[0];
  if (languages.hasOwnProperty(languageWithoutRegion)) {
    return languageWithoutRegion;
  } else {
    return 'en';
  }
};

i18next.init({
  lng: localStorage.getItem('lng') || getMostPreferredAvailableLanguage() || 'de',
  resources: {
    de: { translation: german },
    en: { translation: english },
    cn: { translation: chinese },
    cs: { translation: czechRepublic },
    fr: { translation: french },
    el: { translation: greek },
    hu: { translation: hungarian },
    it: { translation: italian },
    ja: { translation: japanese },
    pl: { translation: polish },
    pt: { translation: portuguese },
    es: { translation: spanish },
    tr: { translation: turkish },
    ua: { translation: ukrainian },
  },
  fallbackLng: 'de',
});
