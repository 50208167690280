export default (bgColor = 'FFFFFF', defaultColor = '000000') => {
  let backgroundColorWithoutHash = bgColor;
  if (bgColor.charAt(0) === '#') {
    backgroundColorWithoutHash = bgColor.substring(1);
  }
  const r = parseInt(backgroundColorWithoutHash.substring(0, 2), 16);
  const g = parseInt(backgroundColorWithoutHash.substring(2, 4), 16);
  const b = parseInt(backgroundColorWithoutHash.substring(4, 6), 16);
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return (col + 0.055) / 1.055 ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.8 ? defaultColor : '#ffffff';
};
