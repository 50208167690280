import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../DefaultStyles';

import WeddingDate from './Components/WeddingDate';
import TopCenter from './Components/TopCenter';
import HorizontalLine from './Components/HorizontalLine';
import Heart from './Components/Heart';
import Headline from './Components/Headline';

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.klassisch.background.colors[1]}, ${defaultStyles.klassisch.background.colors[0]})`};
`;

const KlassischOnboardingContainer = ({ visible, nameOfBride, nameOfGroom, weddingDate }) => {
  return (
    <Container visible={visible}>
      <TopCenter visible={visible}>
        <Heart />
        {nameOfBride && nameOfGroom && (
          <>
            <Headline>{nameOfBride}</Headline>
            <Headline>&amp;</Headline>
            <Headline>{nameOfGroom}</Headline>
            <HorizontalLine />
          </>
        )}
        <WeddingDate>{weddingDate}</WeddingDate>
      </TopCenter>
    </Container>
  );
};

export default KlassischOnboardingContainer;
