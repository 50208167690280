import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import ReactPixel from 'react-facebook-pixel';

import IconBox from '../.../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../.../../../../Shared/Components/SelectionComponent/SelectionContainer';

import QuestionHeader from '../Components/QuestionHeader';
import BackButton from '../Components/BackButton';
import ButtonContainer from '../Components/ButtonContainer';
import NextButton from '../Components/NextButton';
import FieldContainer from '../Components/FieldContainer';
import ErrorMessage from '../Components/ErrorMessage';
import FadeOuterContainer from '../Components/FadeOuterContainer';
import FadeContainer from '../Components/FadeContainer';

import isMobile from '../../../Shared/FunctionUtils/isMobile';
import formatDate from '../../../Shared/FunctionUtils/formatDate';
import getUpcomingFourSeasons from '../../../Shared/FunctionUtils/getUpcomingFourSeasons';
import breakpoints from '../../../Shared/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatePickerContainer = styled.div`
  max-width: 90vw;
  .native-picker input {
    text-align: center;
    width: 250px;
    padding-left: 50px;
  }
`;

const StyledSwitch = styled(Switch)`
  @media (min-width: ${breakpoints.xs}) {
    margin-bottom: -18px;
    margin-top: -12px;
  }
`;

const WeddingDate = ({ submitWeddingDate, onBack, weddingStyle }) => {
  const [weddingDateDefined, setWeddingDateDefined] = useState(true);
  const [weddingDate, setWeddingDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [showError, setShowError] = useState(false);

  const handleSwitch = e => {
    setWeddingDateDefined(e.target.checked);
    setShowError(false);
  };

  const handleNativeDateChange = event => {
    const date = new Date(event.target.valueAsNumber);
    setWeddingDate(date);
    setShowError(false);
  };

  const handleDateChange = date => {
    if (!Number.isNaN(Date.parse(date))) {
      setWeddingDate(date);
      setShowError(false);
    } else {
      setWeddingDate(null);
      setShowError(false);
    }
  };

  const onSelect = selection => {
    setSelectedSeason(selection.value);
    setShowError(false);
  };

  const submit = () => {
    if (weddingDateDefined) {
      if (weddingDate) {
        ReactPixel.trackCustom('DateFunnel', { weddingDateIsExact: true, weddingDate: weddingDate.getTime() });
        submitWeddingDate(
          weddingDate.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          true
        );
      } else {
        setShowError(true);
      }
    } else if (selectedSeason) {
      ReactPixel.trackCustom('DateFunnel', { weddingDateIsExact: false, weddingSeason: selectedSeason });
      submitWeddingDate(selectedSeason, false);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <Container>
        <QuestionHeader>{i18n.t(k.QUESTION_WEDDING_DATE)}</QuestionHeader>
        <FieldContainer>
          <FormControlLabel
            className="color-background-default"
            control={<StyledSwitch checked={weddingDateDefined} onChange={handleSwitch} value="weddingDateDefined" />}
            label={weddingDateDefined ? i18n.t(k.YES_WE_KNOW) : i18n.t(k.NO_WE_DONT_KNOW)}
          />

          <FadeOuterContainer>
            <FadeContainer visible={weddingDateDefined}>
              <DatePickerContainer>
                {!isMobile() && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="filled"
                      format="dd.MM.yyyy"
                      margin="normal"
                      disablePast
                      id="date-picker-inline"
                      value={weddingDate}
                      onChange={handleDateChange}
                      invalidDateMessage={i18n.t(k.INVALID_DATE)}
                      invalidLabel={i18n.t(k.INVALID_DATE)}
                      maxDateMessage={i18n.t(k.INVALID_DATE)}
                      minDateMessage={i18n.t(k.DATE_MUST_BE_IN_FUTURE)}
                      autoComplete="off"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}

                {isMobile() && (
                  <TextField
                    className="native-picker"
                    id="date"
                    label=""
                    variant="filled"
                    value={formatDate(weddingDate)}
                    type="date"
                    onChange={handleNativeDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </DatePickerContainer>
              {showError && <ErrorMessage>{i18n.t(k.MAKE_SELECTION)}</ErrorMessage>}
            </FadeContainer>
            <FadeContainer visible={!weddingDateDefined}>
              <SelectionContainer smallHeight>
                {getUpcomingFourSeasons().map(season => {
                  return (
                    <IconBox
                      active={selectedSeason === season.name}
                      image={`/assets/seasons/${season.image}.jpg`}
                      text={season.name}
                      question="weddingSeason"
                      selectValue={season.name}
                      onSelect={onSelect}
                      key={season.name}
                      weddingStyle={weddingStyle}
                      smallHeight
                    />
                  );
                })}
              </SelectionContainer>
              {showError && <ErrorMessage>{i18n.t(k.MAKE_SELECTION)}</ErrorMessage>}
            </FadeContainer>
          </FadeOuterContainer>
        </FieldContainer>
        <ButtonContainer>
          <BackButton onClick={() => onBack()} variant="contained" color="secondary">
            {i18n.t(k.BACK)}
          </BackButton>
          <NextButton onClick={submit} variant="contained">
            {i18n.t(k.NEXT)}
          </NextButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default WeddingDate;
