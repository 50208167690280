import styled from 'styled-components';

export default styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;
  background-color: #000000bf;
  color: white !important;
  padding: 6px;
  font-size: 14px;
  a {
    color: white !important;
  }
`;
