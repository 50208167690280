import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';

export default styled.div`
  position: absolute;
  background-image: url(${defaultStyles.flower.assets.element2});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
`;
