import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export default styled(Card)`
  width: 700px;
  max-width: 94vw;
  background-color: transparent;
  margin: auto;
  box-shadow: none;
  padding: 10px;
  overflow: visible;
`;
