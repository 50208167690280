import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useEffect, useCallback, useContext, useReducer, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import ReactGA from 'react-ga4';

import useInterval from '../../../../Shared/HookUtils/useInterval';
import UserContext from '../../../../Shared/Context/UserContext';
import FullscreenGallery from '../../../../Shared/Components/FullscreenGallery/FullscreenGallery';
import SimulatedUploadButton from '../../../../Shared/Components/UploadComponent/SimulatedUploadButton';
import UploadPreview from '../../../../Shared/Components/UploadComponent/UploadPreview';
import UploadStyleContainer from '../../../../Shared/Components/UploadComponent/UploadStyleContainer';
import { ReactComponent as CameraSvg } from '../../../../_assets/svg/camera.svg';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import ElementContainer from '../../Components/ElementContainer';
import RefreshButton from '../../Components/RefreshButton';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import CreatorNotRegisteredHintDialog from '../../Components/WeddingStatusHints/CreatorNotRegisteredHint/CreatorNotRegisteredHintDialog';

import EmptyState from './Components/EmptyState/EmptyState';
import LoadingState from '../../../../Shared/Components/LoadingState/LoadingState';
import ImageComponent from './Components/OnPageGallery/ImageComponent';
import GalleryContainer from './Components/GalleryContainer';
import InputContainer from '../../../../Shared/Components/UploadComponent/InputContainer';
import photoReducer from './Reducer/PhotoReducer';
import NoPackageBoughtHintDialog from '../../Components/WeddingStatusHints/NoPackageBoughtHint/NoPackageBoughtHintDialog';
import isDemoWedding from '../../../../Shared/FunctionUtils/isDemoWedding';
import DemoWeddingHintDialog from '../../Components/WeddingStatusHints/DemoWeddingHint/DemoWeddingHintDialog';
import ContentCardWithInfoItem from '../../Components/ContentCardWithInfoItem';
import { Helmet } from 'react-helmet';

const initialState = {
  loadingPhotos: false,
  loadingDelete: false,
  photos: [],
  fullscreenPhoto: null,
};

const PhotoPage = ({ weddingId, weddingConfig, pageData, scrollToTop }) => {
  const [showCreatorNotRegisteredDialog, setShowCreatorNotRegisteredDialog] = useState(false);
  const [showNoPackageBoughtDialog, setShowNoPackageBoughtDialog] = useState(false);
  const [showDemoWeddingHintDialog, setShowDemoWeddingHintDialog] = useState(false);
  const [state, dispatch] = useReducer(photoReducer, initialState);
  const { fidiraUser } = useContext(UserContext);

  const getUploadParams = async file => {
    const { meta } = file;
    if (isDemoWedding(weddingId)) {
      setShowDemoWeddingHintDialog(true);
      file.remove();
      return {};
    } else if (!weddingConfig.creatorIsRegistered) {
      setShowCreatorNotRegisteredDialog(true);
      file.remove();
      return {};
    } else if (weddingConfig.selectedPackage === 'basic' && state.photos.length > 4) {
      setShowNoPackageBoughtDialog(true);
      file.remove();
      return {};
    } else {
      try {
        const response = await axios.post('photo-presign-url/', {
          weddingId: weddingId,
          name: meta.name,
          contentType: meta.type,
          height: meta.height,
          width: meta.width,
        });
        const { fields, url } = response.data;
        const fileUrl = url + fields.key;
        ReactGA.event({
          category: `EHO_Event`,
          action: `Wedding-${weddingId}-Photo-Added`,
        });
        return { fields, meta: { fileUrl }, url: url };
      } catch (error) {
        return {};
      }
    }
  };

  const handleChangeStatus = (file, status) => {
    if (status === 'done') {
      const { meta } = file;
      const [fileName] = meta.fileUrl.split('/').slice(-1);
      dispatch({
        type: 'ADD_PHOTO',
        photo: {
          createdByUser: true,
          creatorDisplayName: fidiraUser.displayName,
          height: meta.height,
          id: `__PHO_${fileName}`,
          likedByUser: false,
          numberOfLikes: 0,
          src: meta.fileUrl.replace('original/', '/original/'),
          srcLarge: meta.fileUrl.replace('original/', '/original/'),
          width: meta.width,
        },
      });
      file.remove();
    }
  };

  const loadPhotos = async () => {
    if (weddingId && weddingConfig.creatorIsRegistered) {
      dispatch({ type: 'START_LOAD_PHOTOS' });
      const { data: photos } = await axios.get(`${weddingId}/photo-read/`);
      dispatch({ type: 'END_LOAD_PHOTOS', photos: photos });
    }
  };

  const handleDelete = async targetId => {
    dispatch({ type: 'START_DELETE_PHOTO' });
    await axios.delete(`${weddingId}/photo/${targetId}/photo-delete/`);
    const { data: photos } = await axios.get(`${weddingId}/photo-read/`);
    dispatch({ type: 'END_DELETE_PHOTO', photos: photos });
  };

  useEffect(() => {
    loadPhotos();
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    loadPhotos();
  }, 60000);

  const toggleLikeOnPhoto = useCallback(
    (targetId, add) => {
      if (add) {
        axios.post(`like-create/`, {
          weddingId: weddingId,
          targetId: targetId,
        });
        dispatch({ type: 'ADD_LIKE', target: targetId });
      } else {
        axios.delete(`${weddingId}/like/${targetId}/like-delete/`);
        dispatch({ type: 'REMOVE_LIKE', target: targetId });
      }
    },
    [weddingId]
  );

  const onFullscreen = useCallback(index => {
    dispatch({ type: 'TOGGLE_FULLSCREEN', index: index });
  }, []);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Photos`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {pageData.elements.map((item, index) => {
          switch (item.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithTitle
                    title={item.title}
                    subtitle={item.subtitle}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'PHOTO', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'PHOTO' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.infoItem.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithInfoItem
                    title={item.title}
                    description={item.description}
                    imageUrl={item.imageUrl}
                    imagePosition={item.imagePosition}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{
                      index: index,
                      contentPage: 'PHOTO',
                      elementType: elementTypes.infoItem.name,
                    }}
                  ></ContentCardWithInfoItem>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'PHOTO' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.photoGallery.name:
              return (
                <div key={index}>
                  <CreatorNotRegisteredHintDialog
                    setShow={setShowCreatorNotRegisteredDialog}
                    show={showCreatorNotRegisteredDialog}
                  ></CreatorNotRegisteredHintDialog>
                  <NoPackageBoughtHintDialog
                    setShow={setShowNoPackageBoughtDialog}
                    show={showNoPackageBoughtDialog}
                    restriction="photos"
                  ></NoPackageBoughtHintDialog>
                  <DemoWeddingHintDialog
                    show={showDemoWeddingHintDialog}
                    setShow={setShowDemoWeddingHintDialog}
                  ></DemoWeddingHintDialog>
                  <UploadStyleContainer className="color-background-white">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      accept="image/*"
                      PreviewComponent={UploadPreview}
                      styles={{
                        dropzoneActive: { borderColor: '#333333', borderStyle: 'dashed', backgroundColor: '#fafafaaa' },
                      }}
                      inputContent={() => (
                        <InputContainer key={0}>
                          <CameraSvg></CameraSvg>
                          <div>
                            <span>{i18n.t(k.DROP_PHOTOS_HERE_OR)} </span>
                            <br></br>
                            <SimulatedUploadButton>{i18n.t(k.SHOOT_PHOTO)}</SimulatedUploadButton>
                          </div>
                        </InputContainer>
                      )}
                    />
                  </UploadStyleContainer>
                  <GalleryContainer className="color-background-white">
                    <RefreshButton refreshing={state.loadingPhotos} startRefresh={loadPhotos}></RefreshButton>
                    {weddingConfig.creatorIsRegistered &&
                      state.loadingPhotos &&
                      state.photos &&
                      state.photos.length === 0 && <LoadingState message="Fotos werden geladen..." />}
                    {(!weddingConfig.creatorIsRegistered ||
                      (!state.loadingPhotos && state.photos && state.photos.length === 0)) && <EmptyState />}
                    {weddingConfig.creatorIsRegistered && state.photos && state.photos.length > 0 && (
                      <>
                        {state.photos.map((photo, index) => (
                          <ImageComponent
                            onFullscreen={onFullscreen}
                            key={index}
                            index={index}
                            photo={photo}
                            addLike={() => toggleLikeOnPhoto(photo.id, true)}
                            removeLike={() => toggleLikeOnPhoto(photo.id, false)}
                          />
                        ))}
                        {state.fullscreenPhoto !== null && (
                          <FullscreenGallery
                            photos={state.photos.map(photo => {
                              return {
                                thumbnail: photo.src,
                                original: photo.srcLarge,
                                likedByUser: photo.likedByUser,
                                numberOfLikes: photo.numberOfLikes,
                                creatorDisplayName: photo.creatorDisplayName,
                                createdByUser: photo.createdByUser,
                                id: photo.id,
                              };
                            })}
                            useCase="wedding"
                            loadingDelete={state.loadingDelete}
                            addLike={id => toggleLikeOnPhoto(id, true)}
                            removeLike={id => toggleLikeOnPhoto(id, false)}
                            handleDelete={handleDelete}
                            onFullscreen={onFullscreen}
                            fullscreenIndex={state.fullscreenPhoto}
                          ></FullscreenGallery>
                        )}
                      </>
                    )}
                  </GalleryContainer>
                </div>
              );

            default:
              return null;
          }
        })}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default PhotoPage;
