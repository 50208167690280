const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Merhaba ',
  [k.ENTER_NEW_PASSWORD]:
    'Lütfen yeni şifrenizi buraya girin. Otomatik olarak giriş yapacak ve yeniden yönlendirileceksiniz.',
  [k.UNSUBSCRIBED_SUCCESSFULLY]:
    'Sizi başarıyla posta listemizden kaldırdık. Artık yalnızca düğününüzle doğrudan ilgili e-postalar alacaksınız. Düğününüzü tamamen platformumuzdan silmek isterseniz lütfen bize e-posta gönderin.',
  [k.VOUCHER_USED_SUCCESSFULLY]: 'Kupon başarıyla girildi. İndirimi kullanmak için şimdi ödeme işlemini başlatın.',
  [k.MINIMUM_6_CHARACTERS]: 'Lütfen en az 6 karakter kullanın.',
  [k.SAVE]: 'Kaydet',
  [k.UNABLE_TO_RESET_PASSWORD]: 'Şifrenizi sıfırlayamadık.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'Şifre başarıyla değiştirildi',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'Şifrenizi değiştirdik! Artık yeni şifrenizle giriş yapabilirsiniz. Sizi şimdi yönlendiriyoruz.',
  [k.FUNCTIONS]: 'Özellikler',
  [k.DESIGNS]: 'Temalar',
  [k.PRICES]: 'Fiyatlandırma',
  [k.FAQ]: 'SSS',
  [k.BLOG]: 'Blog',
  [k.CREATE_NOW_FREE]: 'Şimdi ücretsiz oluşturun',
  [k.PERFECT_PAGE_FOR_YOU]: 'Web siteniz size özel!',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]: 'Tasarım konfigüratörümüz ile sadece birkaç tıklamayla mükemmel tasarıma ulaşın.',
  [k.DESIGN]: 'Tasarım',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: 'Hayallerinizdeki düğünü nasıl tarif edersiniz?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Tasarım konfigüratörünü başlatmak için görsellerden birine tıklayın',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Şimdi ücretsiz oluşturun',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Sıkça Sorulan Sorular',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: 'Herhangi bir sorunuz veya geri bildiriminiz var mı?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'Sizden haber almak için sabırsızlanıyoruz!',
  [k.CONTENT_AND_DESIGN]: 'İÇERİK & TASARIM',
  [k.PHOTO_GALLERY]: 'Fotoğraf Galerileri',
  [k.RSVP_CARD]: 'RSVP/Tepki Kartı',
  [k.PLAYLIST]: 'Çalma Listesi',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'ÇOK DİLLİLİK & KENDİ ALANI',
  [k.DURATION_PASSWORD_PROTECTION]: 'ÇALIŞMA ZAMANI VE ŞIFRE KORUMASI',
  [k.COST_SUPPORT]: 'MALİYETLER & DESTEK',
  [k.WHAT_CONTENT_TYPICALLY]: 'Bir düğün sitesi için tipik içerik nedir?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    'Düğün web sayfasını tüm detaylar (düğün tarihimiz gibi) kesinleşmeden oluşturabilir miyim?',
  [k.CAN_CUSTOMIZE_DESIGN]: 'Düğün web sitemin tasarımını özelleştirebilir miyim?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]: 'Herkes tarafından hemen görülmeden ana sayfada değişiklik yapmak mümkün mü?',
  [k.PHOTO_UPLOAD_LIMIT]: 'Galeriye yükleyebileceğimiz fotoğraf sayısında bir sınır var mı?',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: 'Fotoğraflar orijinal boyutlarında mı kaydedilecek?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: 'Misafirler siteye yüklenen fotoğrafları indirebilir mi?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]:
    'Fotoğraf galerisini partimiz sırasında canlı bir slayt gösterisi için kullanabilir miyiz?',
  [k.HOW_DOES_R_S_V_P_WORK]: 'LCV özelliği nasıl çalışıyor?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: 'Misafirler çalma listesine kendi şarkılarını ekleyebilir mi?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: "DJ'imiz çalma listesine erişebilir mi?",
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: 'Ana sayfamızın farklı dillerde olması mümkün mü?',
  [k.OWN_DOMAIN_POSSIBILITY]: 'Düğün web sitemiz için kendi alan adımızı alabilir miyiz?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: 'Düğün web sitemiz ne kadar süre kullanılabilir olacak?',
  [k.PROTECT_SITE_WITH_PASSWORD]: 'Sitemizi şifre ile koruyabilir miyiz?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: "EureHochzeitOnline.com'u kullanmak ücretsiz mi?",
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    "EureHochzeitOnline.com'da Düğün Web Sayfamı oluştururken nasıl yardım alabilirim?",
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    'Düğün web sayfanızın içeriği düğününüzün kendisi kadar bireyseldir. Tarih ve yer gibi temel bilgilerin yanı sıra, kıyafet kodları, yol tarifleri, özel istekler, görevli iletişim bilgileri veya planlanmış bir zaman çizelgesi ekleyebilirsiniz. Uzaktan seyahat eden ve konaklamaya ihtiyaç duyan konuklar için otel önerilerini listeleyebilirsiniz. Aşk hikayenizi konuklarınızla paylaşmak veya eşiniz hakkında kişisel sözler yazmak da harika bir fikirdir. Yaratıcılığınızın sınırı yok.',
  [k.YES_FEASIBLE_ADVANTAGES]:
    'Evet, bunu yapmak kolaydır ve hatta önemli avantajlar sunar. Düğün davetiyeleriniz gönderildikten sonra bile istediğiniz zaman bilgi ekleyebilir ve güncellemeler yapabilirsiniz.',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Evet, EureHochzeitOnline.com düğün web siteniz için çeşitli tasarım seçenekleri sunuyor. Renkleri, yazı tiplerini ve ek öğeleri özelleştirebilirsiniz. Herhangi bir sorunuz veya yorumunuz varsa lütfen bizimle iletişime geçin.',
  [k.INDEED_CHANGES_VISIBLE]:
    'Aslında, web sitenizdeki tüm değişiklikler gerçek zamanlı olarak tüm ziyaretçiler tarafından anında görülebilecektir. Şu anda değişiklikleri önceden planlamak veya gizlemek için bir özellik sunmuyoruz.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    'Ücretsiz sürümde galerinize yükleyebileceğiniz fotoğraf sayısı sınırlıdır. Bunun nedeni, depolama ve trafik ile ilgili maliyetlerimiz olmasıdır. Ancak Premium veya Domain paketlerini satın alırsanız galerinize sınırsız sayıda fotoğraf yükleyebileceksiniz. Mükemmel düğün fotoğraflarını nasıl elde edeceğinize dair ipuçları için blogumuza göz atın.',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    'Fotoğraflarınızı yüklediğinizde, her görüntü için farklı boyutlar oluşturuyoruz. Ziyaretçinin ekran boyutuna bağlı olarak, mümkün olan en iyi görüntü kalitesiyle hızlı yükleme süreleri sağlamak için en uygun boyut otomatik olarak seçilir. Bununla birlikte, fotoğrafların orijinal dosyalarını da kaydediyoruz, böylece onları da alabilirsiniz.',
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Evet, misafirler yüklenen fotoğrafları indirebilir.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    "Kesinlikle! Aslında bu, EureHochzeitOnline'ın en popüler özelliklerinden biridir. Tek ihtiyacınız olan sabit bir İnternet bağlantısı ve tabii ki bir ekran veya projektör.",
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'RSVP özelliği, düğün web sitenizin giriş bilgilerini konuklarınızla paylaşmanızı kolaylaştırır. Düğün sitenizi ziyaret ettiklerinde, onlardan geri bildirimlerini bırakmaları istenecektir (örneğin, kaç kişiyle katılacakları, çocuk veya yetişkin olup olmadıkları ve herhangi bir özel yemek isteği, alerji veya diğer gereksinimler). Misafir alanında, istediğiniz zaman kabul ve iptal sayılarını takip edebilirsiniz. Adım adım talimatlar için blogumuza bakın.',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    'Kesinlikle! Misafirleriniz en sevdikleri şarkıları önerebilir ve bunları çalma listesine ekleyebilirler. Böylece yalnızca konuklarınızın çeşitliliğini kutlamakla kalmaz, aynı zamanda sizin ve sevdikleriniz için önemli olan müziğin keyfini çıkarabilirsiniz.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    "Tabii ki. DJ'inizi web sitenize davet edin ve tüm istekleriniz onun emrinde olsun - hatta isterseniz partiniz sırasında canlı olarak.",
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Evet, web sitenizi birden fazla dilde hazırlayabilirsiniz. Bu özellik Premium pakette mevcuttur ve tüm bilgileri farklı dillere çevirebilirsiniz. Düğününüz için yapılandırmayı ayarlayabilmemiz için lütfen bizimle iletişime geçin.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Evet, Premium Paketimiz ile düğün web siteniz için kendi alan adınızı (ör. www.juliaundromeo.de) kolayca rezerve edebilirsiniz. Alan adını sizin için rezerve edebilmemiz ve yönlendirmeyi ayarlayabilmemiz için lütfen bizimle iletişime geçin.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'Düğün web siteniz oluşturulduktan sonra iki yıl boyunca kullanılabilir olacaktır. Bundan sonra, yeni düğün web sitelerine yer açmak için silinecektir. Size önceden hatırlatacağız. İstek üzerine yenileme yapılabilir.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'Tüm düğün web sayfaları, oluşturulduktan sonra size gösterilecek olan bir şifre ile korunmaktadır.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    "Evet, EureHochzeitOnline.com'da ücretsiz olarak bir düğün web sitesi oluşturabilir ve tasarlayabilirsiniz. Ancak, bazı gelişmiş özellikler, özellikle de ek sunucu kaynakları gerektirenler ücretsiz değildir. Hiçbir gizli ücret yoktur.",
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    'Düğün web sitenizi oluşturmak için yardıma ihtiyacınız varsa, bize beeni@eurehochzeitonline.com adresinden bir mesaj göndermekten çekinmeyin. Size yardımcı olmak için mümkün olan en kısa sürede size geri döneceğiz.',
  [k.KEY_FEATURES_OVERVIEW]: 'Bir bakışta en iyi özellikler!',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    'İşte hayallerinizdeki düğünü yaratmak için ihtiyacınız olan her şeyi içeren düğün web siteniz.',
  [k.NEW_NOW]: 'Yeni bir özellik:',
  [k.MULTILINGUAL_WEDDINGS]: 'Çok Dilli Düğünler!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Şimdi yeni: Çok dilli web siteleri!',
  [k.SCHEDULE]: 'Düğün Programı',
  [k.LOCATIONS]: 'Düğün Mekanları',
  [k.RSVP]: 'Cevap Kartı',
  [k.GUESTMANAGEMENT]: 'Konuk Yönetimi',
  [k.HOTEL_RECOMMENDATION]: 'Otel Önerileri',
  [k.PRESENT_TABLE]: 'Hediye Masası',
  [k.OWN_DOMAIN]: 'Özel Alan Adı',
  [k.DIVERSE_LAYOUTS]: 'Aralarından seçim yapabileceğiniz ve özelleştirebileceğiniz sayısız düzen ve tasarım.',
  [k.YOUR_WEDDING_DAY]: 'Düğün gününüz misafirleriniz için özenle organize edildi.',
  [k.CLEAR_DIRECTIONS]: 'Tüm düğün mekanlarına açık yol tarifleri.',
  [k.NO_GUESSWORK]: 'Artık düğüne kimin geleceği hakkında sıkıcı sorular yok.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Tek bir tıklama ile adresleri, alerjileri vb. toplayın!',
  [k.HOTEL_SELECTION_FOR_GUESTS]: 'Konuklarınızın seyahatlerini kolaylaştırmak için otel seçimi!',
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'Düğün kayıtlarınız artık çok kolay.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: 'Düğün fotoğraflarınız ve konuklarınızın fotoğrafları tek bir yerde.',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: 'Siz ve misafirleriniz tarafından oluşturulan kendi Spotify çalma listeniz.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Kendi alan adınıza ve şifre korumasına sahip düğün web sitesi.',
  [k.YOUR_WEDDING_WEBSITE]: 'DÜĞÜN WEB SİTENİZ',
  [k.UNIQUE_LIKE_YOU]: '- SIZIN KADAR BENZERSIZ!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Kurulumu ve özelleştirmesi kolay!',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]:
    'Etkinliğinizin tüm ayrıntıları bir bakışta - ister telefonunuzda ister bilgisayarınızda',
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Misafir onayları ve talepleri parmaklarınızın ucunda',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]: 'Tüm açılardan partiniz - siz ve misafirleriniz için canlı resim galerisi',
  [k.MULTILINGUAL_SUPPORT]: '... tabii ki çok dilli düğünler için de',
  [k.FREE_CREATE]: 'Şimdi ücretsiz oluşturun',
  [k.DEMO_WEBSITE]: 'Demo Web Sitesi',
  [k.OUR_PRICING]: 'Fiyatlarımız',
  [k.UNLOCK_ALL_FEATURES]: 'Tek seferlik bir ücret karşılığında istediğiniz tüm özelliklerin kilidini açın',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: 'Herhangi bir sorunuz veya isteğiniz var mı?',
  [k.WE_ARE_HERE_TO_HELP]: 'Sizden haber bekliyoruz!',
  [k.USER_TESTIMONIALS]: 'Kullanıcılarımızın sesleri',
  [k.THOUSANDS_OF_PEOPLE]: 'Binlerce insan düğünlerini EureHochzeitOnline ile kutladı bile!',
  [k.OUR_GIFT_TIP]: 'Hediye fikrimiz:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Gelin ve damada kişiselleştirilmiş bir düğün web sitesi hediye edin!',
  [k.BRIDE]: 'Gelin',
  [k.F_WITNESS]: 'Baş nedime',
  [k.GROOM]: 'Damat',
  [k.TESTIMONIAL_1]:
    'Web sitemizi oluşturmak çocuk oyuncağıydı. Karmaşık bilgileri bile eklemenin bu kadar kolay olması bizi gerçekten şaşırttı. Gerçekten kendi web sitemizi oluşturabildik. Misafirlerimiz çok etkilendi!',
  [k.TESTIMONIAL_2]:
    'Tüm misafirlerimizin siteyi kendi dillerinde kullanabilmeleri harikaydı. Kendi favori şarkılarını önerebilmelerini sağlayan çalma listesi özelliğine bayıldılar. Bu şekilde, parti müziği aracılığıyla konukların çeşitliliği de kutlanmış oldu.',
  [k.TESTIMONIAL_3]:
    'Web sitemiz düğünümüzün planlanması boyunca paha biçilmez bir yardımcı oldu. Önemli bilgileri istediğimiz zaman güncelleyebildik ve hatta fotoğrafları güzel bir fotoğraf galerisinde sergileyebildik. Konuklarımız canlı slayt gösterisini çok sevdi ve biz daha da çok sevdik.',
  [k.TESTIMONIAL_4]:
    'Bir yılı aşkın bir süre sonra, düğünümüzden Spotify çalma listesini dinlemekten hala keyif alıyoruz. Düğün web sitemiz harika anılarla dolu harika bir düğün geçirmemize yardımcı oldu.',
  [k.THANK_YOU]: 'Teşekkürler!',
  [k.ACTIVATED_MAIL]: 'E-posta adresinizi etkinleştirdiniz!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Düğün web sitesine gitmek için buraya tıklayın',
  [k.AND]: 've',
  [k.BE_REDIRECTED]: 'yönlendirilmek için.',
  [k.SET_A_PASSWORD]: 'Her zaman giriş yapabilmek ve düğününüzü düzenleyebilmek için lütfen bir şifre belirleyin!',
  [k.SET_A_PASSWORD_SHORT]: 'Lütfen bir şifre seçin',
  [k.ERROR_OCCURED]:
    'Bir hata oluştu. Lütfen tekrar deneyin. Hata tekrar tekrar oluşursa, bize bir e-posta gönderebilirsiniz:',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'Zaten bir şifreniz var',
  [k.PASSWORD_ALREADY_EXISTING]: 'Zaten bir şifre oluşturduğunuzu fark ettik. Yapabilirsin',
  [k.GO_TO_WEDDING]: 'buradan düğün ana sayfanıza gidin ve kaydolun',
  [k.I_AGREE]: 'Kabul ediyorum.',
  [k.REGISTER_NOW]: 'Şimdi kayıt olun!',
  [k.YOUR_FREE_WEDDING_WEBSITE]: 'Ücretsiz düğün web siteniz!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'Aşağıdaki düğün ana sayfalarını zaten oluşturdunuz. Muhtemelen yeni bir tane oluşturmak yerine mevcut düğün ana sayfalarını düzenlemeyi tercih edersiniz. Sadece aşağıya tıklayın.',
  [k.WEDDING]: 'Düğün Web Siteleri',
  [k.ENTER_PASSWORD]: 'Lütfen şifrenizi girin',
  [k.USER_ALREADY_EXISTING]: 'Bu e-posta adresiyle zaten kayıtlı bir kullanıcımız var. Lütfen şifrenizi giriniz.',
  [k.BACK]: 'Geri',
  [k.LOGIN]: 'Oturum Aç',
  [k.FORGOT_PASSWORD]: 'Şifrenizi mi unuttunuz? Lütfen buraya tıklayınız.',
  [k.MAIL_SENT]: 'Size bir e-posta gönderdik. Şifrenizi sıfırlamak için lütfen e-postadaki bağlantıya tıklayın.',
  [k.OK]: 'TAMAM',
  [k.PERFECT]: 'Mükemmel,',
  [k.NAME_OF]: 'İsim nedir',
  [k.SPOUSE_M]: 'Seçtiğiniz kişi',
  [k.SPOUSE_F]: 'Seçtiğiniz kişi',
  [k.NEXT]: 'Sonraki',
  [k.WHATS_YOUR_NAME]: 'Harika! İsminiz nedir?',
  [k.PREPARING_1]: 'Düğün uygulamanızı hazırlıyoruz...',
  [k.PREPARING_2]: 'Seçtiğiniz tasarımı kaydediyoruz...',
  [k.PREPARING_3]: 'Birkaç son düzeltme...',
  [k.FINISHED_ENTER_MAIL]:
    'Bitti! Düğün uygulamanızı kaydedebilmemiz ve size giriş bilgilerinizi gönderebilmemiz için lütfen e-posta adresinizi girin.',
  [k.ACCEPT_TERMS]: 'Lütfen Hizmet Şartlarını kabul edin.',
  [k.ENTER_VALID_MAIL]: 'Lütfen geçerli bir e-posta adresi girin.',
  [k.QUESTION_BRIDE_OR_GROOM]: 'Gelin veya damat siz misiniz?',
  [k.QUESTION_WEDDING_DATE]: 'Düğün tarihinizi biliyor musunuz?',
  [k.YES_WE_KNOW]: 'Evet, biliyoruz!',
  [k.NO_WE_DONT_KNOW]: 'Hayır, henüz değil!',
  [k.MAKE_SELECTION]: 'Lütfen bir seçim yapın.',
  [k.QUESTION_WEDDING_LOCATION]: 'Bir yeriniz var mı?',
  [k.YES_WE_HAVE]: 'Evet, var!',
  [k.ENTER_YOUR_LOCATION]: 'Lütfen düğün lokasyonunuzu giriniz.',
  [k.LOCATION_CLASSIC]: 'Klasik',
  [k.LOCATION_COUNTRY]: 'Ülke',
  [k.LOCATION_OUTDOOR]: 'Dış mekan',
  [k.LOCATION_PALACE_HOTEL]: 'Kale',
  [k.QUESTION_WEDDING_STYLE]: 'Hangi tarzı tercih edersiniz?',
  [k.HOTEL]: 'Oteller',
  [k.INFOS]: 'Bilgi',
  [k.LOCATION]: 'Konumlar',
  [k.PHOTOS]: 'Fotoğraflar',
  [k.NO_MUSIC_SAVED]: 'Henüz kaydedilmiş şarkı yok...',
  [k.SEARCH_FOR_SONGS]: 'En sevdiğiniz şarkıyı yukarıda arayın!',
  [k.LISTEN_TO_FULL_LIST]: 'Düğün çalma listesinin tamamını dinleyin',
  [k.OPEN_IN_SPOTIFY]: "Spotify'da açın",
  [k.LISTEN_TO_SINGLE_SONGS]: 'Çalma listesindeki şarkıları tek tek dinleyin',
  [k.NO_RESULT_FOUND]: 'Sonuç bulunamadı',
  [k.CANCEL]: 'İptal',
  [k.ADD_SONG]: 'Bir şarkı ekleyin',
  [k.NO_PHOTOS_UPLOADED_YET]: 'Henüz fotoğraf yüklenmedi...',
  [k.ADD_SOME_PHOTOS]: 'Yukarıya tıklayın ve fotoğraf çekin!',
  [k.DROP_PHOTOS_HERE_OR]: 'Fotoğrafları buraya kaydedin veya',
  [k.SHOOT_PHOTO]: 'Bir fotoğraf çekin',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'Tüm hediyeleri ve girişleri görüntüleyin',
  [k.LOADING_BACKINGS]: 'Girişler yüklendi...',
  [k.NO_PRESENTS_YET]: 'Hediye kaydınıza henüz herhangi bir hediye eklemediniz.',
  [k.PRESENT]: 'Bir hediye ekleyin',
  [k.E_MAIL_ADDRESS]: 'E-posta adresiniz',
  [k.DATE]: 'Hediye tarihi',
  [k.NO_BACKINGS_YET]: 'Henüz giriş yok',
  [k.CLOSE]: 'yakın',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Sadece gelin ve damat tarafından görülebilir)',
  [k.SHOW_ALL_ENTRIES]: 'Tüm girişleri göster',
  [k.EUR]: 'MİKTAR AVRO',
  [k.ADD_BACKING]: 'Katılın',
  [k.BUY]: 'Şimdi al',
  [k.YOUR_BACKING_SAVED]: 'Giriş yaptınız!',
  [k.A_GUEST_ALREADY_BACKED]: 'Kayıt olundu.',
  [k.DELETE_MY_BACKING]: 'Kaydımı sil.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Tüm kayıtlara genel bakış',
  [k.LOADING_RSVPS]: 'Kayıtlar yükleniyor...',
  [k.NO_RSVPS_YET]: 'Henüz cevap kartı alınmadı.',
  [k.RESPONSE]: 'Cevap',
  [k.GUESTS_AND_MENU_WISH]: 'Misafirler & Menü Talebi',
  [k.QUESTIONS_AND_REMARKS]: 'Soru/Yorum',
  [k.WILL_ATTEND]: 'Bağlılık',
  [k.WILL_NOT_ATTEND]: 'İptal',
  [k.SHOW_ALL_RSVPS]: 'Tüm talepleri görüntüle',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'Cevabı zaten şu adrese göndermişsiniz',
  [k.CANNOT_CHANGE_ANY_MOR]: 'zaten gönderilmiştir. Detaylar değiştirilemez.',
  [k.MENU_WISH]: 'İstek menüsü',
  [k.RSVP_LOADING]: 'Yanıt kartı yükleniyor...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Lütfen aşağıda bekleyebileceğimiz herkesin bir listesini yapın',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Lütfen aşağıda bekleyebileceğimiz herkesin bir listesini yapın ve ayrıca menü tercihlerinizi bize bildirin',
  [k.GUEST]: 'Misafirlerimiz',
  [k.ADD_GUEST]: 'Başka bir misafir ekleyin',
  [k.SUBMIT_RSVP]: 'Cevap kartı gönder',
  [k.WEBSITE]: 'Web sitesi',
  [k.OUR_PACKAGES]: 'Paketlerimiz',
  [k.PACKAGE_BASIC]: 'Temel',
  [k.PACKAGE_COMPLETE]: 'Tamamlandı',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'En Çok Satanlar',
  [k.SELECTED]: 'Seçilmiş',
  [k.SELECT]: 'Seçiniz',
  [k.CAN_UPGRADE_LATER]: 'Endişelenmeyin, aradaki farkı ödeyerek daha sonra her zaman yükseltme yapabilirsiniz.',
  [k.LOOK_IN_FAQ]: 'Hala sorularınız mı var? SSS bölümümüze göz atın!',
  [k.PACKAGE_FEATURES_DESIGN]: 'Tasarımlar, Renkler, Yazı Tipleri',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'Şifreli alt alan adınız',
  [k.PACKAGE_FEATURES_MOBILE]: 'Masaüstü ve mobil cihazlar için optimize edilmiş',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Zaman çizelgesi ile günlük program',
  [k.PACKAGE_FEATURES_LOCATION]: 'Konum planlayıcı ve bilgi',
  [k.PACKAGE_FEATURES_INFOS]: 'Bilgi sayfaları (SSS, Hediyeler, vb.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Misafir kaydı ve yönetimi',
  [k.PACKAGE_FEATURES_MUSIC]: 'Konukların müzik istekleri',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Misafir fotoğraf yüklemeleri',
  [k.PACKAGE_FEATURES_SUPPORT]: 'E-posta yoluyla müşteri hizmetleri',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Çok dilli web sitesi',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'Kendi alan adınız (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Bir profesyonelden özel tasarım',
  [k.MAX_FIVE]: 'maks. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Ödeme durumunu kontrol edin',
  [k.PAYMENT_SUCCESSFULL]:
    'Teşekkür ederiz! Ödeme başarılı oldu, artık düğün uygulamanızı aşağıdaki pakette kullanabilirsiniz:',
  [k.PAYMENT_FAILED]:
    'Maalesef ödeme başarısız oldu. Tekrar deneyebilir veya bize şu adresten bir e-posta gönderebilirsiniz',
  [k.ACTIVATE_PACKAGE_NOW]: 'Şimdi kilidi aç:',
  [k.PAY]: 'Ödeme',
  [k.EDITING_MODE]: 'Düzenleme Modu',
  [k.ACTIVE]: 'aktif',
  [k.NOT_ACTIVE]: 'aktif değil',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'Bir hata meydana geldi. Teknik geri bildirim:',
  [k.TRY_AGAIN_PLEASE]: 'Lütfen tekrar deneyin. Hata tekrarlanırsa, bize şu adresten e-posta gönderebilirsiniz',
  [k.PACKAGE_UPGRADE]: 'Yükseltme Paketi',
  [k.PACKAGE_ALREADY_BOUGHT]: 'Düğününüz için zaten bir paket satın aldınız.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Elbette, sadece aradaki farkı ödeyerek web sitenizi daha yüksek bir pakete yükseltebilirsiniz. Lütfen bize kısa bir e-posta gönderin ve biz her şeyi halledelim.',
  [k.REGISTRATION_NOT_COMPLETE]: 'Kayıt işlemi henüz tamamlanmadı. Lütfen size gönderdiğimiz bağlantıya tıklayın.',
  [k.YOU_HAVE_AN_EMAIL]: 'Bizden bir e-posta aldınız.',
  [k.YOU_MUST_REGISTER_FIRST]: 'Düğün web sitenizi düzenlemek için kaydınızı tamamlamanız gerekiyor.',
  [k.CREATOR_MUST_REGISTER]:
    'Her şey ücretsiz ve çok hızlı: size gönderdiğimiz e-postadaki bağlantıya tıklamanız yeterli. E-postayı bulamıyor musunuz? Sorun değil, sadece buraya tıklayın:',
  [k.HERE]: 'Buraya tıklayın',
  [k.E_MAIL_RESENT]: 'E-posta tekrar gönderildi.',
  [k.CONFIRMATION_RESENT_TO]: 'Onay bağlantısını size yeniden gönderdik:',
  [k.CAN_TAKE_SOME_MINUTES]:
    'E-postanın size ulaşması birkaç dakika sürebilir. Sorun yaşamaya devam ederseniz, lütfen bize e-posta gönderin, size yardımcı olalım:',
  [k.RESENDING_MAIL]: 'E-posta tekrar gönderilecektir.',
  [k.DEMO_WEDDING_HINT]: 'Demo site - kendi sitenizi ücretsiz oluşturun ve deneyin!',
  [k.CREATE_OWN_WEBSITE]: 'Kendi web sitenizi oluşturun!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Demo sitesinde bazı özellikler devre dışı bırakılmıştır.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'Kayıt olabilir, kendi düğün web sitenizi oluşturabilir ve tüm özellikleri ücretsiz olarak deneyebilirsiniz!',
  [k.NO_PACKAGE_BOUGHT_HINT]:
    'Ücretsiz temel sürüm - sınırsız fotoğraf, konuk ve daha fazla özellik için şimdi yükseltin.',
  [k.LETS_UPGRADE]: 'Hadi yükseltelim.',
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'Ücretsiz temel paket için maksimum fotoğraf sayısına ulaştınız.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'Sınırsız sayıda fotoğraf yüklemek için premium planlarımızdan birine yükseltebilirsiniz.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]:
    'Sınırsız sayıda şarkı eklemek için premium planlarımızdan birine yükseltebilirsiniz.',
  [k.CONFIRM_DELETE_TITLE]: 'Onayla Sil',
  [k.CONFIRM_DELETE_TEXT]: 'Bu öğeyi silmek istediğinizden emin misiniz?',
  [k.DELETE]: 'Öğeyi Sil',
  [k.ADD_ELEMENT]: 'Yeni bir öğe ekleyin',
  [k.WHICH_ELEMENT_TO_ADD]: 'Ne tür bir öğe eklemek istiyorsunuz?',
  [k.SELECT_ICON_FOR_LOCATION]: 'Konum için bir simge seçin',
  [k.IMAGE_POSITION]: 'Görüntü Konumu',
  [k.SELECT_IMAGE]: 'Resim seçiniz',
  [k.UPLOAD]: 'Resim Yükle',
  [k.SELECTED_IMAGE]: 'Seçilen resim',
  [k.NO_IMAGE]: 'Resim yok',
  [k.OTHER_IMAGE]: 'Diğer resim',
  [k.EDIT_WEDDING_BASE_DATA]: 'Düğün ayrıntılarını düzenleyin',
  [k.ENTER_NAME_OF_BRIDE]: 'Lütfen gelinin adını giriniz.',
  [k.ENTER_NAME_OF_GROOM]: 'Lütfen damadın adını giriniz.',
  [k.SELECT_DATE]: 'Lütfen bir tarih seçiniz.',
  [k.HINT_CHANGE_URL]:
    'Not: Düğün web sayfanızın bulunacağı adresi değiştirmek isterseniz, lütfen bize bir e-posta gönderin:',
  [k.EDIT_COUNTDOWN]: 'Düğün Geri Sayımını Düzenle',
  [k.EDIT_INFO_BOX]: 'Bilgi kutusunu düzenle',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Büyük fotoğraflı düzenleme kutusu',
  [k.EDIT_LOCATION]: 'Konumu Düzenle',
  [k.EDIT_PLACE]: 'Konumu Düzenle',
  [k.ADD_LOCATION]: 'Konum Ekle',
  [k.ADD_PLACE]: 'Konum Ekle',
  [k.SELECT_SEARCH_RESULT]: 'Lütfen bir arama sonucu seçin.',
  [k.EDIT_PAGE_TITLE]: 'Sayfa başlığını düzenle',
  [k.EDIT_PRESENTS]: 'Hediye kayıt defterini düzenle',
  [k.PRESENT_INTRO_TEXT]: 'Misafirlerinize ilham vermek için kendi kişiselleştirilmiş hediye kaydınızı oluşturun.',
  [k.ADDITIONAL_ENTRY]: 'Daha fazla giriş',
  [k.EDIT_RSVP]: "RSVP'yi Düzenle",
  [k.MENU_WISHES_ACTIVATED]: 'Misafirlerinizin LCV sırasında menü tercihlerini belirtmelerini istiyor musunuz?',
  [k.EDIT_SCHEDULE_ITEM]: 'Menü öğesini düzenle',
  [k.NAVIGATION]: 'Menü',
  [k.COUPLE]: 'Gelin ve Damat',
  [k.MUSIC]: 'Müzik Listesi',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'Damat olarak kayıt oldunuz.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'Gelin olarak kayıt oldunuz.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'Düğün web sitesi adresiniz:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Misafirleriniz için düğün kodu:',
  [k.CURRENT_PACKAGE]: 'Mevcut paketiniz:',
  [k.UPGRADE]: 'Yükseltme',
  [k.LOGOUT]: 'Çıkış Yap',
  [k.FOR_GUESTS]: 'Misafirler için',
  [k.INVISIBLE]: 'görünmez',
  [k.VISIBLE]: 'Görünür',
  [k.WEDDING_NOT_FOUND]: 'Düğün sayfası bulunamadı.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    'Üzgünüz, girdiğiniz adreste bir düğün web sayfası bulamadık. Yanlış yazmış olabilir misiniz? Lütfen tekrar deneyin.',
  [k.IF_ERROR_REPEATS]: 'Hata tekrarlanırsa, bize şu adresten e-posta gönderebilirsiniz',
  [k.ERROR_GENERAL]: 'Bir hata oluştu',
  [k.SORRY_WRITE_MAIL]:
    'Üzgünüz. Lütfen tekrar deneyin. Hata tekrarlanırsa, bize bir e-posta gönderebilirsiniz ve size daha fazla yardımcı olacağız.',
  [k.MOVE_UP]: 'Yukarı hareket et',
  [k.MOVE_DOWN]: 'Aşağı kaydırın',
  [k.OH_SORRY]: 'Ah canım... Bir hata yaptım.',
  [k.ERROR_REFRESH_PAGE]:
    'Çok özür dileriz. Lütfen sayfayı yeniden yüklemekten çekinmeyin ve tekrar deneyin. Hata tekrarlanırsa, bize şu adresten e-posta gönderebilirsiniz',
  [k.IF_YOU_WANT_TO_HELP]:
    'Hatayı daha hızlı bulmamıza ve düzeltmemize yardımcı olmak isterseniz, aşağıdaki metni e-postanıza kopyalayabilirsiniz:',
  [k.ENTER_DISPLAY_NAME]:
    'Harika! Lütfen adınızı girin - takma ad da işe yarar. Örneğin, yüklediğiniz fotoğrafların altında görünecektir.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Lütfen bir isim girin.',
  [k.WE_SENT_EMAIL]: 'Size bir e-posta gönderdik. Lütfen şifrenizi sıfırlamak için e-postadaki bağlantıya tıklayın.',
  [k.WELCOME_BACK]: 'Siteye tekrar hoş geldiniz,',
  [k.ALMOST_THERE]: 'Neredeyse geldik,',
  [k.PLEASE_SET_PASSWORD]: 'Lütfen şimdi bir şifre seçin.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Bir şifre seçin',
  [k.WELCOME_TO_WEDDING_OF]: 'Düğün sitesine hoş geldiniz',
  [k.PLEASE_ENTER_MAIL]: '! Lütfen e-posta adresinizi giriniz.',
  [k.WELCOME_TO_EHO]: "EureHochzeitOnline.com'un düğün platformuna hoş geldiniz! Lütfen e-posta adresinizi giriniz.",
  [k.TO_WEDDING]: 'Düğün için',
  [k.ENTER_WEDDING_CODE]: '! Lütfen düğün kodunu giriniz.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Lütfen düğün kodunu girin.',
  [k.RESTART]: 'Lütfen yeniden başlatın',
  [k.UPLOAD_PREPARING]: 'Yükleme hazırlanıyor...',
  [k.UPLOAD_STARTED]: 'Yükleme devam ediyor...',
  [k.UPLOAD_FINISHING]: 'Yükleme tamamlandı...',
  [k.UPLOAD_CANCELED]: 'Yükleme iptal edildi...',
  [k.INVALID_FILE_TYPE]: 'Dosya türü geçerli değil...',
  [k.MAX_FILE_NUMBER]: 'Maksimum dosya sayısı aşıldı...',
  [k.FILE_TOO_LARGE]: 'Dosya çok büyük...',
  [k.DAYS_TO_GO]: 'Günler kaldı!',
  [k.DAY_TO_GO]: 'Bir gün daha!',
  [k.TODAY]: 'Bugün',
  [k.INVALID_DATE]: 'Tarih geçerli değil',
  [k.DATE_MUST_BE_IN_FUTURE]: 'Tarih gelecekte olmalıdır',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Konum adresi veya adı',
  [k.ENTER_SONG_WISH]: 'En sevdiğiniz şarkıyı buraya girin...',
  [k.NAME_OF_GUEST]: 'Misafirin adı',
  [k.QUESTION_REMARK_MAX_1000]: 'Sorular/Yorumlar (en fazla 1000 karakter)',
  [k.LEFT]: 'Sol',
  [k.TOP]: 'Üst',
  [k.RIGHT]: 'Sağ',
  [k.BOTTOM]: 'Alt',
  [k.NAME_OF_BRIDE]: 'Gelinin adı',
  [k.NAME_OF_GROOM]: 'Damadın Adı',
  [k.TITLE]: 'Başlık',
  [k.DESCRIPTION]: 'Etkinlik açıklaması',
  [k.NAME_OF_PLACE]: 'Yer adı',
  [k.STREET]: 'Sokak',
  [k.HOUSENUMBER]: 'Hayır.',
  [k.ZIP]: 'POSTA KODU',
  [k.CITY]: 'Şehir/Kasaba',
  [k.COUNTRY]: 'Ülke/ Eyalet',
  [k.HINTS_FOR_GUESTS]: 'Misafirler için notlar',
  [k.TITLE_FOR_THIS_PAGE]: 'Bu sayfa için başlık',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Bu sayfa için altyazı',
  [k.LABEL]: 'Tanımlama',
  [k.AMAZON_URL]: "Amazon URL'si (isteğe bağlı)",
  [k.COSTS]: 'Maliyet',
  [k.NEW_FOOD_OPTION]: 'Yeni menü seçeneği',
  [k.TIME]: 'Zaman',
  [k.SCHEDULE_ITEM]: 'Program öğesi',
  [k.YOUR_NAME_OR_NICKNAME]: 'Adınız veya takma adınız',
  [k.YOUR_EMAIL_ADDRESS]: 'E-posta adresiniz',
  [k.YOUR_WEDDING_CODE]: 'Düğün kodunuz',
  [k.COULD_NOT_SAVE_WEDDING]: 'Üzgünüz, ancak düğünü kaydedemedik.',
  [k.ALREADY_HAVE_WEDDING]: 'Zaten düğün ana sayfalarınız var',
  [k.CREATE_NEW]: 'Yeni bir tane oluşturun',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'Bilgilerinize ulaşamadık.',
  [k.COULD_NOT_SEND_MAIL]: 'E-postayı gönderemedik.',
  [k.DELETE_IMAGE]: 'Resmi sil',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: 'Bu resmi silmek istediğinizden emin misiniz?',
  [k.COULD_NOT_LOGIN]: 'Giriş yapamadık.',
  [k.WRONG_PASSWORD_TITLE]: 'Yanlış şifre',
  [k.WRONG_PASSWORD_TEXT]: 'Girdiğiniz şifre yanlış.',
  [k.WRONG_CODE_TITLE]: 'Yanlış düğün kodu',
  [k.WRONG_CODE_TEXT]: 'Girdiğiniz düğün kodu yanlış.',
  [k.LOADING]: 'Yükleniyor...',
  [k.GUEST_NAME_MISSING]: 'Lütfen aşağıdaki misafirlerin isimlerini giriniz:',
  [k.GUEST_FOOD_MISSING]: 'Lütfen aşağıdaki misafirler için menü talebini tamamlayın:',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'Maksimum deneme sayısını aştınız. Daha sonra tekrar deneyebilirsiniz.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'Şifrenizi sıfırlamak için bu bağlantıyı zaten kullandınız.',
  [k.PLEASE_ENTER_NAME]: 'Lütfen bir isim giriniz',
  [k.COUNTDOWN_VISIBLE]: 'Geri sayım görünür.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Geri sayım görünmüyor.',
  [k.ASK_FOR_MENU_WISHES]: 'Evet, menü isteklerini sorunuz',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'Hayır, menü istekleri için sormayın',
  [k.SPRING]: 'Bahar',
  [k.SUMMER]: 'Yaz',
  [k.AUTUMN]: 'Sonbahar',
  [k.WINTER]: 'Kış',
  [k.COULD_NOT_SAVE_PASSWORD]: 'Şifrenizi kaydedemedik.',
  [k.YES_WE_WILL_ATTEND]: 'Evet, düğününüze katılacağız',
  [k.NO_WE_WILL_NOT_ATTEND]: 'Hayır, düğününüze katılmayacağız',
  [k.CONFIRM_DIALOG_TITLE]: 'Emin misiniz?',
  [k.ALERT_DIALOG_TITLE]: 'Not',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Düğün Pastası',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Düğün Töreni',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Resepsiyon ve Şampanya',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Dans ve parti',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Düğün Yemeği ve Açık Büfe',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Düğün Arabası ve Geçit Töreni',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Oyunlar ve eğlence',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Kıyafet Yönetmeliği',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Otel ve konaklama',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Fotoğraf çekimi',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Hediyeler ve armağanlar',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'Genel ve sevgi',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Sayfa Başlığı',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Sayfa Başlığı',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Bu sayfaya hoş geldiniz',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Program Öğesi',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Program öğesi',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: "Saat 15:00'ten itibaren",
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Grup fotoğrafı',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Bilgi Kutusu',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 Kek',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    ' Eğer pasta yapmak isterseniz, lütfen bize bildirin. Renkli bir seçkiyi bir araya getirmek için sabırsızlanıyoruz.',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Büyük fotoğraflı kutu',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'Sizin tanıtımınız',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Burada misafirlerinize kendinizi tanıtabilirsiniz. Onlara nasıl tanıştığınızı anlatın...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Konumlar için harita',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Düğün Yeri',

  [k.DEFAULT_STYLES_MODERN_TITLE]: `Modern`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `Aşkınızın çağdaş ruhunu yansıtan bir ortamda parlamasına izin verin.`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `Klasik`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `Temiz çizgiler, uyumlu renkler ve bir dokunuş zarafet, aşkınızı onurlandıran bir atmosfer yaratır.`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `Vintage`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `Vintage stil, geçmiş dönemlerin cazibesini biraz nostalji ve zarafetle geri getirir.`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `Çiçek`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `Bu düğün stili, çiçeklerin doğal güzelliğini tüm görkemiyle kutlar.`,
  [k.DEFAULT_STYLES_BOHO_TITLE]: `Boho`,
  [k.DEFAULT_STYLES_BOHO_DESCRIPTION]: `Doğa ve yaratıcılığı birleştiren rahat ve alışılmadık bir atmosfer – özgür ruhlar için mükemmel.`,
  [k.DEFAULT_STYLES_BLOSSOM_TITLE]: `Çiçeklenme`,
  [k.DEFAULT_STYLES_BLOSSOM_DESCRIPTION]: `Çiçekler ve bitki örtüsünün zarif güzelliğini vurgulayan romantik bir stil.`,
  [k.NOT_SURE_ABOUT_DESIGN]: `Emin değil misiniz? Endişelenmeyin, daha sonra stilinizi değiştirebilirsiniz ve verilerinizi kaybetmezsiniz.`,
  [k.OWN_PHOTOS_AFTER_REGISTRATION]: `Kendi fotoğraflarınızı ve logolarınızı ücretsiz kayıt sonrası yükleyebilirsiniz.`,
  [k.YOUR_NAMES]: `İsimleriniz`,
  [k.YOUR_WEDDING_DATE]: `Düğün tarihiniz`,
  [k.WEDDING_DATE_FIX]: `Düğün tarihi belirli`,
  [k.WEDDING_DATE_NOT_FIX]: `Düğün tarihi belirli değil`,
  [k.YOUR_WEDDING_STYLE]: `Web sitenizin tarzı`,
  [k.OWN_LOGO]: `Kendi logonuz`,
  [k.START_PHOTO]: `Başlangıç fotoğrafı`,
  [k.USE_START_PHOTO]: `Kendi fotoğrafınızı kullanın mı?`,
  [k.WITH_START_PHOTO]: `Kendi fotoğrafınızla`,
  [k.WITHOUT_START_PHOTO]: `Kendi fotoğrafınız olmadan`,
  [k.ALL_STYLES]: `Tüm tarzlar`,

  [k.COOKIES_01]: 'Sizin için düğün kurabiyeleri var mı?',
  [k.COOKIES_02]:
    "'Tümünü Onayla 'yı tıkladığınızda tüm çerezler kabul edilecektir. Alternatif olarak, özel bir seçim yapabilirsiniz. Seçiminizi istediğiniz zaman 'Çerez Ayarları' üzerinden iptal edebilirsiniz.",
  [k.COOKIES_03]: 'Gerekli çerezler',
  [k.COOKIES_04]: 'Bu çerezler sitenin işlevselliği için gereklidir, bu nedenle bunları devre dışı bırakamazsınız.',
  [k.COOKIES_05]: 'Daha fazla bilgi',
  [k.COOKIES_06]: 'Pazarlama ve kişiselleştirme çerezleri',
  [k.COOKIES_07]:
    'Bu çerezler, kullanıcı davranışınıza göre uyarlanmış web sitesi içeriği almanızı sağlar ve web sitemizi kullanım istatistiklerine göre müşterilerimizin ilgi alanlarına göre uyarlamamıza olanak tanır.',
  [k.COOKIES_08]: 'Seçimi kabul et',
  [k.COOKIES_09]: 'Tümünü Kabul Et',
  [k.TERMS_AND_CONDITIONS_00]: 'Kullanım Koşulları',
  [k.TERMS_AND_CONDITIONS_01]:
    "fidira Software UG (haftungsbeschränkt), bundan böyle 'sağlayıcı', 'biz' veya 'EureHochzeitOnline.com' olarak da anılacaktır, bireysel düğün uygulamalarının oluşturulması için bir platform sağlar. Bu uygulamalarla, ev sahibi olarak kullanıcılar (bundan böyle toplu olarak 'Kullanıcılar' olarak anılacaktır) düğünleri için bir uygulama oluşturabilir ve misafirlerine bu uygulama aracılığıyla düğün ve etkileşim fırsatları hakkında bilgi sunabilir. Platformun kullanımı için aşağıdaki hüküm ve koşullar geçerlidir.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Platformun Kullanımı',
  [k.TERMS_AND_CONDITIONS_03]:
    'Platformun kullanımı genellikle deneme sürümünde ücretsizdir. Platformun belirli sayıda veya daha fazla davetlinin katılacağı bir düğün için kullanılması halinde tek seferlik bir ücret ödenmesi gerekmektedir. Hem deneme sürümü hem de tam sürüm için önceden kayıt yaptırılması ve bir kullanıcı hesabı oluşturulması gerekmektedir. Kullanıcı, hesabını üçüncü şahısların kullanmasına izin veremez. Kullanıcı, erişim verilerini gizli tutmak ve üçüncü tarafların erişimine karşı korumakla yükümlüdür.',
  [k.TERMS_AND_CONDITIONS_04]:
    'Düğün Uygulaması düğünden sonra altı ay boyunca kullanılabilir olacaktır. Bundan sonra, istenen adresi diğer çiftlere sunabilmemiz için Düğün Uygulamasını devre dışı bırakacağız. Düğün uygulamanızı daha uzun süre çevrimiçi tutmanız gerekiyorsa, özel bir düzenleme yapabiliriz.',
  [k.TERMS_AND_CONDITIONS_05]:
    'Bir düğün uygulaması oluşturulduktan sonra yalnızca bir düğün için kullanılabilir. Örneğin, bir kullanıcının bir düğün uygulamasını başka çiftlere verdiğini veya sattığını fark edersek, bu tür bir kullanımı engelleme ve başka düğünler için ücret talep etme hakkımızı saklı tutarız.',
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Veri Koruma',
  [k.TERMS_AND_CONDITIONS_07]: 'Kullanıcılar yazılımı kullanarak',
  [k.TERMS_AND_CONDITIONS_08]: 'veri̇ koruma',
  [k.TERMS_AND_CONDITIONS_09]: 'yönetmelikler',
  [k.TERMS_AND_CONDITIONS_10]: "§ 3 EureHochzeitOnline.com'un işlevleri ve hizmetleri",
  [k.TERMS_AND_CONDITIONS_11]:
    'EureHochzeitOnline.com üzerinden oluşturulabilen bireysel düğün uygulamaları aşağıdaki özellikleri sunmaktadır',
  [k.TERMS_AND_CONDITIONS_12]: 'Özelleştirme:',
  [k.TERMS_AND_CONDITIONS_13]:
    'Ev sahibi düğün uygulamasının belirli yönlerini yapılandırabilir ve tasarlayabilir, örneğin düğün davetiyelerinin grafik tasarımına uyması için arka planlar, resimler, renkler ve yazı tipleri seçebilirler.',
  [k.TERMS_AND_CONDITIONS_14]: 'Fotoğraf galerisi:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Konuklar düğünden önce veya düğün sırasında fotoğraf çekebilir ve yükleyebilir. Bu fotoğraflar otomatik olarak güncellenen canlı bir resim galerisinde görüntülenebilir.',
  [k.TERMS_AND_CONDITIONS_16]: 'Müzik istekleri:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Konuklar, düğün için şarkı talep etmek ve talepleri derecelendirmek için bir arama işlevini kullanabilir. Ev sahibi şarkıları seçmek için bu özelliği kullanabilir.',
  [k.TERMS_AND_CONDITIONS_18]: 'Düğün Bilgileri:',
  [k.TERMS_AND_CONDITIONS_19]:
    'Ev sahibi, düğün uygulamasında konukları için mekanı interaktif haritalar, program ve diğer bilgilerle birlikte yayınlayabilir.',
  [k.TERMS_AND_CONDITIONS_20]:
    'EureHochzeitOnline.com, yazılımdaki özelliklerin kapsamı ve tasarımında istediği zaman değişiklik yapma hakkını saklı tutar.',
  [k.TERMS_AND_CONDITIONS_21]:
    'Özellikleri kullanmak için modern bir internet tarayıcısına sahip modern bir akıllı telefon, tablet veya PC gereklidir. Özellikle resim yükleme, düğün testleri veya müzik istekleri gibi canlı işlevler, sabit ve hızlı bir internet bağlantısı gerektirir. EureHochzeitOnline.com, sağlanan içeriğin doğruluğu, eksiksizliği, güvenilirliği, güncelliği ve kullanılabilirliği konusunda hiçbir sorumluluk kabul etmez.',
  [k.TERMS_AND_CONDITIONS_22]:
    "EureHochzeitOnline.com'un ev sahiplerine duyuracağı bakım çalışmaları sırasında platform geçici olarak kullanılamayabilir. Hizmetimizi kullanılabilir tutmak için her türlü çabayı gösteriyoruz ve kullanıcılarımızı asla hayal kırıklığına uğratmadığımızı söylemekten gurur duyuyoruz. Bununla birlikte, başka yükümlülükler üstlenemeyiz, özellikle kullanıcının hizmetin sürekli kullanılabilirliği konusunda hiçbir hakkı yoktur.",
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 Kullanıcının yükümlülükleri',
  [k.TERMS_AND_CONDITIONS_24]:
    'Kullanıcı, EureHochzeitOnline.com platformunda kendi içeriğini ve dosyalarını misafir veya ev sahibi olarak kaydedebilir ve yayınlayabilir. Bu içerikten kullanıcı sorumludur. Kullanıcı, genel ahlak kurallarını veya yürürlükteki yasaları ihlal eden herhangi bir makale, dosya, düğün bilgisi veya diğer içerikleri yayınlamamayı taahhüt eder. Özellikle, kullanıcı herhangi bir yazı, dosya, düğün bilgisi veya diğer içeriği yayınlamamayı kabul eder',
  [k.TERMS_AND_CONDITIONS_25]: 'Yayımlanması cezai veya idari bir suç teşkil eden,',
  [k.TERMS_AND_CONDITIONS_26]: 'telif hakkı, ticari marka veya rekabet yasalarını ihlal etmek',
  [k.TERMS_AND_CONDITIONS_27]: 'Yasal Hizmetler Yasasını ihlal eden,',
  [k.TERMS_AND_CONDITIONS_28]: 'veya saldırgan, ırkçı, ayrımcı veya pornografik içerik içeriyorsa',
  [k.TERMS_AND_CONDITIONS_29]: 'reklam içeren içerikler.',
  [k.TERMS_AND_CONDITIONS_30]:
    'EureHochzeitOnline.com, bu yükümlülükleri ihlal eden veya ihlal ettiğinden şüphelenilen her türlü içeriği değiştirme veya silme hakkına sahiptir. EureHochzeitOnline.com ayrıca, kullanıcının kullanım koşullarını ihlal etmesi durumunda kullanıcının erişimini ve kurulan düğün uygulamasını engelleme hakkına sahiptir. Kullanıcı, görev ihlalinden kaynaklanan her türlü zararı sağlayıcıya tazmin edecektir. Sağlayıcı, Kullanıcı tarafından bir hakkın ihlali nedeniyle üçüncü şahıslar tarafından ileri sürülen talepler için kullanıcıdan tazminat talep etme hakkına sahiptir. Kullanıcı, bu tür iddiaların savunulmasında sağlayıcıyı desteklemekle yükümlüdür. Kullanıcı ayrıca sağlayıcının uygun bir yasal savunmasının masraflarını karşılamakla yükümlüdür.',
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Fikri Mülkiyet',
  [k.TERMS_AND_CONDITIONS_32]: 'Fikri Mülkiyetiniz',
  [k.TERMS_AND_CONDITIONS_33]:
    "Gönderdiğiniz içerik, metin, resim ve diğer materyallerin fikri mülkiyet hakları size aittir. EureHochzeitOnline.com bu tür içeriklerde herhangi bir fikri mülkiyet hakkı talep etmez. Ancak, EureHochzeitOnline.com'a düğün misafirlerine sunmak amacıyla bu içeriğe erişme, kopyalama ve dağıtma yetkisi verirsiniz. Ayrıca, yedek kopyalar oluşturmak ve destek personeline erişim sağlamak dahil ancak bunlarla sınırlı olmamak üzere, hizmetlerimizi sağlamak için gerekli diğer eylemleri gerçekleştirmemize izin verirsiniz.",
  [k.TERMS_AND_CONDITIONS_34]: 'Fikri Mülkiyetimiz',
  [k.TERMS_AND_CONDITIONS_35]:
    'Sağlayıcı, EureHochzeitOnline.com web sitesinin veya yazılım platformunun fikri mülkiyet haklarını Kullanım Koşullarına uygun olarak sağlanan düzenli olarak oluşturulmuş bir düğün uygulaması dışında kullanmak için herhangi bir lisans veya başka bir hak vermez. Fikri mülkiyet haklarının çoğaltılması, dağıtılması, çoğaltılması, iletilmesi veya üçüncü taraflara sunulması da dahil olmak üzere diğer kullanımlarına, Sağlayıcının önceden yazılı izni olmadan izin verilmez.',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Cayma hakkı',
  [k.TERMS_AND_CONDITIONS_37]:
    'On dört gün içinde sebep göstermeksizin bu sözleşmeden cayma hakkına sahipsiniz. Cayma süresi, sözleşmenin imzalanmasından itibaren on dört gündür. Cayma hakkınızı aşağıdaki adrese bir e-posta göndererek kullanabilirsiniz',
  [k.TERMS_AND_CONDITIONS_38]:
    'Sözleşmeden çekilme kararınızı açık bir şekilde ifade ederek. E-postanızda aşağıdaki örnek formu da kullanabilirsiniz:',
  [k.TERMS_AND_CONDITIONS_39]: 'Örnek cayma formu',
  [k.TERMS_AND_CONDITIONS_40]: "- fidira Software UG'ye (haftungsbeschränkt), e-posta: beeni@eurehochzeitonline.com:",
  [k.TERMS_AND_CONDITIONS_41]:
    '- Ben/biz ( * ) düğün uygulamamızın etkinleştirilmesi için tarafımdan/bizden ( * ) yapılan sözleşmeyi iptal ediyorum ( * )',
  [k.TERMS_AND_CONDITIONS_42]: '- Sipariş Tarihi ( * ) / Teslim Tarihi ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Tüketici(ler)in adı',
  [k.TERMS_AND_CONDITIONS_44]: '- Tüketici(ler)in adresi',
  [k.TERMS_AND_CONDITIONS_45]: '- Tüketici(ler)in imzası (sadece kağıt bildirim için)',
  [k.TERMS_AND_CONDITIONS_46]: '- Tarih',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Uygun olduğu şekilde silinSırayla',
  [k.TERMS_AND_CONDITIONS_48]:
    'Cayma süresine uymak için, cayma süresinin bitiminden önce cayma hakkının kullanılmasına ilişkin bildirimin gönderilmesi yeterlidir.İptalin sonuçları:',
  [k.TERMS_AND_CONDITIONS_49]: 'İptalin sonuçları:',
  [k.TERMS_AND_CONDITIONS_50]:
    'Sözleşmenizi iptal ederseniz, düğün uygulamanızı sileceğiz ve sizden zaten ödeme aldıysak, bu ödemeleri aynı ödeme yöntemini kullanarak size iade edeceğiz. Bu iade için sizden herhangi bir ücret talep edilmeyecektir.',
  [k.TERMS_AND_CONDITIONS_51]:
    'Düğün uygulaması gibi fiziksel bir veri taşıyıcısında bulunmayan dijital içeriğin teslimine ilişkin bir sözleşme söz konusu olduğunda, Kullanıcı 1. Tedarikçinin sözleşmeyi iptal süresinin sona ermesinden önce ifa etmeye başlamasına açıkça rıza gösterdikten ve 2. sözleşmenin ifasının başlamasını kabul ederek iptal hakkını kaybedeceğinin farkında olduğunu teyit ettikten sonra Tedarikçi sözleşmeyi ifa etmeye başlamışsa, BGB Madde 356 (5) uyarınca iptal hakkı da sona erecektir.',
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Garanti ve Feragat',
  [k.TERMS_AND_CONDITIONS_53]:
    "Sağlayıcı, hizmetlerini 'olduğu gibi', 'tüm hatalarıyla' ve 'mevcut olduğu şekilde' sağlar ve yasaların izin verdiği ölçüde satılabilirlik, belirli bir amaca uygunluk, işçilik performansı, ihlal etmeme veya diğer garantiler konusunda açık veya zımni hiçbir garanti vermez. Ayrıca, Sağlayıcının hizmetlerinin (veya herhangi bir bölümünün, özelliğinin veya içeriğinin) eksiksiz, belirli bir kalitede, güvenilir, güvenli, doğru, amaçlanan faaliyetlerinizle, kullanılan cihazlarla, işletim sistemleriyle, tarayıcılarla veya programlarla (misafirlerin veya ev sahiplerinin) uyumlu olduğu (veya olacağı) veya sizin için geçerli olan yasal gerekliliklere uygun olduğu veya hizmetlerin virüs, böcek veya diğer zararlı bileşenler veya program sınırlamaları içermediği konusunda hiçbir garanti yoktur. Ayrıca, EureHochzeitOnline.com, EureHochzeitOnline.com platformunda belirtilen veya sunulan herhangi bir şirket, ürün veya hizmet (üçüncü taraf hizmetleri dahil) için hiçbir sorumluluk kabul etmez. Veri ve bilgi kaybı için hiçbir sorumluluk kabul edilmez ve sağlayıcı bu tür veri ve bilgilerin yeniden oluşturulmasını garanti etmez. Kullanıcı, veri ve bilgilerini riski kendisine ait olmak üzere yedekleyecektir. EureHochzeitOnline.com ayrıca kullanıcılar tarafından gönderilen içerik veya kullanıcıların doğru kimliği konusunda hiçbir sorumluluk kabul etmez.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Bölünebilirlik Maddesi',
  [k.TERMS_AND_CONDITIONS_55]:
    "Bu Sözleşmenin herhangi bir hükmünün herhangi bir nedenle veya herhangi bir ölçüde geçersiz veya uygulanamaz olduğuna karar verilirse, bu geçersizlik veya uygulanamazlık Sözleşmenin geri kalan hükümlerini etkilemeyecek ve söz konusu hükmün uygulanması yasaların izin verdiği en geniş ölçüde uygulanacaktır. EureHochzeitOnline.com'un bir veya daha fazla şart ve koşulun ihlalini uygulama hakkından feragat etmesi, EureHochzeitOnline.com'un diğer şart ve koşulları uygulama hakkından da feragat ettiği anlamına gelmez.",
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Son hükümler',
  [k.TERMS_AND_CONDITIONS_57]:
    'Yasalar aksini gerektirmedikçe, anlaşmalar Federal Almanya Cumhuriyeti yasalarına tabi olacaktır. Bu Kullanım Koşullarından kaynaklanan veya bunlarla bağlantılı olarak ortaya çıkan tüm anlaşmazlıklar/iddialar için münhasır yargı yeri Münih olacaktır. Bu Kullanım Koşullarını gelecekte değiştirme ve yeni sürümü aynı yerde yayınlama hakkımızı saklı tutarız. Kullanım Koşullarında yapılan bir değişiklikten sonra, yeni sürüm kullanıcı tarafından onaylanmalıdır.',
  [k.TERMS_AND_CONDITIONS_58]: 'Durum 01.04.2018',
  [k.IMPRINT_00]: 'Baskı/Yasal bildirim',
  [k.IMPRINT_01]: 'fidira Software UG (sınırlı sorumluluk)',
  [k.IMPRINT_02]: "EureHochzeitOnline.de, fidira Software UG'nin ticari markasıdır",
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-Posta:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Şirketin kayıtlı ofisi: Münih, Ticaret Sicili Münih HRB',
  [k.IMPRINT_09]: 'Yetkili temsilciler:',
  [k.PRIVACY_00]: 'Gizlilik Politikası',
  [k.PRIVACY_01]: 'Giriş ve Genel Bilgiler',
  [k.PRIVACY_02]:
    'Gizliliğinizi ve düğün misafirlerinizin gizliliğini korumak EureHochzeitOnline.com için en önemli önceliktir.',
  [k.PRIVACY_03]:
    "Alman Federal Veri Koruma Yasası (BDSG) ve Alman Telemedya Yasası (TMG) gerekliliklerine uygun olarak, verilerinizin her zaman yalnızca kişisel düğün uygulamanızın oluşturulması ve kullanılması ve düğün uygulamanızın kullanıcı deneyimini optimize etmek için kullanılmasını sağlıyoruz. Düğün uygulamalarının hem oluşturulması hem de kullanımı için TLS/SSL (Taşıma Katmanı Güvenliği / Güvenli Yuva Katmanı) teknolojisini kullanıyoruz, böylece veriler iletim sırasında her zaman şifreleniyor. Tüm uygulama sunucularımız Avrupa Birliği'nde bulunmaktadır ve AB veri gizliliği düzenlemelerine uygundur. Bilgilerinizi bu Gizlilik Politikasında açıklanan amaçlar dışında işlemeyeceğiz. Kayıt işlemi sırasında, onay kutusuna tıklayarak bu Gizlilik Politikasında açıklanan işlemlere onay vermeniz istenecektir. Onayınızı vererek, kişisel verilerinizi aşağıda açıklanan kapsamda toplayabileceğimizi, işleyebileceğimizi ve kullanabileceğimizi onaylamış olursunuz. Onayınızı istediğiniz zaman geri çekebilirsiniz. Bunun için müşteri hizmetlerimize şu adresten bir e-posta göndermeniz yeterlidir",
  [k.PRIVACY_04]:
    'Kayıt sırasında onay kutusuna tıklayarak vereceğiniz gizlilik politikasında açıklanan işleme işlemlerine onay vermeniz istenecektir. Onayınızı vererek, kişisel verilerinizi aşağıda açıklanan ölçüde toplayabileceğimizi, işleyebileceğimizi ve kullanabileceğimizi onaylamış oluyorsunuz. Ayrıca onayınızı istediğiniz zaman geri çekebilirsiniz. Bunun için müşteri hizmetlerimize e-posta göndermeniz yeterlidir.',
  [k.PRIVACY_05]:
    '. Talep üzerine, müşteri hizmetlerimiz size hakkınızda kaydedilen tüm veriler hakkında da bilgi verecek (Alman Telemedya Yasası (Telemediengesetz) § 13 (7) uyarınca) ve talebiniz üzerine bu verileri silecektir.',
  [k.PRIVACY_06]: '§ 1 Sorumlu kurum ve uygulama alanı',
  [k.PRIVACY_07]:
    "Sorumlu kuruluş fidira Software UG (haftungsbeschränkt) olup, bundan böyle 'sağlayıcı', 'biz' veya 'EureHochzeitOnline.com' olarak da anılacaktır. Adres ve yetkili temsilcilerle ilgili ayrıntılar künyede bulunabilir.",
  [k.PRIVACY_08]:
    'Bu gizlilik politikası, internet tarayıcıları aracılığıyla sağlayıcının platformlarından herhangi birini ziyaret ederken ve kullanırken kişisel verilerin toplanmasını ve kullanılmasını kapsar. Bu teklifler aracılığıyla Sağlayıcı, konukların düğün hakkında kendilerini bilgilendirebilecekleri ve düğün sırasında birbirleriyle etkileşime girebilecekleri (örneğin, fotoğraf paylaşabilecekleri veya soruları yanıtlayabilecekleri) bireysel bir düğün uygulaması oluşturmayı mümkün kılar. Tedarikçi ayrıca kişisel verileri istatistiksel ve pazar analizi amacıyla kullanacaktır. Bunu yaparken, teklifimizi daha da optimize etmek için örneğin düğünler, konumlar, düğün boyutları veya uygulama kullanımı hakkında anonim istatistikler derliyoruz.',
  [k.PRIVACY_09]: '§ 2 Diğer web sitelerine bağlantılar',
  [k.PRIVACY_10]:
    'Web sitemiz diğer web sitelerine bağlantılar içerebilir. Bu bağlantılara tıkladıktan sonra yönlendirildiğiniz içerik üzerinde veya üçüncü tarafa iletilebilecek verilerinizin (örneğin IP adresiniz) işlenmesi üzerinde hiçbir kontrolümüz yoktur. Bu nedenle, üçüncü tarafların içeriği ve bu tür verilerin üçüncü taraflarca işlenmesi konusunda herhangi bir sorumluluk kabul edemeyiz.',
  [k.PRIVACY_11]: '§ 3 Kayıt olmadan veri kullanımı',
  [k.PRIVACY_12]:
    'EureHochzeitOnline.com web sitesini veya uygulamasını ziyaret ettiğinizde, ziyaretinizle ilgili bazı temel bilgileri otomatik olarak kaydederiz: özellikle IP adresi, ziyaret edilen sayfalar ve ziyaret süresi. Bu bilgileri, tekliflerimizi sürekli olarak iyileştirmek amacıyla kullanıcı davranışı ve sık kullanılan özellikler hakkında anonim istatistikler derlemek için kullanırız. IP adresiniz, sistemlerimizi ve oluşturduğumuz düğün uygulamalarını kötüye kullanım ve dolandırıcılıktan korumak için yalnızca sınırlı bir süre için teknik günlük dosyalarında saklanır.',
  [k.PRIVACY_13]: '§ 4 Kayıt sırasında verilerin kullanımı',
  [k.PRIVACY_14]:
    'Misafir olarak bir düğün uygulamasına erişim sağlamak veya ev sahibi olarak bir düğün uygulaması oluşturmak için kullanıcının kaydolması gerekir. Kayıt işlemi sırasında kullanıcının e-posta adresi, bir görünen adı (serbestçe seçilebilir, icat edilmiş bir takma ad da olabilir), bir düğün kodu (varsa) ve bir parola (serbestçe tanımlanabilir) saklanacaktır. Bu bilgiler, ev sahiplerimizin düğün listelerini yetkisiz erişime karşı korumak için kullanılır. Bir düğün uygulaması oluştururken, ev sahipleri düğünleri hakkında sadece o düğünün konukları tarafından görülebilecek bilgiler (örn. yer, zaman, program) girebilirler. Bu, konukların düğün uygulamasına erişmek için girmeleri gereken düğün kodlarının ev sahibi tarafından oluşturulmasıyla gerçekleştirilir.',
  [k.PRIVACY_15]:
    'E-posta adresinizi iletişim amacıyla da kullanırız: örneğin, yeni kayıtlar, diğer düğün haberleri ile ilgili olarak ev sahibine e-posta bildirimleri gönderilebilir ve EureHochzeitOnline.com hizmet e-postaları e-posta adresine gönderilebilir (örneğin, memnuniyet anketleri). Ayrıca düğün davetlilerinin e-posta adreslerini, düğün organizasyonu için kullanabilmeleri amacıyla ev sahibine gösteririz.',
  [k.PRIVACY_16]: 'E-posta adresiniz asla üçüncü şahıslarla veya diğer şirketlerle paylaşılmayacaktır.',
  [k.PRIVACY_17]: '§ 5 Çerezler',
  [k.PRIVACY_18]:
    "Size en iyi kullanıcı deneyimini sunmak için 'çerezleri' ve tarayıcınızın 'yerel depolama' olarak adlandırılan özelliğini kullanıyoruz. Bu, Wedding Uygulamanız hakkında tarayıcınızda küçük miktarlarda veri depolamamızı sağlar, böylece örneğin tarayıcınızı kapattığınızda, Uygulama Yapılandırıcısının tam ekran moduna geçtiğinizde veya her seferinde oturum açmanız gerekmediğinde değişiklikleriniz kaybolmaz. Çerezleri kullanmak istemiyorsanız veya mevcut çerezleri silmek istiyorsanız, tarayıcınız aracılığıyla bunları devre dışı bırakabilir veya kaldırabilirsiniz. Lütfen bunu nasıl yapacağınıza dair tarayıcınızın talimatlarına bakın veya yardım için bizimle iletişime geçin.",
  [k.PRIVACY_19]:
    "Size daha iyi bir kullanıcı deneyimi sunmak için aşağıda '§ 7 Uygulamalar ve Web Siteleri' bölümünde listelenen üçüncü taraf hizmetleri için de çerezler kullanıyoruz.",
  [k.PRIVACY_20]: '§ 6 Uygulamalar ve Web Siteleri',
  [k.PRIVACY_21]: 'Google Haritalar',
  [k.PRIVACY_22]:
    "Etkileşimli haritaları görüntülemek için Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, ABD'den Google Maps kullanıyoruz. IP adresiniz, konumunuz ve hizmetlerimizi kullanımınızla ilgili bilgiler Google'a iletilecek ve Google tarafından ABD'deki sunucularda saklanacaktır. Google Haritalar için kullanım koşulları adresinde bulunabilir.",
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "Ayrıca, pazarlama ve optimizasyon amacıyla veri toplamak ve depolamak için Hotjar Ltd. şirketinin bir web analiz aracı olan HotJar'ı kullanıyoruz. Veriler, takma adlı kullanıcı profilleri oluşturmak için kullanılabilir. Rastgele seçilen bireysel ziyaretler (ek kişisel bilgiler olmadan, 'kullanıcı nitelikleri' olarak adlandırılır) kaydedilir. Bu, bireysel web sitesi ziyaretlerini rastgele oynatmak ve web sitesi için potansiyel iyileştirmeler elde etmek amacıyla fare hareketlerinin ve tıklamaların bir günlüğünü oluşturur. Toplanan veriler, bu web sitesinin ziyaretçisini kişisel olarak tanımlamak için kullanılmayacak veya ilgili kişinin ayrı rızası olmadan takma adın taşıyıcısı hakkındaki kişisel verilerle birleştirilmeyecektir. İzlenmek istemiyorsanız, aşağıdaki bağlantıya tıklayarak şu anda kullandığınız tarayıcı için HotJar kullanan tüm web sitelerinde izlemeyi genel olarak devre dışı bırakabilirsiniz",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'Haber bültenimizi göndermek için The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp) şirketinden Mailchimp kullanıyoruz. Bu, abonelerle doğrudan iletişime geçmemizi sağlar. Ayrıca, tekliflerimizi optimize etmek için kullanım davranışınızı analiz ediyoruz.',
  [k.PRIVACY_29]: 'Bu amaçla aşağıdaki kişisel bilgileri Mailchimp ile paylaşıyoruz',
  [k.PRIVACY_30]: 'E-posta Adresi [Ad] [Düğün Tarihi] [Düğün Bilgileri] [Giriş Bilgileri].',
  [k.PRIVACY_31]:
    "E-posta gönderilerimiz kişisel bilgilerinizi güncellemeniz için bir bağlantı içerecektir. Mailchimp, kişisel verilerinizin alıcısıdır ve bültenimizin dağıtımı söz konusu olduğunda bizim adımıza bir işlemci olarak hareket eder. Bu bölümde açıklanan verilerin işlenmesi yasa veya sözleşme gereği değildir. Onayınız ve kişisel verilerinizin gönderilmesi olmadan size bir haber bülteni gönderemeyiz. Ayrıca Mailchimp, çerezleri ve diğer izleme yöntemlerini kullanarak aşağıdaki kişisel bilgileri toplar Cihazınızla ilgili bilgiler (IP adresi, cihaz bilgileri, işletim sistemi, tarayıcı kimliği, e-postanızı okumak için kullandığınız uygulama hakkında bilgiler ve donanımınız ve İnternet bağlantınızla ilgili diğer bilgiler. Ayrıca, e-postayı/kampanyayı açtığınız tarih ve saat ve tarayıcı etkinliği (örneğin, hangi e-postaların/web sitelerinin açıldığı) gibi kullanım verileri toplanır. Mailchimp, sistemlerin güvenliğini ve güvenilirliğini sağlamak, Hizmet Şartlarını uygulamak ve kötüye kullanımı önlemek için bu bilgilere ihtiyaç duyar. Bu, Mailchimp'in meşru menfaatine karşılık gelir (Madde 6 paragraf 1 lit. f GDPR uyarınca) ve sözleşmenin yerine getirilmesine hizmet eder (Madde 6 paragraf 1 lit. b GDPR uyarınca). Mailchimp ayrıca e-posta teslim istatistikleri ve diğer iletişim verileri gibi performans verilerini de analiz eder. Bu bilgiler, Hizmetler için kullanım ve performans istatistiklerini derlemek için kullanılır. Mailchimp ayrıca diğer kaynaklardan sizin hakkınızda bilgi toplar. Zaman zaman ve belirsiz bir ölçekte, kişisel bilgiler sosyal medya ve diğer üçüncü taraf veri sağlayıcıları aracılığıyla toplanır. Bu süreçler üzerinde hiçbir kontrolümüz yoktur. ",
  [k.PRIVACY_32]:
    'Mailchimp ile ilgili seçimleriniz ve kaldırma seçenekleriniz hakkında daha fazla bilgi için lütfen şu adresi ziyaret edin',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'Bu işleme için yasal dayanak, GDPR Madde 6 paragraf 1 litre a uyarınca onayınızdır Kişisel verilerinizin işlenmesine ilişkin onayınızı istediğiniz zaman geri çekebilirsiniz Tüm postalarda bunu yapmak için bir bağlantı bulacaksınız Ayrıca sağlanan iletişim seçeneklerini kullanarak onayınızı iptal edebilirsiniz. Geri çekme, halihazırda gerçekleşmiş olan herhangi bir işlemin yasallığını etkilemez.',
  [k.PRIVACY_35]:
    'Verileriniz, onay verdiğiniz sürece işlenecektir. Aksi takdirde, yasal gereklilikler daha fazla depolamayı gerekli kılmadıkça, Mailchimp ile aramızdaki sözleşme sona erdikten sonra silinecektir.',
  [k.PRIVACY_36]:
    "Mailchimp, uluslararası veri aktarımları için uyumluluk önlemleri uygulamıştır. Bu önlemler, Mailchimp'in AB'deki bireylerin kişisel verilerini işlediği tüm küresel operasyonlar için geçerlidir. Bu politikalar AB Standart Sözleşme Maddelerine (SCC'ler) dayanmaktadır. Daha fazla bilgi şu adreste bulunabilir",
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    "Düğün çalma listesini dinleyebilmeniz için Spotify AB'nin bir müzik oynatıcısı olan Spotify Music Player Widget'ını da kullanıyoruz. Spotify Music Player Widget'ının hizmet sağlayıcısı Spotify AB, Regeringsgatan 19, 111 53 Stockholm, İsveç'tir. Spotify AB'nin veri işlemesi hakkında daha fazla bilgiyi şu adreste bulabilirsiniz",
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Facebook Pikseli',
  [k.PRIVACY_42]:
    "EureHochzeitOnline.com'u geliştirmek ve daha geniş bir kullanıcı grubu tarafından bilinmesini sağlamak için Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, ABD'nin ('Facebook') Facebook Pikselini kullanıyoruz. Bunu yapmak için Facebook tarayıcınıza bir çerez yerleştirir. Bu çerez tarafından bu web sitesini kullanımınız hakkında oluşturulan bilgiler, Facebook tarafından Amerika Birleşik Devletleri'ndeki sunuculara iletilecek ve burada saklanacaktır. Facebook bu veri aktarımını, tarayıcınızdan belirli bir sayfaya erişildiğini ve bunun bir reklama tıklayarak yapılıp yapılmadığını belirlemek için kullanabilir. Ancak, bu verileri sitemizden bilgi toplamak için kullanmıyoruz. Facebook bu bilgileri ek reklamlar oluşturmamıza yardımcı olmak ve EureHochzeitOnline.com'u tanıtmak için kullanır. Facebook pikselinden elde edilen verileri, EureHochzeitOnline.com'u daha da geliştirmek için Facebook analiz aracı 'Facebook Analytics'te değerlendiriyoruz. Facebook, bu bilgileri yasaların gerektirdiği durumlarda veya bu üçüncü tarafların bilgileri Facebook adına işlediği durumlarda üçüncü taraflara aktarabilir. EureHochzeitOnline.com'u kullanarak, hakkınızdaki verilerin Facebook tarafından açıklanan şekilde ve EureHochzeitOnline.com'u geliştirmek amacıyla işlenmesine izin vermiş olursunuz. Kişisel, iletişim veya düğün bilgilerinizin hiçbirini Facebook ile paylaşmıyoruz. Facebook Pixel'in güvenlik ve gizlilik politikaları hakkında daha fazla bilgiyi şu adresten edinebilirsiniz",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: 'Ayrıca buraya tıklayarak Facebook Pixel tarafından bilgi toplanmasını devre dışı bırakabilirsiniz:',
  [k.PRIVACY_45]: 'Pikseli Devre Dışı Bırak',
  [k.PRIVACY_46]: "§ 7 BDSG'ye göre bilgi edinme hakkı ve diğer haklar",
  [k.PRIVACY_47]:
    'Tarafımızca saklanan kişisel verileriniz hakkında istediğiniz zaman size bilgi vermekten memnuniyet duyarız. Talebiniz üzerine verilerinizi düzeltecek, engelleyecek veya sileceğiz. Bu talepler veya iptal beyanları için istediğiniz zaman aşağıdaki adresten bizimle iletişime geçebilirsiniz',
  [k.PRIVACY_48]: 've değişiklikleri mümkün olan en kısa sürede yapacağız. ',
  [k.PRIVACY_49]: '§ 8 Veri koruma beyanında yapılan değişiklikler',
  [k.PRIVACY_50]:
    'İnternet ve teknik imkanlar sürekli gelişmektedir. Kullanıcılarımızın düğünlerini daha da unutulmaz kılmak için bu yeni olanakları kullanacağımızı söylemeye gerek yok. Bu, Gizlilik Politikamızı güncellememize ve değiştirmemize yol açabilir, bu güncellemeleri burada yayınlayacağız.',
  [k.PRIVACY_51]: 'Son güncelleme 11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'Daha fazla dile ihtiyacınız varsa, lütfen bize bir e-posta yazın.',
  [k.DECIDE_LANGUAGES]:
    'Düğün web sitenizin hangi dilleri desteklemesi gerektiğine karar verin. Seçilen tüm diller için metinlerinizi girebilirsiniz.',
  [k.EDIT_LANGUAGES]: `Dilleri Düzenle`,
  [k.ACTIVE_VOUCHER]: `Aktif kupon:`,
  [k.ENTER_VOUCHER_CODE]: `Kupon kodunu girin`,
  [k.VOUCHER_CODE]: `İndirim kodu`,
  [k.INVALID_CODE]: `Maalesef geçerli bir kod değil!`,
  [k.WANT_CUSTOM_LOGO]: `Kendi düğün logonuzu kullanmak istiyor musunuz?`,
  [k.INDIVIDUAL_LOGO]: `Özel logo`,
  [k.STANDARD_LOGO]: `Standart logo`,
  [k.PRIVATE_PURCHASE]: `Satın alma özel amaçlıdır`,
  [k.COMMERCIAL_PURCHASE]: `Satın alma ticari amaçlıdır`,
  [k.MAX_GUESTS]: 'Free Version: Maximum Number of Guests',
  [k.MAX_GUESTS_REACHED]:
    'This wedding is still using the free basic version of EureHochzeitOnline.com but now has more than five registered guests. According to our terms of use, an upgrade to a complete or premium package is required. Therefore, please upgrade your package to continue using your wedding website as usual.',
  [k.ACTIVATE_PACKAGE]: 'Unlock Package',
  [k.IF_MESSAGE_WRONGFUL]: 'If you believe this message is displayed incorrectly, please send us an email at:',
};
