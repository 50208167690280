import styled from 'styled-components';

export default styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: ${({ visible }) => (visible ? 'none' : ' translate3d(0, -500px, 0)')};
  position: absolute;
  bottom: 0;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
`;
