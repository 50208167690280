import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import SectionContainer from '../Components/SectionContainer';
import SectionTextContainer from '../Components/SectionTextContainer';
import SectionTitle from '../Components/SectionTitle';
import SectionSubTitle from '../Components/SectionSubTitle';
import boxShadows from '../../../Shared/boxShadows';
import { Button } from '@material-ui/core';
import breakpoints from '../../../Shared/breakpoints';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 900px;
  background-color: #fbeff1;
  padding-bottom: 50px;
  box-shadow: ${boxShadows.z2};
  margin-bottom: 12px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const SideImage = styled.div`
  position: relative;
  background-image: url('/assets/landing-page/hochzeits-website-fragen-testimonials.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 30%;
  margin: 25px auto 25px 0;
  box-shadow: ${boxShadows.z1};
  border-radius: 0 12px 12px 0;
  @media (max-width: ${breakpoints.sm}) {
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
    bottom: -100px;
    width: 100%;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 170px;
  }
`;

const ImageText = styled.div`
  background-color: #ba5a5e66;
  width: 70%;
  margin-right: auto;
  border-radius: 0 12px 12px 0;
  box-shadow: ${boxShadows.z2};
  h3 {
    padding: 6px 12px;
    font-weight: bold;
    margin-bottom: -6px;
    color: #ffd2d4;
  }
  p {
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    margin-top: -6px;
  }
`;

const TestimonialList = styled.div`
  width: 55%;
  @media (max-width: ${breakpoints.md}) {
    flex-wrap: nowrap;
    height: auto;
    width: 50%;
    min-width: 660px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-top: 200px;
    z-index: 1;
    width: 90%;
    min-width: unset;
  }
`;
const Testimonial = styled.div`
  display: flex;
  background: #ffffffbf;
  margin: 24px 0;
  padding: 12px;
  margin: 25px auto 25px 0;
  border-radius: 12px 0 0 12px;
  box-shadow: ${boxShadows.z1};
`;
const TestimonialText = styled.div`
  width: calc(100% - 100px);
  span {
    font-weight: bold;
    font-size: 15px;
  }
  p {
    margin-bottom: 6px;
  }
  @media (max-width: ${breakpoints.md}) {
    p,
    span {
      font-size: 15px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    width: calc(100% - 70px);
  }
`;
const TestimonialImage = styled.div`
  height: 70px;
  width: 70px;
  margin: auto;
  border-radius: 50%;
  background-image: ${({ src }) => `url('/assets/testimonials/${src}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: solid;
  @media (max-width: ${breakpoints.sm}) {
    height: 50px;
    width: 50px;
  }
`;

const TestimonialsSection = ({ search }) => {
  const testimonials = [
    {
      name: 'Rebecca',
      role: i18n.t(k.BRIDE),
      text: i18n.t(k.TESTIMONIAL_1),
      image: 'rebecca.jpeg',
    },
    {
      name: 'Anne',
      role: i18n.t(k.F_WITNESS),
      text: i18n.t(k.TESTIMONIAL_2),
      image: 'anne.jpeg',
    },
    {
      name: 'Ursi',
      role: i18n.t(k.BRIDE),
      text: i18n.t(k.TESTIMONIAL_3),
      image: 'ursi.jpeg',
    },
    {
      name: 'Martin',
      role: i18n.t(k.GROOM),
      text: i18n.t(k.TESTIMONIAL_4),
      image: 'martin.jpg',
    },
  ];

  return (
    <Container>
      <SectionContainer alignment="left" id="faq">
        <SectionTextContainer alignment="left">
          <Element></Element>
          <SectionTitle>{i18n.t(k.USER_TESTIMONIALS)}</SectionTitle>
          <SectionSubTitle>{i18n.t(k.THOUSANDS_OF_PEOPLE)}</SectionSubTitle>
          <FlexContainer>
            <TestimonialList>
              {testimonials.map(testimonial => (
                <Testimonial key={testimonial.name}>
                  <TestimonialImage src={testimonial.image}></TestimonialImage>
                  <TestimonialText>
                    <p>{testimonial.text}</p>
                    <span>
                      {testimonial.name} | {testimonial.role}
                    </span>
                  </TestimonialText>
                </Testimonial>
              ))}
            </TestimonialList>
            <SideImage>
              <ImageText>
                <h3>
                  {i18n.t(k.OUR_GIFT_TIP)}
                  <br></br> {i18n.t(k.FOR_ALL_WITNESSES)}
                </h3>
                <p>{i18n.t(k.BUY_WEDDING_WEBSITE_AS_GIFT)}</p>
              </ImageText>
            </SideImage>
          </FlexContainer>
          <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
            <Button color="primary" variant="contained">
              {i18n.t(k.CREATE_WEDDING_WEBSITE_NOW)}
            </Button>
          </NavLink>
        </SectionTextContainer>
      </SectionContainer>
    </Container>
  );
};

export default TestimonialsSection;
