import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  transform: ${({ visible }) => (visible ? 'rotate(37deg)' : 'rotate(37deg) translate3d(-800px, -500px, 0)')};
  top: -60px;
  left: 0;
  @media (max-width: ${breakpoints.md}) {
    top: -200px;
    left: -200px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    transform: ${({ visible }) => (visible ? 'rotate(250deg)' : 'rotate(250deg) translate3d(500px, 0, 0)')};
    top: -350px;
    left: -100px;
  }
`;
