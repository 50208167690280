import React from 'react';
import styled from 'styled-components';

import { ReactComponent as QuestionSvg } from '../../../../_assets/svg/question.svg';

const Container = styled.div`
  margin-right: 9px;
  height: 50px;
  width: 50px;
  min-width: 50px;
`;

const ConfirmIcon = () => {
  return (
    <Container>
      <QuestionSvg></QuestionSvg>
    </Container>
  );
};

export default ConfirmIcon;
