import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import WeddingContext from '../../Context/WeddingContext';
import GenericStyleContainer from '../../WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../WeddingStyles/DefaultStyles';

import ConfirmIcon from './Components/ConfirmIcon';

const StyledDialogTitle = styled(DialogTitle)`
  h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  p {
    margin-top: 0;
  }
  min-width: 300px;
`;
const StyledDialogActions = styled(DialogActions)`
  button {
    width: 150px;
  }
`;

const ConfirmDialog = ({
  open,
  messageTitle = i18n.t(k.CONFIRM_DIALOG_TITLE),
  messageDescriptionText,
  confirmButtonText = i18n.t(k.OK),
  cancelButtonText = i18n.t(k.CANCEL),
  handleCancel,
  handleConfirm,
  loadingConfirm = false,
}) => {
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';
  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby={messageTitle} aria-describedby={messageDescriptionText}>
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
        <StyledDialogTitle className="color-background-white">
          <ConfirmIcon></ConfirmIcon>
          <span>{messageTitle}</span>
        </StyledDialogTitle>
        <StyledDialogContent className="color-background-white">
          <p>{messageDescriptionText}</p>
        </StyledDialogContent>
        <StyledDialogActions className="color-background-white">
          <Button disabled={loadingConfirm} onClick={handleCancel} color="secondary" variant="contained">
            {cancelButtonText}
          </Button>
          <Button disabled={loadingConfirm} onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            {loadingConfirm ? <CircularProgress size={30}></CircularProgress> : <>{confirmButtonText}</>}
          </Button>
        </StyledDialogActions>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default ConfirmDialog;
