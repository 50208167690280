import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useContext } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core/';

import WeddingContext from '../../../Context/WeddingContext';
import EditingContext from '../../../Context/EditingContext';
import defaultStyles from '../../../WeddingStyles/DefaultStyles';

import Container from './Components/Container';

const EditingModeToggle = ({ belowTopBar }) => {
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';
  const { editingModeActive, setEditingModeActive } = useContext(EditingContext);

  const handleSwitch = e => {
    setEditingModeActive(e.target.checked);
  };
  return (
    <Container belowTopBar={belowTopBar} weddingStyle={defaultStyles[weddingStyle]}>
      <FormControlLabel
        control={<Switch onChange={handleSwitch} checked={editingModeActive} value={editingModeActive} />}
        label={
          <span>
            {i18n.t(k.EDITING_MODE)} <strong>{editingModeActive ? i18n.t(k.ACTIVE) : i18n.t(k.NOT_ACTIVE)}</strong>
          </span>
        }
      />
    </Container>
  );
};

export default EditingModeToggle;
