import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  width: 100%;
  padding: 6px;
  font-size: 14px;
  background-color: #fafafa;
  h2 {
    color: #e87076;
  }
  a {
    color: #a15757;
    font-weight: bold;
  }
`;
