import axios from 'axios';
import cleanWeddingContent from './cleanWeddingContent';

export default async (changedWeddingContent, weddingId, setWeddingContent) => {
  cleanWeddingContent(changedWeddingContent);
  const savedWeddingContent = await axios.patch(`wedding-update/`, {
    updateType: 'UPDATE_WEDDING_CONTENT',
    weddingId: weddingId,
    weddingContent: changedWeddingContent,
  });
  setWeddingContent(savedWeddingContent.data);
};
