import React, { useEffect } from 'react';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import ContentCardWithMap from '../../Components/ContentCardWithMap/ContentCardWithMap';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import { Helmet } from 'react-helmet';

const LocationPage = ({ scrollToTop, locations, weddingId }) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Locations`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {locations.elements.map((locationItem, index) => {
          switch (locationItem.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={locationItem.title}>
                  <ContentCardWithTitle
                    title={locationItem.title}
                    subtitle={locationItem.subtitle}
                    substituteLanguage={locationItem.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'LOCATION', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'LOCATION' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.locationMap.name:
              return (
                <ElementContainer key={locationItem.elementType}>
                  <ContentCardWithMap locations={locationItem.locations} contentPage="LOCATION"></ContentCardWithMap>
                </ElementContainer>
              );
            default:
              return null;
          }
        })}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default LocationPage;
