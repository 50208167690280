import styled from 'styled-components';
import boxShadows from '../../../../../../Shared/boxShadows';

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  box-shadow: ${boxShadows.z2};
  background-color: #fff;
  border-radius: 0;
  border-top-left-radius: 12px;
  margin: 21px;
  padding: 9px;
`;
