import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle, TextField, Button, LinearProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { Auth } from 'aws-amplify';

import LinkButton from '../../../Shared/Components/LinkButton/LinkButton';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../Shared/WeddingStyles/GenericStyleContainer';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  width: 350px;
  max-width: 70vw;
  margin: 24px auto 6px auto;
`;

const StyledButton = styled(Button)`
  min-width: 100px;
  margin: auto;
`;

const StyledProgress = styled(LinearProgress)`
  margin: auto;
  bottom: 9px;
  width: 350px;
  max-width: 70vw;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
`;

const ForgotPasswordButton = styled(LinkButton)`
  background-color: transparent !important;
  border: none !important;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0;
  margin: 12px 0;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: #7b7b7b !important;
  margin: 6px auto 12px auto;
  :hover {
    background-color: transparent !important;
    border: none !important;
  }
`;

const EnterPasswordDialog = ({
  show,
  weddingStyle,
  onSubmitPassword,
  onCancelPassword,
  username,
  displayName,
  loadingLogin,
}) => {
  const { handleSubmit, register, errors, setValue } = useForm({});
  const inputRef = useRef(null);
  const [showForgotPasswordMessage, setShowForgotPasswordMessage] = useState(false);
  const [loadingForgotPasswordMessage, setLoadingForgotPasswordMessage] = useState(false);

  const sendForgotPassword = async e => {
    e.preventDefault();
    setLoadingForgotPasswordMessage(true);
    await Auth.forgotPassword(username.toLowerCase(), {
      reasonForMail: 'ForgotPassword',
      username: username.toLowerCase(),
      displayName: displayName,
      wedding: null,
    });
    setShowForgotPasswordMessage(true);
    setLoadingForgotPasswordMessage(false);
  };

  return (
    <Dialog open={show}>
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
        <DialogTitle className="color-background-white">{i18n.t(k.ENTER_PASSWORD)}</DialogTitle>
        <DialogContent className="color-background-white">
          {!showForgotPasswordMessage && (
            <StyledForm onSubmit={handleSubmit(onSubmitPassword)}>
              <span>{i18n.t(k.USER_ALREADY_EXISTING)}</span>
              <RHFInput
                as={
                  <StyledTextField
                    type="password"
                    variant="filled"
                    error={!!errors.password}
                    helperText={errors.password ? i18n.t(k.MINIMUM_6_CHARACTERS) : ''}
                    label={i18n.t(k.ENTER_PASSWORD)}
                    autoComplete="password"
                    inputRef={inputRef}
                  />
                }
                rules={{
                  required: true,
                  minLength: 6,
                }}
                mode="onSubmit"
                name="password"
                register={register}
                setValue={setValue}
              ></RHFInput>
              <StyledProgress visible={(loadingForgotPasswordMessage || loadingLogin).toString()}></StyledProgress>
              <ButtonContainer>
                <StyledButton onClick={onCancelPassword} color="secondary" variant="contained">
                  {i18n.t(k.BACK)}
                </StyledButton>
                <StyledButton type="submit" color="primary" variant="contained">
                  {i18n.t(k.LOGIN)}
                </StyledButton>
              </ButtonContainer>
              <ForgotPasswordButton className="color-background-white" onClick={sendForgotPassword}>
                {i18n.t(k.FORGOT_PASSWORD)}
              </ForgotPasswordButton>
            </StyledForm>
          )}

          {showForgotPasswordMessage && (
            <MessageContainer>
              <span>{i18n.t(k.MAIL_SENT)}</span>
              <br></br>
              <br></br>
              <ButtonContainer>
                <StyledButton
                  onClick={() => {
                    setShowForgotPasswordMessage(false);
                    onCancelPassword();
                  }}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.t(k.OK)}
                </StyledButton>
              </ButtonContainer>
            </MessageContainer>
          )}
        </DialogContent>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default EnterPasswordDialog;
