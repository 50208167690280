import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';

import boxShadows from '../../../../../../Shared/boxShadows';
import LocationSearchDialog from '../EditingDialogLocationItem/LocationSearchDialog';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import ElementTypeThumbnail from '../Components/ElementTypeThumbnail';
import EditingDialogContainer from '../../EditingDialogContainer/EditingDialogContainer';
import elementTypes from '../../../Constants/elementTypes';

const ElementTypeContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: #e8e8e8;
  width: 100%;
  margin: 12px 0;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: ${({ selected }) => (selected ? boxShadows.z2 : boxShadows.z1)};
  border: ${({ selected }) => (selected ? '3px solid' : '3px solid')};
  border-color: ${({ selected }) => (selected ? 'rgba(0, 0, 0, 0.42) !important' : 'white !important')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  flex-direction: column;
  align-items: center;
  height: auto;
`;

const ElementTypeDescription = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 100px;
  width: 100%;
  justify-content: center;
  padding: 12px;
`;

const AddingDialogElementSelection = ({ editingKeys, stopAdding, saveElement }) => {
  const [selectedElementType, setSelectedElementType] = useState(null);
  const [showEditingContainer, setShowEditingContainer] = useState(null);

  const openEditingContainer = () => {
    setShowEditingContainer(true);
  };

  const backToElementTypeSelection = () => {
    setShowEditingContainer(false);
  };
  const saveChanges = element => {
    setShowEditingContainer(false);
    saveElement({ ...element, elementType: selectedElementType });
  };

  const getLabelForElementType = elementTypeName => {
    switch (elementTypeName) {
      case 'pageTitle':
        return i18n.t(k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL);
      case 'scheduleItem':
        return i18n.t(k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL);
      case 'infoItem':
        return i18n.t(k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL);
      case 'largePhotoItem':
        return i18n.t(k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL);
      case 'locationMap':
        return i18n.t(k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL);
      case 'locationItem':
        return i18n.t(k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL);
      default:
        return '';
    }
  };

  return (
    <>
      <Dialog open isForm={false}>
        <DialogTitle>{i18n.t(k.ADD_ELEMENT)}</DialogTitle>
        <DialogContent>
          <strong>{i18n.t(k.WHICH_ELEMENT_TO_ADD)}</strong>
          {Object.keys(elementTypes).map(elementType => {
            if (elementTypes[elementType].allowedOnPages.includes(editingKeys.contentPage)) {
              return (
                <ElementTypeContainer
                  selected={selectedElementType === elementTypes[elementType].name}
                  key={elementType}
                  onClick={() => {
                    setSelectedElementType(elementTypes[elementType].name);
                  }}
                >
                  <ElementTypeDescription>
                    {selectedElementType === elementTypes[elementType].name ? (
                      <strong>{getLabelForElementType(elementType)}</strong>
                    ) : (
                      <span>{getLabelForElementType(elementType)}</span>
                    )}
                  </ElementTypeDescription>
                  <ElementTypeThumbnail>{elementTypes[elementType].component()}</ElementTypeThumbnail>
                </ElementTypeContainer>
              );
            } else {
              return null;
            }
          })}
        </DialogContent>
        <DialogActions
          stopEditing={stopAdding}
          onComplete={openEditingContainer}
          completeButtonText={i18n.t(k.NEXT)}
        ></DialogActions>
      </Dialog>
      {showEditingContainer &&
        (selectedElementType === 'locationItem' ? (
          <LocationSearchDialog
            stopAdding={backToElementTypeSelection}
            saveElement={saveChanges}
          ></LocationSearchDialog>
        ) : (
          <EditingDialogContainer
            stopEditing={backToElementTypeSelection}
            saveChanges={saveChanges}
            editingKeys={{ ...editingKeys, elementType: selectedElementType }}
            content={null}
          ></EditingDialogContainer>
        ))}
    </>
  );
};

export default AddingDialogElementSelection;
