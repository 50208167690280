import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import defaultStyles from '../DefaultStyles';
import breakpoints from '../../breakpoints';

import WeddingDate from './Components/WeddingDate';

const Container = styled.div`
  background-color: #ffffffd9;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  a {
    font-family: ${defaultStyles.modern.bodyFont};
    font-weight: bold;
  }
`;

const WeddingTitle = styled.div`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 50px !important;
  transform: rotate(-10deg);
  font-family: ${defaultStyles.modern.headerFont} !important;
  color: ${defaultStyles.modern.colors.primary.base} !important;
  font-weight: normal;
  line-height: 1.25;
  @media (max-height: 980px) {
    font-size: 40px !important;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    font-size: 30px !important;
  }
`;

const CustomLogo = styled.div`
  height: 100px;
  width: 300px;
  margin: 3px auto;
  max-width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledWeddingDate = styled(WeddingDate)`
  font-size: 15px !important;
  text-align: center;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const ModernMenuContainer = ({ weddingId, weddingConfig, closeDrawer, children }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container>
      <StyledNavLink to={{ pathname: `/${weddingId}/` }} onClick={() => closeDrawer()}>
        {weddingConfig.useCustomLogo ? (
          <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
        ) : (
          <>
            {weddingConfig && nameOfGroom && nameOfBride && (
              <>
                <WeddingTitle>
                  {nameOfBride} &amp; {nameOfGroom}
                </WeddingTitle>
                <StyledWeddingDate>{weddingDate}</StyledWeddingDate>
              </>
            )}
          </>
        )}
      </StyledNavLink>
      <div>{children}</div>
    </Container>
  );
};

export default ModernMenuContainer;
