import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../breakpoints';

import WeddingTitle from './Components/WeddingTitle';
import WeddingDate from './Components/WeddingDate';
import Overlay from './Components/Overlay';
import Container from './Components/Container';
import TopCenter from './Components/TopCenter';
import StartPhoto from './Components/StartPhoto';
import FloralFrame from './Components/FloralFrame';

const StyledTopCenter = styled(TopCenter)`
  transform: ${({ visible }) => (visible ? 'scale(0.7)' : 'translate3d(0, -500px, 0); scale(0.7)')};
  margin-top: -90px;
  @media (max-width: ${breakpoints.xs}) {
    margin-left: 0;
  }
`;

const BohoOnboardingContainer = ({ visible, nameOfBride, nameOfGroom, weddingDate }) => {
  return (
    <Container visible={visible}>
      <Overlay />
      <StyledTopCenter visible={visible}>
        <StartPhoto imageUrl="  ">
          <FloralFrame></FloralFrame>
        </StartPhoto>
        {nameOfBride && nameOfGroom && (
          <>
            <WeddingTitle>
              {nameOfBride} &amp; {nameOfGroom}
            </WeddingTitle>
          </>
        )}
        <WeddingDate>{weddingDate}</WeddingDate>
      </StyledTopCenter>
    </Container>
  );
};

export default BohoOnboardingContainer;
