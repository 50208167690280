import i18n from 'i18next';
import k from '../../../../../i18n/keys';
import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import defaultStyles from '../../../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../../../Shared/WeddingStyles/GenericStyleContainer';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import LinkButton from '../../../../../Shared/Components/LinkButton/LinkButton';

const StyledDialogContent = styled(DialogContent)`
  height: 450px;
  width: 500px;
  max-width: 65vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3,
  p {
    margin: 0px;
    margin-bottom: 12px;
  }
  button {
    background-color: transparent !important;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
  button {
    width: 150px;
  }
`;

const StyledH2 = styled.h2`
  margin: 12px 0;
`;

const GuestNumberExceededHintDialog = ({ show, setShow }) => {
  const { weddingConfig, weddingId } = useContext(WeddingContext);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="{messageTitle}"
      aria-describedby="{messageDescriptionText}"
      className="color-background-white"
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingConfig.weddingStyle]}>
        <StyledDialogContent className="color-background-white">
          <>
            <StyledH2>{i18n.t(k.MAX_GUESTS)} </StyledH2>
            <p>{i18n.t(k.MAX_GUESTS_REACHED)}</p>
            <p>
              <NavLink exact to={{ pathname: `/${weddingId}/payment` }}>
                <LinkButton>{i18n.t(k.ACTIVATE_PACKAGE)}</LinkButton>
              </NavLink>
            </p>
            <p>
              {i18n.t(k.IF_MESSAGE_WRONGFUL)}&nbsp;
              <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
            </p>
          </>
        </StyledDialogContent>
        <StyledDialogActions className="color-background-white">
          <Button onClick={handleClose} color="primary" variant="contained">
            {i18n.t(k.OK)}
          </Button>
        </StyledDialogActions>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default GuestNumberExceededHintDialog;
