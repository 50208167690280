import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 450px;
  width: 450px;
  transform: scale(1.2);
  background-image: url(${defaultStyles.boho.assets.floralFrame});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.macbookHeight}) {
    height: 320px;
    width: 320px;
  }
`;
