import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import HeroSection from './Sections/HeroSection';
import TopBar from './Components/TopBar';
import FAQSection from './Sections/FAQSection';
import DesignSection from './Sections/DesignSection';
import FeaturesSection from './Sections/FeaturesSection';
import TestimonialsSection from './Sections/TestimonialsSection';
import PricingSection from './Sections/PricingSection';
import isProduction from '../../Shared/FunctionUtils/isProduction';
import { Button } from '@material-ui/core';
import boxShadows from '../../Shared/boxShadows';
import { ReactComponent as MessengerSvg } from '../../_assets/svg/messenger.svg';

const StyledButton = styled(Button)`
  position: fixed;
  right: 12px;
  bottom: 12px;
  margin: 12px;
  margin-top: 0;
  height: 60px;
  width: 60px;
  min-width: 60px;
  padding: 0;
  border-radius: 50%;
  box-shadow: ${boxShadows.z3};
  z-index: 1;
  svg {
    height: 44px;
  }
`;

const LandingPage = ({ cookiePreference }) => {
  const { search } = useLocation();
  useEffect(() => {
    if (cookiePreference === 'ALL' && isProduction()) {
      hotjar.initialize(1808603, 6);
    }
  }, [cookiePreference]);

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <TopBar></TopBar>
      <HeroSection search={search}></HeroSection>
      <Element name="features">
        <FeaturesSection search={search}></FeaturesSection>
      </Element>
      <Element name="design">
        <DesignSection search={search}></DesignSection>
        <TestimonialsSection search={search}></TestimonialsSection>
      </Element>
      <Element name="pricing">
        <PricingSection search={search}></PricingSection>
      </Element>
      <Element name="faq">
        <FAQSection search={search}></FAQSection>
      </Element>

      <a href="https://www.m.me/eurehochzeitonline" target="_blank" rel="noopener noreferrer">
        <StyledButton color="primary" variant="contained">
          <MessengerSvg />
        </StyledButton>
      </a>
      <LegalFooter weddingStyle={'eurehochzeitonline'}></LegalFooter>
    </GenericStyleContainer>
  );
};

export default LandingPage;
