import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import boxShadows from '../../../../../Shared/boxShadows';
import BackingOverviewDialog from './BackingList/BackingOverviewDialog';

const Container = styled.div`
  overflow: hidden;
  background-color: #ffffffe0;
  border-radius: 0;
  border-top-left-radius: 12px;
  box-shadow: ${boxShadows.z2};
  border: none;
  padding-bottom: 21px;
`;

const BackingOverview = () => {
  const [showBackingOverviewDialog, setShowBackingOverviewDialog] = useState(false);

  return (
    <Container className="color-background-white">
      <p>
        <strong>{i18n.t(k.OVERVIEW_OF_ALL_PRESENTS)}</strong>
        <br></br>
        <span>{i18n.t(k.ONLY_VISIBLE_FOR_WEDDING_COUPLE)}</span>
      </p>
      <Button onClick={() => setShowBackingOverviewDialog(true)}>{i18n.t(k.SHOW_ALL_ENTRIES)}</Button>
      {showBackingOverviewDialog && (
        <BackingOverviewDialog
          showBackingOverviewDialog={showBackingOverviewDialog}
          setShowBackingOverviewDialog={setShowBackingOverviewDialog}
        ></BackingOverviewDialog>
      )}
    </Container>
  );
};

export default BackingOverview;
