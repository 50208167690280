import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import WeddingContext from '../../../Context/WeddingContext';

import Form from '../Components/Form';
import TextField from '../Components/TextField';
import Button from '../Components/Button';
import Subheader from '../Components/Subheader';
import StyledProgress from '../Components/StyledProgress';

const Username = ({ visible, onNext, loadingUsername }) => {
  const weddingContext = useContext(WeddingContext);
  const weddingConfig = weddingContext ? weddingContext.weddingConfig : null;
  const { handleSubmit, register, errors, setValue } = useForm({});
  const inputRef = useRef(null);

  useEffect(() => {
    if (visible && inputRef.current !== document.activeElement) {
      inputRef.current.focus();
    }
  }, [visible]);

  return (
    <Form visible={visible} onSubmit={handleSubmit(onNext)}>
      {weddingConfig ? (
        <Subheader>
          {i18n.t(k.WELCOME_TO_WEDDING_OF)} {weddingConfig.nameOfBride} &amp; {weddingConfig.nameOfGroom}
          {i18n.t(k.PLEASE_ENTER_MAIL)}
        </Subheader>
      ) : (
        <Subheader>{i18n.t(k.WELCOME_TO_EHO)}</Subheader>
      )}

      <RHFInput
        as={
          <TextField
            type="email"
            variant="filled"
            error={!!errors.username}
            helperText={errors.username ? i18n.t(k.ENTER_VALID_MAIL) : ''}
            label={i18n.t(k.YOUR_EMAIL_ADDRESS)}
            inputRef={inputRef}
          />
        }
        rules={{
          // eslint-disable-next-line
          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        }}
        mode="onSubmit"
        name="username"
        register={register}
        setValue={setValue}
      ></RHFInput>
      <StyledProgress visible={loadingUsername.toString()}></StyledProgress>
      <Button type="submit" disabled={loadingUsername} color="primary" variant="contained">
        {i18n.t(k.TO_WEDDING)}
      </Button>
    </Form>
  );
};

export default Username;
