import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 20px;
  width: 300px;
  margin: 12px;
  background-image: url(${defaultStyles.vintage.assets.horizontalBorder});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    width: 200px;
    margin: 6px;
  }
`;
