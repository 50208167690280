import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

export default styled.div`
  position: fixed;
  top: 37vh;
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    top: 50px;
  }
  z-index: 1;
  h1,
  a,
  p {
    z-index: 1;
    width: 500px;
    max-width: 90vw;
  }
`;
