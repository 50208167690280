import i18n from 'i18next';
import k from './../../../../../i18n/keys';

import React, { useContext, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { ReactComponent as EditSvg } from '../../../../../_assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../../../_assets/svg/delete.svg';
import EditingContext from '../../../../../Shared/Context/EditingContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import UserContext from '../../../../../Shared/Context/UserContext';
import ConfirmDialog from '../../../../../Shared/Components/Dialog/ConfirmDialog';
import moveElementInArray from '../../../../../Shared/FunctionUtils/moveElementInArray';

import EditingDialogContainer from '../EditingDialogContainer/EditingDialogContainer';
import saveWeddingContentUpdates from '../../Functions/saveWeddingContentUpdates';
import getContentPage from '../../Functions/getContentPage';
import abortDueToMissingRegistration from '../../Functions/abortDueToMissingRegistration';

import OuterContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/OuterContainer';
import InnerContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/InnerContainer';
import StyledIconButton from '../../../../../Shared/Components/Editing/EditingContainerComponents/StyledIconButton';
import MoreMenu from '../../../../../Shared/Components/Editing/EditingContainerComponents/MoreMenu';
import LanguageContext from '../../../../../Shared/Context/LanguageContext';
import elementTypes from '../../Constants/elementTypes';

const EditingContainerForCard = ({ children, editingKeys, content, loadingSave, setLoadingSave, deletionDisabled }) => {
  const { editingModeActive, setWeddingContent, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const [startedEditing, setStartedEditing] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { currentLanguage } = useContext(LanguageContext);

  const handleMove = direction => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    const delta = direction === 'UP' ? -1 : 1;
    const contentPage = getContentPage(weddingConfig, editingKeys);
    moveElementInArray(
      contentPage.elements,
      editingKeys.index,
      Math.max(Math.min(editingKeys.index + delta, contentPage.elements.length - 1), 0)
    );
    setLoadingSave(true);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  const handleEdit = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setStartedEditing(true);
  };

  const stopEditing = () => {
    setStartedEditing(false);
  };

  const requestDelete = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setShowDeleteDialog(true);
  };

  const doDelete = () => {
    setShowDeleteDialog(false);
    const contentPage = getContentPage(weddingConfig, editingKeys);
    contentPage.elements.splice(editingKeys.index, 1);
    setLoadingSave(true);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  const saveChanges = async changes => {
    const elementType = elementTypes[editingKeys.elementType];
    const contentPage = getContentPage(weddingConfig, editingKeys);
    const originalElement = { ...contentPage.elements[editingKeys.index] };

    if (editingKeys.elementType === 'presentElement') {
      changes.presents = changes.presents.map(present => {
        let changedLanguageEntry = {
          lng: currentLanguage,
        };
        const newPresent = { ...present };
        Object.entries(newPresent).forEach(change => {
          if (elementType.i18nProperties.indexOf(change[0]) >= 0) {
            changedLanguageEntry[change[0]] = change[1];
            delete newPresent[change[0]];
          }
        });
        if (!newPresent.i18n) newPresent.i18n = [];
        const originalLanguageIndex = newPresent.i18n.findIndex(l => l.lng === currentLanguage);
        if (originalLanguageIndex >= 0) {
          newPresent.i18n.splice(originalLanguageIndex, 1);
        }
        newPresent.i18n.push(changedLanguageEntry);
        return newPresent;
      });
    } else if (editingKeys.elementType === 'rsvpElement') {
      changes.foodOptions = changes.foodOptions.map(foodOption => {
        let changedLanguageEntry = {
          lng: currentLanguage,
        };
        const newFoodOption = { ...foodOption };
        Object.entries(newFoodOption).forEach(change => {
          if (change[0] === 'option') {
            changedLanguageEntry[change[0]] = change[1];
            delete newFoodOption[change[0]];
          }
        });
        if (!newFoodOption.i18n) newFoodOption.i18n = [];
        const originalLanguageIndex = newFoodOption.i18n.findIndex(l => l.lng === currentLanguage);
        if (originalLanguageIndex >= 0) {
          newFoodOption.i18n.splice(originalLanguageIndex, 1);
        }
        newFoodOption.i18n.push(changedLanguageEntry);
        return newFoodOption;
      });
    } else {
      let changedLanguageEntry = {
        lng: currentLanguage,
      };
      Object.entries(changes).forEach(change => {
        if (elementType.i18nProperties.indexOf(change[0]) >= 0) {
          changedLanguageEntry[change[0]] = change[1];
          delete changes[change[0]];
        }
      });
      const originalLanguageIndex = originalElement.i18n.findIndex(l => l.lng === currentLanguage);
      if (originalLanguageIndex >= 0) {
        originalElement.i18n.splice(originalLanguageIndex, 1);
      }
      originalElement.i18n.push(changedLanguageEntry);
    }

    contentPage.elements[editingKeys.index] = { ...contentPage.elements[editingKeys.index], ...changes };
    setStartedEditing(false);
    setLoadingSave(true);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  useEffect(() => {
    setLoadingSave(false);
  }, [weddingConfig, setLoadingSave]);

  return (
    <>
      {editingModeActive ? (
        <>
          <OuterContainer>
            <InnerContainer loadingSave={loadingSave} numberOfButtons={deletionDisabled ? 2 : 3}>
              <StyledIconButton onClick={handleEdit}>
                <EditSvg></EditSvg>
              </StyledIconButton>
              {!deletionDisabled && (
                <StyledIconButton onClick={requestDelete}>
                  <DeleteSvg></DeleteSvg>
                </StyledIconButton>
              )}
              <MoreMenu handleMove={handleMove}></MoreMenu>
            </InnerContainer>
          </OuterContainer>
          {startedEditing && (
            <EditingDialogContainer
              editingKeys={editingKeys}
              stopEditing={stopEditing}
              saveChanges={saveChanges}
              content={content}
            ></EditingDialogContainer>
          )}
          <ConfirmDialog
            open={showDeleteDialog}
            handleCancel={() => {
              setShowDeleteDialog(false);
            }}
            handleConfirm={doDelete}
            messageTitle={i18n.t(k.CONFIRM_DELETE_TITLE)}
            messageDescriptionText={i18n.t(k.CONFIRM_DELETE_TEXT)}
            confirmButtonText={i18n.t(k.DELETE)}
          ></ConfirmDialog>
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditingContainerForCard;
