import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export default styled(TextField)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
  }
  input {
    font-size: 16px !important;
    height: 26px;
    width: 300px;
    max-width: 90vw;
  }
`;
