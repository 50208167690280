const getSearchParamsAsObject = searchParams => {
  if (searchParams) {
    try {
      return JSON.parse(
        '{"' +
          decodeURI(searchParams.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    } catch (error) {
      return {};
    }
  } else {
    return {};
  }
};

export default getSearchParamsAsObject;
