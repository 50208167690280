import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default styled(Button)`
  margin: 0 auto 12px 12px;
  width: 40%;
  background-color: white;
  font-weight: bold;
  width: 30%;
`;
