import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';

import isMobile from '../../../../../../Shared/FunctionUtils/isMobile';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import ImageAndRadioContainer from '../Components/ImageAndRadioContainer';

const EditingDialogScheduleItem = ({ time, title, description, imageUrl, imagePosition, stopEditing, saveChanges }) => {
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentTime, setCurrentTime] = useState(time);
  const [currentDescription, setCurrentDescription] = useState(description);
  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);
  const [currentImagePosition, setCurrentImagePosition] = useState(imagePosition);

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      title: currentTitle,
      time: currentTime,
      description: currentDescription,
      imageUrl: currentImageUrl,
      imagePosition: currentImagePosition,
    });
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{i18n.t(k.EDIT_SCHEDULE_ITEM)}</DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          variant="filled"
          value={currentTime}
          onChange={event => setCurrentTime(event.target.value)}
          label={i18n.t(k.TIME)}
          autoFocus={isMobile() ? false : true}
        />

        <TextField
          type="text"
          variant="filled"
          value={currentTitle}
          onChange={event => setCurrentTitle(event.target.value)}
          label={i18n.t(k.SCHEDULE_ITEM)}
        />

        <TextField
          type="text"
          multiline
          minRows={3}
          variant="filled"
          value={currentDescription}
          onChange={event => setCurrentDescription(event.target.value)}
          label={i18n.t(k.DESCRIPTION)}
        />

        <ImageAndRadioContainer
          imageUrl={currentImageUrl}
          setImageUrl={setCurrentImageUrl}
          imagePosition={currentImagePosition}
          setImagePosition={setCurrentImagePosition}
        ></ImageAndRadioContainer>
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogScheduleItem;
