import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../DefaultStyles';

import EditingContainerForBaseData from '../../../Pages/WeddingPage/Editing/Components/EditingContainer/EditingContainerForBaseData';
import BackgroundElementContainer from '../../Components/BackgroundElementContainer/BackgroundElementContainer';

import TopLeft from './Components/TopLeft';
import TopRight from './Components/TopRight';
import BottomLeft from './Components/BottomLeft';
import BottomRight from './Components/BottomRight';
import WeddingTitle from './Components/WeddingTitle';
import WeddingDate from './Components/WeddingDate';
import Countdown from '../Components/Countdown';
import StartPhoto from './Components/StartPhoto';

const Container = styled.div`
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.modern.background.colors[1]}, ${defaultStyles.modern.background.colors[0]})`};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
`;

const WeddingTitleContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CustomLogo = styled.div`
  height: 350px;
  max-height: 25vh;
  width: 500px;
  max-width: 70vw;
  margin: auto;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const WeddingTitleInnerContainer = styled.div`
  position: relative;
  width: 600px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModernAppBackground = ({ showStartPage, weddingConfig }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container>
      <BackgroundElementContainer>
        {showStartPage && (
          <Countdown weddingConfig={weddingConfig} weddingStyle={weddingConfig.weddingStyle}></Countdown>
        )}
        <TopLeft visible reducedOpacity={!showStartPage}></TopLeft>
        <TopRight visible reducedOpacity={!showStartPage}></TopRight>
        {showStartPage && weddingConfig && (
          <WeddingTitleContainer>
            {weddingConfig.useCustomLogo ? (
              <WeddingTitleInnerContainer>
                {weddingConfig.useStartPhoto && <StartPhoto imageUrl={weddingConfig.startPhotoUrl}></StartPhoto>}
                <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
                <EditingContainerForBaseData></EditingContainerForBaseData>
              </WeddingTitleInnerContainer>
            ) : (
              <WeddingTitleInnerContainer>
                {weddingConfig.useStartPhoto && <StartPhoto imageUrl={weddingConfig.startPhotoUrl}></StartPhoto>}
                {nameOfBride && nameOfGroom && (
                  <WeddingTitle>
                    {nameOfBride} &amp; {nameOfGroom}
                  </WeddingTitle>
                )}
                <EditingContainerForBaseData>
                  <WeddingDate>{weddingDate}</WeddingDate>
                </EditingContainerForBaseData>
              </WeddingTitleInnerContainer>
            )}
          </WeddingTitleContainer>
        )}
        <BottomLeft visible reducedOpacity={!showStartPage}></BottomLeft>
        <BottomRight visible reducedOpacity={!showStartPage}></BottomRight>
      </BackgroundElementContainer>
    </Container>
  );
};

export default ModernAppBackground;
