import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

const Container = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiLinearProgress-root {
    width: 200px;
    margin: 12px;
  }
`;

const LoadingState = ({ message }) => {
  return (
    <Container>
      <LinearProgress></LinearProgress>
      <strong>{message}</strong>
    </Container>
  );
};

export default LoadingState;
