import i18n from 'i18next';
import k from './../../../../i18n/keys';

import React from 'react';
import styled from 'styled-components';
import { DialogActions, Button } from '@material-ui/core';

const StyledDialogActions = styled(DialogActions)`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffffeb;
  z-index: 1;
  display: flex;
  align-items: stretch;
`;

const SaveButton = styled(Button)`
  min-width: 140px;
`;

const EditingDialogActions = ({
  stopEditing,
  onComplete,
  completeButtonText = i18n.t(k.OK),
  stopEditingButtonText = i18n.t(k.CANCEL),
}) => {
  return (
    <StyledDialogActions>
      <Button onClick={stopEditing} color="secondary" variant="contained">
        {stopEditingButtonText}
      </Button>
      {onComplete ? (
        <SaveButton onClick={onComplete} color="primary" variant="contained">
          {completeButtonText}
        </SaveButton>
      ) : (
        <SaveButton type="submit" color="primary" variant="contained">
          {completeButtonText}
        </SaveButton>
      )}
    </StyledDialogActions>
  );
};

export default EditingDialogActions;
