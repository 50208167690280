import styled from 'styled-components';
import { DialogTitle } from '@material-ui/core';

import breakpoints from '../../../breakpoints';

export default styled(DialogTitle)`
  text-align: center;
  h2 {
    font-size: 36px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    padding: 12px;
    h2 {
      font-size: 26px;
    }
  }
`;
