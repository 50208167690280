import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React from 'react';
import BottomHintContainer from '../Components/BottomHintContainer';

const CreatorNotRegisteredHintBottom = () => {
  return <BottomHintContainer>{i18n.t(k.REGISTRATION_NOT_COMPLETE)}</BottomHintContainer>;
};

export default CreatorNotRegisteredHintBottom;
