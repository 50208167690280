import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 30px;
  z-index: 1;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    bottom: 21px;
  }
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    display: none;
  }
`;

const Knob = styled.div`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin: 12px;
  cursor: ${({ currentlyShown, clickable }) => (!currentlyShown && clickable ? 'pointer' : 'default')};
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ currentlyShown }) => (currentlyShown ? 'scale(1.5)' : 'none')};
`;

const Stepper = ({ currentStep, goToStep }) => {
  return (
    <Container visible={currentStep > 1}>
      {[1, 2, 3, 4, 5, 6, 7].map(step => (
        <Knob
          currentlyShown={currentStep === step}
          clickable={currentStep >= step}
          className={currentStep >= step ? 'active knob' : 'knob'}
          key={step}
          onClick={() => {
            if (currentStep >= step) {
              goToStep(step);
            }
          }}
        />
      ))}
    </Container>
  );
};

export default Stepper;
