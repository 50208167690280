import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormControlLabel, Radio, FormLabel } from '@material-ui/core';

import SelectedImage from './SelectedImage';
import RadioContainer from './RadioContainer';
import RadioGroup from './RadioGroup';
import ImageSelectorAndUploader from './ImageSelectorAndUploader/ImageSelectorAndUploader';

const Container = styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: 12px auto;
`;

const ImageAndRadioContainer = ({ imageUrl, setImageUrl, imagePosition, setImagePosition }) => {
  const [imageSelectionVisible, setImageSelectionVisible] = useState(false);

  const removeImage = () => {
    setImageUrl(null);
  };

  const openImageSelection = () => {
    setImageSelectionVisible(true);
  };
  const closeImageSelection = () => {
    setImageSelectionVisible(false);
  };

  const onCompleteImageSelection = selectedImage => {
    setImageUrl(selectedImage);
    setImageSelectionVisible(false);
  };

  return (
    <Container>
      <SelectedImage
        imageUrl={imageUrl}
        onRequestChange={openImageSelection}
        onRemoveImage={removeImage}
      ></SelectedImage>
      <ImageSelectorAndUploader
        open={imageSelectionVisible}
        closeImageSelection={closeImageSelection}
        onCompleteSelection={onCompleteImageSelection}
      ></ImageSelectorAndUploader>
      <RadioContainer>
        <FormLabel>{i18n.t(k.IMAGE_POSITION)}</FormLabel>
        <RadioGroup
          value={imagePosition}
          onChange={event => {
            setImagePosition(event.target.value);
          }}
        >
          <FormControlLabel value="left" control={<Radio />} label={i18n.t(k.LEFT)} />
          <FormControlLabel value="top" control={<Radio />} label={i18n.t(k.TOP)} />
          <FormControlLabel value="right" control={<Radio />} label={i18n.t(k.RIGHT)} />
          <FormControlLabel value="bottom" control={<Radio />} label={i18n.t(k.BOTTOM)} />
        </RadioGroup>
      </RadioContainer>
    </Container>
  );
};

export default ImageAndRadioContainer;
