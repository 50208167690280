import i18n from 'i18next';
import k from './../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';

const Container = styled.div`
  text-align: left;
  width: 100%;
  padding: 6px;
  font-size: 14px;
  background-color: #fafafa;
  h2 {
    color: #a54c4c;
  }
  h3 {
    color: #a15757;
  }
  a {
    color: #a15757;
  }
`;
const InnerContainer = styled.div`
  margin: auto;
  width: 700px;
  max-width: 99vw;
`;

const TermsAndConditionsPage = () => {
  return (
    <>
      <Container>
        <LanguageSelect></LanguageSelect>
        <InnerContainer>
          <h2>{i18n.t(k.TERMS_AND_CONDITIONS_00)}</h2>
          <div>
            <h3>{i18n.t(k.PRIVACY_01)}</h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_01)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_02)}</h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_03)}</p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_04)}</p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_05)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_06)}</h3>
            <p>
              {i18n.t(k.TERMS_AND_CONDITIONS_07)}{' '}
              <a target="_blank" href="/f/datenschutz/">
                {i18n.t(k.TERMS_AND_CONDITIONS_08)}
              </a>{' '}
              {i18n.t(k.TERMS_AND_CONDITIONS_09)}
            </p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_10)}</h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_11)}</p>
            <ul>
              <li>
                <strong>{i18n.t(k.TERMS_AND_CONDITIONS_12)}</strong> {i18n.t(k.TERMS_AND_CONDITIONS_13)}
              </li>
              <li>
                <strong>{i18n.t(k.TERMS_AND_CONDITIONS_14)}</strong> {i18n.t(k.TERMS_AND_CONDITIONS_15)}{' '}
              </li>
              <li>
                <strong>{i18n.t(k.TERMS_AND_CONDITIONS_16)}</strong> {i18n.t(k.TERMS_AND_CONDITIONS_17)}
              </li>
              <li>
                <strong>{i18n.t(k.TERMS_AND_CONDITIONS_18)}</strong> {i18n.t(k.TERMS_AND_CONDITIONS_19)}
              </li>
            </ul>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_20)}</p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_21)}</p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_22)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_23)}</h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_24)}</p>
            <ul>
              <li>{i18n.t(k.TERMS_AND_CONDITIONS_25)}</li>
              <li>{i18n.t(k.TERMS_AND_CONDITIONS_26)}</li>
              <li>{i18n.t(k.TERMS_AND_CONDITIONS_27)}</li>
              <li>{i18n.t(k.TERMS_AND_CONDITIONS_28)}</li>
              <li>{i18n.t(k.TERMS_AND_CONDITIONS_29)}</li>
            </ul>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_30)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_31)}</h3>
            <p>
              <strong>{i18n.t(k.TERMS_AND_CONDITIONS_32)}</strong>
            </p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_33)}</p>
            <p>
              <strong>{i18n.t(k.TERMS_AND_CONDITIONS_34)}</strong>
            </p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_35)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_36)}</h3>
            <p>
              {i18n.t(k.TERMS_AND_CONDITIONS_37)}{' '}
              <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>{' '}
              {i18n.t(k.TERMS_AND_CONDITIONS_38)}
            </p>
            <p>
              {i18n.t(k.TERMS_AND_CONDITIONS_39)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_40)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_41)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_42)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_43)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_44)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_45)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_46)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_47)}
              <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_48)}
            </p>
            <p>
              {i18n.t(k.TERMS_AND_CONDITIONS_49)} <br />
              {i18n.t(k.TERMS_AND_CONDITIONS_50)}
            </p>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_51)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_52)}</h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_53)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_54)} </h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_55)}</p>
            <h3>{i18n.t(k.TERMS_AND_CONDITIONS_56)}</h3>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_57)}</p>
            <div></div>
            <p>{i18n.t(k.TERMS_AND_CONDITIONS_58)}</p>
          </div>
        </InnerContainer>
      </Container>
    </>
  );
};

export default TermsAndConditionsPage;
