import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button, IconButton } from '@material-ui/core';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import boxShadows from '../../../../../../Shared/boxShadows';
import { ReactComponent as DeleteSvg } from '../../../../../../_assets/svg/delete-playlist.svg';
import { ReactComponent as AddSvg } from '../../../../../../_assets/svg/plus-slim.svg';
import ImageUploader from '../Components/ImageUploader/ImageUploader';
import breakpoints from '../../../../../../Shared/breakpoints';

const LineContainer = styled.div`
  display: flex;
  margin: 6px auto;
  box-shadow: ${boxShadows.z2};
  border-radius: 4px;
  position: relative;
  .MuiTextField-root {
    width: 370px;
  }
  .MuiFilledInput-input {
    font-size: 14px !important;
  }
  .name {
    input {
      font-weight: bold;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
    flex-direction: column;
    padding-bottom: 56px;
    .MuiTextField-root {
      width: 100%;
    }
  }
`;

const AddButton = styled(Button)`
  padding: 6px;
  box-shadow: ${boxShadows.z1};
  margin-top: 12px;
  width: 400px;
  margin-left: auto;
  svg {
    width: 21px;
    margin-right: 12px;
  }
  @media (max-width: ${breakpoints.xs}) {
    width: 300px;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 12px;
`;

const UrlPriceLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3px;
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
  }
`;

const AmazonUrl = styled(TextField)`
  width: 240px !important;
  max-width: 100%;
  margin: 0;
  @media (max-width: ${breakpoints.xs}) {
    width: 220px !important;
    margin: 0;
  }
`;
const Price = styled(TextField)`
  width: 100px !important;
  max-width: 100%;
  margin: 0;
  @media (max-width: ${breakpoints.xs}) {
    width: 80px !important;
    margin: 0;
  }
`;

const Image = styled.div`
  .image-uploader-container {
    width: 90px;
    margin: 6px 6px 3px 6px;
    @media (max-width: ${breakpoints.xs}) {
      width: 100%;
      margin: auto;
      margin-top: 6px;
    }
    .image {
      width: 90px;
      height: 168px;
      @media (max-width: ${breakpoints.xs}) {
        width: 80%;
        margin: auto;
      }
      .color-background-white {
        padding: 3px 0 0 3px;
        .dzu-dropzone {
          min-width: 40px;
          border: none;
          background-color: transparent;
          .dzu-inputLabel {
            background-color: transparent;
            border: none;
          }
          .filename {
            display: none;
          }
          .preview-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .preview-image {
            display: none;
          }
        }
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  height: 45px;
  width: 45px;
  margin: auto 6px 6px 0;
  box-shadow: ${boxShadows.z2};
  svg {
    height: 25px;
    width: 25px;
  }
  span {
    font-weight: bold !important;
  }
  @media (max-width: ${breakpoints.xs}) {
    position: absolute;
    bottom: 0;
    right: 0px;
  }
`;

const EditingDialogPresent = ({ presents, stopEditing, saveChanges }) => {
  const [currentPresents, setCurrentPresents] = useState(presents);

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      presents: currentPresents,
    });
  };

  const addNewPresent = () => {
    const newPresents = [
      ...currentPresents,
      {
        name: '',
        details: '',
        amazonUrl: '',
        price: '',
        id: uuidv4(),
        imageUrl: '',
      },
    ];
    setCurrentPresents([...newPresents]);
  };

  const removePresent = id => {
    const newPresents = [...currentPresents.filter(p => p.id !== id)];
    setCurrentPresents([...newPresents]);
  };

  const setPresentData = (value, index, type, id) => {
    const oldPresentsWithoutCurrent = [...currentPresents.filter(p => p.id !== id)];
    const newPresent = [...currentPresents][index];
    newPresent[type] = value;
    oldPresentsWithoutCurrent.splice(index, 0, newPresent);
    setCurrentPresents(oldPresentsWithoutCurrent);
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{i18n.t(k.EDIT_PRESENTS)}</DialogTitle>
      <DialogContent>
        <p>{i18n.t(k.PRESENT_INTRO_TEXT)}</p>
        <>
          {currentPresents.map((present, index) => (
            <LineContainer key={index}>
              <Image>
                <ImageUploader
                  imageUrl={present.imageUrl}
                  setImageUrl={imageUrl => setPresentData(imageUrl, index, 'imageUrl', present.id)}
                  hideButtonText
                ></ImageUploader>
              </Image>
              <FieldContainer>
                <TextField
                  type="text"
                  variant="filled"
                  value={present.name}
                  size="small"
                  onChange={event => setPresentData(event.target.value, index, 'name', present.id)}
                  label={i18n.t(k.LABEL)}
                  className="name"
                />

                <TextField
                  type="text"
                  variant="filled"
                  multiline
                  minRows={2}
                  value={present.details}
                  size="small"
                  onChange={event => setPresentData(event.target.value, index, 'details', present.id)}
                  label={i18n.t(k.DESCRIPTION)}
                />

                <UrlPriceLine>
                  <AmazonUrl
                    type="text"
                    variant="filled"
                    value={present.amazonUrl}
                    size="small"
                    onChange={event => setPresentData(event.target.value, index, 'amazonUrl', present.id)}
                    label={i18n.t(k.AMAZON_URL)}
                  />

                  <Price
                    type="number"
                    variant="filled"
                    value={present.price}
                    size="small"
                    onChange={event => setPresentData(event.target.value, index, 'price', present.id)}
                    label={i18n.t(k.COSTS)}
                  />
                </UrlPriceLine>
              </FieldContainer>
              <StyledIconButton onClick={() => removePresent(present.id)}>
                <DeleteSvg></DeleteSvg>
              </StyledIconButton>
            </LineContainer>
          ))}
          <AddButton onClick={addNewPresent} variant="contained" color="primary">
            <AddSvg></AddSvg> {i18n.t(k.ADDITIONAL_ENTRY)}
          </AddButton>
        </>
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogPresent;
