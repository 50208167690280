import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.h1`
  font-size: 85px !important;
  margin: 0 auto;
  line-height: 1;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${defaultStyles.vintage.headerFont} !important;
  color: ${defaultStyles.vintage.colors.primary.base} !important;
  @media (max-height: 980px) {
    font-size: 70px !important;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    font-size: 40px !important;
  }
`;
