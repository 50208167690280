import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';

export default styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-color: ${defaultStyles.vintage.background.overlay};
`;
