import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import styled from 'styled-components';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';

import EditingDialogLocationItem from './EditingDialogLocationItem';

const StyledDialog = styled(Dialog)`
  background-color: red;
  .MuiPaper-root {
    transform: translateY(-60px);
    background-color: red;
  }
  label {
    color: red !important;
  }
`;

const StyledTextField = styled(TextField)`
  @media (min-width: 450px) {
    margin-left: calc((100% - 400px) / 2) !important;
    margin-right: calc((100% - 400px) / 2) !important;
  }
`;

const SelectLocationError = styled.span`
  text-align: center;
  color: red !important;
  background-color: white !important;
  font-weight: bold;
  font-size: 16px !important;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;

const LocationSearchDialog = ({ name, stopAdding, saveElement, contentPage }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(name);
  const [autocomplete, setAutocomplete] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [showEditingDialog, setShowEditingDialog] = useState(false);
  const [showSelectLocationError, setShowSelectLocationError] = useState(false);

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete);
    setBounds(new window.google.maps.LatLngBounds({ lat: 48.15228, lng: 11.528074 }));
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const rawPlace = autocomplete.getPlace();
      const place = {
        name: rawPlace.name,
        street: rawPlace.address_components.filter(c => c.types.includes('route'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('route'))[0].long_name
          : '',
        houseNumber: rawPlace.address_components.filter(c => c.types.includes('street_number'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('street_number'))[0].long_name
          : '',
        zip: rawPlace.address_components.filter(c => c.types.includes('postal_code'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('postal_code'))[0].long_name
          : '',
        city: rawPlace.address_components.filter(c => c.types.includes('locality'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('locality'))[0].long_name
          : '',
        country: rawPlace.address_components.filter(c => c.types.includes('country'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('country'))[0].long_name
          : '',
        iconType: '',
        latitude: rawPlace.geometry.location.lat(),
        longitude: rawPlace.geometry.location.lng(),
        mapsUrl: rawPlace.url,
      };
      setShowSelectLocationError(false);
      setSelectedLocation(place);
      setSearchTerm(`${place.name}`);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const onChange = e => {
    setSelectedLocation(null);
    setSearchTerm(e.target.value);
  };

  const handleSave = async e => {
    e.preventDefault();
    if (selectedLocation) {
      setShowEditingDialog(true);
    } else {
      setShowSelectLocationError(true);
    }
  };

  return (
    <StyledDialog onSubmit={handleSave} open>
      <DialogTitle>{contentPage === 'LOCATION' ? i18n.t(k.ADD_LOCATION) : i18n.t(k.ADD_PLACE)}</DialogTitle>
      <DialogContent>
        <Autocomplete
          debug
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          bounds={bounds}
          styles={{ textAlign: 'center' }}
          options={{
            fields: ['name', 'address_component', 'geometry', 'type', 'url'],
          }}
        >
          <StyledTextField
            type="text"
            variant="filled"
            label={i18n.t(k.ADDRESS_OR_NAME_OF_LOCATION)}
            placeholder=""
            onChange={onChange}
            value={searchTerm}
            autoFocus
          ></StyledTextField>
        </Autocomplete>
        <SelectLocationError visible={showSelectLocationError}>{i18n.t(k.SELECT_SEARCH_RESULT)}</SelectLocationError>
      </DialogContent>
      <DialogActions stopEditing={stopAdding}></DialogActions>

      {showEditingDialog && (
        <EditingDialogLocationItem
          name={selectedLocation.name}
          street={selectedLocation.street}
          houseNumber={selectedLocation.houseNumber}
          zip={selectedLocation.zip}
          city={selectedLocation.city}
          country={selectedLocation.country}
          iconType={selectedLocation.iconType}
          latitude={selectedLocation.latitude}
          longitude={selectedLocation.longitude}
          mapsUrl={selectedLocation.mapsUrl}
          websiteUrl=""
          stopEditing={() => setShowEditingDialog(false)}
          saveChanges={saveElement}
          contentPage={contentPage}
        ></EditingDialogLocationItem>
      )}
    </StyledDialog>
  );
};

export default LocationSearchDialog;
