import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.p`
  font-size: 24px !important;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 6px;
  margin-top: -25px;
  font-family: ${defaultStyles.modern.bodyFont} !important;
  color: ${defaultStyles.modern.colors.secondary.base} !important;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    font-size: 16px !important;
  }
`;
