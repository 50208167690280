import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import boxShadows from '../../../../../../Shared/boxShadows';

export default styled(IconButton)`
  margin: 4px;
  box-shadow: ${boxShadows.z1};
  width: 50px;
  height: 50px;
  svg {
    height: 100%;
    width: 100%;
  }
`;
