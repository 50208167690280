import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SuccessSvg } from '../../../../_assets/svg/success.svg';
import { ReactComponent as ErrorSvg } from '../../../../_assets/svg/error.svg';

const Container = styled.div`
  margin-right: 9px;
  height: 50px;
  width: 50px;
  min-width: 50px;
  fill: ${({ isError }) => (isError ? '#c2373e' : '#1a9b58')};
`;

const ErrorIcon = ({ isError }) => {
  return (
    <Container isError={isError}>
      {isError && <ErrorSvg></ErrorSvg>}
      {!isError && <SuccessSvg></SuccessSvg>}
    </Container>
  );
};

export default ErrorIcon;
