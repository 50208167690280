import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export default styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #000000;
  }
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    width: 1920px;
    height: 900px;
    max-width: 100vw;
    max-height: 90vh;
  }
  .image-gallery {
    overflow: hidden;
  }
  .image-gallery-thumbnails-container {
    position: fixed;
    bottom: 0;
    margin: 12px 0;
    opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
    z-index: ${({ visible }) => (visible === 'true' ? '1000' : '0')};
  }
  .image-gallery-swipe {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .image-gallery-slide {
    padding-top: 12px;
    outline: none !important;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 4px solid #810405;
  }
`;
