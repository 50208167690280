import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import Form from '../Components/Form';
import TextField from '../Components/TextField';
import Button from '../Components/Button';
import ButtonContainer from '../Components/ButtonContainer';
import Subheader from '../Components/Subheader';
import ForgotPasswordLink from '../Components/ForgotPasswordLink';
import StyledProgress from '../Components/StyledProgress';

const Password = ({
  visible,
  onBack,
  onNext,
  onForgotPassword,
  newPassword,
  displayName,
  loadingPassword,
  weddingStyle,
  weddingId,
  appPage,
  appPageId,
}) => {
  const { handleSubmit, register, errors, setValue } = useForm({});
  const inputRef = useRef(null);

  useEffect(() => {
    if (visible && inputRef.current !== document.activeElement) {
      inputRef.current.focus();
    }
  }, [visible]);

  return (
    <Form visible={visible} onSubmit={handleSubmit(onNext)}>
      {!newPassword && (
        <Subheader>
          {i18n.t(k.WELCOME_BACK)} {displayName}
          {i18n.t(k._DOT)} <br></br>
          {i18n.t(k.ENTER_PASSWORD)}
        </Subheader>
      )}

      {newPassword && (
        <Subheader>
          {i18n.t(k.ALMOST_THERE)} {displayName}
          {i18n.t(k._DOT)} <br></br>
          {i18n.t(k.PLEASE_SET_PASSWORD)}
        </Subheader>
      )}

      {!newPassword && (
        <ForgotPasswordLink
          weddingstyle={weddingStyle}
          to={{
            pathname: weddingId
              ? `/${weddingId}/login/forgotPasswordMessage/`
              : `/${appPage}/${appPageId}/app-login/forgotPasswordMessage/`,
          }}
          onClick={onForgotPassword}
        >
          {i18n.t(k.FORGOT_PASSWORD)}
        </ForgotPasswordLink>
      )}

      <RHFInput
        as={
          <TextField
            type="password"
            variant="filled"
            error={!!errors.password}
            helperText={errors.password ? i18n.t(k.MINIMUM_6_CHARACTERS) : ''}
            label={newPassword ? i18n.t(k.PLEASE_SET_PASSWORD_SHORT) : i18n.t(k.ENTER_PASSWORD)}
            autoComplete="password"
            inputRef={inputRef}
          />
        }
        rules={{
          required: true,
          minLength: 6,
        }}
        mode="onSubmit"
        name="password"
        register={register}
        setValue={setValue}
      ></RHFInput>
      <StyledProgress visible={loadingPassword.toString()}></StyledProgress>
      <ButtonContainer>
        <Button disabled={loadingPassword} onClick={onBack} color="secondary" variant="contained">
          {i18n.t(k.BACK)}
        </Button>
        <Button disabled={loadingPassword} type="submit" color="primary" variant="contained">
          {i18n.t(k.LOGIN)}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default Password;
