import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import adjustFontSize from './FontAdjustment';
import adjustColor from './adjustColor';
import breakpoints from '../breakpoints';
import getTextColorFromBackground from './getTextColorFromBackColor';

const GlobalStyle = createGlobalStyle`
  .MuiPopover-root { 
    .MuiPickersToolbar-toolbar {
      background-color: ${({ wsd }) => adjustColor(wsd.colors.secondary.base, 30)};
    } 
    .MuiPickersDay-daySelected {
      background-color: ${({ wsd }) => adjustColor(wsd.colors.secondary.base, 30)};
    }
  }
  .MuiButton-containedPrimary {
      background-color: ${({ wsd }) => wsd.colors.button.primaryColor};
      color: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)};
  }
  .MuiButton-containedPrimary:hover {
      background-color: ${({ wsd }) => adjustColor(wsd.colors.button.primaryColor, -30)};
      color: ${({ wsd }) =>
        adjustColor(getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base), 20)};
  }
  .MuiDialogTitle-root {
    h2 {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '20px')};
      font-weight: bold;
      color: ${({ wsd }) => wsd.colors.primary.onWhite};
    }
  }
  .MuiDialogContent-root {
    .MuiTypography-colorTextSecondary {
      color: rgba(0, 0, 0, 0.87);
    }
    p {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '16px')};
    }
    a {
      color: ${({ wsd }) => wsd.colors.primary.onWhite};

    }
  }
  .Mui-error.MuiFormHelperText-root.MuiFormHelperText-contained {
    background-color: rgba(255, 255, 255, 0.62);
    border-radius: 3px;
    padding: 3px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .MuiDialog-paperScrollPaper {
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    max-height: calc(100% - 100px);
    }
  }
  .MuiDialog-paper {
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    margin: 20px;
    }
  }
  .MuiTouchRipple-child {
    background-color: rgba(0, 0, 0, 0.62);
  }
  .MuiTypography-root {
    font-family: ${({ wsd }) => wsd.bodyFont};
    font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '14px')};
    }
  }
  .MuiPickersYear-root.MuiTypography-colorPrimary, .MuiPickersYear-root:focus, .MuiPickersDay-current{
    color: ${({ wsd }) => adjustColor(wsd.colors.secondary.base, 30)};
  }
  .MuiPickersDay-daySelected{
    color: #ffffff !important;
  }
  .google-place-results {
    span {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '16px')};
      font-family: ${({ wsd }) => wsd.bodyFont};
      color: #333333;
    }
  }
  a {
    color: ${({ wsd }) => wsd.colors.secondary.onWhite};
  }
  div,
  p,
  span, 
  .MuiTableCell-root,
  .MuiMenuItem-root {
    font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '14px')};
    }
    font-family: ${({ wsd }) => wsd.bodyFont};
  }
  .MuiTabs-indicator {
    background-color: ${({ wsd }) => adjustColor(wsd.colors.secondary.base, -30)};
  }
  .MuiBadge-badge {
    background-color: ${({ wsd }) => `${wsd.colors.button.primaryColor}`};
    color: ${({ wsd }) =>
      getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)} !important;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${({ wsd }) => adjustColor(wsd.colors.secondary.base, -30)};
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ wsd }) => adjustColor(wsd.colors.secondary.base, 30)};
  }
  .pac-container{
    z-index: 1500;    
    max-height: 200px;
    overflow-y: auto;
    :after {
      display: none;
    }
  .pac-item {
    display: flex;
    flex-direction: column;
    padding: 6px;
    cursor: pointer;
    > .pac-icon-marker {
      display: none;
    }
    span {
    font-size: 18px !important;
    }
    > :last-child {      
      font-size: 15px !important;
      margin-top: -3px;
    }
  }
  }
`;

const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ wsd }) => wsd.headerFont};
    color: ${({ wsd }) => wsd.colors.primary.onDefaultBackground};
    font-weight: normal;
  }
  h1 {
    font-size: ${({ wsd }) => adjustFontSize(wsd.headerFont, '36px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ wsd }) => adjustFontSize(wsd.headerFont, '18px')};
    }
  }
  h2 {
    font-size: ${({ wsd }) => adjustFontSize(wsd.headerFont, '24px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ wsd }) => adjustFontSize(wsd.headerFont, '18px')};
    }
  }
  div,
  p,
  span {
    font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '14px')};
    }
    color: ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
    font-family: ${({ wsd }) => wsd.bodyFont};
    border-color: ${({ wsd }) => wsd.colors.primary.base};
  }

  .MuiSwitch-thumb {
    background-color: ${({ wsd }) =>
      `${getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)}!important`};
    filter: brightness(0.8);
  }
  .MuiSwitch-track {
    background-color: ${({ wsd }) =>
      `${getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)}99!important`};
  }
  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: ${({ wsd }) =>
        getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)} !important;
      filter: brightness(1.5);
    }
  }
  .color-background-white {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${({ wsd }) => wsd.colors.primary.onWhite};
    }
    div,
    p,
    span,
    a,
    .MuiInputBase-input,
    .MuiFormLabel-root,
    .MuiFormControlLabel-root .MuiTypography-root {
      color: ${({ wsd }) => wsd.colors.secondary.onWhite};
    }
    svg {
      fill: ${({ wsd }) => wsd.colors.button.primaryOnWhite};
    }
    button,
    a.MuiButton-root {
      border-color: ${({ wsd }) => wsd.colors.button.primaryOnWhite};
      background-color: ${({ wsd }) => wsd.colors.button.primaryOnWhite};
      > span {
        color: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.primaryOnWhite, wsd.colors.primary.onWhite)};
      }
      svg {
        fill: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.primaryOnWhite, wsd.colors.primary.onWhite)};
        circle {
          stroke: ${({ wsd }) =>
            getTextColorFromBackground(wsd.colors.button.primaryOnWhite, wsd.colors.primary.onWhite)};
        }
      }
      &.MuiButton-containedSecondary {
        background-color: ${({ wsd }) => wsd.colors.button.secondaryOnWhite};
        > span {
          color: ${({ wsd }) =>
            getTextColorFromBackground(wsd.colors.button.secondaryOnWhite, wsd.colors.secondary.onWhite)};
        }
        svg {
          fill: ${({ wsd }) =>
            getTextColorFromBackground(wsd.colors.button.secondaryOnWhite, wsd.colors.secondary.onWhite)};
        }
      }
      :hover {
        border-color: ${({ wsd }) => adjustColor(wsd.colors.button.primaryOnWhite, -30)};
        background-color: ${({ wsd }) => adjustColor(wsd.colors.button.primaryOnWhite, -30)};
        > span {
          color: ${({ wsd }) =>
            adjustColor(getTextColorFromBackground(wsd.colors.button.primaryOnWhite, wsd.colors.primary.onWhite), 20)};
        }
        svg {
          fill: ${({ wsd }) =>
            adjustColor(getTextColorFromBackground(wsd.colors.button.primaryOnWhite, wsd.colors.primary.onWhite), 20)};
        }
        &.MuiButton-containedSecondary {
          background-color: ${({ wsd }) => adjustColor(wsd.colors.button.secondaryOnWhite, -30)};
          > span {
            color: ${({ wsd }) =>
              adjustColor(
                getTextColorFromBackground(wsd.colors.button.secondaryOnWhite, wsd.colors.secondary.onWhite),
                20
              )};
          }
          svg {
            fill: ${({ wsd }) =>
              adjustColor(
                getTextColorFromBackground(wsd.colors.button.secondaryOnWhite, wsd.colors.secondary.onWhite),
                20
              )};
          }
        }
      }
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: ${({ wsd }) => `2px solid ${wsd.colors.primary.onWhite}`};
    }
    .MuiInput-underline:after {
      border-bottom: ${({ wsd }) => `2px solid ${wsd.colors.primary.onWhite}`};
    }
    .MuiFilledInput-underline:after {
      border-bottom: 2px solid ${({ wsd }) => wsd.colors.primary.onWhite};
    }
    .MuiSwitch-thumb {
      background-color: ${({ wsd }) => `${wsd.colors.button.primaryOnWhite}!important`};
      filter: brightness(0.8);
    }
    .MuiSwitch-track {
      background-color: ${({ wsd }) => `${wsd.colors.button.primaryOnWhite}99!important`};
    }
    .Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ wsd }) => `${wsd.colors.button.primaryOnWhite}!important`};
        filter: brightness(1.5);
      }
    }
  }
  .color-background-default {
    .MuiSwitch-thumb {
      background-color: ${({ wsd }) => `${adjustColor(wsd.colors.button.primaryColor, -30)}!important`};
      filter: brightness(0.8);
    }
    .MuiSwitch-track {
      background-color: ${({ wsd }) => `${adjustColor(wsd.colors.button.primaryColor, -30)}99!important`};
    }
    .Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ wsd }) => `${adjustColor(wsd.colors.button.primaryColor, -30)}!important`};
        filter: brightness(1.5);
      }
    }
  }
  a {
    color: ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
    font-weight: bold;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: ${({ wsd }) => `2px solid ${wsd.colors.secondary.base}`};
  }
  .MuiInput-underline:after {
    border-bottom: ${({ wsd }) => `2px solid ${wsd.colors.secondary.base}`};
  }
  .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }
  .MuiFormLabel-root,
  .MuiFormControlLabel-root .MuiTypography-root {
    font-family: ${({ wsd }) => wsd.bodyFont};
    color: ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
    font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '14px')};
    }
  }
  .MuiInputBase-input {
    font-family: ${({ wsd }) => wsd.bodyFont};
    font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '24px')};
    color: ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
    transition: background-color 50000s ease-in-out 0s;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-family: ${({ wsd }) => wsd.bodyFont};
    font-size: ${({ wsd }) => adjustFontSize(wsd.bodyFont, '24px')};
    color: ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
    -webkit-text-fill-color: ${({ wsd }) => wsd.colors.secondary.onDefaultBackground};
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .MuiPickersDatePickerRoot-toolbar {
    background-color: black !important;
    opacity: 0.7;
  }
  button {
    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1), color 500ms cubic-bezier(0.4, 0, 0.2, 1);
    font-family: ${({ wsd }) => wsd.bodyFont};
    border-color: ${({ wsd }) => wsd.colors.button.primaryColor};
    background-color: ${({ wsd }) => wsd.colors.button.primaryColor};
    span {
      color: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)};
    }
    svg {
      fill: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base)};
    }
    &.MuiButton-containedSecondary {
      background-color: ${({ wsd }) => wsd.colors.button.secondaryColor};
      > span {
        color: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.secondaryColor, wsd.colors.secondary.base)};
      }
      svg {
        fill: ${({ wsd }) => getTextColorFromBackground(wsd.colors.button.secondaryColor, wsd.colors.secondary.base)};
      }
    }
    :hover {
      border-color: ${({ wsd }) => adjustColor(wsd.colors.button.primaryColor, -30)};
      background-color: ${({ wsd }) => adjustColor(wsd.colors.button.primaryColor, -30)};
      > span {
        color: ${({ wsd }) =>
          adjustColor(getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base), 20)};
      }
      &.MuiButton-containedSecondary {
        background-color: ${({ wsd }) => adjustColor(wsd.colors.button.secondaryColor, -20)};
        > span {
          color: ${({ wsd }) =>
            adjustColor(getTextColorFromBackground(wsd.colors.button.secondaryColor, wsd.colors.secondary.base), 20)};
        }
        svg {
          fill: ${({ wsd }) =>
            adjustColor(getTextColorFromBackground(wsd.colors.button.secondaryColor, wsd.colors.secondary.base), 20)};
        }
      }
    }
    svg {
      fill: ${({ wsd }) =>
        adjustColor(getTextColorFromBackground(wsd.colors.button.primaryColor, wsd.colors.primary.base), 20)};
    }
  }

  .knob {
    border: ${({ wsd }) => `solid 2px ${wsd.colors.primary.base}`};
    &.active {
      background-color: ${({ wsd }) => wsd.colors.primary.base};
    }
  }
`;

const GenericStyleContainer = ({ weddingStyleData, children }) => {
  return (
    <>
      <GlobalStyle wsd={weddingStyleData} />
      <Container wsd={weddingStyleData}>{children}</Container>
    </>
  );
};

export default GenericStyleContainer;
