import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  transform: ${({ visible }) => (visible ? 'rotate(340deg)' : 'rotate(340deg) translate3d(800px, 500px, 0)')};
  bottom: 17px;
  right: -582px;
  @media (max-width: ${breakpoints.md}) {
    bottom: -100px;
    right: -800px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    display: none;
  }
`;
