import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';

import isMobile from '../../../../../../Shared/FunctionUtils/isMobile';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import StreetAndHouseNumber from '../Components/StreetAndHouseNumber';
import ZipAndCity from '../Components/ZipAndCity';
import IconSelector from '../Components/IconSelector';

const EditingDialogLocationItem = ({
  name,
  street,
  houseNumber,
  zip,
  city,
  country,
  iconType,
  latitude,
  longitude,
  mapsUrl,
  stopEditing,
  saveChanges,
  websiteUrl,
  contentPage,
  remarks,
}) => {
  const [currentName, setCurrentName] = useState(name);
  const [currentStreet, setCurrentStreet] = useState(street);
  const [currentHouseNumber, setCurrentHouseNumber] = useState(houseNumber);
  const [currentZip, setCurrentZip] = useState(zip);
  const [currentCity, setCurrentCity] = useState(city);
  const [currentCountry, setCurrentCountry] = useState(country);
  const [currentIconType, setCurrentIconType] = useState(iconType);
  const [currentWebsiteUrl, setCurrentWebsiteUrl] = useState(websiteUrl);
  const [currentRemarks, setCurrentRemarks] = useState(remarks);

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      name: currentName,
      street: currentStreet,
      houseNumber: currentHouseNumber,
      zip: currentZip,
      city: currentCity,
      country: currentCountry,
      iconType: currentIconType,
      websiteUrl: currentWebsiteUrl,
      latitude: latitude,
      longitude: longitude,
      mapsUrl: mapsUrl,
      remarks: currentRemarks,
    });
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{contentPage === 'LOCATION' ? i18n.t(k.EDIT_LOCATION) : i18n.t(k.EDIT_PLACE)}</DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          variant="filled"
          value={currentName}
          onChange={event => setCurrentName(event.target.value)}
          label={i18n.t(k.NAME_OF_PLACE)}
          autoFocus={isMobile() ? false : true}
        />

        <StreetAndHouseNumber>
          <TextField
            type="text"
            variant="filled"
            value={currentStreet}
            onChange={event => setCurrentStreet(event.target.value)}
            label={i18n.t(k.STREET)}
          />

          <TextField
            type="text"
            variant="filled"
            value={currentHouseNumber}
            onChange={event => setCurrentHouseNumber(event.target.value)}
            label={i18n.t(k.HOUSENUMBER)}
          />
        </StreetAndHouseNumber>
        <ZipAndCity>
          <TextField
            type="text"
            variant="filled"
            value={currentZip}
            onChange={event => setCurrentZip(event.target.value)}
            label={i18n.t(k.ZIP)}
          />

          <TextField
            type="text"
            variant="filled"
            value={currentCity}
            onChange={event => setCurrentCity(event.target.value)}
            label={i18n.t(k.CITY)}
          />
        </ZipAndCity>
        <TextField
          type="text"
          variant="filled"
          value={currentCountry}
          onChange={event => setCurrentCountry(event.target.value)}
          label={i18n.t(k.COUNTRY)}
        />

        <TextField
          type="text"
          variant="filled"
          value={currentWebsiteUrl}
          onChange={event => setCurrentWebsiteUrl(event.target.value)}
          label={i18n.t(k.WEBSITE)}
        />

        <TextField
          type="text"
          variant="filled"
          multiline
          minRows={3}
          value={currentRemarks}
          onChange={event => setCurrentRemarks(event.target.value)}
          label={i18n.t(k.HINTS_FOR_GUESTS)}
        />

        <IconSelector
          contentPage={contentPage}
          onSelectIcon={icon => {
            setCurrentIconType(icon);
          }}
          selectedIcon={currentIconType}
        ></IconSelector>
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogLocationItem;
