import styled from 'styled-components';

import breakpoints from '../../../breakpoints';

export default styled.div`
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(0, -500px, 0)')};
  margin-top: 100px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 70vh;
  @media (max-width: ${breakpoints.xs}) {
    margin-top: 10px;
    margin-left: 12vw;
  }
  @media (orientation: portrait) and (max-height: ${breakpoints.mobileKeyboardHeight}) {
    opacity: 0;
  }
`;
