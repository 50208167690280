import getRoleOfUserInWedding from './getRoleOfUserInWedding';
import isDemoWedding from './isDemoWedding';

const hasUserReadPermissionForWedding = (user, requestedWedding) => {
  if (isDemoWedding(requestedWedding)) {
    return true;
  } else if (user && user.weddings && getRoleOfUserInWedding(user.weddings, requestedWedding)) {
    return true;
  } else {
    return false;
  }
};

export default hasUserReadPermissionForWedding;
