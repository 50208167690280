import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import defaultStyles from '../../DefaultStyles';

export default styled.h1`
  margin: 6px 0;
  display: flex;
  font-family: ${defaultStyles.flower.headerFont} !important;
  color: ${defaultStyles.flower.colors.primary.base} !important;
  font-size: ${({ characters }) => `calc(420px / ${characters}) !important`};
  @media (max-width: ${breakpoints.xs}) {
    font-size: ${({ characters }) => `calc(200px / ${characters}) !important`};
  }
`;
