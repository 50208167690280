import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ReactGA from 'react-ga4';
import UserContext from '../../../../../Shared/Context/UserContext';
import AttendanceToggle from './RsvpElement/AttendanceToggle';
import GuestContainer from './RsvpElement/GuestContainer';
import GuestFoodSelection from './RsvpElement/GuestFoodSelection';
import GuestFormContainer from './RsvpElement/GuestFormContainer';
import GuestImageContainer from './RsvpElement/GuestImageContainer';
import GuestName from './RsvpElement/GuestName';
import Introtext from './RsvpElement/Introtext';
import Remark from './RsvpElement/Remark';
import RemoveGuestButton from './RsvpElement/RemoveGuestButton';
import SubmitButton from './RsvpElement/SubmitButton';
import { ReactComponent as GuestSvg } from '../../../../../_assets/svg/guest_guestlist.svg';
import { ReactComponent as CloseSvg } from '../../../../../_assets/svg/close-simple.svg';
import { ReactComponent as PlusSvg } from '../../../../../_assets/svg/plus-slim.svg';
import { Button, CircularProgress } from '@material-ui/core';
import AddGuestButtonContainer from './RsvpElement/AddGuestButtonContainer';
import AlertDialog from '../../../../../Shared/Components/Dialog/AlertDialog';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import LoadingState from '../../../../../Shared/Components/LoadingState/LoadingState';
import AlreadySubmittedHint from './RsvpElement/AlreadySubmittedHint';
import isDemoWedding from '../../../../../Shared/FunctionUtils/isDemoWedding';
import DemoWeddingHintDialog from '../../../Components/WeddingStatusHints/DemoWeddingHint/DemoWeddingHintDialog';
import getRoleOfUserInWedding from '../../../../../Shared/FunctionUtils/getRoleOfUserInWedding';
import CreatorNotRegisteredHintDialog from '../../../Components/WeddingStatusHints/CreatorNotRegisteredHint/CreatorNotRegisteredHintDialog';

const Container = styled.div`
  overflow: hidden;
  min-height: 500px;
`;

const RsvpElement = ({ foodOptions, foodOptionsActive }) => {
  const { fidiraUser } = useContext(UserContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const [loadingIfRsvpSubmitted, setLoadingIfRsvpSubmitted] = useState(true);
  const [rsvpSubmitted, setRsvpSubmitted] = useState(false);
  const [submissionDate, setSubmissionDate] = useState(null);
  const [willAttend, setWillAttend] = useState(true);
  const [guests, setGuests] = useState([{ displayName: fidiraUser?.displayName || '', selectedFood: null }]);
  const [remark, setRemark] = useState('');
  const [shownValidationError, setShownValidationError] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showDemoWeddingHintDialog, setShowDemoWeddingHintDialog] = useState(false);
  const [showCreatorNotRegisteredDialog, setShowCreatorNotRegisteredDialog] = useState(false);

  const setGuestName = (guestName, index) => {
    const newGuests = [...guests];
    newGuests[index].displayName = guestName;
    setGuests(newGuests);
  };
  const setSelectedFood = (food, index) => {
    const newGuests = [...guests];
    newGuests[index].selectedFood = food;
    setGuests(newGuests);
  };

  const addGuest = () => {
    setGuests([...guests, { displayName: '', selectedFood: null }]);
  };

  const removeGuest = guestIndex => {
    const newGuests = [...guests];
    newGuests.splice(guestIndex, 1);
    setGuests(newGuests);
  };

  const submitRsvp = async () => {
    const usersWithMissingNames = [];
    const usersWithMissingFoodSelection = [];
    guests.forEach((g, index) => {
      if (!g.displayName) {
        usersWithMissingNames.push(index + 1);
      }
      if (foodOptionsActive && !g.selectedFood) {
        usersWithMissingFoodSelection.push(index + 1);
      }
    });
    let validationError = '';
    if (usersWithMissingNames.length > 0) {
      validationError =
        validationError + `${i18n.t(k.GUEST_NAME_MISSING)} ${usersWithMissingNames.map(g => `#${g}`).join(', ')}. `;
    }
    if (usersWithMissingFoodSelection.length > 0) {
      validationError =
        validationError +
        `${i18n.t(k.GUEST_FOOD_MISSING)} ${usersWithMissingFoodSelection.map(g => `#${g}`).join(', ')}. `;
    }
    if (validationError && willAttend) {
      setShownValidationError(validationError);
    } else {
      if (isDemoWedding(weddingId)) {
        setShowDemoWeddingHintDialog(true);
      } else if (!weddingConfig.creatorIsRegistered) {
        setShowCreatorNotRegisteredDialog(true);
      } else {
        setLoadingSubmit(true);
        await axios.post(`wedding-rsvp-create/`, {
          willAttend: willAttend,
          guests: guests,
          remark: remark,
          weddingId: weddingId,
        });
        setSubmissionDate(new Date().toLocaleDateString());
        setRsvpSubmitted(true);
        setLoadingSubmit(false);
        ReactGA.event({
          category: `EHO_Event`,
          action: `Wedding-${weddingId}-RSVP-Submitted`,
        });
      }
    }
  };

  const checkForSubmitted = async () => {
    setLoadingIfRsvpSubmitted(true);
    const role = getRoleOfUserInWedding(fidiraUser?.weddings, weddingId);
    if (role) {
      const { data: result } = await axios.get(`/${weddingId}/wedding-rsvp-read/`);
      if (result) {
        setGuests(result.guests);
        setWillAttend(result.willAttend);
        setRemark(result.remark);
        setSubmissionDate(new Date(result.submissionDate).toLocaleDateString());
        setRsvpSubmitted(true);
      } else {
        setRsvpSubmitted(false);
      }
    }
    setLoadingIfRsvpSubmitted(false);
  };

  useEffect(() => {
    checkForSubmitted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="color-background-white">
      {loadingIfRsvpSubmitted ? (
        <LoadingState message={i18n.t(k.RSVP_LOADING)}></LoadingState>
      ) : (
        <>
          <p>
            <strong>{i18n.t(k.RSVP)}</strong>
          </p>
          {rsvpSubmitted && <AlreadySubmittedHint submissionDate={submissionDate}></AlreadySubmittedHint>}
          <AttendanceToggle
            willAttend={willAttend}
            changeWillAttend={setWillAttend}
            disabled={rsvpSubmitted}
          ></AttendanceToggle>
          {willAttend && (
            <>
              <Introtext>
                {foodOptionsActive
                  ? i18n.t(k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU)
                  : i18n.t(k.LIST_EVERYONE_WE_MAY_EXPECT)}
              </Introtext>
              {guests.map((guest, guestIndex) => (
                <GuestContainer key={guestIndex}>
                  <GuestImageContainer>
                    <GuestSvg></GuestSvg>
                    <h3>
                      {i18n.t(k.GUEST)} #{guestIndex + 1}
                    </h3>
                    <h3>{guest.displayName}</h3>
                  </GuestImageContainer>
                  <GuestFormContainer>
                    <GuestName
                      guestName={guest.displayName}
                      changeGuestName={e => {
                        setGuestName(e.target.value, guestIndex);
                      }}
                      disabled={rsvpSubmitted}
                    ></GuestName>
                    {foodOptionsActive && (
                      <GuestFoodSelection
                        foodOptions={foodOptions}
                        currentSelection={guest.selectedFood}
                        selectFood={e => {
                          setSelectedFood(e.target.value, guestIndex);
                        }}
                        disabled={rsvpSubmitted}
                      ></GuestFoodSelection>
                    )}
                  </GuestFormContainer>
                  {!rsvpSubmitted && guests.length > 1 && (
                    <RemoveGuestButton onClick={() => removeGuest(guestIndex)}>
                      <CloseSvg></CloseSvg>
                    </RemoveGuestButton>
                  )}
                </GuestContainer>
              ))}
              {!rsvpSubmitted && (
                <AddGuestButtonContainer>
                  <Button onClick={addGuest}>
                    <PlusSvg></PlusSvg>
                    {i18n.t(k.ADD_GUEST)}
                  </Button>
                </AddGuestButtonContainer>
              )}
            </>
          )}

          <Remark remark={remark} setRemark={setRemark} disabled={rsvpSubmitted}></Remark>
          {!rsvpSubmitted && (
            <SubmitButton onClick={submitRsvp} disabled={loadingSubmit}>
              {loadingSubmit ? <CircularProgress></CircularProgress> : i18n.t(k.SUBMIT_RSVP)}
            </SubmitButton>
          )}

          <AlertDialog
            open={!!shownValidationError}
            handleConfirm={() => setShownValidationError('')}
            messageDescriptionText={shownValidationError}
          ></AlertDialog>
          <DemoWeddingHintDialog
            show={showDemoWeddingHintDialog}
            setShow={setShowDemoWeddingHintDialog}
          ></DemoWeddingHintDialog>
          <CreatorNotRegisteredHintDialog
            setShow={setShowCreatorNotRegisteredDialog}
            show={showCreatorNotRegisteredDialog}
          ></CreatorNotRegisteredHintDialog>
        </>
      )}
    </Container>
  );
};

export default RsvpElement;
