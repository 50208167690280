import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #333333;
  color: #ffffff !important;
  padding: 12px;
  font-size: 13px !important;
`;

const AlreadySubmittedHint = ({ submissionDate }) => {
  return (
    <Container>
      {i18n.t(k.YOU_ALREADY_SUBMITTED_ON)} {submissionDate} {i18n.t(k.CANNOT_CHANGE_ANY_MOR)}
    </Container>
  );
};

export default AlreadySubmittedHint;
