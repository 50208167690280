import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as PhotographerSvg } from '../../../../../../_assets/svg/photographer.svg';

const Container = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 150px;
    margin: 12px auto;
  }
`;

const EmptyState = () => {
  return (
    <Container>
      <PhotographerSvg></PhotographerSvg>
      <strong>{i18n.t(k.NO_PHOTOS_UPLOADED_YET)}</strong>
      <span>{i18n.t(k.ADD_SOME_PHOTOS)}</span>
    </Container>
  );
};

export default EmptyState;
