import styled from 'styled-components';
import breakpoints from '../../breakpoints';
import boxShadows from '../../boxShadows';
import { Button } from '@material-ui/core';

export default styled(Button)`
     width: 80px;
     height: 80px;
     padding: 9px;
     margin: 15px
     background-color: #ffffffdd !important ;
     border: 6px;
     border-color: red;
     box-shadow: ${boxShadows.z3};
     border-radius: 6px;
     cursor: pointer;
     :hover {
       background-color: #eeeeeedd !important;
     }
     >span {
        display: flex;
        flex-direction: column;
        p {
            font-size: 10px !important;
        }
     }
     @media (max-width: ${breakpoints.md}) {
     min-width: 75px;
     width: 75px;
     height: 70px;
     margin: 6px
     }
   `;
