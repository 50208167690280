import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import defaultStyles from '../../DefaultStyles';

export default styled.div`
  position: absolute;
  bottom: 70px;
  font-weight: bold;
  font-size: 22px !important;
  font-family: ${defaultStyles.flower.bodyFont} !important;
  color: ${defaultStyles.flower.colors.secondary.base} !important;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    bottom: 45px;
    font-weight: bold;
    font-size: 14px !important;
  }
`;
