import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import ReactPixel from 'react-facebook-pixel';

import QuestionHeader from '../Components/QuestionHeader';
import BackButton from '../Components/BackButton';
import ButtonContainer from '../Components/ButtonContainer';
import NextButton from '../Components/NextButton';
import FieldContainer from '../Components/FieldContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const schema = object().shape({
  nameOfUser: string().required(i18n.t(k.PLEASE_ENTER_NAME)),
});

const NameOfUser = ({ submitToParent, userIsBride, onBack }) => {
  const { handleSubmit, register, errors } = useForm({ validationSchema: schema });

  const onSubmit = values => {
    ReactPixel.trackCustom('NameOfCreatorFunnel', { creatorIsBride: userIsBride, nameOfCreator: values.nameOfUser });
    submitToParent({
      value: values.nameOfUser,
      question: userIsBride ? 'nameOfBride' : 'nameOfGroom',
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <QuestionHeader>{i18n.t(k.WHATS_YOUR_NAME)}</QuestionHeader>
        <FieldContainer>
          <TextField
            name="nameOfUser"
            error={!!errors.nameOfUser}
            helperText={errors.nameOfUser ? errors.nameOfUser.message : ''}
            type="text"
            inputRef={register}
            variant="filled"
            autoComplete="off"
          />
        </FieldContainer>
        <ButtonContainer>
          <BackButton onClick={() => onBack()} variant="contained" color="secondary">
            {i18n.t(k.BACK)}
          </BackButton>
          <NextButton type="submit" variant="contained">
            {i18n.t(k.NEXT)}
          </NextButton>
        </ButtonContainer>
      </Container>
    </form>
  );
};

export default NameOfUser;
