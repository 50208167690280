import getRoleOfUserInWedding from '../../../FunctionUtils/getRoleOfUserInWedding';

export default (user, weddingId) => {
  if (user) {
    if (weddingId) {
      const roleOfUser = getRoleOfUserInWedding(user.weddings, weddingId);
      if (roleOfUser) {
        return 'WEDDING_PAGE_LOGIN_EXISTING_GUEST';
      } else {
        return 'WEDDING_PAGE_LOGIN_NEW_GUEST_FOR_WEDDING';
      }
    } else {
      return 'GENERAL_LOGIN_EXISTING_USER';
    }
  } else {
    if (weddingId) {
      return 'WEDDING_PAGE_LOGIN_NEW_USER';
    } else {
      return 'GENERAL_LOGIN_NEW_USER';
    }
  }
};
