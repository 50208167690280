import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';

const Container = styled.div`
  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin-top: 6px;
    .MuiButtonBase-root {
      margin-top: -9px;
    }
  }
`;

const SelectionContainer = styled.div`
  margin: 18px 0;
`;

const CookieConsentDialog = ({ open, saveCookieConsent }) => {
  const [thirdParty, setThirdParty] = useState(true);

  return (
    <>
      <Dialog open={open} className="cookie-modal">
        <DialogTitle>{i18n.t(k.COOKIES_01)}</DialogTitle>
        <DialogContent>
          <Container>
            <>{i18n.t(k.COOKIES_02)}</>
            <SelectionContainer>
              <strong>{i18n.t(k.COOKIES_03)}</strong>
              <FormControlLabel
                control={<Checkbox checked={true} onChange={() => {}} color="default" name="AcceptTermsCheckbox" />}
                label={
                  <>
                    {i18n.t(k.COOKIES_04)}{' '}
                    <a
                      href="https://www.eurehochzeitonline.com/f/datenschutz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.t(k.COOKIES_05)}
                    </a>
                  </>
                }
              />
            </SelectionContainer>
            <SelectionContainer>
              <strong>{i18n.t(k.COOKIES_06)}</strong>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={thirdParty}
                    onChange={() => {
                      setThirdParty(!thirdParty);
                    }}
                    color="default"
                    name="AcceptTermsCheckbox"
                  />
                }
                label={
                  <>
                    {i18n.t(k.COOKIES_07)}{' '}
                    <a
                      href="https://www.eurehochzeitonline.com/f/datenschutz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.t(k.COOKIES_05)}
                    </a>
                  </>
                }
              />
            </SelectionContainer>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              saveCookieConsent(thirdParty ? 'ALL' : 'ESSENTIAL');
            }}
          >
            {i18n.t(k.COOKIES_08)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              saveCookieConsent('ALL');
            }}
          >
            {i18n.t(k.COOKIES_09)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookieConsentDialog;
