import styled from 'styled-components';

export default styled.div`
  background-color: #000000bf;
  color: white !important;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  position: fixed;
  top: 0;
  padding: 12px;
  font-size: 14px !important;
`;
