import styled from 'styled-components';

export default styled.div`
  height: 30px;
  width: 100%;
  background-image: ${({ icon }) => `url(/assets/flags/${icon}.png)`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
