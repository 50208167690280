import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  transform: ${({ visible }) => (visible ? 'rotate(137deg)' : 'rotate(137deg) translate3d(800px, 500px, 0)')};
  bottom: -260px;
  left: -500px;
  @media (max-width: ${breakpoints.md}) {
    bottom: -450px;
    left: -420px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    transform: ${({ visible }) => (visible ? 'rotate(37deg)' : 'rotate(37deg) translate3d(800px, 1200px, 0)')};
    bottom: -350px;
    left: -220px;
  }
`;
