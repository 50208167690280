import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../_assets/svg/logos/eurehochzeitonline.svg';

const Container = styled.div`
  height: 100px;
`;

const LogoSvg = () => {
  return (
    <Container>
      <Logo></Logo>
    </Container>
  );
};

export default LogoSvg;
