import React, { useState } from 'react';
import styled from 'styled-components';
import { LinearProgress, Button } from '@material-ui/core';
import axios from 'axios';

import getSearchParamsAsObject from '../../Shared/FunctionUtils/getSearchParamsAsObject';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import LogoLarge from '../../Shared/Components/EureHochzeitOnlineLogo/LogoLarge';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import OuterContainer from '../../Shared/Components/EureHochzeitOnline/OuterContainer';
import InnerContainer from '../../Shared/Components/EureHochzeitOnline/InnerContainer';

const StyledLinearProgress = styled(LinearProgress)`
  width: 300px;
  max-width: 70vw;
`;

const StyledResult = styled.code`
  width: 300px;
  max-width: 70vw;
  word-break: break-all;
`;

const ChangePasswordPage = () => {
  const [tokens, setTokens] = useState(null);
  const [loading, setLoading] = useState(null);

  const createTokens = async () => {
    setLoading(true);
    const spotifyData = getSearchParamsAsObject(window.location.search);
    spotifyData.redirectBaseUri = window.location.origin;
    const { data: tokenData } = await axios.post('/spotify-auth-create/', spotifyData);
    setLoading(false);
    setTokens(tokenData);
  };

  const reload = async () => {
    window.location.href = window.location.origin + '/f/spotify-login/';
  };

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <InnerContainer>
          <LogoLarge></LogoLarge>
          {loading && <StyledLinearProgress />}
          {tokens && <StyledResult>{JSON.stringify(tokens)}</StyledResult>}
          <br></br>
          {!tokens && <Button onClick={createTokens}>Load</Button>}
          <br></br>
          <Button onClick={reload}>Reload</Button>
        </InnerContainer>
      </OuterContainer>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default ChangePasswordPage;
