import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  width: 60px;
  background-image: url(${defaultStyles.vintage.assets.border});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: absolute;
  top: 0;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  height: 50vh;
  @media (min-width: ${breakpoints.md}) {
    height: 90vh;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 80px;
    width: 30px;
  }
`;
