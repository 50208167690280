import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import OuterStyledMenu from './OuterStyledMenu';

import breakpoints from '../../../Shared/breakpoints';

const StyledDrawer = styled(Drawer)`
  > .MuiDrawer-paper {
    border: none;
    width: 350px;
    overflow: hidden;
    max-width: 100vw;
    background-color: transparent;
    @media (min-width: ${breakpoints.md}) {
      box-shadow: ${({ shadow }) => (shadow === 'true' ? '' : 'none')};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  z-index: 10;
  width: 48px;
  margin-left: auto;
  margin-right: 12px;
`;
const ButtonContainer = styled.div`
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

const StyledButton = styled(Button)`
  z-index: 10;
  border-radius: 0;
  border-bottom-right-radius: 12px;
  padding: 6px 12px;
  @media (orientation: landscape) and (max-width: ${breakpoints.sm}) {
    padding-left: env(safe-area-inset-left);
  }
`;
const ButtonText = styled.span`
  margin: 6px;
`;

const Menu = ({ container, navigationWithShadow }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  return (
    <>
      <Hidden lgUp>
        <StyledDrawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          SlideProps={{
            direction: 'right',
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledIconButton onClick={closeDrawer}>
            <CloseIcon />
          </StyledIconButton>
          <OuterStyledMenu closeDrawer={closeDrawer} />
        </StyledDrawer>
        <ButtonContainer>
          <StyledButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
            <MenuIcon /> <ButtonText>{i18n.t(k.NAVIGATION)}</ButtonText>
          </StyledButton>
        </ButtonContainer>
      </Hidden>
      <Hidden mdDown>
        <StyledDrawer PaperProps={{ elevation: 12 }} variant="permanent" open shadow={navigationWithShadow.toString()}>
          <OuterStyledMenu closeDrawer={closeDrawer} />
        </StyledDrawer>
      </Hidden>
    </>
  );
};

export default Menu;
