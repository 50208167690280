import i18n from 'i18next';
import k from './../../../../../../../i18n/keys';
import React, { useState } from 'react';

import Dialog from '../../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogTitle from '../../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogContent from '../../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogActions from '../../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import defaultImageList from '../../../../Constants/defaultImageList';

import Thumbnail from './Components/Thumbnail';
import ThumbnailContainer from './Components/ThumbnailContainer';
import CategoryContainer from './Components/CategoryContainer';

const ImageSelectorAndUploader = ({ open, onCompleteSelection, closeImageSelection, initialSelection }) => {
  const [currentSelection, setCurrentSelection] = useState(initialSelection);

  const selectImage = imageUrl => {
    setCurrentSelection(imageUrl);
  };
  const onComplete = () => {
    onCompleteSelection(currentSelection);
  };

  return (
    <Dialog open={open} isForm={false}>
      <DialogTitle>{i18n.t(k.SELECT_IMAGE)}</DialogTitle>
      <DialogContent>
        {defaultImageList.map(category => {
          const categoryTitle = i18n.t(k[`DEFAULT_IMAGES_TYPE_${category.type.toUpperCase()}`]);
          return (
            <CategoryContainer key={categoryTitle}>
              <strong>{categoryTitle}</strong>
              <ThumbnailContainer>
                {category.images.map(imageUrl => (
                  <Thumbnail
                    key={imageUrl}
                    imageUrl={imageUrl}
                    onSelect={selectImage}
                    selected={currentSelection === imageUrl}
                  ></Thumbnail>
                ))}
              </ThumbnailContainer>
            </CategoryContainer>
          );
        })}
      </DialogContent>
      <DialogActions stopEditing={closeImageSelection} onComplete={onComplete}></DialogActions>
    </Dialog>
  );
};

export default ImageSelectorAndUploader;
