import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import boxShadows from '../../../../../../Shared/boxShadows';

export default styled(IconButton)`
  box-shadow: ${boxShadows.z2};
  position: absolute;
  right: -6px;
  top: -9px;
  svg {
    fill: white;
    width: 15px;
    height: 15px;
  }
`;
