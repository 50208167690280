import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useRef, useContext, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { ReactComponent as DeleteSvg } from '../../../_assets/svg/delete-gallery.svg';
import { ReactComponent as DownloadSvg } from '../../../_assets/svg/download.svg';
import { ReactComponent as CloseSvg } from '../../../_assets/svg/close.svg';
import { ReactComponent as NextSvg } from '../../../_assets/svg/next.svg';
import { ReactComponent as PreviousSvg } from '../../../_assets/svg/previous.svg';
import { ReactComponent as HeartFilledSvg } from '../../../_assets/svg/heart-filled.svg';
import { ReactComponent as HeartOutlineSvg } from '../../../_assets/svg/heart-outline.svg';
import { ReactComponent as PresentationOutlineSvg } from '../../../_assets/svg/presentation-outline.svg';
import { ReactComponent as PresentationFilledSvg } from '../../../_assets/svg/presentation-filled.svg';

import UserContext from '../../Context/UserContext';
import WeddingContext from '../../Context/WeddingContext';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import hasUserWritePermissionForWedding from '../../FunctionUtils/hasUserWritePermissionForWedding';

import LikeContainer from '../../../Pages/WeddingPage/AppPages/Photo/Components/CommonComponents/LikeContainer';
import NumberOfLikes from '../../../Pages/WeddingPage/AppPages/Photo/Components/CommonComponents/NumberOfLikes';

import UploaderNameContainer from './Components/UploaderNameContainer';
import StyledDialog from './Components/StyledDialog';
import Image from './Components/Image';
import DeleteButton from './Components/DeleteButton';
import StyledIconButton from './Components/StyledIconButton';
import CloseButton from './Components/CloseButton';
import ControlContainer from './Components/ControlContainer';
import LikeButton from './Components/LikeButton';
import LikeOuterContainer from './Components/LikeOuterContainer';
import Thumbnail from './Components/Thumbnail';
import PresentationButton from './Components/PresentationButton';

const FullscreenGallery = ({
  useCase,
  photos,
  onFullscreen,
  addLike,
  removeLike,
  handleDelete,
  fullscreenIndex,
  loadingDelete,
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [diaShowRunning, setDiaShowRunning] = useState(false);
  const [photosLength, setPhotosLength] = useState(photos.length);
  const weddingContext = useContext(WeddingContext);
  const weddingId = weddingContext ? weddingContext.weddingId : null;
  const { fidiraUser } = useContext(UserContext);
  const requestDelete = () => {
    setShowConfirmDelete(true);
  };

  const toggleControls = () => {
    setShowControls(!showControls);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!diaShowRunning) return;
      const random = Math.floor(Math.random() * photos.length + 1);
      imageGallery.current.slideToIndex(random);
    }, 3000);

    return () => clearInterval(interval);
  }, [diaShowRunning, photos.length]);

  useEffect(() => {
    if (diaShowRunning && photos.length > photosLength) {
      imageGallery.current.slideToIndex(0);
      setPhotosLength(photos.length);
    }
  }, [photos, photosLength, diaShowRunning]);

  const imageGallery = useRef(null);
  return (
    <StyledDialog open onClose={() => onFullscreen(null)} visible={showControls.toString()}>
      <PresentationButton onClick={() => setDiaShowRunning(!diaShowRunning)} visible={showControls.toString()}>
        {diaShowRunning ? (
          <PresentationFilledSvg></PresentationFilledSvg>
        ) : (
          <PresentationOutlineSvg></PresentationOutlineSvg>
        )}
      </PresentationButton>
      <CloseButton onClick={() => onFullscreen(null)} visible={showControls.toString()}>
        <CloseSvg></CloseSvg>
      </CloseButton>
      <ImageGallery
        ref={imageGallery}
        autoPlay={diaShowRunning}
        items={photos}
        startIndex={fullscreenIndex}
        renderLeftNav={() => null}
        renderRightNav={() => null}
        renderItem={({ original }) => <Image onClick={toggleControls} imageUrl={original}></Image>}
        renderThumbInner={({ thumbnail }) => <Thumbnail imageUrl={thumbnail}></Thumbnail>}
        showFullscreenButton={false}
        showPlayButton={false}
        onSlide={index => onFullscreen(index)}
        slideInterval={3000}
        slideDuration={0}
        renderCustomControls={() => {
          return (
            <ControlContainer visible={showControls}>
              {useCase === 'wedding' && (
                <UploaderNameContainer>{photos[fullscreenIndex].creatorDisplayName}</UploaderNameContainer>
              )}
              {useCase === 'wedding' && (
                <DeleteButton
                  onClick={() => requestDelete()}
                  disabled={
                    !photos[fullscreenIndex].createdByUser && !hasUserWritePermissionForWedding(fidiraUser, weddingId)
                  }
                  hidden={
                    !photos[fullscreenIndex].createdByUser && !hasUserWritePermissionForWedding(fidiraUser, weddingId)
                  }
                >
                  <DeleteSvg></DeleteSvg>
                </DeleteButton>
              )}
              <StyledIconButton
                onClick={() => {
                  imageGallery.current.slideLeft();
                }}
              >
                <PreviousSvg></PreviousSvg>
              </StyledIconButton>
              {useCase === 'wedding' && (
                <LikeOuterContainer>
                  <LikeContainer
                    onClick={e => {
                      if (photos[fullscreenIndex].likedByUser) {
                        removeLike(photos[fullscreenIndex].id);
                      } else {
                        addLike(photos[fullscreenIndex].id);
                      }
                      e.stopPropagation();
                    }}
                  >
                    {photos[fullscreenIndex].likedByUser && (
                      <LikeButton>
                        <HeartFilledSvg></HeartFilledSvg>
                      </LikeButton>
                    )}
                    {!photos[fullscreenIndex].likedByUser && (
                      <LikeButton>
                        <HeartOutlineSvg></HeartOutlineSvg>
                      </LikeButton>
                    )}
                    <NumberOfLikes>{photos[fullscreenIndex].numberOfLikes || ''}</NumberOfLikes>
                  </LikeContainer>
                </LikeOuterContainer>
              )}
              <StyledIconButton
                onClick={() => {
                  imageGallery.current.slideRight();
                }}
              >
                <NextSvg></NextSvg>
              </StyledIconButton>
              {useCase === 'wedding' && (
                <StyledIconButton href={photos[fullscreenIndex].original}>
                  <DownloadSvg></DownloadSvg>
                </StyledIconButton>
              )}
            </ControlContainer>
          );
        }}
      />
      <ConfirmDialog
        open={showConfirmDelete || loadingDelete}
        loadingConfirm={loadingDelete}
        handleCancel={() => setShowConfirmDelete(false)}
        handleConfirm={() => {
          setShowConfirmDelete(false);
          handleDelete(photos[fullscreenIndex].id, photos[fullscreenIndex].original);
        }}
        messageTitle={i18n.t(k.DELETE_IMAGE)}
        messageDescriptionText={i18n.t(k.DELETE_IMAGE_ARE_YOU_SURE)}
      ></ConfirmDialog>
    </StyledDialog>
  );
};

export default FullscreenGallery;
