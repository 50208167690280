export default {
  de: {
    icon: 'germany',
    label: 'Deutsch',
  },
  en: {
    icon: 'united-kingdom',
    label: 'English',
  },
  cn: {
    icon: 'china',
    label: '中国人',
  },
  cs: {
    icon: 'czech-republic',
    label: 'čeština',
  },
  fr: {
    icon: 'france',
    label: 'Français',
  },
  el: {
    icon: 'greece',
    label: 'Ελληνικά',
  },
  hu: {
    icon: 'hungary',
    label: 'Magyar',
  },
  it: {
    icon: 'italy',
    label: 'Italiano',
  },
  ja: {
    icon: 'japan',
    label: '日本語',
  },
  pl: {
    icon: 'poland',
    label: 'Polski',
  },
  pt: {
    icon: 'portugal',
    label: 'Português',
  },
  es: {
    icon: 'spain',
    label: 'Español',
  },
  tr: {
    icon: 'turkey',
    label: 'Türkçe',
  },
  ua: {
    icon: 'ukraine',
    label: 'українська',
  },
};
