import React, { useState } from 'react';
import styled from 'styled-components';
import LogoSvg from '../../../Shared/Components/EureHochzeitOnlineLogo/LogoSvg';
import Navigation from '../Navigation/Navigation';
import boxShadows from '../../../Shared/boxShadows';
import breakpoints from '../../../Shared/breakpoints';
import LanguageSelect from './LanguageSelect';

const OuterContainer = styled.div`
  position: fixed;
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #ffffffdd;
  z-index: 1000;
  transition-property: background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  box-shadow: ${boxShadows.z2};
  &.scrolled {
    background-color: #ba5a5e;
    svg {
      fill: white !important;
    }
    a {
      color: white !important;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    height: 90px;
  }
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 1500px;
  max-width: 95vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  > div {
    @media (max-width: ${breakpoints.md}) {
      height: 60px;
    }
    @media (max-width: ${breakpoints.sm}) {
      height: 50px;
    }
  }
  svg {
    transition-property: fill;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    padding: 12px 0;
    height: 70px;
    max-width: 100%;
    fill: #9a3241 !important;
    @media (max-width: ${breakpoints.md}) {
      height: 40px;
    }
    @media (max-width: ${breakpoints.sm}) {
      padding: 6px 0;
    }
  }
`;

const TopBar = () => {
  const [scrolled, setScrolled] = useState(false);

  const changeScrollPosition = () => {
    if (window.scrollY >= 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener('scroll', changeScrollPosition);

  return (
    <OuterContainer className={scrolled ? 'scrolled' : ''}>
      <Container>
        <LogoContainer>
          <LogoSvg></LogoSvg>
        </LogoContainer>
        <Navigation scrolled={scrolled}></Navigation>
      </Container>
      <LanguageSelect></LanguageSelect>
    </OuterContainer>
  );
};

export default TopBar;
