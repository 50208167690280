import styled from 'styled-components';
import breakpoints from '../../breakpoints';

export default styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: relative;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    overflow: hidden;
  }
`;
