import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import IconBox from '../.../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../.../../../../Shared/Components/SelectionComponent/SelectionContainer';

import QuestionHeader from '../Components/QuestionHeader';
import OuterContainer from '../Components/OuterContainer';
import ButtonContainer from '../Components/ButtonContainer';
import BackButton from '../Components/BackButton';
import NextButton from '../Components/NextButton';

const UserIsBride = ({ onSubmit, onBack, weddingStyle }) => {
  const [userIsBride, setUserIsBride] = useState(true);

  const onSelect = selection => {
    setUserIsBride(selection.value);
  };

  const submit = () => {
    ReactPixel.trackCustom('BrideGroomFunnel', { creatorIsBride: userIsBride });
    onSubmit({
      value: userIsBride,
      question: 'userIsBride',
    });
  };

  return (
    <OuterContainer>
      <QuestionHeader>{i18n.t(k.QUESTION_BRIDE_OR_GROOM)}</QuestionHeader>
      <SelectionContainer>
        <IconBox
          image="/assets/bride-groom/bride3.jpg"
          text={i18n.t(k.BRIDE)}
          active={userIsBride}
          question="userIsBride"
          selectValue
          onSelect={onSelect}
          weddingStyle={weddingStyle}
        />

        <IconBox
          image="/assets/bride-groom/groom3.jpg"
          text={i18n.t(k.GROOM)}
          active={!userIsBride}
          question="userIsBride"
          selectValue={false}
          onSelect={onSelect}
          weddingStyle={weddingStyle}
        />
      </SelectionContainer>
      <ButtonContainer>
        <BackButton onClick={() => onBack()} variant="contained" color="secondary">
          {i18n.t(k.BACK)}
        </BackButton>
        <NextButton onClick={() => submit()} variant="contained">
          {i18n.t(k.NEXT)}
        </NextButton>
      </ButtonContainer>
    </OuterContainer>
  );
};

export default UserIsBride;
