import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckMark } from '../../../../../../../../_assets/svg/check-mark.svg';

const Container = styled.div`
  width: calc(33% - 10px);
  height: 100px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  margin: 1px;
  border-width: 4px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? '#333333 !important' : 'white')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  position: relative;
`;

const CheckMarkContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35%;
  max-width: 30px;
  background-color: #333333;
  padding: 6px 3px 0px 6px;
  border-top-left-radius: 19px;
  svg {
    fill: white !important;
  }
`;

const Thumbnail = ({ imageUrl, onSelect, selected }) => {
  return (
    <Container selected={selected} imageUrl={`${imageUrl}`} onClick={() => onSelect(imageUrl)}>
      {selected && (
        <CheckMarkContainer>
          <CheckMark></CheckMark>
        </CheckMarkContainer>
      )}
    </Container>
  );
};

const MemoizedThumbnail = React.memo(Thumbnail);

export default MemoizedThumbnail;
