import styled from 'styled-components';

export default styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  > .MuiTextField-root {
    margin: 12px 0 24px 0;
  }
`;
