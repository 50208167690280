import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../breakpoints';

import EditingContainerForBaseData from '../../../Pages/WeddingPage/Editing/Components/EditingContainer/EditingContainerForBaseData';
import BackgroundElementContainer from '../../Components/BackgroundElementContainer/BackgroundElementContainer';

import WeddingTitle from './Components/WeddingTitle';
import WeddingDate from './Components/WeddingDate';
import TopCenter from './Components/TopCenter';
import Overlay from './Components/Overlay';
import Container from './Components/Container';
import Countdown from '../Components/Countdown';
import FloralFrame from './Components/FloralFrame';
import StartPhoto from './Components/StartPhoto';

const StyledTopCenter = styled(TopCenter)`
  @media (max-width: ${breakpoints.xs}) {
    margin-left: 0;
  }
`;

const CustomLogo = styled.div`
  height: 200px;
  max-height: 50vh;
  width: 550px;
  max-width: 70vw;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const WeddingTitleInnerContainer = styled.div`
  position: relative;
  width: 600px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const BohoAppBackground = ({ showStartPage, weddingConfig }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container visible>
      <Overlay />
      <BackgroundElementContainer>
        {showStartPage && weddingConfig && (
          <StyledTopCenter visible>
            <Countdown weddingConfig={weddingConfig} weddingStyle={weddingConfig.weddingStyle}></Countdown>
            <WeddingTitleInnerContainer>
              {weddingConfig.useStartPhoto ? (
                <StartPhoto imageUrl={weddingConfig.startPhotoUrl}>
                  <FloralFrame></FloralFrame>
                </StartPhoto>
              ) : (
                <FloralFrame></FloralFrame>
              )}
              {weddingConfig.useCustomLogo ? (
                <>
                  <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
                  <EditingContainerForBaseData></EditingContainerForBaseData>
                </>
              ) : (
                <>
                  {nameOfBride && nameOfGroom && (
                    <>
                      <WeddingTitle>
                        {nameOfBride} &amp; {nameOfGroom}
                      </WeddingTitle>
                      <EditingContainerForBaseData></EditingContainerForBaseData>
                    </>
                  )}
                  <WeddingDate>{weddingDate}</WeddingDate>
                </>
              )}
            </WeddingTitleInnerContainer>
          </StyledTopCenter>
        )}
      </BackgroundElementContainer>
    </Container>
  );
};

export default BohoAppBackground;
