import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 50vh;
  width: 50vw;
  bottom: -5vh;
  right: -5vw;
  background-image: url(${defaultStyles.blossom.assets.element});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${({ reducedOpacity }) => (reducedOpacity ? '0.7' : '1')};
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(500px, 800px, 0)')};
  @media (max-width: ${breakpoints.xs}) {
    bottom: -10vh;
    right: 0;
    height: 80vh;
    width: 80vw;
  }
`;
