import styled from 'styled-components';
import boxShadows from '../../boxShadows';

export default styled.div`
  .dzu-dropzone {
    overflow: hidden;
    background-color: #ffffffe0;
    border-radius: 0;
    border-top-left-radius: 12px;
    box-shadow: ${boxShadows.z2};
    border: none;
    label {
      font-family: inherit;
      color: inherit;
    }
    .dzu-inputLabelWithFiles {
      display: none;
    }
  }
`;
