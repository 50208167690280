import styled from 'styled-components';

export default styled.hr`
  position: absolute;
  width: 100%;
  left: 0;
  top: 20px;
  border-style: none;
  border-top-style: dashed;
  border-width: 3px;
  border-color: inherit;
`;
