import React from 'react';

import elementTypes from '../../Constants/elementTypes';
import EditingDialogPageTitle from '../EditingDialogs/EditingDialogPageTitle/EditingDialogPageTitle';
import EditingDialogScheduleItem from '../EditingDialogs/EditingDialogScheduleItem/EditingDialogScheduleItem';
import EditingDialogLocationItem from '../EditingDialogs/EditingDialogLocationItem/EditingDialogLocationItem';
import EditingDialogBaseData from '../EditingDialogs/EditingDialogBaseData/EditingDialogBaseData';
import EditingDialogInfoItem from '../EditingDialogs/EditingDialogInfoItem/EditingDialogInfoItem';
import EditingDialogRsvp from '../EditingDialogs/EditingDialogRsvp/EditingDialogRsvp';
import EditingDialogCountdown from '../EditingDialogs/EditingDialogCountdown/EditingDialogCountdown';
import EditingDialogLargePhotoItem from '../EditingDialogs/EditingDialogLargePhotoItem/EditingDialogLargePhotoItem';
import EditingDialogPresent from '../EditingDialogs/EditingDialogPresent/EditingDialogPresent';
import EditingDialogLanguages from '../EditingDialogs/EditingDialogLanguages/EditingDialogLanguages';

const EditingDialogContainer = ({ editingKeys, stopEditing, saveChanges, content }) => {
  const { elementType, contentPage } = editingKeys;
  return (
    <>
      {elementType === elementTypes.pageTitle.name && (
        <EditingDialogPageTitle
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          title={content ? content.title : ''}
          subtitle={content ? content.subtitle : ''}
        ></EditingDialogPageTitle>
      )}
      {elementType === elementTypes.scheduleItem.name && (
        <EditingDialogScheduleItem
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          title={content ? content.title : ''}
          time={content ? content.time : ''}
          description={content ? content.description : ''}
          imageUrl={content ? content.imageUrl : ''}
          imagePosition={content ? content.imagePosition : ''}
        ></EditingDialogScheduleItem>
      )}
      {elementType === elementTypes.locationItem.name && (
        <EditingDialogLocationItem
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          name={content ? content.name : ''}
          street={content ? content.street : ''}
          houseNumber={content ? content.houseNumber : ''}
          zip={content ? content.zip : ''}
          city={content ? content.city : ''}
          country={content ? content.country : ''}
          iconType={content ? content.iconType : ''}
          latitude={content ? content.latitude : ''}
          longitude={content ? content.longitude : ''}
          mapsUrl={content ? content.mapsUrl : ''}
          websiteUrl={content ? content.websiteUrl : ''}
          remarks={content ? content.remarks : ''}
          contentPage={contentPage}
        ></EditingDialogLocationItem>
      )}
      {elementType === elementTypes.baseData.name && (
        <EditingDialogBaseData
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          nameOfBride={content ? content.nameOfBride : ''}
          nameOfGroom={content ? content.nameOfGroom : ''}
          weddingDate={content ? content.weddingDate : ''}
          weddingDateIsExact={content ? content.weddingDateIsExact : false}
          useCustomLogo={content ? content.useCustomLogo || false : false}
          customLogoUrl={content ? content.customLogoUrl : ''}
          weddingStyle={content ? content.weddingStyle : ''}
          useStartPhoto={content ? content.useStartPhoto || false : false}
          startPhotoUrl={content ? content.startPhotoUrl : ''}
        ></EditingDialogBaseData>
      )}
      {elementType === elementTypes.countdown.name && (
        <EditingDialogCountdown
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          showCountdown={content ? content.showCountdown : ''}
        ></EditingDialogCountdown>
      )}
      {elementType === elementTypes.languages.name && (
        <EditingDialogLanguages
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          selectedLanguagesForWedding={content ? content.selectedLanguagesForWedding : []}
        ></EditingDialogLanguages>
      )}
      {elementType === elementTypes.infoItem.name && (
        <EditingDialogInfoItem
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          title={content ? content.title : ''}
          description={content ? content.description : ''}
          imageUrl={content ? content.imageUrl : ''}
          imagePosition={content ? content.imagePosition : ''}
        ></EditingDialogInfoItem>
      )}
      {elementType === elementTypes.rsvpElement.name && (
        <EditingDialogRsvp
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          foodOptions={content ? content.foodOptions : []}
          foodOptionsActive={content ? content.foodOptionsActive : []}
        ></EditingDialogRsvp>
      )}
      {elementType === elementTypes.largePhotoItem.name && (
        <EditingDialogLargePhotoItem
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          title={content ? content.title : ''}
          description={content ? content.description : ''}
          imageUrl={content ? content.imageUrl : ''}
        ></EditingDialogLargePhotoItem>
      )}
      {elementType === elementTypes.presentElement.name && (
        <EditingDialogPresent
          stopEditing={stopEditing}
          saveChanges={saveChanges}
          presents={content ? content.presents : []}
        ></EditingDialogPresent>
      )}
    </>
  );
};

export default EditingDialogContainer;
