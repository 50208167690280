import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export default styled(Card)`
  position: relative;
  width: 100%;
  margin: 20px auto 20px auto;
  background-color: transparent;
  box-shadow: none;
  overflow: visible;
`;
