import i18n from 'i18next';
import k from './../../../../i18n/keys';

import React from 'react';
import styled from 'styled-components';

import boxShadows from '../../../../Shared/boxShadows';

import ContentCardWithScheduleItem from '../../Components/ContentCardWithScheduleItem';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import ContentCardWithInfoItem from '../../Components/ContentCardWithInfoItem';
import LocationBox from '../../Components/ContentCardWithMap/Components/LocationBox';
import ContentCardWithLargePhoto from '../../Components/ContentCardWithLargePhoto';

const LocationBoxContainer = styled.div`
  position: relative;
  background-color: white;
  box-shadow: ${() => `${boxShadows.z2}`};
  cursor: pointer;
  text-align: center;
  padding: 9px 0;
  > div {
    box-shadow: none;
    border: none !important;
    margin: auto;
  }
`;

export default {
  pageTitle: {
    name: 'pageTitle',
    label: i18n.t(k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL),
    component: () => (
      <ContentCardWithTitle
        hideEditingContainer
        title={i18n.t(k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE)}
        subtitle={i18n.t(k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE)}
      ></ContentCardWithTitle>
    ),
    allowedOnPages: ['PHOTO', 'SCHEDULE', 'LOCATION', 'MUSIC', 'INFO', 'RSVP', 'HOTEL', 'COUPLE', 'PRESENT'],
    i18nProperties: ['title', 'subtitle'],
  },
  scheduleItem: {
    name: 'scheduleItem',
    label: i18n.t(k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL),
    component: () => (
      <ContentCardWithScheduleItem
        hideEditingContainer
        title={i18n.t(k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE)}
        time={i18n.t(k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME)}
        description={i18n.t(k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION)}
        imageUrl="/assets/content/photo/4.jpg"
        imagePosition="right"
      ></ContentCardWithScheduleItem>
    ),
    allowedOnPages: ['SCHEDULE'],
    i18nProperties: ['title', 'time', 'description'],
  },
  infoItem: {
    name: 'infoItem',
    label: i18n.t(k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL),
    component: () => (
      <ContentCardWithInfoItem
        hideEditingContainer
        title={i18n.t(k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE)}
        description={i18n.t(k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION)}
        imageUrl="/assets/content/cake/6.jpg"
        imagePosition="left"
      ></ContentCardWithInfoItem>
    ),
    allowedOnPages: ['INFO', 'MUSIC', 'RSVP', 'PHOTO', 'COUPLE', 'PRESENT'],
    i18nProperties: ['title', 'description'],
  },
  largePhotoItem: {
    name: 'largePhotoItem',
    label: i18n.t(k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL),
    component: () => (
      <ContentCardWithLargePhoto
        hideEditingContainer
        title={i18n.t(k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE)}
        description={i18n.t(k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION)}
        imageUrl="/assets/content/ceremony/1.jpg"
      ></ContentCardWithLargePhoto>
    ),
    allowedOnPages: ['INFO', 'COUPLE'],
    i18nProperties: ['title', 'description'],
  },
  locationMap: {
    name: 'locationMap',
    label: i18n.t(k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL),
    component: () => <div></div>,
    allowedOnPages: [],
  },
  locationItem: {
    name: 'locationItem',
    label: i18n.t(k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL),
    component: () => (
      <LocationBoxContainer>
        <LocationBox
          location={{
            city: 'München',
            country: 'Deutschland',
            houseNumber: '9',
            iconType: 'city-hall',
            latitude: 48.150969,
            longitude: 11.534556,
            mapsUrl: 'https://maps.google.com/?cid=6475061181695922317',
            name: 'Restaurant & Weinhandel Broeding',
            street: 'Schulstraße',
            websiteUrl: '',
            zip: '80634',
          }}
          index={0}
          highlighted={false}
          key="Restaurant &amp; Weinhandel Broeding"
          highlightLocation={() => {}}
          weddingStyle="flower"
          hideEditingContainer
        ></LocationBox>
      </LocationBoxContainer>
    ),
    allowedOnPages: [],
    i18nProperties: ['remarks'],
  },
  photoGallery: {
    name: 'photoGallery',
    component: () => <div></div>,
    allowedOnPages: [],
  },
  playlistEditor: {
    name: 'playlistEditor',
    component: () => <div></div>,
    allowedOnPages: [],
  },
  baseData: {
    name: 'baseData',
    component: () => <div></div>,
    allowedOnPages: [],
  },
  rsvpElement: {
    name: 'rsvpElement',
    component: () => <div></div>,
    allowedOnPages: [],
  },
  presentElement: {
    name: 'presentElement',
    component: () => <div></div>,
    allowedOnPages: [],
    i18nProperties: ['details', 'name'],
  },
  countdown: {
    name: 'countdown',
    component: () => <div></div>,
    allowedOnPages: [],
  },
  languages: {
    name: 'languages',
    component: () => <div></div>,
    allowedOnPages: [],
  },
};
