import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import SectionContainer from '../Components/SectionContainer';
import SectionTitle from '../Components/SectionTitle';
import SectionSubTitle from '../Components/SectionSubTitle';
import PackageSelection from '../../WeddingPage/Components/PackageSelection/PackageSelection';
import boxShadows from '../../../Shared/boxShadows';
import { Button, Hidden } from '@material-ui/core';
import SectionTextContainer from '../Components/SectionTextContainer';
import breakpoints from '../../../Shared/breakpoints';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 900px;
  background-color: #fde1e5;
  padding-bottom: 50px;
  box-shadow: ${boxShadows.z2};
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  width: 100%;
`;

const SideImage = styled.div`
  position: relative;
  background-image: url('/assets/landing-page/hochzeits-website-fragen-preise.jpg');
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25%;
  margin: 25px 0 25px auto;
  box-shadow: ${boxShadows.z1};
  border-radius: 12px 0 0 12px;
`;

const ImageText = styled.div`
  background-color: #ba5a5e66;
  width: 70%;
  margin-left: auto;
  border-radius: 12px 0 0 12px;
  box-shadow: ${boxShadows.z2};
  h3 {
    padding: 6px 12px;
    font-weight: bold;
    margin-bottom: -6px;
  }
  p {
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    margin-top: -6px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  > div:first-child {
    width: 70%;
    background: #ffffffbf;
    margin: 24px 0;
    padding: 12px;
    border-radius: 0 12px 12px 0;
    box-shadow: ${boxShadows.z1};
    @media (max-width: ${breakpoints.md}) {
      width: 100%;
    }
    .MuiTableCell-body.deselected {
      cursor: default;
    }
  }
`;

const PricingSection = ({ search }) => {
  return (
    <Container>
      <SectionContainer alignment="left">
        <InnerContainer>
          <SectionTitle>{i18n.t(k.OUR_PRICING)}</SectionTitle>
          <SectionSubTitle>{i18n.t(k.UNLOCK_ALL_FEATURES)} </SectionSubTitle>
          <FlexContainer>
            <PackageSelection enableSelection={false}></PackageSelection>
            <Hidden mdDown>
              <SideImage>
                <ImageText>
                  <h3>{i18n.t(k.ANY_QUESTIONS_OR_FEEDBACK)}</h3>
                  <p>{i18n.t(k.WE_ARE_HERE_TO_HELP)}</p>
                </ImageText>
              </SideImage>
            </Hidden>
          </FlexContainer>
          <SectionTextContainer>
            <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
              <Button color="primary" variant="contained">
                {i18n.t(k.CREATE_WEDDING_WEBSITE_NOW)}
              </Button>
            </NavLink>
          </SectionTextContainer>
        </InnerContainer>
      </SectionContainer>
    </Container>
  );
};

export default PricingSection;
