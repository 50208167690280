import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState } from 'react';

import isMobile from '../../../../../../Shared/FunctionUtils/isMobile';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import ImageUploader from '../Components/ImageUploader/ImageUploader';

const EditingDialogLargePhotoItem = ({ title, description, imageUrl, stopEditing, saveChanges }) => {
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentDescription, setCurrentDescription] = useState(description);
  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      title: currentTitle,
      description: currentDescription,
      imageUrl: currentImageUrl,
    });
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{i18n.t(k.EDIT_BOX_WITH_LARGE_PHOTO)}</DialogTitle>
      <DialogContent>
        <ImageUploader imageUrl={currentImageUrl} setImageUrl={setCurrentImageUrl}></ImageUploader>
        <TextField
          type="text"
          variant="filled"
          value={currentTitle}
          onChange={event => setCurrentTitle(event.target.value)}
          label={i18n.t(k.TITLE)}
          autoFocus={isMobile() ? false : true}
        />

        <TextField
          type="text"
          multiline
          minRows={3}
          variant="filled"
          value={currentDescription}
          onChange={event => setCurrentDescription(event.target.value)}
          label={i18n.t(k.DESCRIPTION)}
        />
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogLargePhotoItem;
