import axios from 'axios';

const getFidiraUserByUsername = async username => {
  const userResponse = await axios.get(`user-read/`, {
    headers: { 'data-username': username },
  });
  return userResponse.data;
};

export default getFidiraUserByUsername;
