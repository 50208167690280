import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import Subheader from '../Components/Subheader';
import StyledProgress from '../Components/StyledProgress';

const Container = styled.div`
  transform: ${({ visible }) => (visible ? 'none' : ' translate3d(0, -500px, 0)')};
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 100px;
`;

const ForgotPasswordMessage = ({ visible, loadingForgotPassword }) => {
  return (
    <Container visible={visible}>
      <StyledProgress></StyledProgress>
      <Subheader>{i18n.t(k.WE_SENT_EMAIL)}</Subheader>
    </Container>
  );
};

export default ForgotPasswordMessage;
