import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../../Shared/Context/UserContext';
import { ReactComponent as LogoutSvg } from '../../../../_assets/svg/logout.svg';
import { ReactComponent as Star } from '../../../../_assets/svg/rating-star-empty.svg';
import { ReactComponent as ArrowDownSvg } from '../../../../_assets/svg/arrow-down.svg';
import getRoleOfUserInWedding from '../../../../Shared/FunctionUtils/getRoleOfUserInWedding';
import hasUserWritePermissionForWedding from '../../../../Shared/FunctionUtils/hasUserWritePermissionForWedding';

import OuterContainer from './Components/OuterContainer';
import InfoContainer from './Components/InfoContainer';
import UserAvatar from './Components/UserAvatar';
import UserNameContainer from './Components/UserNameContainer';
import AddressContainer from './Components/AddressContainer';
import ButtonContainer from './Components/ButtonContainer';
import CompactButton from './Components/CompactButton';
import BottomContainer from './Components/BottomContainer';
import ToggleButton from './Components/ToggleButton';
import PACKAGES from '../../../../Shared/Constants/PACKAGES';

const UserContainer = ({ styleConfig, weddingId, weddingConfig, selectedPackage }) => {
  const history = useHistory();
  const { fidiraUser, logOutCurrentUser } = useContext(UserContext);
  const [detailsShown, setDetailsShown] = useState(true);
  const [userRole, setUserRole] = useState('');
  const logOut = async () => {
    await logOutCurrentUser();
  };
  const startPayment = async () => {
    history.push(`/${weddingId}/payment`);
  };
  useEffect(() => {
    if (fidiraUser && fidiraUser.weddings) {
      setUserRole(getRoleOfUserInWedding(fidiraUser.weddings, weddingId));
    }
  }, [weddingId, fidiraUser, setUserRole]);
  const userHasWritePermission = hasUserWritePermissionForWedding(fidiraUser, weddingId);
  const heightOfBottomcontainer = !detailsShown ? '0px' : userHasWritePermission ? '170px' : '70px';
  return (
    <>
      {fidiraUser && userRole && (
        <OuterContainer styleConfig={styleConfig}>
          <InfoContainer>
            <UserAvatar styleConfig={styleConfig} userRole={userRole}></UserAvatar>
            <UserNameContainer fidiraUser={fidiraUser} userRole={userRole}></UserNameContainer>
          </InfoContainer>
          <ToggleButton
            styles={styleConfig}
            details={detailsShown.toString()}
            onClick={() => setDetailsShown(!detailsShown)}
          >
            <ArrowDownSvg></ArrowDownSvg>
          </ToggleButton>
          <BottomContainer heightOfBottomcontainer={heightOfBottomcontainer}>
            {userHasWritePermission && (
              <AddressContainer>
                {i18n.t(k.ADDRESS_OF_YOUR_PAGE)} <br></br>
                <a href={`${window.location.origin}/${weddingId}`}>{`${window.location.origin}/${weddingId}`}</a>
                <br></br>
                {i18n.t(k.WEDDING_CODE_FOR_GUESTS)}"<strong>{weddingConfig.weddingCodeForGuests}</strong>"
              </AddressContainer>
            )}

            {userHasWritePermission && (
              <AddressContainer>
                {i18n.t(k.CURRENT_PACKAGE)} {PACKAGES[selectedPackage]?.label || 'Basis'}
              </AddressContainer>
            )}

            <ButtonContainer>
              {(selectedPackage === 'basic' || userHasWritePermission) && selectedPackage !== 'vip' && (
                <CompactButton onClick={startPayment} styles={styleConfig}>
                  <Star></Star>
                  {i18n.t(k.UPGRADE)}
                </CompactButton>
              )}

              <CompactButton onClick={logOut} styles={styleConfig}>
                <LogoutSvg></LogoutSvg>
                {i18n.t(k.LOGOUT)}
              </CompactButton>
            </ButtonContainer>
          </BottomContainer>
        </OuterContainer>
      )}
    </>
  );
};

export default UserContainer;
