import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import hasUserReadPermissionForWedding from '../../FunctionUtils/hasUserReadPermissionForWedding';
import UserContext from '../../Context/UserContext';
import WeddingContext from '../../Context/WeddingContext';

const PrivateRoute = ({ requestedWedding, children }) => {
  const { fidiraUser } = useContext(UserContext);
  const { weddingConfig } = useContext(WeddingContext);
  return (
    <>
      {hasUserReadPermissionForWedding(fidiraUser, requestedWedding) || !weddingConfig.creatorIsRegistered ? (
        children
      ) : (
        <Redirect to={`/${requestedWedding}/login/enterUsername`} />
      )}
    </>
  );
};

export default PrivateRoute;
