import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4e4e4e40;
  border-style: dashed;
  border-width: 3px;
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  z-index: 1;
`;
