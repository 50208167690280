import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.p`
  font-size: 24px !important;
  margin-top: 6px;
  text-transform: uppercase;
  letter-spacing: 6px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${defaultStyles.boho.bodyFont} !important;
  color: ${defaultStyles.boho.colors.primary.base} !important;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.macbookHeight}) {
    margin-top: 0;
    font-size: 16px;
  }
`;
