import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: end;
  button {
    margin: 0 21px 15px 21px;
    svg {
      width: 21px;
      margin: 0 12px 0 9px;
    }
  }
`;
