import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';
import LanguagesContainer from '../../../../../../Shared/Components/LanguageSelection/LanguagesContainer';
import LanguageSelectButton from '../../../../../../Shared/Components/LanguageSelection/LanguageSelectButton';
import Label from '../../../../../../Shared/Components/LanguageSelection/Label';
import Icon from '../../../../../../Shared/Components/LanguageSelection/Icon';
import languages from '../../../../../../i18n/languages';
import { ReactComponent as CheckMark } from '../../../../../../_assets/svg/check-mark-simple.svg';

const SelectionMarker = styled(CheckMark)`
  fill: black !important;
  padding: 6px;
  position: absolute;
  bottom: -15px;
  right: -15px;
  width: 20px;
  background-color: #81d557;
  border-radius: 50%;
  border: solid black 2px;
`;

const EditingDialogLanguages = ({ selectedLanguagesForWedding, stopEditing, saveChanges }) => {
  const { weddingConfig } = useContext(WeddingContext);
  const [newLanguages, setNewLanguages] = useState(
    selectedLanguagesForWedding?.length > 0 ? selectedLanguagesForWedding : ['de']
  );

  const handleLanguageToggle = language => {
    let languagesToBeChanged = [...newLanguages];
    const index = newLanguages.indexOf(language);
    if (index >= 0 && languagesToBeChanged.length > 1) {
      languagesToBeChanged.splice(index, 1);
    } else {
      languagesToBeChanged.push(language);
    }
    setNewLanguages(languagesToBeChanged);
  };

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      nameOfBride: weddingConfig.nameOfBride,
      nameOfGroom: weddingConfig.nameOfGroom,
      weddingDateIsExact: weddingConfig.weddingDateIsExact,
      weddingDate: weddingConfig.weddingDate,
      showCountdown: weddingConfig.showCountdown,
      selectedLanguagesForWedding: newLanguages,
      useCustomLogo: weddingConfig.useCustomLogo,
      customLogoUrl: weddingConfig.customLogoUrl,
      weddingStyle: weddingConfig.weddingStyle,
      useStartPhoto: weddingConfig.useStartPhoto,
      startPhotoUrl: weddingConfig.startPhotoUrl,
    });
  };

  return (
    <Dialog open>
      <DialogTitle>{i18n.t(k.EDIT_LANGUAGES)}</DialogTitle>
      <DialogContent>
        <p>{i18n.t(k.DECIDE_LANGUAGES)}</p>
        <LanguagesContainer>
          {Object.entries(languages).map(language => (
            <LanguageSelectButton key={language[1].icon} onClick={() => handleLanguageToggle(language[0])}>
              <Icon icon={language[1].icon}></Icon>
              <Label>{language[1].label}</Label>
              {newLanguages.includes(language[0]) && <SelectionMarker></SelectionMarker>}
            </LanguageSelectButton>
          ))}
        </LanguagesContainer>
        <p>
          {i18n.t(k.IF_YOU_NEED_MORE_LANGUAGES)} {i18n.t(k.WE_LOOK_FORWARD_TO_IT)}{' '}
          <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
        </p>
      </DialogContent>
      <DialogActions stopEditing={stopEditing} onComplete={handleSave}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogLanguages;
