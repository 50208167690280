import styled from 'styled-components';

export default styled.button`
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: inherit !important;
`;
