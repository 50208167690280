import styled from 'styled-components';

export default styled.p`
  background-color: rgba(255, 255, 255, 0.62);
  border-radius: 3px;
  padding: 3px;
  text-align: center;
  font-size: 14px !important;
  font-weight: bold;
  color: #f44336 !important;
  margin: 0 auto 14px auto;
  max-width: 90vw;
  line-height: 1em;
  letter-spacing: 0.03333em;
`;
