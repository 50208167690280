import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

const Outer = styled.div`
  max-width: 100vw;
  height: ${({ smallHeight }) => (smallHeight ? '110px' : '130px')};
  max-height: 30vh;
  @media (min-width: ${breakpoints.xs}) {
    height: ${({ smallHeight }) => (smallHeight ? '120px' : '190px')};
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    overflow: auto;
  }
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    max-height: 35vh;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SelectionContainer = ({ children, smallHeight }) => {
  return (
    <Outer className="selection-container-outer" smallHeight={smallHeight}>
      <Inner className="selection-container-flex">{children}</Inner>
    </Outer>
  );
};

export default SelectionContainer;
