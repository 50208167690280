import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import UserContext from '../../../../../Shared/Context/UserContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import SinglePresentCard from './PresentElement/SinglePresentCard';
import Axios from 'axios';
import getRoleOfUserInWedding from '../../../../../Shared/FunctionUtils/getRoleOfUserInWedding';
import ReactGA from 'react-ga4';

const Container = styled.div`
  overflow: hidden;
  min-height: 500px;
`;

const PresentElement = ({ presents }) => {
  const { fidiraUser } = useContext(UserContext);
  const { weddingId } = useContext(WeddingContext);
  const [loadingSubmit, setLoadingSubmit] = useState(null);
  const [loadingIfBackingSubmitted, setLoadingIfBackingSubmitted] = useState(false);
  const [presentsWithBackings, setPresentsWithBackings] = useState(presents);

  const submitBacking = async presentId => {
    setLoadingSubmit(presentId);
    await Axios.post(`wedding-backing-create/`, {
      presentId: presentId,
      weddingId: weddingId,
    });
    setLoadingSubmit(null);
    await checkForSubmitted();
    ReactGA.event({
      category: `EHO_Event`,
      action: `Wedding-${weddingId}-Present-Backing-Added`,
    });
  };

  const deleteBacking = async presentId => {
    setLoadingSubmit(presentId);
    await Axios.delete(`${weddingId}/wedding-backing/${presentId}/wedding-backing-delete/`);
    setLoadingSubmit(null);
    await checkForSubmitted();
  };

  const checkForSubmitted = async () => {
    setLoadingIfBackingSubmitted(true);
    const role = getRoleOfUserInWedding(fidiraUser?.weddings, weddingId);
    if (role) {
      const { data: result } = await Axios.get(`/${weddingId}/wedding-backing-read/`);
      const newPresents = [...presents].map(present => {
        const backing = result.find(backing => backing.presentId === present.id);
        return {
          ...present,
          backing,
        };
      });
      setPresentsWithBackings(newPresents);
    }
    setLoadingIfBackingSubmitted(false);
  };

  useEffect(() => {
    checkForSubmitted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presents]);

  return (
    <Container>
      {presentsWithBackings.map(present => (
        <SinglePresentCard
          key={present.id}
          present={present}
          submitBacking={submitBacking}
          loadingSubmit={loadingSubmit === present.id}
          loadingIfBackingSubmitted={loadingIfBackingSubmitted}
          deleteBacking={deleteBacking}
          substituteLanguage={present.substituteLanguage}
        ></SinglePresentCard>
      ))}
    </Container>
  );
};

export default PresentElement;
