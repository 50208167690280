import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../../WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../WeddingStyles/GenericStyleContainer';
import LogoLarge from '../EureHochzeitOnlineLogo/LogoLarge';
import LegalFooter from '../LegalFooter/LegalFooter';
import OuterContainer from '../EureHochzeitOnline/OuterContainer';
import InnerContainer from '../EureHochzeitOnline/InnerContainer';
import { ReactComponent as ErrorFaceSvg } from '../../../_assets/svg/error-face.svg';

const StyledErrorFaceSvg = styled(ErrorFaceSvg)`
  fill: #e06e7e;
  height: 200px;
  margin: 30px;
`;

const ContentContainer = styled.div`
  div {
    overflow-y: scroll;
    max-height: 100px;
    background-color: #e7e7e7;
    max-width: 85vw;
  }
  p {
    max-width: 85vw;
  }
  code {
    font-size: 12px;
    line-height: 1;
  }
`;

const ErrorScreen = ({ stack }) => {
  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <InnerContainer>
          <LogoLarge></LogoLarge>
          <ContentContainer>
            <strong>{i18n.t(k.OH_SORRY)} </strong>
            <p>
              {i18n.t(k.ERROR_REFRESH_PAGE)}{' '}
              <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
            </p>

            <p>{i18n.t(k.IF_YOU_WANT_TO_HELP)}</p>
            <div>
              <code>{stack}</code>
            </div>
          </ContentContainer>
          <StyledErrorFaceSvg></StyledErrorFaceSvg>
        </InnerContainer>
      </OuterContainer>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default ErrorScreen;
