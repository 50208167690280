import i18n from 'i18next';
import k from '../../i18n/keys';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import LogoLarge from '../../Shared/Components/EureHochzeitOnlineLogo/LogoLarge';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import OuterContainer from '../../Shared/Components/EureHochzeitOnline/OuterContainer';
import InnerContainer from '../../Shared/Components/EureHochzeitOnline/InnerContainer';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';
import UserContext from '../../Shared/Context/UserContext';

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  > button,
  > div {
    margin-top: 12px;
  }
  .MuiTable-root {
    width: 300px;
    margin: 21px 0;
  }
  .MuiCircularProgress-root {
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const GdprDeletionPage = () => {
  const { fidiraUser } = useContext(UserContext);
  const [adminMode, setAdminMode] = useState(false);
  const [username, setUsername] = useState('');
  const [loadingDeletionPreview, setLoadingDeletionPreview] = useState(false);
  const [loadingDeletionResult, setLoadingDeletionResult] = useState(false);

  const [deletionPreview, setDeletionPreview] = useState(null);
  const [deletionResult, setDeletionResult] = useState(null);

  const loadDeletionPreview = async () => {
    setLoadingDeletionPreview(true);
    setDeletionResult(null);
    const { data: deletionPreview } = await axios.get(`/gdpr-deletion?username=${username}`);
    setDeletionPreview(deletionPreview);
    setLoadingDeletionPreview(false);
  };

  const deletePermanently = async () => {
    setLoadingDeletionResult(true);
    const { data: deletionResult } = await axios.delete(`/gdpr-deletion?username=${username}`);
    setDeletionPreview(null);
    setDeletionResult(deletionResult);
    setLoadingDeletionResult(false);
  };

  useEffect(() => {
    if (fidiraUser && fidiraUser.isFidiraAdmin) {
      setAdminMode(true);
    }
  }, [fidiraUser]);

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <LanguageSelect></LanguageSelect>
        <InnerContainer>
          <LogoLarge></LogoLarge>
          <div>
            {adminMode && (
              <>
                <strong>
                  {i18n.t(k.HELLO)}
                  {fidiraUser.displayName}
                </strong>
                <ControlsContainer>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Mail of the user to be deleted"
                    value={username}
                    disabled={!!deletionPreview}
                    onChange={e => setUsername(e.target.value)}
                  />
                  <Button
                    onClick={loadDeletionPreview}
                    disabled={!!deletionPreview || loadingDeletionResult || loadingDeletionPreview}
                    color="secondary"
                    variant="contained"
                  >
                    {loadingDeletionPreview ? <CircularProgress size="15"></CircularProgress> : <>Deletion Preview</>}
                  </Button>
                  {deletionPreview && (
                    <>
                      <h2>Host Weddings</h2>
                      {deletionPreview.hostWeddings.length === 0 ? (
                        <p>No weddings as host</p>
                      ) : (
                        deletionPreview.hostWeddings.map(hostWedding => (
                          <Table key={hostWedding.weddingId} size="small">
                            <TableHead>
                              {hostWedding.weddingId && (
                                <TableRow>
                                  <TableCell>
                                    <strong>
                                      Hochzeit: {hostWedding.weddingId} <br></br>{' '}
                                      {new Date(hostWedding.creationDate).toLocaleDateString()}
                                      <br></br> {hostWedding.brideUsername}
                                      {hostWedding.groomUsername}
                                    </strong>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            <TableBody>
                              {Object.keys(hostWedding.statistics).map(key => (
                                <TableRow key={key}>
                                  <TableCell>{key}</TableCell>
                                  <TableCell>{hostWedding.statistics[key]}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ))
                      )}
                      <h2>Guest Weddings</h2>
                      {deletionPreview.guestWeddings.length === 0 ? (
                        <p>No weddings as guest</p>
                      ) : (
                        <>
                          {deletionPreview.guestWeddings.map(guestWedding => (
                            <p key={guestWedding} size="small">
                              {guestWedding}
                            </p>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  <Button
                    onClick={deletePermanently}
                    disabled={!deletionPreview || loadingDeletionResult || loadingDeletionPreview}
                    color="primary"
                    variant="contained"
                  >
                    {loadingDeletionResult ? <CircularProgress size="15"></CircularProgress> : <>Delete Permanently</>}
                  </Button>
                  {deletionResult && (
                    <div>
                      <strong>Deletion successful:</strong>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>deletedS3Objects</TableCell>
                            <TableCell>{deletionResult.deletedS3Objects}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>anonymizedSpotifyPlaylists</TableCell>
                            <TableCell>{deletionResult.anonymizedSpotifyPlaylists}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>deletedCognitoUsers</TableCell>
                            <TableCell>{deletionResult.deletedCognitoUsers}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>deletedMailchimpMembers</TableCell>
                            <TableCell>{deletionResult.deletedMailchimpMembers}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>deletedDynamoDBItems</TableCell>
                            <TableCell>{deletionResult.deletedDynamoDBItems}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>deletedDynamoDBUsers</TableCell>
                            <TableCell>{deletionResult.deletedDynamoDBUsers}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </ControlsContainer>
              </>
            )}
          </div>
        </InnerContainer>
      </OuterContainer>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default GdprDeletionPage;
