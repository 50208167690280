import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import BottomHintContainer from '../Components/BottomHintContainer';

const DemoWeddingHintBottom = () => {
  const { search } = useLocation();
  return (
    <BottomHintContainer>
      <NavLink to={{ pathname: `/hochzeit-erstellen/1${search}` }}>{i18n.t(k.DEMO_WEDDING_HINT)}</NavLink>
    </BottomHintContainer>
  );
};

export default DemoWeddingHintBottom;
