import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../../Shared/breakpoints';

import ContentCardContainer from './ContentCardContainer';

const ScheduleItem = styled.div`
  padding: 12px;
  border-bottom: solid 2px;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-around;
  align-items: stretch;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 12px;
  flex: 8;
`;
const Time = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
`;
const Title = styled.h2`
  max-width: 80%;
  margin-bottom: 0;
  @media (max-width: ${breakpoints.xs}) {
    max-width: 100%;
  }
`;
const Description = styled.p`
  max-width: 80%;
  white-space: pre-wrap;
  @media (max-width: ${breakpoints.xs}) {
    max-width: 100%;
  }
`;
const ImageContainer = styled.div`
  flex: 4;
  min-width: 150px;
`;
const Image = styled.div`
  min-height: 250px;
  height: 100%;
  width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const mappingImagePositionToFlexDirection = {
  left: 'row',
  right: 'row-reverse',
  top: 'column',
  bottom: 'column-reverse',
};

const ContentCardWithScheduleItem = ({
  time,
  title,
  description,
  imageUrl,
  imagePosition,
  editingKeys,
  hideEditingContainer,
  substituteLanguage,
}) => {
  return (
    <ContentCardContainer
      hideEditingContainer={hideEditingContainer}
      editingKeys={editingKeys}
      substituteLanguage={substituteLanguage}
      content={{ time: time, title: title, description: description, imageUrl: imageUrl, imagePosition: imagePosition }}
    >
      <ScheduleItem direction={mappingImagePositionToFlexDirection[imagePosition]}>
        {imageUrl && (
          <ImageContainer>
            <Image imageUrl={imageUrl}></Image>
          </ImageContainer>
        )}
        <TextContainer>
          <Time>{time}</Time>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextContainer>
      </ScheduleItem>
    </ContentCardContainer>
  );
};

export default ContentCardWithScheduleItem;
