import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import ReactPixel from 'react-facebook-pixel';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { TextField, FormControlLabel, Switch } from '@material-ui/core';

import IconBox from '../.../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../.../../../../Shared/Components/SelectionComponent/SelectionContainer';

import QuestionHeader from '../Components/QuestionHeader';
import ButtonContainer from '../Components/ButtonContainer';
import BackButton from '../Components/BackButton';
import NextButton from '../Components/NextButton';
import FieldContainer from '../Components/FieldContainer';
import ErrorMessage from '../Components/ErrorMessage';
import FadeOuterContainer from '../Components/FadeOuterContainer';
import FadeContainer from '../Components/FadeContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 6px;
  margin-top: 16px;
  #google-map {
    font-size: 16px;
    height: 28px;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-bottom: -18px;
  margin-top: -12px;
`;

const googleMapsLibraries = ['places'];

const WeddingLocation = ({ submitLocation, onBack, weddingStyle }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [locationDefined, setLocationDefined] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocationType, setSelectedLocationType] = useState(null);
  const [showError, setShowError] = useState(false);

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete);
    setBounds(new window.google.maps.LatLngBounds({ lat: 48.15228, lng: 11.528074 }));
  };

  const handleSwitch = e => {
    setLocationDefined(e.target.checked);
    setShowError(false);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const rawPlace = autocomplete.getPlace();
      const place = {
        name: rawPlace.name,
        street: rawPlace.address_components.filter(c => c.types.includes('route'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('route'))[0].long_name
          : '',
        houseNumber: rawPlace.address_components.filter(c => c.types.includes('street_number'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('street_number'))[0].long_name
          : '',
        zip: rawPlace.address_components.filter(c => c.types.includes('postal_code'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('postal_code'))[0].long_name
          : '',
        city: rawPlace.address_components.filter(c => c.types.includes('locality'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('locality'))[0].long_name
          : '',
        country: rawPlace.address_components.filter(c => c.types.includes('country'))[0]
          ? rawPlace.address_components.filter(c => c.types.includes('country'))[0].long_name
          : '',
        iconType: 'couple-balloon',
        latitude: rawPlace.geometry.location.lat(),
        longitude: rawPlace.geometry.location.lng(),
        mapsUrl: rawPlace.url,
      };
      setShowError(false);
      setSelectedLocation(place);
      setSearchTerm(`${place.name}`);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const onChange = e => {
    setSelectedLocation(null);
    setSearchTerm(e.target.value);
  };

  const handleLocationTypeSelect = selection => {
    setSelectedLocationType(selection.value);
    setShowError(false);
  };

  const submit = () => {
    if (locationDefined) {
      if (selectedLocation) {
        ReactPixel.trackCustom('LocationFunnel', {
          weddingLocationIsExact: true,
          weddingLocationName: selectedLocation,
        });
        submitLocation(selectedLocation, true);
      } else {
        setShowError(true);
      }
    } else {
      if (selectedLocationType) {
        ReactPixel.trackCustom('LocationFunnel', {
          weddingLocationIsExact: false,
          weddingLocationStyle: selectedLocationType,
        });
        submitLocation(selectedLocationType, false);
      } else {
        setShowError(true);
      }
    }
  };

  const locationTypes = [
    { name: i18n.t(k.LOCATION_CLASSIC), image: 'klassisch' },
    { name: i18n.t(k.LOCATION_COUNTRY), image: 'laendlich' },
    { name: i18n.t(k.LOCATION_OUTDOOR), image: 'outdoor' },
    { name: i18n.t(k.LOCATION_PALACE_HOTEL), image: 'schloss' },
  ];

  return (
    <LoadScript
      id="script-loader"
      libraries={googleMapsLibraries}
      googleMapsApiKey="AIzaSyAUO_P0hkO_78JaRqQLYEX3CLvHDVvpAkQ"
    >
      <Container>
        <QuestionHeader>{i18n.t(k.QUESTION_WEDDING_LOCATION)}</QuestionHeader>
        <FieldContainer>
          <FormControlLabel
            className="color-background-default"
            control={<StyledSwitch checked={locationDefined} onChange={handleSwitch} value="locationDefined" />}
            label={locationDefined ? i18n.t(k.YES_WE_HAVE) : i18n.t(k.NO_WE_DONT_KNOW)}
          />

          <FadeOuterContainer>
            <FadeContainer visible={locationDefined}>
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                bounds={bounds}
                styles={{ textAlign: 'center' }}
                options={{
                  fields: ['name', 'address_component', 'geometry', 'type', 'url'],
                }}
              >
                <StyledTextField
                  type="text"
                  variant="filled"
                  label={i18n.t(k.ADDRESS_OR_NAME_OF_LOCATION)}
                  placeholder=""
                  onChange={onChange}
                  value={searchTerm}
                  autoFocus
                ></StyledTextField>
              </Autocomplete>
              {showError && <ErrorMessage>{i18n.t(k.ENTER_YOUR_LOCATION)}</ErrorMessage>}
            </FadeContainer>
            <FadeContainer visible={!locationDefined}>
              <SelectionContainer smallHeight>
                {locationTypes.map(locationType => {
                  return (
                    <IconBox
                      active={selectedLocationType === locationType.name}
                      image={`/assets/locations/${locationType.image}.jpg`}
                      text={locationType.name}
                      question="locationType"
                      selectValue={locationType.name}
                      onSelect={handleLocationTypeSelect}
                      key={locationType.name}
                      weddingStyle={weddingStyle}
                      smallHeight
                    />
                  );
                })}
              </SelectionContainer>
              {showError && <ErrorMessage>{i18n.t(k.MAKE_SELECTION)}</ErrorMessage>}
            </FadeContainer>
          </FadeOuterContainer>
        </FieldContainer>
        <ButtonContainer>
          <BackButton onClick={() => onBack()} variant="contained" color="secondary">
            {i18n.t(k.BACK)}
          </BackButton>
          <NextButton onClick={() => submit()} variant="contained">
            {i18n.t(k.NEXT)}
          </NextButton>
        </ButtonContainer>
      </Container>
    </LoadScript>
  );
};

export default WeddingLocation;
