import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';
import ContentCardContainer from './ContentCardContainer';

const InnerContainer = styled.div`
  border-bottom: solid 6px;
  padding: 12px 6px;
`;

const SubTitle = styled.p`
  margin-top: 0;
  font-weight: bold;
  letter-spacing: 1px;
`;
const MainTitle = styled.h1`
  margin-bottom: 0;
  @media (max-width: ${breakpoints.xs}) {
    font-size: 35px !important;
  }
`;

const ContentCardWithTitle = ({ title, subtitle, substituteLanguage, editingKeys, hideEditingContainer }) => {
  return (
    <ContentCardContainer
      editingKeys={editingKeys}
      content={{ title: title, subtitle: subtitle }}
      hideEditingContainer={hideEditingContainer}
      substituteLanguage={substituteLanguage}
    >
      <InnerContainer>
        <MainTitle>{title}</MainTitle>
        <SubTitle>{subtitle}</SubTitle>
      </InnerContainer>
    </ContentCardContainer>
  );
};

export default ContentCardWithTitle;
