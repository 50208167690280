import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import { ReactComponent as RefreshSvg } from '../../../../../_assets/svg/refresh.svg';
import boxShadows from '../../../../../Shared/boxShadows';

const StyledIconButton = styled(IconButton)`
  z-index: 1;
  margin: 6px;
  box-shadow: ${boxShadows.z2};
  position: absolute;
  top: 0;
  right: 0;
  div {
    height: 25px;
    width: 25px;
    animation: ${({ refreshing }) => (refreshing === 'true' ? 'rotating 1.5s linear infinite' : '')};
  }
  svg {
    height: 25px;
    width: 25px;
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const RefreshButton = ({ refreshing, startRefresh }) => {
  return (
    <StyledIconButton onClick={startRefresh} refreshing={refreshing.toString()}>
      <div>
        <RefreshSvg></RefreshSvg>
      </div>
    </StyledIconButton>
  );
};

export default RefreshButton;
