import React from 'react';
import styled from 'styled-components';

import breakpoints from '../../breakpoints';

import WeddingTitle from './Components/WeddingTitle';
import WeddingDate from './Components/WeddingDate';
import Border from './Components/Border';
import Heart from './Components/Heart';
import HorizontalLine from './Components/HorizontalLine';
import Overlay from './Components/Overlay';
import Container from './Components/Container';
import TopCenter from './Components/TopCenter';
import TopLeft from './Components/TopLeft';

const BorderLeft = styled(Border)`
  left: 17vw;
  transform: scaleX(-1);
  transform: ${({ visible }) => (visible ? 'scaleX(-1)' : 'scaleX(-1) translate3d(800px, 0, 0)')};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    left: 18vw;
  }
`;
const BorderRight = styled(Border)`
  right: 17vw;
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(800px, 0, 0)')};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    right: 6vw;
  }
`;

const VintageOnboardingContainer = ({ visible, nameOfBride, nameOfGroom, weddingDate }) => {
  return (
    <Container visible={visible}>
      <Overlay />
      <BorderLeft visible={visible} />
      <TopLeft visible={visible} />
      <TopCenter visible={visible}>
        <Heart />
        {nameOfBride && nameOfGroom && (
          <>
            <WeddingTitle>
              {nameOfBride} &amp; {nameOfGroom}
            </WeddingTitle>
            <HorizontalLine />
          </>
        )}
        <WeddingDate>{weddingDate}</WeddingDate>
      </TopCenter>
      <BorderRight visible={visible} />
    </Container>
  );
};

export default VintageOnboardingContainer;
