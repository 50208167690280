import styled from 'styled-components';

import breakpoints from '../../../breakpoints';

export default styled.hr`
  border: white solid 3px;
  margin: 30px 0;
  min-width: 99%;
  @media (max-height: 980px) {
    margin: 12px 0;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    border: white solid 2px;
    margin: 6px 0;
    width: 50%;
  }
`;
