import styled from 'styled-components';

export default styled.div`
  background-color: #000000bf;
  color: white !important;
  border-top-right-radius: 12px;
  position: absolute;
  bottom: 3px;
  left: 3px;
  padding: 6px;
  font-size: 14px !important;
`;
