import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, LinearProgress } from '@material-ui/core';
import styled from 'styled-components';
import axios from 'axios';

import breakpoints from '../../../../../Shared/breakpoints';
import defaultStyles from '../../../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../../../Shared/WeddingStyles/GenericStyleContainer';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import LinkButton from '../../../../../Shared/Components/LinkButton/LinkButton';
import FidiraDialog from '../../../../../Shared/Components/Dialog/FidiraDialog';
import maskEmailAddress from '../../../../../Shared/FunctionUtils/maskEmailAddress';
import { ReactComponent as LoveLetter } from '../../../../../_assets/svg/love-letter.svg';

const StyledDialogContent = styled(DialogContent)`
  height: 400px;
  width: 500px;
  max-width: 65vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3,
  p {
    margin: 0px;
    margin-bottom: 12px;
  }
  button {
    background-color: transparent !important;
  }
`;

const IconContainer = styled.div`
  margin: 30px;
  svg {
    height: 100px;
    width: 100px;
    fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite};
  }
  @media (max-width: ${breakpoints.xs}) {
    margin: 10px;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
  button {
    width: 150px;
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 50%;
`;

const StyledH2 = styled.h2`
  margin: 0 0 6px 0;
`;

const CreatorNotRegisteredHintDialog = ({ show, setShow }) => {
  const { weddingConfig, weddingId } = useContext(WeddingContext);
  const [mailSent, setMailSent] = useState(false);
  const [errorMailSent, setErrorMailSent] = useState(false);
  const [loadingMailSent, setLoadingMailSent] = useState(false);
  const [recipient, setRecipient] = useState(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseErrorMessage = () => {
    setRecipient(null);
    setErrorMailSent(false);
    setMailSent(false);
  };

  const resendMail = async () => {
    setLoadingMailSent(true);
    try {
      const resendResponse = await axios.post(`wedding-send-creation-doi/`, { weddingId: weddingId });
      const { recipient } = resendResponse.data;
      setRecipient(maskEmailAddress(recipient));
      setMailSent(true);
      setLoadingMailSent(false);
    } catch (error) {
      setErrorMailSent(true);
      setLoadingMailSent(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="{messageTitle}"
      aria-describedby="{messageDescriptionText}"
      className="color-background-white"
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingConfig.weddingStyle]}>
        <StyledDialogContent className="color-background-white">
          <IconContainer weddingStyle={weddingConfig.weddingStyle}>
            <LoveLetter></LoveLetter>
          </IconContainer>
          {!mailSent && !loadingMailSent && (
            <>
              <StyledH2>{i18n.t(k.YOU_HAVE_AN_EMAIL)} </StyledH2>
              <p>{i18n.t(k.YOU_MUST_REGISTER_FIRST)} </p>
              <p>
                {i18n.t(k.CREATOR_MUST_REGISTER)} <LinkButton onClick={resendMail}>{i18n.t(k.HERE)}</LinkButton>
              </p>
            </>
          )}

          {mailSent && !loadingMailSent && (
            <>
              <StyledH2>{i18n.t(k.E_MAIL_RESENT)} </StyledH2>
              <p>
                {i18n.t(k.CONFIRMATION_RESENT_TO)} {recipient}
              </p>
              <p>
                {i18n.t(k.CAN_TAKE_SOME_MINUTES)}
                <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
              </p>
            </>
          )}

          {loadingMailSent && (
            <>
              <p>{i18n.t(k.RESENDING_MAIL)} </p>
              <StyledLinearProgress></StyledLinearProgress>
            </>
          )}
        </StyledDialogContent>
        <StyledDialogActions className="color-background-white">
          <Button onClick={handleClose} color="primary" variant="contained">
            {i18n.t(k.OK)}
          </Button>
        </StyledDialogActions>
        <FidiraDialog
          open={errorMailSent}
          messageDescriptionText={i18n.t(k.COULD_NOT_SEND_MAIL)}
          handleClose={handleCloseErrorMessage}
        ></FidiraDialog>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default CreatorNotRegisteredHintDialog;
