import i18n from 'i18next';
import k from './../../i18n/keys';

const getUpcomingFourSeasons = () => {
  const currentDate = new Date();
  const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);
  const currentYear = currentDate.getFullYear();

  let seasons;
  switch (currentQuarter) {
    case 1:
      seasons = [
        { name: `${i18n.t(k.SPRING)} ${currentYear}`, image: 'spring' },
        { name: `${i18n.t(k.SUMMER)}  ${currentYear}`, image: 'summer' },
        { name: `${i18n.t(k.AUTUMN)}  ${currentYear}`, image: 'autumn' },
        { name: `${i18n.t(k.WINTER)}  ${currentYear}`, image: 'winter' },
      ];
      break;
    case 2:
      seasons = [
        { name: `${i18n.t(k.SUMMER)}  ${currentYear}`, image: 'summer' },
        { name: `${i18n.t(k.AUTUMN)}  ${currentYear}`, image: 'autumn' },
        { name: `${i18n.t(k.WINTER)}  ${currentYear}`, image: 'winter' },
        { name: `${i18n.t(k.SPRING)}  ${currentYear + 1}`, image: 'spring' },
      ];
      break;
    case 3:
      seasons = [
        { name: `${i18n.t(k.AUTUMN)}  ${currentYear}`, image: 'autumn' },
        { name: `${i18n.t(k.WINTER)}  ${currentYear}`, image: 'winter' },
        { name: `${i18n.t(k.SPRING)}  ${currentYear + 1}`, image: 'spring' },
        { name: `${i18n.t(k.SUMMER)}  ${currentYear + 1}`, image: 'summer' },
      ];
      break;
    case 4:
      seasons = [
        { name: `${i18n.t(k.WINTER)}  ${currentYear}`, image: 'winter' },
        { name: `${i18n.t(k.SPRING)}  ${currentYear + 1}`, image: 'spring' },
        { name: `${i18n.t(k.SUMMER)}  ${currentYear + 1}`, image: 'summer' },
        { name: `${i18n.t(k.AUTUMN)}  ${currentYear + 1}`, image: 'autumn' },
      ];
      break;
    default:
  }
  return seasons;
};

export default getUpcomingFourSeasons;
