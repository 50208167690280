import React from 'react';
import i18n from 'i18next';
import k from './../../../i18n/keys';
import styled from 'styled-components';
import defaultStyles from '../../WeddingStyles/DefaultStyles';
import boxShadows from '../../boxShadows';
import breakpoints from '../../breakpoints';

const Container = styled.div`
  width: 110px;
  min-width: 110px;
  border-radius: 6px;
  margin: 12px 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  box-shadow: ${({ weddingStyle }) =>
    `0px 0px 0px 1px ${defaultStyles[weddingStyle].colors.primary.onWhite}, ${boxShadows.z1}`};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    margin: 6px;
    border: solid 1px;
    width: 70px;
    min-width: 70px;
  }
`;

const ImageContainer = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: ${({ active }) => (active ? 'solid #ffffff 1px !important' : 'none')};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 70px;
  }
`;

const StyledThumbnailImageContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const DesktopScreen = styled.div`
  width: 50px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
  box-shadow: ${boxShadows.z1};
  margin: 2px;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  @media (max-width: ${breakpoints.xs}) {
    max-width: 32px;
    max-height: 20px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin: 1px;
  }
`;
const MobileScreen = styled.div`
  position: absolute;
  top: 3px;
  left: 10px;
  border-radius: 2px;
  width: 15px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
  @media (max-width: ${breakpoints.xs}) {
    width: 10px;
    height: 20px;
    top: 2px;
    left: 3px;
  }
`;
const MobileMockup = styled.div`
  width: 15px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/wedding-styles/phone-mock.png);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  box-shadow: ${boxShadows.z1};
  @media (max-width: ${breakpoints.xs}) {
    width: 10px;
    height: 20px;
  }
`;

const Text = styled.span`
  color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.secondary.base} !important;
`;

const ShowAllStylesButton = ({ onClick, weddingStyle = 'eurehochzeitonline' }) => {
  return (
    <Container weddingStyle={weddingStyle} onClick={onClick}>
      <ImageContainer>
        {Object.keys(defaultStyles)
          .filter(key => key !== 'eurehochzeitonline')
          .slice(0, 6)
          .map(key => {
            return (
              <StyledThumbnailImageContainer key={key}>
                <DesktopScreen image={`/assets/wedding-styles/${key}/desktop-${key}.jpg`}></DesktopScreen>
                <MobileScreen image={`/assets/wedding-styles/${key}/mobile-${key}.jpg`}>
                  <MobileMockup></MobileMockup>
                </MobileScreen>
              </StyledThumbnailImageContainer>
            );
          })}
      </ImageContainer>
      <Text weddingStyle={weddingStyle}>{i18n.t(k.ALL_STYLES)}</Text>
    </Container>
  );
};

export default ShowAllStylesButton;
