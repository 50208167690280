import React, { useEffect } from 'react';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';

import PlaylistArea from './Components/Container/Container';
import ContentCardWithInfoItem from '../../Components/ContentCardWithInfoItem';
import { Helmet } from 'react-helmet';

const MusicPage = ({ weddingId, pageData, weddingConfig, refreshWeddingConfig, loadingWeddingConfig, scrollToTop }) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Music`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {pageData.elements.map((item, index) => {
          switch (item.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithTitle
                    title={item.title}
                    subtitle={item.subtitle}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'MUSIC', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'MUSIC' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.infoItem.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithInfoItem
                    title={item.title}
                    description={item.description}
                    imageUrl={item.imageUrl}
                    imagePosition={item.imagePosition}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{
                      index: index,
                      contentPage: 'MUSIC',
                      elementType: elementTypes.infoItem.name,
                    }}
                  ></ContentCardWithInfoItem>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'MUSIC' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.playlistEditor.name:
              return (
                <PlaylistArea
                  key="1"
                  weddingId={weddingId}
                  playlistId={item.playlistId}
                  nameOfBride={weddingConfig.nameOfBride}
                  nameOfGroom={weddingConfig.nameOfGroom}
                  creatorIsRegistered={weddingConfig.creatorIsRegistered}
                  refreshWeddingConfig={refreshWeddingConfig}
                  loadingWeddingConfig={loadingWeddingConfig}
                  weddingConfig={weddingConfig}
                ></PlaylistArea>
              );
            default:
              return null;
          }
        })}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default MusicPage;
