import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React, { useState } from 'react';

import defaultStyles from '../../../../../Shared/WeddingStyles/DefaultStyles';
import { ReactComponent as ChurchSvg } from '../../../../../_assets/svg/map-markers/church.svg';
import { ReactComponent as DinnerSvg } from '../../../../../_assets/svg/map-markers/dinner.svg';
import { ReactComponent as CoupleBalloonSvg } from '../../../../../_assets/svg/map-markers/couple-balloon.svg';
import { ReactComponent as CoupleCakeSvg } from '../../../../../_assets/svg/map-markers/couple-cake.svg';
import { ReactComponent as CityHallSvg } from '../../../../../_assets/svg/map-markers/city-hall.svg';
import { ReactComponent as HotelSvg } from '../../../../../_assets/svg/map-markers/hotel.svg';
import { ReactComponent as ParkingSvg } from '../../../../../_assets/svg/map-markers/parking.svg';
import { ReactComponent as CarRentalSvg } from '../../../../../_assets/svg/map-markers/car-rental.svg';

import EditingContainerForLocation from '../../../Editing/Components/EditingContainer/EditingContainerForLocation';

import LocationBoxContainer from './LocationBoxContainer';
import IconContainerInBox from './IconContainerInBox';

const getExternalLink = url => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `https://${url}`;
  }
};

const renderInnerLocationBox = (weddingStyle, location) => (
  <>
    <IconContainerInBox
      backgroundColor={defaultStyles[weddingStyle].colors.mapMarker.background}
      iconColor={defaultStyles[weddingStyle].colors.mapMarker.icon}
    >
      {location.iconType === 'church' && <ChurchSvg></ChurchSvg>}
      {location.iconType === 'dinner' && <DinnerSvg></DinnerSvg>}
      {location.iconType === 'couple-cake' && <CoupleCakeSvg></CoupleCakeSvg>}
      {location.iconType === 'couple-balloon' && <CoupleBalloonSvg></CoupleBalloonSvg>}
      {location.iconType === 'city-hall' && <CityHallSvg></CityHallSvg>}
      {location.iconType === 'hotel' && <HotelSvg></HotelSvg>}
      {location.iconType === 'parking' && <ParkingSvg></ParkingSvg>}
      {location.iconType === 'car-rental' && <CarRentalSvg></CarRentalSvg>}
    </IconContainerInBox>
    <h3>{location.name}</h3>
    <div>
      <span>{location.street}</span> <span>{location.houseNumber}</span>
      <br></br>
      <span>{location.zip}</span> <span>{location.city}</span>
      <br></br>
      <span>{location.country}</span>
    </div>
    <p>{location.remarks}</p>
    {location.websiteUrl && (
      <a href={`${getExternalLink(location.websiteUrl)}`} target="_blank" rel="noopener noreferrer">
        {i18n.t(k.WEBSITE)}
      </a>
    )}
  </>
);

const LocationBox = ({
  index,
  location,
  weddingStyle,
  highlighted,
  highlightLocation,
  hideEditingContainer,
  contentPageName,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  return (
    <LocationBoxContainer
      backgroundColor={defaultStyles[weddingStyle].colors.mapMarker.background}
      iconColor={defaultStyles[weddingStyle].colors.mapMarker.icon}
      weddingStyle={weddingStyle}
      highlighted={highlighted}
      onClick={() => highlightLocation(index)}
      substituteLanguage={location.substituteLanguage}
    >
      {hideEditingContainer ? (
        renderInnerLocationBox(weddingStyle, location)
      ) : (
        <EditingContainerForLocation
          editingKeys={{ index: index, contentPage: contentPageName, elementType: 'locationItem' }}
          content={location}
          loadingSave={loadingSave}
          setLoadingSave={setLoadingSave}
        >
          {renderInnerLocationBox(weddingStyle, location)}
        </EditingContainerForLocation>
      )}
    </LocationBoxContainer>
  );
};

export default LocationBox;
