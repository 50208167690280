import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import boxShadows from '../../../../../../Shared/boxShadows';

const Container = styled.div`
  box-shadow: ${boxShadows.z2};
  background-color: #fff;
  border-radius: 0;
  border-top-left-radius: 12px;
  margin: 21px;
  padding: 9px;
  .MuiTextField-root {
    width: 100%;
  }
`;

const Remark = ({ remark, setRemark, disabled }) => {
  return (
    <Container>
      <TextField
        type="text"
        disabled={disabled}
        multiline
        minRows={4}
        variant="filled"
        value={remark}
        onChange={event => setRemark(event.target.value)}
        label={i18n.t(k.QUESTION_REMARK_MAX_1000)}
      />
    </Container>
  );
};

export default Remark;
