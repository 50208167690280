export default [
  {
    type: 'cake',
    images: [
      '/assets/content/cake/1.jpg',
      '/assets/content/cake/2.jpg',
      '/assets/content/cake/3.jpg',
      '/assets/content/cake/4.jpg',
      '/assets/content/cake/5.jpg',
      '/assets/content/cake/6.jpg',
      '/assets/content/cake/7.jpg',
      '/assets/content/cake/8.jpg',
      '/assets/content/cake/9.jpg',
    ],
  },
  {
    type: 'ceremony',
    images: [
      '/assets/content/ceremony/1.jpg',
      '/assets/content/ceremony/2.jpg',
      '/assets/content/ceremony/3.jpg',
      '/assets/content/ceremony/4.jpg',
      '/assets/content/ceremony/5.jpg',
      '/assets/content/ceremony/6.jpg',
      '/assets/content/ceremony/7.jpg',
      '/assets/content/ceremony/8.jpg',
      '/assets/content/ceremony/9.jpg',
      '/assets/content/ceremony/10.jpg',
      '/assets/content/ceremony/11.jpg',
      '/assets/content/ceremony/12.jpg',
      '/assets/content/ceremony/13.jpg',
      '/assets/content/ceremony/14.jpg',
      '/assets/content/ceremony/15.jpg',
      '/assets/content/ceremony/16.jpg',
      '/assets/content/ceremony/17.jpg',
      '/assets/content/ceremony/18.jpg',
      '/assets/content/ceremony/19.jpg',
      '/assets/content/ceremony/20.jpg',
      '/assets/content/ceremony/21.jpg',
      '/assets/content/ceremony/22.jpg',
      '/assets/content/ceremony/23.jpg',
      '/assets/content/ceremony/24.jpg',
      '/assets/content/ceremony/25.jpg',
      '/assets/content/ceremony/26.jpg',
    ],
  },
  {
    type: 'champagneReception',
    images: [
      '/assets/content/champagneReception/1.jpg',
      '/assets/content/champagneReception/2.jpg',
      '/assets/content/champagneReception/3.jpg',
      '/assets/content/champagneReception/4.jpg',
      '/assets/content/champagneReception/5.jpg',
      '/assets/content/champagneReception/6.jpg',
      '/assets/content/champagneReception/7.jpg',
      '/assets/content/champagneReception/8.jpg',
    ],
  },
  {
    type: 'dance',
    images: [
      '/assets/content/dance/1.jpg',
      '/assets/content/dance/2.jpg',
      '/assets/content/dance/3.jpg',
      '/assets/content/dance/4.jpg',
      '/assets/content/dance/5.jpg',
      '/assets/content/dance/6.jpg',
      '/assets/content/dance/7.jpg',
      '/assets/content/dance/8.jpg',
      '/assets/content/dance/9.jpg',
      '/assets/content/dance/10.jpg',
      '/assets/content/dance/11.jpg',
      '/assets/content/dance/12.jpg',
      '/assets/content/dance/13.jpg',
    ],
  },
  {
    type: 'food',
    images: [
      '/assets/content/food/1.jpg',
      '/assets/content/food/2.jpg',
      '/assets/content/food/3.jpg',
      '/assets/content/food/4.jpg',
      '/assets/content/food/5.jpg',
      '/assets/content/food/6.jpg',
      '/assets/content/food/7.jpg',
      '/assets/content/food/8.jpg',
      '/assets/content/food/9.jpg',
      '/assets/content/food/10.jpg',
      '/assets/content/food/11.jpg',
      '/assets/content/food/12.jpg',
    ],
  },
  {
    type: 'car',
    images: [
      '/assets/content/car/1.jpg',
      '/assets/content/car/2.jpg',
      '/assets/content/car/3.jpg',
      '/assets/content/car/4.jpg',
      '/assets/content/car/5.jpg',
      '/assets/content/car/6.jpg',
      '/assets/content/car/7.jpg',
      '/assets/content/car/8.jpg',
      '/assets/content/car/9.jpg',
      '/assets/content/car/10.jpg',
      '/assets/content/car/11.jpg',
      '/assets/content/car/12.jpg',
      '/assets/content/car/13.jpg',
      '/assets/content/car/14.jpg',
    ],
  },
  {
    type: 'game',
    images: [
      '/assets/content/game/1.jpg',
      '/assets/content/game/2.jpg',
      '/assets/content/game/3.jpg',
      '/assets/content/game/4.jpg',
      '/assets/content/game/5.jpg',
      '/assets/content/game/6.jpg',
      '/assets/content/game/7.jpg',
      '/assets/content/game/8.jpg',
      '/assets/content/game/9.jpg',
      '/assets/content/game/10.jpg',
    ],
  },
  {
    type: 'dresscode',
    images: [
      '/assets/content/dresscode/1.jpg',
      '/assets/content/dresscode/2.jpg',
      '/assets/content/dresscode/3.jpg',
      '/assets/content/dresscode/4.jpg',
      '/assets/content/dresscode/5.jpg',
      '/assets/content/dresscode/6.jpg',
      '/assets/content/dresscode/7.jpg',
    ],
  },
  {
    type: 'hotel',
    images: [
      '/assets/content/hotel/1.jpg',
      '/assets/content/hotel/2.jpg',
      '/assets/content/hotel/3.jpg',
      '/assets/content/hotel/4.jpg',
      '/assets/content/hotel/5.jpg',
      '/assets/content/hotel/6.jpg',
    ],
  },
  {
    type: 'photo',
    images: [
      '/assets/content/photo/1.jpg',
      '/assets/content/photo/2.jpg',
      '/assets/content/photo/3.jpg',
      '/assets/content/photo/4.jpg',
      '/assets/content/photo/5.jpg',
      '/assets/content/photo/6.jpg',
      '/assets/content/photo/7.jpg',
      '/assets/content/photo/8.jpg',
      '/assets/content/photo/9.jpg',
      '/assets/content/photo/10.jpg',
      '/assets/content/photo/11.jpg',
      '/assets/content/photo/12.jpg',
    ],
  },
  {
    type: 'present',
    images: [
      '/assets/content/present/1.jpg',
      '/assets/content/present/2.jpg',
      '/assets/content/present/3.jpg',
      '/assets/content/present/4.jpg',
    ],
  },
  {
    type: 'love',
    images: [
      '/assets/content/love/1.jpg',
      '/assets/content/love/2.jpg',
      '/assets/content/love/3.jpg',
      '/assets/content/love/4.jpg',
      '/assets/content/love/5.jpg',
      '/assets/content/love/6.jpg',
      '/assets/content/love/7.jpg',
      '/assets/content/love/8.jpg',
      '/assets/content/love/9.jpg',
      '/assets/content/love/10.jpg',
      '/assets/content/love/11.jpg',
      '/assets/content/love/12.jpg',
      '/assets/content/love/13.jpg',
      '/assets/content/love/14.jpg',
      '/assets/content/love/15.jpg',
      '/assets/content/love/16.jpg',
    ],
  },
];
