import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import GenericStyleContainer from '../../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import LogoLarge from '../../../Shared/Components/EureHochzeitOnlineLogo/LogoLarge';
import OuterContainer from '../../../Shared/Components/EureHochzeitOnline/OuterContainer';
import InnerContainer from '../../../Shared/Components/EureHochzeitOnline/InnerContainer';
import LegalFooter from '../../../Shared/Components/LegalFooter/LegalFooter';

const Content = styled.div`
  width: 500px;
  max-width: 80vw;
`;

const WeddingNotFoundPage = () => {
  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <InnerContainer>
          <Content>
            <LogoLarge></LogoLarge>
            <h2>{i18n.t(k.WEDDING_NOT_FOUND)}</h2>
            <p>
              {i18n.t(k.WEDDING_NOT_FOUND_TEXT)}
              <br></br>
              <br></br>
              {i18n.t(k.IF_ERROR_REPEATS)}{' '}
              <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
            </p>
          </Content>
        </InnerContainer>
      </OuterContainer>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default WeddingNotFoundPage;
