import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import defaultStyles from '../../DefaultStyles';

export default styled.div`
  position: relative;
  background-image: url(${defaultStyles.flower.assets.frame});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 150px;
  width: 300px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(0, -800px, 0)')};
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  @media (max-height: 980px) {
    margin-top: 30px;
    width: 260px;
    height: 440px;
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-top: -15px;
    height: 260px;
    width: 150px;
  }
  @media (orientation: portrait) and (max-height: ${breakpoints.mobileKeyboardHeight}) {
    opacity: 0;
  }
`;
