import React, { useEffect } from 'react';

import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import ContentCardWithMap from '../../Components/ContentCardWithMap/ContentCardWithMap';
import elementTypes from '../../Editing/Constants/elementTypes';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import { Helmet } from 'react-helmet';

const HotelPage = ({ scrollToTop, hotels, weddingId }) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Hotel`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {hotels.elements.map((hotelItem, index) => {
          switch (hotelItem.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={hotelItem.title}>
                  <ContentCardWithTitle
                    title={hotelItem.title}
                    subtitle={hotelItem.subtitle}
                    substituteLanguage={hotelItem.substituteLanguage}
                    editingKeys={{ index: index, contentPage: 'HOTEL', elementType: elementTypes.pageTitle.name }}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'HOTEL' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.locationMap.name:
              return (
                <ElementContainer key={hotelItem.elementType}>
                  <ContentCardWithMap locations={hotelItem.locations} contentPage="HOTEL"></ContentCardWithMap>
                </ElementContainer>
              );
            default:
              return null;
          }
        })}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default HotelPage;
