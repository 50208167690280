import React, { useContext } from 'react';
import styled from 'styled-components';
import LanguageSelect from '../../../LandingPage/Components/LanguageSelect';
import EditingContext from '../../../../Shared/Context/EditingContext';
import EditingContainerForLanguage from '../../Editing/Components/EditingContainer/EditingContainerForLanguage';
import WeddingContext from '../../../../Shared/Context/WeddingContext';

const Container = styled.div`
  position: fixed;
  bottom: 70px;
  right: 0;
  height: 170px;
  z-index: ${({ editingModeActive }) => (editingModeActive === 'true' ? '1200' : '1400')};
`;

const WeddingLanguageSelection = () => {
  const { editingModeActive } = useContext(EditingContext);
  const { weddingConfig } = useContext(WeddingContext);

  return (
    <Container editingModeActive={editingModeActive.toString()}>
      {(editingModeActive || weddingConfig.selectedLanguagesForWedding?.length > 1) && (
        <EditingContainerForLanguage>
          <LanguageSelect
            selectedLanguagesForWedding={weddingConfig.selectedLanguagesForWedding || ['de']}
          ></LanguageSelect>
        </EditingContainerForLanguage>
      )}
    </Container>
  );
};

export default WeddingLanguageSelection;
