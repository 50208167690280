import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

export default styled(LinearProgress)`
  margin: auto;
  bottom: 9px;
  width: 350px;
  max-width: 70vw;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
`;
