import React, { useContext, useEffect } from 'react';
import UserContext from '../../../../Shared/Context/UserContext';
import ContentPageOuterContainer from '../../Components/ContentPageOuterContainer';
import ContentPageInnerContainer from '../../Components/ContentPageInnerContainer';
import ElementContainer from '../../Components/ElementContainer';
import ContentCardWithTitle from '../../Components/ContentCardWithTitle';
import AddingContainer from '../../Editing/Components/AddingContainer/AddingContainerForCard';
import ContentCardWithInfoItem from '../../Components/ContentCardWithInfoItem';
import elementTypes from '../../Editing/Constants/elementTypes';
import getRoleOfUserInWedding from '../../../../Shared/FunctionUtils/getRoleOfUserInWedding';
import ContentCardContainer from '../../Components/ContentCardContainer';
import PresentElement from './Components/PresentElement';
import BackingOverview from './Components/BackingOverview';
import { Helmet } from 'react-helmet';

const PresentPage = ({ pageData, scrollToTop, weddingId }) => {
  const { fidiraUser } = useContext(UserContext);

  const showBackingGuests =
    getRoleOfUserInWedding(fidiraUser?.weddings, weddingId) === 'BRIDE' ||
    getRoleOfUserInWedding(fidiraUser?.weddings, weddingId) === 'GROOM';

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <ContentPageOuterContainer>
      <Helmet>
        <title>{`EureHochzeitOnline: ${weddingId} | Present`}</title>
      </Helmet>
      <ContentPageInnerContainer>
        {pageData.elements.map((item, index) => {
          switch (item.elementType) {
            case elementTypes.pageTitle.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithTitle
                    title={item.title}
                    subtitle={item.subtitle}
                    editingKeys={{ index: index, contentPage: 'PRESENT', elementType: elementTypes.pageTitle.name }}
                    substituteLanguage={item.substituteLanguage}
                  ></ContentCardWithTitle>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'PRESENT' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.infoItem.name:
              return (
                <ElementContainer key={item.title}>
                  <ContentCardWithInfoItem
                    title={item.title}
                    description={item.description}
                    imageUrl={item.imageUrl}
                    imagePosition={item.imagePosition}
                    substituteLanguage={item.substituteLanguage}
                    editingKeys={{
                      index: index,
                      contentPage: 'PRESENT',
                      elementType: elementTypes.infoItem.name,
                    }}
                  ></ContentCardWithInfoItem>
                  <AddingContainer editingKeys={{ index: index, contentPage: 'PRESENT' }}></AddingContainer>
                </ElementContainer>
              );
            case elementTypes.presentElement.name:
              return (
                <ContentCardContainer
                  key="1"
                  editingKeys={{ index: index, contentPage: 'PRESENT', elementType: elementTypes.presentElement.name }}
                  content={{ presents: item.presents }}
                  deletionDisabled
                >
                  <PresentElement presents={item.presents}></PresentElement>
                </ContentCardContainer>
              );
            default:
              return null;
          }
        })}
        {showBackingGuests && <BackingOverview></BackingOverview>}
      </ContentPageInnerContainer>
    </ContentPageOuterContainer>
  );
};

export default PresentPage;
