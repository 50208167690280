import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: 12px auto;
  justify-content: space-between;
  > div:first-child {
    width: 70%;
    margin: 0;
  }
  > div:last-child {
    width: 25%;
    margin: 0;
  }
`;
