import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DjSvg } from '../../../../../../_assets/svg/dj.svg';

const Container = styled.div`
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 150px;
    margin: 12px auto;
  }
`;

const EmptyState = () => {
  return (
    <Container>
      <DjSvg></DjSvg>
      <strong>{i18n.t(k.NO_MUSIC_SAVED)}</strong>
      <span>{i18n.t(k.SEARCH_FOR_SONGS)}</span>
    </Container>
  );
};

export default EmptyState;
