import styled from 'styled-components';
import breakpoints from '../../../../../Shared/breakpoints';

export default styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  margin: 20px auto 0px auto;
  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;
  }
`;
