import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import boxShadows from '../../../../../Shared/boxShadows';
import RsvpOverviewDialog from './GuestListOverview/RsvpOverviewDialog';

const Container = styled.div`
  overflow: hidden;
  background-color: #ffffffe0;
  border-radius: 0;
  border-top-left-radius: 12px;
  box-shadow: ${boxShadows.z2};
  border: none;
  padding-bottom: 21px;
`;

const GuestListOverview = ({ pageData }) => {
  const [showGuestListDialog, setShowGuestListDialog] = useState(false);

  return (
    <Container className="color-background-white">
      <p>
        <strong>{i18n.t(k.OVERVIEW_OF_ALL_RSVPS)}</strong>
        <br></br>
        <span>{i18n.t(k.ONLY_VISIBLE_FOR_WEDDING_COUPLE)}</span>
      </p>
      <Button onClick={() => setShowGuestListDialog(true)}>{i18n.t(k.SHOW_ALL_RSVPS)}</Button>
      {showGuestListDialog && (
        <RsvpOverviewDialog
          pageData={pageData}
          showRsvpOverviewDialog={showGuestListDialog}
          setShowRsvpOverviewDialog={setShowGuestListDialog}
        ></RsvpOverviewDialog>
      )}
    </Container>
  );
};

export default GuestListOverview;
