export default weddingContent => {
  // Helper function to find common keys in all objects within an array
  function findCommonKeys(array) {
    const allKeys = array.map(item => Object.keys(item));
    return allKeys.reduce((commonKeys, keys) => commonKeys.filter(key => keys.includes(key)));
  }

  // Recursive function to process the object
  function recursiveProcess(obj) {
    if (Array.isArray(obj)) {
      obj.forEach(item => recursiveProcess(item));
    } else if (typeof obj === 'object' && obj !== null) {
      if ('i18n' in obj && Array.isArray(obj.i18n)) {
        // Sort the i18n array alphabetically by 'lng'
        obj.i18n.sort((a, b) => a.lng.localeCompare(b.lng));

        // Find common keys in the i18n objects
        const commonKeys = findCommonKeys(obj.i18n);
        commonKeys.forEach(key => {
          if (key !== 'lng') {
            delete obj[key];
          }
        });
      }

      // Process all keys within the object, handling 'substituteLanguage' and recursing
      Object.keys(obj).forEach(key => {
        if (key === 'substituteLanguage') {
          delete obj[key];
        } else {
          recursiveProcess(obj[key]);
        }
      });
    }
  }

  recursiveProcess(weddingContent);
  return weddingContent;
};
