export default (state, action) => {
  switch (action.type) {
    case 'START_LOAD_PHOTOS':
      return {
        ...state,
        loadingPhotos: true,
      };
    case 'END_LOAD_PHOTOS':
      return {
        ...state,
        photos: action.photos,
        loadingPhotos: false,
      };
    case 'START_DELETE_PHOTO':
      return {
        ...state,
        loadingDelete: true,
      };
    case 'END_DELETE_PHOTO':
      return {
        ...state,
        photos: action.photos,
        fullscreenPhoto: action.photos.length === 0 ? null : Math.max(state.fullscreenPhoto - 1, 0),
        loadingDelete: false,
      };
    case 'ADD_PHOTO':
      return {
        ...state,
        photos: [action.photo, ...state.photos],
      };
    case 'ADD_LIKE':
      return {
        ...state,
        photos: state.photos.map(photo => {
          if (photo.id === action.target) {
            photo.likedByUser = true;
            photo.numberOfLikes++;
          }
          return photo;
        }),
      };
    case 'REMOVE_LIKE':
      return {
        ...state,
        photos: state.photos.map(photo => {
          if (photo.id === action.target) {
            photo.likedByUser = false;
            photo.numberOfLikes--;
          }
          return photo;
        }),
      };
    case 'TOGGLE_FULLSCREEN':
      return {
        ...state,
        fullscreenPhoto: action.index,
      };
    default:
      return state;
  }
};
