import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../DefaultStyles';
import WeddingDate from './Components/WeddingDate';
import useDynamicFontSize from '../../HookUtils/useDynamicFontSize';
import Element from './Components/Element';
import adjustColor from '../adjustColor';

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.blossom.background.colors[1]}, ${defaultStyles.blossom.background.colors[0]})`};
`;

const WeddingTitleInnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledWeddingTitle = styled.h1`
  margin-top: 100px;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  font-family: ${defaultStyles.blossom.headerFont} !important;
  color: ${adjustColor(defaultStyles.blossom.colors.primary.base, 200)} !important;
  z-index: 0;
  &:before {
    content: "${props => props.title}";
    position: absolute;
    left: 0;
    right: 0;
    -webkit-text-stroke: 1vw ${defaultStyles.blossom.colors.primary.base};
    z-index: -1;
  }
`;

const StyledWeddingDate = styled(WeddingDate)`
  text-align: center;
  margin: auto;
`;

const BlossomOnboardingContainer = ({ visible, nameOfBride, nameOfGroom, weddingDate }) => {
  const { fontSize, textRef } = useDynamicFontSize(`${nameOfBride} & ${nameOfGroom}`);

  return (
    <Container visible={visible}>
      <Element visible={visible}></Element>
      {nameOfBride && nameOfGroom && (
        <WeddingTitleInnerContainer>
          <StyledWeddingTitle
            ref={textRef}
            style={{ fontSize: `${fontSize}px`, whiteSpace: 'nowrap' }}
            title={`${nameOfBride} & ${nameOfGroom}`}
          >
            {nameOfBride} &amp; {nameOfGroom}
          </StyledWeddingTitle>
          <StyledWeddingDate>{weddingDate}</StyledWeddingDate>
        </WeddingTitleInnerContainer>
      )}
    </Container>
  );
};

export default BlossomOnboardingContainer;
