import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import defaultStyles from '../DefaultStyles';
import WeddingDate from './Components/WeddingDate';
import adjustColor from '../adjustColor';

const Container = styled.div`
  background-color: ${defaultStyles.blossom.colors.navigation.inverseColor};
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  a {
    font-family: ${defaultStyles.blossom.bodyFont};
    font-weight: bold;
  }
  nav {
    margin-top: 200px;
  }
`;

const WeddingTitle = styled.div`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 50px !important;
  font-family: ${defaultStyles.blossom.headerFont} !important;
  color: ${adjustColor(defaultStyles.blossom.colors.primary.base, 200)} !important;
  z-index: 0;
  font-weight: normal;
  line-height: 1.25;
  position: absolute;
  width: 100%;
  &:before {
    content: "${props => props.title}";
    position: absolute;
    left: 0;
    -webkit-text-stroke: 9px ${defaultStyles.blossom.colors.primary.base};
    z-index: -1;
    width: 100%;
  }
`;

const CustomLogo = styled.div`
  height: 100px;
  width: 300px;
  margin: 3px auto;
  max-width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledWeddingDate = styled(WeddingDate)`
  font-size: 15px !important;
  text-align: center;
  color: white !important;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  text-align: center;
  letter-spacing: 12px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const BlossomMenuContainer = ({ weddingId, weddingConfig, closeDrawer, children }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container>
      <StyledNavLink to={{ pathname: `/${weddingId}/` }} onClick={() => closeDrawer()}>
        {weddingConfig.useCustomLogo ? (
          <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
        ) : (
          <>
            {weddingConfig && nameOfGroom && nameOfBride && (
              <>
                <WeddingTitle title={`${nameOfBride} & ${nameOfGroom}`}>
                  {nameOfBride} &amp; {nameOfGroom}
                </WeddingTitle>
                <StyledWeddingDate>{weddingDate}</StyledWeddingDate>
              </>
            )}
          </>
        )}
      </StyledNavLink>
      <div>{children}</div>
    </Container>
  );
};

export default BlossomMenuContainer;
