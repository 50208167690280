import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import WeddingContext from '../../../Context/WeddingContext';

import Form from '../Components/Form';
import TextField from '../Components/TextField';
import Button from '../Components/Button';
import ButtonContainer from '../Components/ButtonContainer';
import Subheader from '../Components/Subheader';

const Username = ({ visible, onNext, onBack, displayName }) => {
  const weddingContext = useContext(WeddingContext);
  const weddingConfig = weddingContext ? weddingContext.weddingConfig : null;
  const { handleSubmit, register, errors, setValue } = useForm({});
  const inputRef = useRef(null);

  useEffect(() => {
    if (visible && inputRef.current !== document.activeElement) {
      inputRef.current.focus();
    }
  }, [visible]);

  return (
    <Form visible={visible} onSubmit={handleSubmit(onNext)}>
      {weddingConfig && (
        <Subheader>
          {i18n.t(k.WELCOME_TO_WEDDING_OF)} {weddingConfig.nameOfBride} &amp;
          {weddingConfig.nameOfGroom}
          {i18n.t(k.ENTER_WEDDING_CODE)}
        </Subheader>
      )}

      <RHFInput
        as={
          <TextField
            type="text"
            variant="filled"
            error={!!errors.weddingCode}
            helperText={errors.weddingCode ? i18n.t(k.ENTER_WEDDING_CODE_SHORT) : ''}
            label={i18n.t(k.ENTER_WEDDING_CODE_SHORT)}
            inputRef={inputRef}
          />
        }
        rules={{
          required: true,
        }}
        mode="onSubmit"
        name="weddingCode"
        register={register}
        setValue={setValue}
      ></RHFInput>
      <ButtonContainer>
        <Button onClick={onBack} color="secondary" variant="contained">
          {i18n.t(k.BACK)}
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {i18n.t(k.NEXT)}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default Username;
