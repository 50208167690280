import styled from 'styled-components';

export default styled.div`
  width: 80vw;
  height: 75vh;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-color: black !important;
`;
