import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  transform: ${({ visible }) => (visible ? 'rotate(255deg)' : 'rotate(255deg) translate3d(300px, 900px, 0)')};
  top: -630px;
  right: -200px;
  @media (max-width: ${breakpoints.md}) {
    top: -630px;
    right: -450px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    transform: ${({ visible }) => (visible ? 'rotate(337deg)' : 'rotate(337deg) translate3d(500px, 0, 0)')};
    top: -130px;
    right: -370px;
  }
`;
