import styled from 'styled-components';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 100%;
  width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: ${breakpoints.xs}) {
    height: 100%;
    width: 100%;
  }
`;
