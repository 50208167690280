import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export default styled(IconButton)`
  background-color: transparent !important;
  position: absolute;
  bottom: 0;
  right: 0;
  svg {
    width: 40px;
    height: 40px;
    fill: #ffffff;
  }
`;
