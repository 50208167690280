import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';
import adjustColor from '../../adjustColor';

export default styled.h1`
  margin-top: 100px;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  font-family: ${defaultStyles.blossom.headerFont} !important;
  color: ${adjustColor(defaultStyles.blossom.colors.primary.base, 200)} !important;
  z-index: 0;
  &:before {
    content: "${props => props.title}";
    position: absolute;
    left: 0;
    right: 0;
    -webkit-text-stroke: 1vw ${defaultStyles.blossom.colors.primary.base};
    z-index: -1;
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: 48px !important;
    max-width: 80vw;
    white-space: wrap !important;
    margin-bottom: 48px;
  }
`;
