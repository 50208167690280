import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import defaultStyles from '../DefaultStyles';
import breakpoints from '../../breakpoints';

import WeddingDate from './Components/WeddingDate';
import HorizontalLine from './Components/HorizontalLine';

const Container = styled.div`
  background-color: ${({ usestartphoto }) =>
    usestartphoto ? defaultStyles.klassisch.colors.button.primaryColor : 'transparent'};
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  border-right: solid 9px ${defaultStyles.klassisch.colors.primary.onDefaultBackground};
  width: calc(100% - 9px);
  @media (max-width: ${breakpoints.md}) {
    background-color: ${defaultStyles.klassisch.background.colors[1]};
  }
  a {
    font-family: ${defaultStyles.klassisch.bodyFont};
    font-weight: bold;
    @media (max-height: 980px) {
      > div {
        margin-top: 10px;
      }
      > p {
        margin: 3px;
      }
    }
  }
`;

const WeddingTitle = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 40px !important;
  text-transform: uppercase;
  font-family: ${defaultStyles.klassisch.headerFont} !important;
  color: ${defaultStyles.klassisch.colors.primary.base} !important;
  font-weight: normal;
  @media (max-height: 980px) {
    font-size: 25px !important;
  }
`;

const CustomLogo = styled.div`
  height: 90px;
  width: 280px;
  margin: 18px auto;
  max-width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledWeddingDate = styled(WeddingDate)`
  font-size: 15px !important;
  text-align: center;
  margin-bottom: 12px !important;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
const StyledHorizontalLine = styled(HorizontalLine)`
  width: 80%;
  margin: auto !important;
`;

const KlassischMenuContainer = ({ weddingId, weddingConfig, closeDrawer, children }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container usestartphoto={weddingConfig.useStartPhoto}>
      <StyledNavLink to={{ pathname: `/${weddingId}/` }} onClick={() => closeDrawer()}>
        {weddingConfig.useCustomLogo ? (
          <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
        ) : (
          <>
            {weddingConfig && nameOfGroom && nameOfBride && (
              <>
                <WeddingTitle>
                  {nameOfBride} <br></br> &amp; <br></br> {nameOfGroom}
                </WeddingTitle>
                <StyledWeddingDate>{weddingDate}</StyledWeddingDate>
              </>
            )}
          </>
        )}
      </StyledNavLink>
      <StyledHorizontalLine></StyledHorizontalLine>
      <div>{children}</div>
    </Container>
  );
};

export default KlassischMenuContainer;
