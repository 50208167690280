import React from 'react';
import styled from 'styled-components';

import { ReactComponent as UserSvg } from '../../../../../_assets/svg/user.svg';
import { ReactComponent as GroomSvg } from '../../../../../_assets/svg/groom.svg';
import { ReactComponent as BrideSvg } from '../../../../../_assets/svg/bride.svg';

const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  margin: 12px;
  fill: ${({ styles }) => styles.textColor};
  background-color: ${({ userRole, styles }) => (userRole === 'GUEST' ? `${styles.textColor}33` : 'transparent')};
  border-radius: 50%;
`;

const UserAvatar = ({ styleConfig, userRole }) => {
  return (
    <AvatarContainer userRole={userRole} styles={styleConfig}>
      {userRole === 'GUEST' && <UserSvg></UserSvg>}
      {userRole === 'GROOM' && <GroomSvg></GroomSvg>}
      {userRole === 'BRIDE' && <BrideSvg></BrideSvg>}
    </AvatarContainer>
  );
};

export default UserAvatar;
