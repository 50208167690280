import i18n from 'i18next';
import k from '../../i18n/keys';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import axios from 'axios';

import getSearchParamsAsObject from '../../Shared/FunctionUtils/getSearchParamsAsObject';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import LogoLarge from '../../Shared/Components/EureHochzeitOnlineLogo/LogoLarge';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import OuterContainer from '../../Shared/Components/EureHochzeitOnline/OuterContainer';
import InnerContainer from '../../Shared/Components/EureHochzeitOnline/InnerContainer';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';

const StyledLinearProgress = styled(LinearProgress)`
  width: 300px;
  max-width: 70vw;
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
`;

const UnsubscribePage = () => {
  const [loadingDecryptValues, setLoadingDecryptValues] = useState(true);
  const [errorFromBackend, setErrorFromBackend] = useState(false);

  useEffect(() => {
    const decryptData = async () => {
      try {
        setLoadingDecryptValues(true);
        const weddingData = getSearchParamsAsObject(window.location.search);
        const arrayOfStringsToBeDecrypted = [weddingData.u, weddingData.c];
        const decryptedDataFromUrl = await axios.post(`util-decrypt/`, {
          arrayOfStringsToBeDecrypted: arrayOfStringsToBeDecrypted,
        });
        await axios.patch(`user-update/`, {
          updateType: 'UNSUBSCRIBE_USER_FROM_MAILINGS',
          username: decryptedDataFromUrl.data.decrypted[0],
          campaignId: decryptedDataFromUrl.data.decrypted[1],
          role: weddingData.r,
          weddingId: weddingData.w,
        });
        setLoadingDecryptValues(false);
      } catch (error) {
        setErrorFromBackend(true);
        setLoadingDecryptValues(false);
      }
    };
    decryptData();
  }, []);

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <LanguageSelect></LanguageSelect>
        <InnerContainer>
          <LogoLarge></LogoLarge>
          {loadingDecryptValues && <StyledLinearProgress visible={loadingDecryptValues.toString()} />}
          {!loadingDecryptValues && !errorFromBackend && (
            <div>
              <strong>
                {i18n.t(k.HELLO)}
                {i18n.t(k._)}
              </strong>
              <p>{i18n.t(k.UNSUBSCRIBED_SUCCESSFULLY)}</p>
            </div>
          )}
          {!loadingDecryptValues && errorFromBackend && (
            <div>
              <strong>
                {i18n.t(k.HELLO)}
                {i18n.t(k._)}
              </strong>
              <p>{i18n.t(k.ERROR_OCCURED)}</p>
              <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
            </div>
          )}
        </InnerContainer>
      </OuterContainer>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default UnsubscribePage;
