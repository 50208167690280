import { useLayoutEffect, useState, useRef } from 'react';

const useDynamicFontSize = (text, maxWidthVw = 60) => {
  const [fontSize, setFontSize] = useState(200); // Initial base font size
  const textRef = useRef(null);

  const updateFontSize = () => {
    if (textRef.current) {
      const element = textRef.current;
      const maxAllowedWidth = (window.innerWidth * maxWidthVw) / 100;

      // Get the current width of the text element
      const textWidth = element.scrollWidth;

      // Calculate the scaling factor
      const scalingFactor = maxAllowedWidth / textWidth;

      // Calculate a new font size but only update if it has changed
      let newFontSize = Math.min(700, Math.max(12, fontSize * scalingFactor)); // Limit between 12px and 700px

      // To avoid frequent updates, check if the font size has changed by at least 1 pixel
      if (Math.abs(newFontSize - fontSize) > 1) {
        setFontSize(newFontSize);
      }
    }
  };

  useLayoutEffect(() => {
    // Use requestAnimationFrame to ensure DOM is updated before measurement
    const handleResize = () => requestAnimationFrame(updateFontSize);

    // Trigger the initial font size calculation
    handleResize();

    // Update font size on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, maxWidthVw, fontSize]);

  return { fontSize, textRef };
};

export default useDynamicFontSize;
