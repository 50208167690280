import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import GenericStyleContainer from '../../../../../Shared/WeddingStyles/GenericStyleContainer';
import { ReactComponent as Success } from '../../../../../_assets/svg/success.svg';
import defaultStyles from '../../../../../Shared/WeddingStyles/DefaultStyles';
import styled from 'styled-components';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import k from '../../../../../i18n/keys';
import axios from 'axios';
import breakpoints from '../../../../../Shared/breakpoints';

const ErrorText = styled.p`
  color: darkred !important;
  font-size: 14px !important;
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
  button {
    width: 150px;
    height: 42px;
    svg {
      height: 25px;
    }
  }
`;

const IconContainer = styled.div`
  margin: 9px;
  svg {
    height: 72px;
    width: 72px;
    fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite};
    @media (max-width: ${breakpoints.sm}) {
      height: 48px;
      width: 48px;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VoucherDialog = ({ setAppliedVoucher, setShowVoucherDialog, showVoucherDialog }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccesDialog, setShowSuccesDialog] = useState(false);
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';

  const handleSave = async () => {
    if (!currentValue) {
      setShowError(true);
      return;
    }
    if (weddingContext) {
      setLoadingVoucher(true);
      const { data: voucher } = await axios.post(`${weddingContext.weddingId}/payment-service/get-voucher-data`, {
        code: currentValue,
      });
      const voucherExpired = new Date() > new Date(voucher.validUntil);
      if (voucher && !voucherExpired) {
        setAppliedVoucher({
          code: currentValue,
          validUntil: voucher.validUntil,
          voucherDiscount: voucher.voucherDiscount,
          voucherType: voucher.voucherType,
          voucherCampaign: voucher.voucherCampaign,
        });
        setShowSuccesDialog(true);
        setLoadingVoucher(false);
        setShowVoucherDialog(false);
      } else {
        setLoadingVoucher(false);
        setShowError(true);
      }
    }
  };

  const handleChange = value => {
    setCurrentValue(value);
    setShowError(false);
  };

  return (
    <>
      <Dialog
        open={showVoucherDialog}
        onClose={() => {
          setCurrentValue('');
          setShowError(false);
          setShowVoucherDialog(false);
        }}
        disableEscapeKeyDown
      >
        <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
          <DialogTitle className="color-background-white">
            <span>{i18n.t(k.ENTER_VOUCHER_CODE)}</span>
          </DialogTitle>
          <DialogContent className="color-background-white">
            <TextField
              type="text"
              variant="filled"
              value={currentValue}
              onChange={event => handleChange(event.target.value)}
              label={i18n.t(k.VOUCHER_CODE)}
            />
            <ErrorText>{showError && i18n.t(k.INVALID_CODE)}</ErrorText>
          </DialogContent>
          <StyledDialogActions className="color-background-white">
            <Button
              onClick={() => {
                setCurrentValue('');
                setShowError(false);
                setShowVoucherDialog(false);
              }}
              disabled={loadingVoucher}
              color="secondary"
              variant="contained"
            >
              {i18n.t(k.CANCEL)}
            </Button>
            <Button
              onClick={() => {
                handleSave();
              }}
              color="primary"
              variant="contained"
              disabled={loadingVoucher}
              autoFocus
            >
              {loadingVoucher ? <CircularProgress size="15"></CircularProgress> : <>{i18n.t(k.SAVE)}</>}
            </Button>
          </StyledDialogActions>
        </GenericStyleContainer>
      </Dialog>
      <Dialog open={showSuccesDialog} disableEscapeKeyDown>
        <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
          <StyledDialogContent className="color-background-white">
            <IconContainer weddingStyle={weddingStyle}>
              <Success></Success>
            </IconContainer>
            <span>{i18n.t(k.VOUCHER_USED_SUCCESSFULLY)}</span>
          </StyledDialogContent>
          <DialogActions className="color-background-white">
            <Button
              onClick={() => {
                setShowSuccesDialog(false);
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              <>{i18n.t(k.OK)}</>
            </Button>
          </DialogActions>
        </GenericStyleContainer>
      </Dialog>
    </>
  );
};

export default VoucherDialog;
