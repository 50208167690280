import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 400px;
  max-height: 23vh;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  background-image: url(${defaultStyles.klassisch.assets.heart});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-height: 980px) {
    height: 300px;
    max-height: 17vh;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 200px;
    margin-top: 0;
  }
`;
