import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import boxShadows from '../../../boxShadows';

export default styled(IconButton)`
  margin: 6px;
  box-shadow: ${boxShadows.z1};
  svg {
    height: 25px;
    width: 25px;
  }
`;
