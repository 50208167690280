import React from 'react';
import styled from 'styled-components';

import ContentCardContainer from './ContentCardContainer';
import breakpoints from '../../../Shared/breakpoints';

const InnerContainer = styled.div`
  padding: 12px;
  border-bottom: solid 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 12px;
  flex: 8;
`;
const Title = styled.h2`
  margin-bottom: 0;
`;
const Description = styled.p`
  white-space: pre-wrap;
`;
const ImageContainer = styled.div`
  flex: 4;
  min-width: 150px;
`;
const Image = styled.div`
  min-height: 500px;
  height: 100%;
  width: 100%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: ${breakpoints.xs}) {
    min-height: 300px;
  }
`;

const ContentCardWithLargePhoto = ({
  title,
  description,
  imageUrl,
  editingKeys,
  hideEditingContainer,
  substituteLanguage,
}) => {
  return (
    <ContentCardContainer
      hideEditingContainer={hideEditingContainer}
      editingKeys={editingKeys}
      substituteLanguage={substituteLanguage}
      content={{ title: title, description: description, imageUrl: imageUrl }}
    >
      <InnerContainer>
        {imageUrl && (
          <ImageContainer>
            <Image imageUrl={imageUrl}></Image>
          </ImageContainer>
        )}
        <TextContainer>
          {title && <Title imageUrl={imageUrl}>{title}</Title>}
          {description && <Description imageUrl={imageUrl}>{description}</Description>}
        </TextContainer>
      </InnerContainer>
    </ContentCardContainer>
  );
};

export default ContentCardWithLargePhoto;
