import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export default styled(IconButton)`
  z-index: 1;
  opacity: ${({ hidden }) => (hidden ? '0.4' : '1')};
  svg {
    fill: white;
    width: 35px;
    height: 35px;
  }
`;
