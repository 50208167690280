import i18n from 'i18next';
import k from './../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';

const Container = styled.div`
  text-align: left;
  width: 100%;
  padding: 6px;
  font-size: 14px;
  background-color: #fafafa;
  h2 {
    color: #a54c4c;
  }
  h3 {
    color: #a15757;
  }
  a {
    color: #a15757;
  }
`;
const InnerContainer = styled.div`
  margin: auto;
  width: 700px;
  max-width: 99vw;
`;

const ImprintPage = () => {
  return (
    <Container>
      <LanguageSelect></LanguageSelect>
      <InnerContainer>
        <div>
          <h2>{i18n.t(k.IMPRINT_00)}</h2>
          <div>
            <p>
              <strong>{i18n.t(k.IMPRINT_01)}</strong>
              <br></br>
              <strong>{i18n.t(k.IMPRINT_02)}</strong>
            </p>
            <p>
              {i18n.t(k.IMPRINT_03)} <br />
              {i18n.t(k.IMPRINT_04)}
              <br />
              <br />
              {i18n.t(k.IMPRINT_05)} <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
              <br />
              {i18n.t(k.IMPRINT_06)} <a href="https://www.eurehochzeitonline.com">{i18n.t(k.IMPRINT_07)}</a>
            </p>
            <p>
              {i18n.t(k.IMPRINT_08)} <br />
            </p>
            <p>
              {i18n.t(k.IMPRINT_09)} <br />
              Osman Agirbas <br />
              Benjamin Girr
            </p>
          </div>
        </div>
      </InnerContainer>
    </Container>
  );
};

export default ImprintPage;
