import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import { TextField } from '@material-ui/core';
import React from 'react';

const GuestName = ({ guestName, changeGuestName, disabled }) => {
  return (
    <TextField
      type="text"
      variant="filled"
      label={i18n.t(k.NAME_OF_GUEST)}
      disabled={disabled}
      value={guestName}
      onChange={changeGuestName}
    />
  );
};

export default GuestName;
