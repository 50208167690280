import React, { useState, useContext, useEffect } from 'react';
import { GoogleMap, OverlayView } from '@react-google-maps/api';

import WeddingContext from '../../../../Shared/Context/WeddingContext';
import defaultStyles from '../../../../Shared/WeddingStyles/DefaultStyles';

import { ReactComponent as ChurchSvg } from '../../../../_assets/svg/map-markers/church.svg';
import { ReactComponent as DinnerSvg } from '../../../../_assets/svg/map-markers/dinner.svg';
import { ReactComponent as CoupleBalloonSvg } from '../../../../_assets/svg/map-markers/couple-balloon.svg';
import { ReactComponent as CoupleCakeSvg } from '../../../../_assets/svg/map-markers/couple-cake.svg';
import { ReactComponent as CityHallSvg } from '../../../../_assets/svg/map-markers/city-hall.svg';
import { ReactComponent as HotelSvg } from '../../../../_assets/svg/map-markers/hotel.svg';
import { ReactComponent as ParkingSvg } from '../../../../_assets/svg/map-markers/parking.svg';
import { ReactComponent as CarRentalSvg } from '../../../../_assets/svg/map-markers/car-rental.svg';

import Container from './Components/Container';
import OverlayContainer from './Components/OverlayContainer';
import IconContainer from './Components/IconContainer';
import Locations from './Components/Locations';
import StyleWrapperForGoogleMap from './Components/StyleWrapperForGoogleMap';

const ContentCardWithMap = ({ locations, contentPage }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [highlightedLocation, setHighlightedLocation] = useState(null);
  const [map, setMap] = useState(null);
  const { weddingConfig } = useContext(WeddingContext);
  const { weddingStyle } = weddingConfig;

  const handleMapLoad = map => {
    setMap(map);
    const bounds = new window.google.maps.LatLngBounds();
    if (locations.length > 0) {
      locations.map(location => bounds.extend(new window.google.maps.LatLng(location.latitude, location.longitude)));
    } else {
      bounds.extend(new window.google.maps.LatLng({ lat: 48.15228, lng: 11.528074 }));
    }
    map.fitBounds(bounds);
    window.google.maps.event.addListenerOnce(map, 'idle', function() {
      if (locations.length > 0) {
        if (map.getZoom() > 15) map.setZoom(15);
      } else {
        map.setZoom(5);
      }
    });
    setMapLoaded(true);
  };

  const highlightLocation = locationIndex => {
    setHighlightedLocation(locationIndex);
  };

  useEffect(() => {
    if (map) {
      handleMapLoad(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  return (
    <Container>
      <StyleWrapperForGoogleMap backgroundColor={defaultStyles[weddingStyle].colors.mapMarker.background}>
        <GoogleMap
          id="wedding-map"
          zoom={15}
          gestureHandling="cooperative"
          clickableIcons={false}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}
          onLoad={handleMapLoad}
        >
          {mapLoaded &&
            locations.map((location, index) => (
              <OverlayView
                key={location.name}
                position={{ lat: location.latitude, lng: location.longitude }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <OverlayContainer
                  onClick={() => highlightLocation(index)}
                  backgroundColor={defaultStyles[weddingStyle].colors.mapMarker.background}
                  iconColor={defaultStyles[weddingStyle].colors.mapMarker.icon}
                  highlighted={highlightedLocation === index}
                >
                  <IconContainer
                    backgroundColor={defaultStyles[weddingStyle].colors.mapMarker.background}
                    iconColor={defaultStyles[weddingStyle].colors.mapMarker.icon}
                  >
                    {location.iconType === 'church' && <ChurchSvg></ChurchSvg>}
                    {location.iconType === 'dinner' && <DinnerSvg></DinnerSvg>}
                    {location.iconType === 'couple-cake' && <CoupleCakeSvg></CoupleCakeSvg>}
                    {location.iconType === 'couple-balloon' && <CoupleBalloonSvg></CoupleBalloonSvg>}
                    {location.iconType === 'city-hall' && <CityHallSvg></CityHallSvg>}
                    {location.iconType === 'hotel' && <HotelSvg></HotelSvg>}
                    {location.iconType === 'parking' && <ParkingSvg></ParkingSvg>}
                    {location.iconType === 'car-rental' && <CarRentalSvg></CarRentalSvg>}
                  </IconContainer>
                  <span>{location.name}</span>
                </OverlayContainer>
              </OverlayView>
            ))}
        </GoogleMap>
      </StyleWrapperForGoogleMap>
      <Locations
        locations={locations}
        highlightLocation={highlightLocation}
        highlightedLocation={highlightedLocation}
        weddingStyle={weddingStyle}
        contentPage={contentPage}
      ></Locations>
    </Container>
  );
};

export default ContentCardWithMap;
