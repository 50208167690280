import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../DefaultStyles';
import breakpoints from '../../breakpoints';
import WeddingTitle from './Components/WeddingTitle';
import WeddingDate from './Components/WeddingDate';
import TopLeft from './Components/TopLeft';
import TopRight from './Components/TopRight';
import BottomLeft from './Components/BottomLeft';
import BottomRight from './Components/BottomRight';

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.modern.background.colors[1]}, ${defaultStyles.modern.background.colors[0]})`};
`;

const TopCenter = styled.div`
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(0, -800px, 0)')};
  @media (orientation: portrait) and (max-height: ${breakpoints.mobileKeyboardHeight}) {
    opacity: 0;
  }
`;

const ModernOnboardingContainer = ({ visible, nameOfBride, nameOfGroom, weddingDate }) => {
  return (
    <Container visible={visible}>
      <TopLeft visible={visible} />
      <TopRight visible={visible} />
      {nameOfBride && nameOfGroom && (
        <TopCenter visible={visible}>
          <WeddingTitle>
            {nameOfBride} &amp; {nameOfGroom}
          </WeddingTitle>
          <WeddingDate>{weddingDate}</WeddingDate>
        </TopCenter>
      )}
      <BottomLeft visible={visible} />
      <BottomRight visible={visible} />
    </Container>
  );
};

export default ModernOnboardingContainer;
