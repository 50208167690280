import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import { Link } from 'react-scroll';
import { Button } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
  }
`;

const LinkContainer = styled.div``;

const StyledButton = styled(Button)`
  background-color: #ffa9ad;
  margin-left: 18px;
  font-weight: bold;
  span {
    transition-property: color;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
  }
  :hover {
    span {
      color: white;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    max-width: 300px;
    margin: auto;
    margin-top: 6px;
    > span {
      font-size: 14px;
    }
  }
`;

const StyledLink = styled(Link)`
  margin: 0 12px;
  cursor: pointer;
  transition-property: color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  padding: 6px 8px;
  box-sizing: border-box;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  min-height: 31px;
  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 16px;
    margin: 0 3px;
    padding: 6px 9px;
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: 14px;
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const StyledBlogLink = styled.a`
  margin: 0 12px;
  cursor: pointer;
  transition-property: color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  padding: 6px 8px;
  box-sizing: border-box;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  min-height: 31px;
  text-decoration: none;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 16px;
    margin: 0 3px;
    padding: 6px 9px;
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: 14px;
  }
`;

const Navigation = ({ scrolled }) => {
  const { search } = useLocation();
  return (
    <Container className="color-background-white">
      <LinkContainer>
        <StyledLink to="features" spy={true} smooth={true} duration={500} offset={-60}>
          {i18n.t(k.FUNCTIONS)}
        </StyledLink>
        <StyledLink to="design" spy={true} smooth={true} duration={500} offset={-60}>
          {i18n.t(k.DESIGNS)}
        </StyledLink>
        <StyledLink to="pricing" spy={true} smooth={true} duration={500} offset={-60}>
          {i18n.t(k.PRICES)}
        </StyledLink>
        <StyledLink to="faq" spy={true} smooth={true} duration={500} offset={-60}>
          {i18n.t(k.FAQ)}
        </StyledLink>
        <StyledBlogLink href="https://blog.eurehochzeitonline.com/" target="_blank" rel="noopener noreferrer">
          {i18n.t(k.BLOG)}
        </StyledBlogLink>
      </LinkContainer>
      <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
        <StyledButton className={scrolled ? 'scrolled' : ''} color="primary" variant="contained">
          {i18n.t(k.CREATE_NOW_FREE)}
        </StyledButton>
      </NavLink>
    </Container>
  );
};

export default Navigation;
