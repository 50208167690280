import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

export default styled.h2`
  margin: 80px 0 0 0;
  width: 100%;
  font-size: 42px !important;
  @media (max-width: ${breakpoints.md}) {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: 18px !important;
  }
`;
