import styled from 'styled-components';

export default styled.span`
  color: white !important;
  font-size: 14px !important;
  line-height: 1;
  margin: 0;
  z-index: 1;
  font-weight: bold;
`;
