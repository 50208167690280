import React from 'react';

import Ampersand from './Ampersand';
import Headline from './Headline';
import Frame from './Frame';
import WeddingDate from './WeddingDate';

const WeddingTitle = ({ nameOfBride, nameOfGroom, visible, weddingDate }) => {
  const maxCharacters = Math.max(nameOfBride.length, nameOfGroom.length, 7);
  return (
    <>
      {nameOfBride && nameOfGroom && (
        <Frame visible={visible}>
          <Headline characters={maxCharacters}>{nameOfBride}</Headline>
          <Ampersand characters={maxCharacters}>&amp;</Ampersand>
          <Headline characters={maxCharacters}>{nameOfGroom}</Headline>
          <WeddingDate>{weddingDate}</WeddingDate>
        </Frame>
      )}
    </>
  );
};

export default WeddingTitle;
