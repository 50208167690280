import styled from 'styled-components';
import breakpoints from '../../../breakpoints';

export default styled.div`
  height: 400px;
  width: 400px;
  border: solid 5px;
  border-radius: 50%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 350px;
    width: 350px;
  }
`;
