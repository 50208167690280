import i18n from 'i18next';
import k from './../../../../../i18n/keys';

import React, { useContext, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import { ReactComponent as EditSvg } from '../../../../../_assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../../../_assets/svg/delete.svg';
import EditingContext from '../../../../../Shared/Context/EditingContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import UserContext from '../../../../../Shared/Context/UserContext';
import ConfirmDialog from '../../../../../Shared/Components/Dialog/ConfirmDialog';
import moveElementInArray from '../../../../../Shared/FunctionUtils/moveElementInArray';

import EditingDialogContainer from '../EditingDialogContainer/EditingDialogContainer';
import getContentPage from '../../Functions/getContentPage';
import saveWeddingContentUpdates from '../../Functions/saveWeddingContentUpdates';
import abortDueToMissingRegistration from '../../Functions/abortDueToMissingRegistration';

import OuterContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/OuterContainer';
import InnerContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/InnerContainer';
import StyledIconButton from '../../../../../Shared/Components/Editing/EditingContainerComponents/StyledIconButton';
import MoreMenu from '../../../../../Shared/Components/Editing/EditingContainerComponents/MoreMenu';
import LanguageContext from '../../../../../Shared/Context/LanguageContext';
import elementTypes from '../../Constants/elementTypes';

const StyledOuterContainer = styled(OuterContainer)`
  border-top-left-radius: 0;
  margin-right: -3px;
`;

const EditingContainerForLocation = ({ children, editingKeys, content, loadingSave, setLoadingSave }) => {
  const { editingModeActive, setWeddingContent, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [startedEditing, setStartedEditing] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleMove = direction => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    const delta = direction === 'UP' ? -1 : 1;
    const contentPage = getContentPage(weddingConfig, editingKeys);
    const element = contentPage.elements.filter(element => element.elementType === 'locationMap')[0];
    moveElementInArray(
      element.locations,
      editingKeys.index,
      Math.max(Math.min(editingKeys.index + delta, element.locations.length - 1), 0)
    );
    setLoadingSave(true);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  const handleEdit = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setStartedEditing(true);
  };

  const stopEditing = () => {
    setStartedEditing(false);
  };

  const requestDelete = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setShowDeleteDialog(true);
  };

  const doDelete = () => {
    setShowDeleteDialog(false);
    const contentPage = getContentPage(weddingConfig, editingKeys);
    const element = contentPage.elements.filter(element => element.elementType === 'locationMap')[0];
    element.locations.splice(editingKeys.index, 1);
    setLoadingSave(true);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  const saveChanges = async changes => {
    const elementType = elementTypes[editingKeys.elementType];
    const contentPage = getContentPage(weddingConfig, editingKeys);
    const originalElement = contentPage.elements.filter(element => element.elementType === 'locationMap')[0].locations[
      editingKeys.index
    ];
    let changedLanguageEntry = {
      lng: currentLanguage,
    };
    Object.entries(changes).forEach(change => {
      if (elementType.i18nProperties.indexOf(change[0]) >= 0) {
        changedLanguageEntry[change[0]] = change[1];
        delete changes[change[0]];
      }
    });
    if (!originalElement.i18n) originalElement.i18n = [];
    const originalLanguageIndex = originalElement.i18n.findIndex(l => l.lng === currentLanguage);
    if (originalLanguageIndex >= 0) {
      originalElement.i18n.splice(originalLanguageIndex, 1);
    }
    originalElement.i18n.push(changedLanguageEntry);
    contentPage.elements.filter(element => element.elementType === 'locationMap')[0].locations[editingKeys.index] = {
      ...contentPage.elements.filter(element => element.elementType === 'locationMap')[0].locations[editingKeys.index],
      ...changes,
    };
    setStartedEditing(false);
    setLoadingSave(true);
    saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
    ReactGA.event({
      category: `EHO_Edit`,
      action: `Edited-Wedding-${weddingId}-Page-${editingKeys.contentPage}`,
    });
  };

  useEffect(() => {
    setLoadingSave(false);
  }, [weddingConfig, setLoadingSave]);

  return (
    <>
      {editingModeActive ? (
        <>
          <StyledOuterContainer>
            <InnerContainer loadingSave={loadingSave} numberOfButtons={3}>
              <StyledIconButton onClick={handleEdit}>
                <EditSvg></EditSvg>
              </StyledIconButton>
              <StyledIconButton onClick={requestDelete}>
                <DeleteSvg></DeleteSvg>
              </StyledIconButton>
              <MoreMenu handleMove={handleMove}></MoreMenu>
            </InnerContainer>
          </StyledOuterContainer>
          {startedEditing && (
            <EditingDialogContainer
              editingKeys={editingKeys}
              stopEditing={stopEditing}
              saveChanges={saveChanges}
              content={content}
            ></EditingDialogContainer>
          )}
          <ConfirmDialog
            open={showDeleteDialog}
            handleCancel={() => {
              setShowDeleteDialog(false);
            }}
            handleConfirm={doDelete}
            messageTitle={i18n.t(k.CONFIRM_DELETE_TITLE)}
            messageDescriptionText={i18n.t(k.CONFIRM_DELETE_TEXT)}
            confirmButtonText={i18n.t(k.DELETE)}
          ></ConfirmDialog>
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditingContainerForLocation;
