import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import defaultStyles from '../../WeddingStyles/DefaultStyles';
import adjustFontSize from '../../WeddingStyles/FontAdjustment';
import breakpoints from '../../breakpoints';

const Container = styled.div`
  position: ${({ fixedBottom }) => (fixedBottom ? 'fixed' : 'relative')};
  bottom: ${({ fixedBottom }) => (fixedBottom ? '0' : 'unset')};
  z-index: 1;
  display: flex;
  justify-content: space-around;
  width: 100%;
  justify-content: flex-end;
  a {
    background-color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.navigation.inverseColor} !important;
    font-size: ${({ weddingStyle }) => adjustFontSize(defaultStyles[weddingStyle].bodyFont, '14px')};
    padding: 3px 12px;
    margin-left: 6px;
    color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.navigation.textColor} !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    @media (max-width: ${breakpoints.xs}) {
      font-size: 9px;
    }
  }
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    display: none;
  }
`;

const LegalFooter = ({ weddingStyle, fixedBottom }) => {
  return (
    <Container weddingStyle={weddingStyle} fixedBottom={fixedBottom}>
      <a href="/f/impressum/" target="_blank" rel="noopener noreferrer">
        {i18n.t(k.IMPRINT_00)}
      </a>
      <a href="/f/datenschutz/" target="_blank" rel="noopener noreferrer">
        {i18n.t(k.PRIVACY_00)}
      </a>
      <a href="/f/nutzungsbedingungen/" target="_blank" rel="noopener noreferrer">
        {i18n.t(k.TERMS_AND_CONDITIONS_00)}
      </a>{' '}
    </Container>
  );
};

export default LegalFooter;
