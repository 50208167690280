export default {
  vintage: {
    type: 'vintage',
    navigationWithShadow: false,
    headerFont: 'Amatic SC',
    navigationFont: 'Amatic SC',
    bodyFont: 'Quicksand',
    background: {
      type: 'image',
      image: '/assets/wedding-styles/vintage/vintage_kraftpapier.jpg',
      overlay: ['#99570066'],
    },
    colors: {
      primary: {
        base: '#f5f5f5',
        onWhite: '#5e3f1e',
        onDefaultBackground: '#ffffff',
      },
      secondary: {
        base: '#7b532d',
        onWhite: '#5e3f1e',
        onDefaultBackground: '#ffffff',
      },
      button: {
        primaryColor: '#5e3f1e',
        secondaryColor: '#97784b',
        primaryOnWhite: '#805819',
        secondaryOnWhite: '#97784b',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#725328',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#725328',
      },
    },
    assets: {
      border: '/assets/wedding-styles/vintage/vintage_trennstrich.png',
      horizontalBorder: '/assets/wedding-styles/vintage/vintage_horizontale_linie.png',
      heart: '/assets/wedding-styles/vintage/vintage_herz.png',
      threeHearts: '/assets/wedding-styles/vintage/vintage_drei_herzen.png',
    },
  },
  boho: {
    type: 'boho',
    navigationWithShadow: false,
    headerFont: 'Ms Madi',
    navigationFont: 'Poppins',
    bodyFont: 'Poppins',
    background: {
      type: 'image',
      image: '/assets/wedding-styles/boho/paper.jpg',
      overlay: ['#fbf5ec96'],
    },
    colors: {
      primary: {
        base: '#975f10 ',
        onWhite: '#5d421c',
        onDefaultBackground: '#b88947',
      },
      secondary: {
        base: '#ab6e53',
        onWhite: '#9d4c29',
        onDefaultBackground: '#9d4c29',
      },
      button: {
        primaryColor: '#5d421c',
        secondaryColor: '#ab6e53',
        primaryOnWhite: '#975f10',
        secondaryOnWhite: '#9d4c29',
      },
      navigation: {
        textColor: '#5d421c',
        inverseColor: '#ffffff',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#a4762f',
      },
    },
    assets: {
      floralFrame: '/assets/wedding-styles/boho/floral-frame.png',
    },
  },
  blossom: {
    type: 'blossom',
    navigationWithShadow: false,
    headerFont: 'Yellowtail',
    navigationFont: 'Poppins',
    bodyFont: 'Poppins',
    background: {
      type: 'gradient',
      colors: ['#ede0d2', '#dce6e3'],
    },
    colors: {
      primary: {
        base: '#81090d ',
        onWhite: '#81090d',
        onDefaultBackground: '#81090d',
      },
      secondary: {
        base: '#8a4952',
        onWhite: '#8a4952',
        onDefaultBackground: '#8a4952',
      },
      button: {
        primaryColor: '#81090d',
        secondaryColor: '#8a4952',
        primaryOnWhite: '#81090d',
        secondaryOnWhite: '#8a4952',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#bb5b71',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#81090d',
      },
    },
    assets: {
      element: '/assets/wedding-styles/blossom/element.png',
    },
  },
  modern: {
    type: 'modern',
    navigationWithShadow: true,
    headerFont: 'Great Vibes',
    navigationFont: 'Poppins',
    bodyFont: 'Poppins',
    background: {
      type: 'gradient',
      colors: ['#ffeaca', '#fcfdf8'],
    },
    colors: {
      primary: {
        base: '#b88947',
        onWhite: '#8c672e',
        onDefaultBackground: '#b88947',
      },
      secondary: {
        base: '#6b8a4f',
        onWhite: '#4a602e',
        onDefaultBackground: '#6b8a4f',
      },
      button: {
        primaryColor: '#8c672e',
        secondaryColor: '#c7b389',
        primaryOnWhite: '#a4762f',
        secondaryOnWhite: '#baa17a',
      },
      navigation: {
        textColor: '#49633f',
        inverseColor: '#ffffff',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#a4762f',
      },
    },
    assets: {
      element: '/assets/wedding-styles/modern/modern_element.png',
    },
  },
  flower: {
    type: 'flower',
    navigationWithShadow: true,
    headerFont: 'Sofia',
    navigationFont: 'Poppins',
    bodyFont: 'Poppins',
    background: {
      type: 'gradient',
      colors: ['#fcf3fb', '#fce9eb'],
    },
    colors: {
      primary: {
        base: '#557197',
        onWhite: '#557197',
        onDefaultBackground: '#557197',
      },
      secondary: {
        base: '#a386c7',
        onWhite: '#78539a',
        onDefaultBackground: '#78539a',
      },
      button: {
        primaryColor: '#557197',
        secondaryColor: '#a386c7',
        primaryOnWhite: '#677b9a',
        secondaryOnWhite: '#937bab',
      },
      navigation: {
        textColor: '#8b64a2',
        inverseColor: '#ffffff',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#8b64a2',
      },
    },
    assets: {
      element1: '/assets/wedding-styles/flower/flower_element_1.png',
      element2: '/assets/wedding-styles/flower/flower_element_2.png',
      frame: '/assets/wedding-styles/flower/flower_frame.png',
    },
  },
  klassisch: {
    type: 'klassisch',
    navigationWithShadow: false,
    headerFont: 'Quicksand',
    navigationFont: 'Quicksand',
    bodyFont: 'Quicksand',
    background: {
      type: 'gradient',
      colors: ['#f47386', '#f47386'],
    },
    colors: {
      primary: {
        base: '#f0f0f0',
        onWhite: '#7a2533',
        onDefaultBackground: '#ffffff',
      },
      secondary: {
        base: '#b9475a',
        onWhite: '#7a2533',
        onDefaultBackground: '#ffffff',
      },
      button: {
        primaryColor: '#7a2533',
        secondaryColor: '#f0f0f0',
        primaryOnWhite: '#b04756',
        secondaryOnWhite: '#f47386',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#9a3241',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#9a3241',
      },
    },
    assets: {
      heart: '/assets/wedding-styles/klassisch/klassisch_herz.png',
    },
  },
  eurehochzeitonline: {
    type: 'eurehochzeitonline',
    navigationWithShadow: false,
    headerFont: 'Quicksand',
    navigationFont: 'Quicksand',
    bodyFont: 'Quicksand',
    background: {
      type: 'gradient',
      colors: ['#f47386', '#f47386'],
    },
    colors: {
      primary: {
        base: '#e87076',
        onWhite: '#b4505e',
        onDefaultBackground: '#b4505e',
      },
      secondary: {
        base: '#333333',
        onWhite: '#666666',
        onDefaultBackground: '#333333',
      },
      button: {
        primaryColor: '#b4505e',
        secondaryColor: '#666666',
        primaryOnWhite: '#e87076',
        secondaryOnWhite: '#999999',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#9a3241',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#9a3241',
      },
    },
  },
};
