import React from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import k from './../../../i18n/keys';

import defaultStyles from '../../WeddingStyles/DefaultStyles';
import boxShadows from '../../boxShadows';
import breakpoints from '../../breakpoints';
import { Button } from '@material-ui/core';
import ThumbnailImageContainer from './ThumbnailImageContainer';

const Container = styled.div`
  margin: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${breakpoints.md}) {
    flex-direction: ${({ lp }) => (lp ? 'column' : 'row')};
  }
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    margin: 6px;
    flex-direction: column;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px;
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    max-width: 80vw;
    width: 450px;
  }
`;

const Title = styled.h3`
  font-size: 32px;
  margin: 12px 0 6px 0;
  color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite} !important;
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    font-size: 24px;
    font-weight: bold !important;
  }
`;

const Description = styled.span`
  color: #333333 !important;
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
  background-color: ${({ weddingstyle }) => defaultStyles[weddingstyle].colors.primary.onWhite} !important;
  box-shadow: ${boxShadows.z3};
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const StyleThumbnailLarge = ({
  active = false,
  title,
  description,
  onSelect,
  weddingStyle = 'eurehochzeitonline',
  keyOfStyle,
  landingPageStyle,
}) => {
  return (
    <Container lp={landingPageStyle} active={active} weddingStyle={weddingStyle} onClick={() => {}}>
      <ThumbnailImageContainer lp={landingPageStyle} keyOfStyle={keyOfStyle}></ThumbnailImageContainer>
      <TextContainer>
        <Title weddingStyle={keyOfStyle}>{title}</Title>
        <Description weddingStyle={keyOfStyle}>{description}</Description>
        <StyledButton weddingstyle={keyOfStyle} onClick={() => onSelect(keyOfStyle)}>
          {i18n.t(k.SELECT)}
        </StyledButton>
      </TextContainer>
    </Container>
  );
};

export default StyleThumbnailLarge;
