import styled from 'styled-components';
import boxShadows from '../../../../../Shared/boxShadows';

export default styled.div`
  min-height: 350px;
  background-color: #ffffffe0;
  position: relative;
  box-shadow: ${boxShadows.z2};
  padding: 12px;
  border-top: solid;
  border-width: 3px;
`;
