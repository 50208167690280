import hasUserWritePermissionForWedding from '../../../../Shared/FunctionUtils/hasUserWritePermissionForWedding';

export default (weddingId, fidiraUser, showDialogFunction) => {
  if (!hasUserWritePermissionForWedding(fidiraUser, weddingId)) {
    showDialogFunction(true);
    return true;
  } else {
    return false;
  }
};
