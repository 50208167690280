import styled from 'styled-components';
import { TextField } from '@material-ui/core';

import breakpoints from '../../../breakpoints';

export default styled(TextField)`
  width: 400px;
  max-width: 100%;
  margin: 3px auto;
  .MuiInputBase-root {
    line-height: 1.3;
  }
  @media (max-width: ${breakpoints.xs}) {
    margin: 3px auto;
  }
  textarea {
    font-size: 18px !important;
  }
`;
