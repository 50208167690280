import styled from 'styled-components';
import breakpoints from '../../../Shared/breakpoints';

export default styled.div`
  position: relative;
  width: 1500px;
  max-width: 95vw;
  margin: 48px auto;
  text-align: left;
  display: flex;
  flex-direction: ${({ alignment }) => (alignment === 'right' ? 'row-reverse' : 'row')};
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;
