import styled from 'styled-components';
import { Button } from '@material-ui/core';

export default styled(Button)`
  background-color: ${({ styles }) => `${styles?.textColor}2e !important`};
  padding: 0 9px;
  span {
    color: ${({ styles }) => styles?.textColor} !important;
    font-size: 16px;
  }
  svg {
    width: 18px;
    height: 18px;
    fill: ${({ styles }) => styles?.textColor} !important;
    margin-right: 6px;
  }
`;
