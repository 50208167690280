import React, { useContext, useState, useEffect } from 'react';

import { ReactComponent as EditSvg } from '../../../../../_assets/svg/edit.svg';
import EditingContext from '../../../../../Shared/Context/EditingContext';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import UserContext from '../../../../../Shared/Context/UserContext';

import elementTypes from '../../Constants/elementTypes';
import EditingDialogContainer from '../EditingDialogContainer/EditingDialogContainer';
import saveWeddingBaseDataUpdates from '../../Functions/saveWeddingBaseDataUpdates';
import abortDueToMissingRegistration from '../../Functions/abortDueToMissingRegistration';

import OuterContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/OuterContainer';
import InnerContainer from '../../../../../Shared/Components/Editing/EditingContainerComponents/InnerContainer';
import StyledIconButton from '../../../../../Shared/Components/Editing/EditingContainerComponents/StyledIconButton';

const EditingContainerForCountdown = ({ children }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const { editingModeActive, setWeddingBaseData, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const [startedEditing, setStartedEditing] = useState(false);

  const handleEdit = () => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setStartedEditing(true);
  };

  const stopEditing = () => {
    setStartedEditing(false);
  };

  const saveChanges = async baseData => {
    setStartedEditing(false);
    setLoadingSave(true);
    saveWeddingBaseDataUpdates(baseData, weddingId, setWeddingBaseData);
  };

  useEffect(() => {
    setLoadingSave(false);
  }, [weddingConfig, setLoadingSave]);

  return (
    <>
      {editingModeActive ? (
        <>
          <OuterContainer className="color-background-white">
            <InnerContainer loadingSave={loadingSave} numberOfButtons={1}>
              <StyledIconButton onClick={handleEdit}>
                <EditSvg></EditSvg>
              </StyledIconButton>
            </InnerContainer>
          </OuterContainer>
          {startedEditing && (
            <EditingDialogContainer
              editingKeys={{ elementType: elementTypes.countdown.name }}
              stopEditing={stopEditing}
              saveChanges={saveChanges}
              content={{
                showCountdown: weddingConfig.showCountdown,
              }}
            ></EditingDialogContainer>
          )}
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditingContainerForCountdown;
