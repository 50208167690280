import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 0;
  transform: scale(0.4);
  max-width: 140vw;
  transform-origin: center;
  transform-origin: center center;
  margin-bottom: 12px;
  max-height: ${({ calculatedHeight }) => calculatedHeight};
  > div {
    margin: 6px;
    width: 550px;
  }
`;

const ElementTypeThumbnail = ({ children }) => {
  const [calculatedHeight, setCalculatedHeight] = useState('auto');
  const thumbnail = useRef(null);
  useEffect(() => {
    setCalculatedHeight(thumbnail.current.getBoundingClientRect().height + 'px');
  }, [thumbnail]);

  return (
    <Container ref={thumbnail} calculatedHeight={calculatedHeight}>
      {children}
    </Container>
  );
};

export default ElementTypeThumbnail;
