import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

import isProduction from '../../Shared/FunctionUtils/isProduction';
import UserContext from '../../Shared/Context/UserContext';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import LogoLarge from '../../Shared/Components/EureHochzeitOnlineLogo/LogoLarge';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import OuterContainer from '../../Shared/Components/EureHochzeitOnline/OuterContainer';
import InnerContainer from '../../Shared/Components/EureHochzeitOnline/InnerContainer';

const StyledLinearProgress = styled(LinearProgress)`
  width: 300px;
  max-width: 70vw;
`;

const SpotifyLoginPage = () => {
  const { fidiraUser } = useContext(UserContext);

  useEffect(() => {
    const clientId = isProduction()
      ? process.env.REACT_APP_SPOTIFY_CLIENT_ID_PROD
      : process.env.REACT_APP_SPOTIFY_CLIENT_ID_TEST;
    const redirectUri = `${window.location.origin}/f/spotify-post-login/`;
    const state = btoa(fidiraUser.username);
    const scope = 'playlist-modify-public%20playlist-modify-private%20ugc-image-upload';
    window.location.href = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  }, [fidiraUser.username]);

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <OuterContainer>
        <InnerContainer>
          <LogoLarge></LogoLarge>
          <StyledLinearProgress />
        </InnerContainer>
      </OuterContainer>
      <LegalFooter weddingStyle={'eurehochzeitonline'} fixedBottom></LegalFooter>
    </GenericStyleContainer>
  );
};

export default SpotifyLoginPage;
