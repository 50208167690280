import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.h1`
  font-size: 45px !important;
  margin: 0;
  font-size: 60px !important;
  margin-top: 70px;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${defaultStyles.boho.headerFont} !important;
  color: ${defaultStyles.boho.colors.primary.base} !important;
  @media (max-height: 980px) {
    font-size: 48px !important;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.macbookHeight}) {
    font-size: 40px !important;
    margin-top: 60px;
  }
`;
