import i18n from 'i18next';
import k from '../../../i18n/keys';
import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import GenericStyleContainer from '../../WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../WeddingStyles/DefaultStyles';
import breakpoints from '../../breakpoints';
import StyleThumbnailLarge from './StyleThumbnailLarge';
import { ReactComponent as CloseIcon } from '../../../_assets/svg/close-simple.svg';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    transform: translateY(-20px);
    position: relative;
    overflow-y: unset;
    max-width: 97%;
    width: 800px;
    > div {
      overflow-y: auto;
    }
  }
`;

const InnerContainerDiv = styled.div``;

const StyledDialogTitle = styled(DialogTitle)`
  text-align: center;
  h2 {
    font-size: 36px;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    padding: 12px;
    h2 {
      font-size: 26px;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  max-height: 65vh;
  margin: auto;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    padding: 3px 12px;
    padding-bottom: 60px;
  }
`;

const CloseButton = styled(IconButton)`
  display: block;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 10;
  svg {
    height: 15px;
    width: 15px;
  }
`;

const WeddingStyleSelectionDialog = ({ open, handleClose, savedWeddingStyle, weddingStyle, onSelect }) => {
  const onClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    handleClose();
  };

  const onStyleSelected = style => {
    onSelect(style);
    handleClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <GenericStyleContainer weddingStyleData={defaultStyles[savedWeddingStyle]}>
        <InnerContainerDiv>
          <StyledDialogTitle className="color-background-white">
            {i18n.t(k.QUESTION_WEDDING_STYLE)}
            <CloseButton onClick={() => onClose()}>
              <CloseIcon></CloseIcon>
            </CloseButton>
          </StyledDialogTitle>
          <StyledDialogContent className="color-background-white">
            {Object.keys(defaultStyles)
              .filter(key => key !== 'eurehochzeitonline')
              .map(key => {
                const title = i18n.t(k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_TITLE`]);
                const description = i18n.t(k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_DESCRIPTION`]);
                return (
                  <StyleThumbnailLarge
                    active={weddingStyle === key}
                    title={title}
                    description={description}
                    onSelect={onStyleSelected}
                    keyOfStyle={key}
                    weddingStyle={weddingStyle}
                    key={key}
                  />
                );
              })}
          </StyledDialogContent>
        </InnerContainerDiv>
      </GenericStyleContainer>
    </StyledDialog>
  );
};

export default WeddingStyleSelectionDialog;
