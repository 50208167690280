import styled from 'styled-components';
import breakpoints from '../../../../../../Shared/breakpoints';

export default styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  > h3 {
    margin: 0;
  }
  > svg {
    width: 100px;
    height: 100px;
    margin: 12px auto;
  }
  @media (max-width: ${breakpoints.xs}) {
    display: none;
  }
`;
