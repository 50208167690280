import styled from 'styled-components';

import breakpoints from '../../breakpoints';

export default styled.div`
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${breakpoints.md}) {
    width: calc(100% - 350px);
  }
`;
