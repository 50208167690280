import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Badge, FormControlLabel, Switch, Tab, Tabs } from '@material-ui/core';

import formatDate from '../../../../../../Shared/FunctionUtils/formatDate';
import getUpcomingFourSeasons from '../../../../../../Shared/FunctionUtils/getUpcomingFourSeasons';
import isMobile from '../../../../../../Shared/FunctionUtils/isMobile';
import breakpoints from '../../../../../../Shared/breakpoints';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';
import IconBox from '../../../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../../../../../../Shared/Components/SelectionComponent/SelectionContainer';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import ErrorMessage from '../Components/ErrorMessage';
import FadeOuterContainer from '../Components/FadeOuterContainer';
import FadeContainer from '../Components/FadeContainer';
import ImageUploader from '../Components/ImageUploader/ImageUploader';
import defaultStyles from '../../../../../../Shared/WeddingStyles/DefaultStyles';
import ShowAllStylesButton from '../../../../../../Shared/Components/SelectionComponent/ShowAllStylesButton';
import WeddingStyleSelectionDialog from '../../../../../../Shared/Components/WeddingStyleSelectionDialog/WeddingStyleSelectionDialog';
import adjustColor from '../../../../../../Shared/WeddingStyles/adjustColor';

const CustomLogoContainer = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 6px auto;
  text-align: center;
  @media (min-width: ${breakpoints.xs}) {
    min-height: 250px;
  }
  .image {
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const WeddingDateContainer = styled.div`
  width: 400px;
  max-width: 100%;
  min-height: 300px;
  margin: 6px auto;
  text-align: center;
  @media (max-width: ${breakpoints.xs}) {
    min-height: ${({ exact }) => (exact ? '150px' : '300px')};
  }
  .selection-container-outer {
    min-height: 280px;
    @media (max-width: ${breakpoints.xs}) {
      min-height: 150px;
    }
  }
  .selection-container-flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    @media (max-width: ${breakpoints.xs}) {
      display: inline-flex;
    }
  }
  .MuiTextField-root {
    width: 400px;
    max-width: 100%;
  }
`;
const DatePickerContainer = styled.div`
  .native-picker input {
    text-align: center;
    padding-left: 50px;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: -24px;
`;

const StyledSwitch = styled(Switch)`
  @media (min-width: ${breakpoints.xs}) {
    margin-bottom: -18px;
    margin-top: -12px;
  }
`;

const Question = styled.p`
  margin: 0;
`;

const Hint = styled.p`
  max-width: 100%;
  font-size: 14px !important;
  margin: auto;
  line-height: 1.2;
  margin-top: 9px;
`;

const TabPanel = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  background-color: ${({ weddingStyle }) =>
    adjustColor(defaultStyles[weddingStyle].colors.button.secondaryColor, -10) + '35 '};
  flex-direction: column;
  padding: 12px;
  @media (min-width: ${breakpoints.xs}) {
    .selection-container-outer {
      display: flex;
      justify-content: center;
    }
  }
`;

const QuestionTitle = styled.h3`
  text-align: center;
  font-size: 28px;
  margin: 6px;
  font-weight: bold !important;
  @media (max-width: ${breakpoints.xs}) {
    font-size: 21px;
  }
`;

const UploadArea = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 13px;
    right: 15px;
    min-width: 44px;
    font-size: 14px !important;
  }
`;

const EditingDialogBaseData = ({
  nameOfGroom,
  nameOfBride,
  weddingDate,
  weddingDateIsExact,
  stopEditing,
  saveChanges,
  useCustomLogo,
  customLogoUrl,
  weddingStyle,
  useStartPhoto,
  startPhotoUrl,
}) => {
  const formattedWeddingDate = weddingDateIsExact
    ? Date.parse(`${weddingDate.split('.')[1]}/${weddingDate.split('.')[0]}/${weddingDate.split('.')[2]}`)
    : new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const season = weddingDateIsExact ? '' : weddingDate;
  const [currentWeddingDate, setCurrentWeddingDate] = useState(formattedWeddingDate);
  const [currentSeason, setCurrentSeason] = useState(season);
  const [currentWeddingDateIsExact, setCurrentWeddingDateIsExact] = useState(weddingDateIsExact);
  const [showError, setShowError] = useState(null);
  const { weddingConfig } = useContext(WeddingContext);
  const { handleSubmit, register, errors, setValue } = useForm({});
  const [currentUseCustomLogo, setCurrentUseCustomLogo] = useState(useCustomLogo);
  const [currentCustomLogoUrl, setCurrentCustomLogoUrl] = useState(customLogoUrl);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentWeddingStyle, setCurrentWeddingStyle] = useState(weddingStyle);
  const [randomStyles, setRandomStyles] = useState([]);
  const [currentUseStartPhoto, setCurrentUseStartPhoto] = useState(useStartPhoto);
  const [currentStartPhotoUrl, setCurrentStartPhotoUrl] = useState(startPhotoUrl);
  const [styleSelectionDialogOpen, setStyleSelectionDialogOpen] = useState(false);

  const handleDateSwitch = e => {
    setCurrentWeddingDateIsExact(e.target.checked);
    setShowError(false);
  };

  const handleLogoSwitch = e => {
    setCurrentUseCustomLogo(e.target.checked);
    setShowError(false);
  };

  const handleStartPhotoSwitch = e => {
    setCurrentUseStartPhoto(e.target.checked);
    setShowError(false);
  };

  const handleNativeDateChange = event => {
    const date = new Date(event.target.valueAsNumber);
    setCurrentWeddingDate(date);
    setShowError(false);
  };

  const handleDateChange = date => {
    if (!Number.isNaN(Date.parse(date))) {
      setCurrentWeddingDate(date);
      setShowError(false);
    } else {
      setCurrentWeddingDate(null);
      setShowError(false);
    }
  };

  const onSelectSeason = selection => {
    setCurrentSeason(selection.value);
    setShowError(false);
  };

  const handleSave = async values => {
    const weddingDateToBeSaved = currentWeddingDateIsExact
      ? new Date(currentWeddingDate).toLocaleDateString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : currentSeason;
    if (!weddingDateToBeSaved || weddingDateToBeSaved === '01.01.1970' || weddingDateToBeSaved === 'Invalid Date') {
      setShowError(true);
      return;
    }
    saveChanges({
      nameOfBride: values.currentNameOfBride,
      nameOfGroom: values.currentNameOfGroom,
      weddingDate: weddingDateToBeSaved,
      weddingDateIsExact: currentWeddingDateIsExact,
      showCountdown: weddingConfig.showCountdown,
      selectedLanguagesForWedding: weddingConfig.selectedLanguagesForWedding,
      useCustomLogo: currentUseCustomLogo,
      customLogoUrl: currentCustomLogoUrl,
      weddingStyle: currentWeddingStyle,
      useStartPhoto: currentUseStartPhoto,
      startPhotoUrl: currentStartPhotoUrl,
    });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const onSelectWeddingStyle = style => {
    if (randomStyles.indexOf(style) === -1 && style !== weddingStyle) {
      randomStyles.splice(0, 1, style);
    }
    setCurrentWeddingStyle(style);
  };

  useEffect(() => {
    const randomized = Object.keys(defaultStyles)
      .filter(key => key !== 'eurehochzeitonline')
      .filter(key => key !== weddingStyle)
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);

    setRandomStyles(randomized);
  }, [weddingStyle]);

  return (
    <Dialog onSubmit={handleSubmit(handleSave)} open>
      <DialogTitle>{i18n.t(k.EDIT_WEDDING_BASE_DATA)}</DialogTitle>
      <DialogContent>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Basisdaten" />
          <Tab label="Design" />
        </Tabs>
        <TabPanel weddingStyle={weddingStyle} hidden={currentTab !== 0}>
          <QuestionTitle>{i18n.t(k.YOUR_NAMES)}</QuestionTitle>
          <RHFInput
            as={
              <TextField
                type="text"
                variant="filled"
                label={i18n.t(k.NAME_OF_BRIDE)}
                ref={register}
                autoFocus={isMobile() ? false : true}
                error={!!errors.currentNameOfBride}
                helperText={errors.currentNameOfBride ? i18n.t(k.ENTER_NAME_OF_BRIDE) : ''}
              />
            }
            rules={{
              required: true,
            }}
            mode="onBlur"
            name="currentNameOfBride"
            register={register}
            setValue={setValue}
            defaultValue={nameOfBride}
          ></RHFInput>
          <RHFInput
            as={
              <TextField
                type="text"
                variant="filled"
                ref={register}
                label={i18n.t(k.NAME_OF_GROOM)}
                error={!!errors.currentNameOfGroom}
                helperText={errors.currentNameOfGroom ? i18n.t(k.ENTER_NAME_OF_GROOM) : ''}
              />
            }
            rules={{
              required: true,
            }}
            mode="onBlur"
            name="currentNameOfGroom"
            register={register}
            setValue={setValue}
            defaultValue={nameOfGroom}
          ></RHFInput>
          <WeddingDateContainer exact={currentWeddingDateIsExact}>
            <QuestionTitle>{i18n.t(k.YOUR_WEDDING_DATE)}</QuestionTitle>
            <FormControlLabel
              control={
                <StyledSwitch
                  checked={currentWeddingDateIsExact}
                  onChange={handleDateSwitch}
                  value="currentWeddingDateIsExact"
                />
              }
              label={currentWeddingDateIsExact ? i18n.t(k.WEDDING_DATE_FIX) : i18n.t(k.WEDDING_DATE_NOT_FIX)}
            />
            <FadeOuterContainer>
              <FadeContainer visible={currentWeddingDateIsExact}>
                <DatePickerContainer>
                  {!isMobile() && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="filled"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={currentWeddingDate}
                        onChange={handleDateChange}
                        invalidDateMessage={i18n.t(k.INVALID_DATE)}
                        invalidLabel={i18n.t(k.INVALID_DATE)}
                        maxDateMessage={i18n.t(k.INVALID_DATE)}
                        minDateMessage={i18n.t(k.DATE_MUST_BE_IN_FUTURE)}
                        autoComplete="off"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}

                  {isMobile() && (
                    <TextField
                      className="native-picker"
                      id="date"
                      label=""
                      variant="filled"
                      value={formatDate(currentWeddingDate)}
                      type="date"
                      onChange={handleNativeDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </DatePickerContainer>
                {showError && <ErrorMessage>{i18n.t(k.SELECT_DATE)}</ErrorMessage>}
              </FadeContainer>
              <FadeContainer visible={!currentWeddingDateIsExact}>
                <SelectionContainer smallHeight>
                  {getUpcomingFourSeasons().map(season => {
                    return (
                      <IconBox
                        active={currentSeason === season.name}
                        image={`/assets/seasons/${season.image}.jpg`}
                        text={season.name}
                        question="weddingSeason"
                        selectValue={season.name}
                        onSelect={onSelectSeason}
                        key={season.name}
                        weddingStyle={weddingConfig.weddingStyle}
                        smallHeight
                      />
                    );
                  })}
                </SelectionContainer>
                {showError && <StyledErrorMessage>{i18n.t(k.MAKE_SELECTION)}</StyledErrorMessage>}
              </FadeContainer>
            </FadeOuterContainer>
          </WeddingDateContainer>
        </TabPanel>
        <TabPanel weddingStyle={weddingStyle} hidden={currentTab !== 1}>
          <QuestionTitle>{i18n.t(k.YOUR_WEDDING_STYLE)}</QuestionTitle>
          <SelectionContainer>
            <IconBox
              active={currentWeddingStyle === weddingStyle}
              image={`/assets/wedding-styles/${weddingStyle}/desktop-${weddingStyle}.jpg`}
              text={i18n.t(k[`DEFAULT_STYLES_${defaultStyles[weddingStyle].type.toUpperCase()}_TITLE`])}
              onSelect={() => {
                onSelectWeddingStyle(weddingStyle);
              }}
              weddingStyle={currentWeddingStyle}
              narrow
            />
            {randomStyles.map(key => {
              const title = i18n.t(k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_TITLE`]);
              return (
                <IconBox
                  active={currentWeddingStyle === key}
                  image={`/assets/wedding-styles/${key}/desktop-${key}.jpg`}
                  text={title}
                  question="weddingStyle"
                  selectValue={key}
                  onSelect={() => {
                    onSelectWeddingStyle(key);
                  }}
                  key={key}
                  weddingStyle={currentWeddingStyle}
                  narrow
                />
              );
            })}
            <StyledBadge badgeContent={`+ ${Object.keys(defaultStyles).length - 1}`} color="primary">
              <ShowAllStylesButton
                color="secondary"
                variant="contained"
                onClick={() => setStyleSelectionDialogOpen(true)}
                weddingStyle={currentWeddingStyle}
              ></ShowAllStylesButton>
            </StyledBadge>
          </SelectionContainer>
          <UploadArea>
            <CustomLogoContainer>
              <QuestionTitle>{i18n.t(k.OWN_LOGO)}</QuestionTitle>
              <Question>{i18n.t(k.WANT_CUSTOM_LOGO)}</Question>
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={currentUseCustomLogo}
                    onChange={handleLogoSwitch}
                    value="currentUseCustomLogo"
                  />
                }
                label={currentUseCustomLogo ? i18n.t(k.INDIVIDUAL_LOGO) : i18n.t(k.STANDARD_LOGO)}
              />
              {currentUseCustomLogo && (
                <ImageUploader imageUrl={currentCustomLogoUrl} setImageUrl={setCurrentCustomLogoUrl}></ImageUploader>
              )}
            </CustomLogoContainer>
            <CustomLogoContainer>
              <QuestionTitle>{i18n.t(k.START_PHOTO)}</QuestionTitle>
              <Question>{i18n.t(k.USE_START_PHOTO)}</Question>
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={currentUseStartPhoto}
                    onChange={handleStartPhotoSwitch}
                    value="currentUseStartPhoto"
                  />
                }
                label={currentUseStartPhoto ? i18n.t(k.WITH_START_PHOTO) : i18n.t(k.WITHOUT_START_PHOTO)}
              />
              {currentUseStartPhoto && (
                <ImageUploader imageUrl={currentStartPhotoUrl} setImageUrl={setCurrentStartPhotoUrl}></ImageUploader>
              )}
            </CustomLogoContainer>
          </UploadArea>
        </TabPanel>
        <WeddingStyleSelectionDialog
          open={styleSelectionDialogOpen}
          handleClose={() => setStyleSelectionDialogOpen(false)}
          weddingStyle={currentWeddingStyle}
          savedWeddingStyle={weddingConfig.weddingStyle}
          onSelect={style => onSelectWeddingStyle(style)}
        ></WeddingStyleSelectionDialog>
        <Hint>
          {i18n.t(k.HINT_CHANGE_URL)}
          <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
          {i18n.t(k._DOT)}
        </Hint>
      </DialogContent>
      <DialogActions stopEditing={stopEditing} completeButtonText={i18n.t(k.SAVE)}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogBaseData;
