import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { NavLink } from 'react-router-dom';

import abortDueToMissingRegistration from '../Editing/Functions/abortDueToMissingRegistration';
import saveWeddingContentUpdates from '../Editing/Functions/saveWeddingContentUpdates';
import getContentPage from '../Editing/Functions/getContentPage';

import UserContext from '../../../Shared/Context/UserContext';
import EditingContext from '../../../Shared/Context/EditingContext';
import WeddingContext from '../../../Shared/Context/WeddingContext';
import breakpoints from '../../../Shared/breakpoints';
import hasUserWritePermissionForWedding from '../../../Shared/FunctionUtils/hasUserWritePermissionForWedding';

import { ReactComponent as LocationSvg } from '../../../_assets/svg/meeting-point.svg';
import { ReactComponent as ScheduleSvg } from '../../../_assets/svg/schedule.svg';
import { ReactComponent as CameraSvg } from '../../../_assets/svg/camera.svg';
import { ReactComponent as MusicSvg } from '../../../_assets/svg/music.svg';
import { ReactComponent as InfoSvg } from '../../../_assets/svg/info.svg';
import { ReactComponent as LoveLetterSvg } from '../../../_assets/svg/love-letter-menu.svg';
import { ReactComponent as HotelSvg } from '../../../_assets/svg/house-heart.svg';
import { ReactComponent as PresentSvg } from '../../../_assets/svg/offer.svg';
import { ReactComponent as CoupleSvg } from '../../../_assets/svg/wedding-arch.svg';

import VisibilityAndOrderContainer from './VisibilityAndOrderContainer/VisibilityAndOrderContainer';

const Container = styled.ul`
  width: 350px;
  padding-inline-start: 0;
  max-width: 100vw;
  margin-top: 15px;
  @media (max-width: ${breakpoints.xs}) {
    margin-top: 10px;
  }
`;
const Item = styled.li`
  display: block;
  text-align: center;
  position: relative;
  :last-child {
    margin-bottom: 100px;
  }
`;
const StyledButton = styled(NavLink)`
  width: ${({ editing }) => (editing === 'true' ? 'calc(100% - 125px)' : '100%')};
  min-width: 250px;
  color: ${({ styles }) => styles.textColor};
  font-size: 16px;
  padding: 0 9px;
  @media (orientation: landscape) and (max-width: ${breakpoints.sm}) {
    padding-left: env(safe-area-inset-left);
  }
  margin: 9px 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 2px;
  > p {
    text-align: start;
    color: ${({ styles }) => styles.textColor};
  }
  &.active {
    font-weight: bold;
    background-color: ${({ styles }) => styles.textColor};
    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);
    > p {
      color: ${({ styles }) => styles.backgroundColor};
    }
    > .icon {
      fill: ${({ styles }) => styles.backgroundColor};
    }
  }
`;
const StyledIcon = styled.div`
  display: block;
  height: 40px;
  width: 40px;
  fill: ${({ styleConfig }) => styleConfig.textColor};
  margin: 12px 12px 12px 6px;
`;

const InnerContentMenu = ({ styleConfig, closeDrawer }) => {
  const { editingModeActive, setWeddingContent, setShowCreatorNotRegisteredDialog } = useContext(EditingContext);
  const { weddingId, weddingConfig } = useContext(WeddingContext);
  const { fidiraUser } = useContext(UserContext);
  const [pageVisibilitiesBeingToggled, setPageVisibilitiesBeingToggled] = useState([]);
  const [pageOrdersBeingToggled, setPageOrdersBeingToggled] = useState([]);

  const toggleVisibility = async page => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setPageVisibilitiesBeingToggled([...pageVisibilitiesBeingToggled, page]);
    const contentPage = getContentPage(weddingConfig, { contentPage: page });
    if (contentPage) {
      contentPage.visible = !!!contentPage.visible;
      await saveWeddingContentUpdates(weddingConfig.weddingContent, weddingId, setWeddingContent);
      setPageVisibilitiesBeingToggled(pageVisibilitiesBeingToggled.filter(current => current === page));
      ReactGA.event({
        category: `EHO_Edit`,
        action: `Edited-Wedding-${weddingId}-Visibility-${page}`,
      });
    }
  };

  const navItemIsVisible = page => {
    return (
      weddingConfig.weddingContent &&
      (getContentPage(weddingConfig, { contentPage: page })?.visible ||
        (hasUserWritePermissionForWedding(fidiraUser, weddingId) && editingModeActive))
    );
  };

  const menuItems = [
    {
      icon: LocationSvg,
      labelKey: 'LOCATION',
      path: 'location',
      navItemKey: 'LOCATION',
    },
    {
      icon: HotelSvg,
      labelKey: 'HOTEL',
      path: 'hotel',
      navItemKey: 'HOTEL',
    },
    {
      icon: ScheduleSvg,
      labelKey: 'SCHEDULE',
      path: 'ablauf',
      navItemKey: 'SCHEDULE',
    },
    {
      icon: InfoSvg,
      labelKey: 'INFOS',
      path: 'infos',
      navItemKey: 'INFO',
    },
    {
      icon: CoupleSvg,
      labelKey: 'COUPLE',
      path: 'couple',
      navItemKey: 'COUPLE',
    },
    {
      icon: LoveLetterSvg,
      labelKey: 'RSVP',
      path: 'rsvp',
      navItemKey: 'RSVP',
    },
    {
      icon: PresentSvg,
      labelKey: 'PRESENT_TABLE',
      path: 'present',
      navItemKey: 'PRESENT',
    },
    {
      icon: CameraSvg,
      labelKey: 'PHOTOS',
      path: 'fotos',
      navItemKey: 'PHOTO',
    },
    {
      icon: MusicSvg,
      labelKey: 'MUSIC',
      path: 'musik',
      navItemKey: 'MUSIC',
    },
  ];

  const movePage = async (page, direction) => {
    if (abortDueToMissingRegistration(weddingId, fidiraUser, setShowCreatorNotRegisteredDialog)) return;
    setPageOrdersBeingToggled([...pageOrdersBeingToggled, page]);
    const newContent = { ...weddingConfig.weddingContent };
    const contentPageIndex = newContent.contentPages.findIndex(p => p.pageType === page);
    const contentPage = { ...newContent.contentPages[contentPageIndex] };
    if (contentPage) {
      newContent.contentPages.splice(contentPageIndex, 1);
      if (direction === 'UP') {
        newContent.contentPages.splice(contentPageIndex - 1, 0, contentPage);
      } else {
        newContent.contentPages.splice(contentPageIndex + 1, 0, contentPage);
      }
      await saveWeddingContentUpdates(newContent, weddingId, setWeddingContent);
      setPageOrdersBeingToggled(pageOrdersBeingToggled.filter(current => current === page));
      ReactGA.event({
        category: `EHO_Edit`,
        action: `Edited-Wedding-${weddingId}-Order-${page}`,
      });
    }
  };

  const moveUp = async page => {
    await movePage(page, 'UP');
  };

  const moveDown = async page => {
    await movePage(page, 'DOWN');
  };

  return (
    <>
      <nav>
        <Container styleConfig={styleConfig}>
          {weddingConfig.weddingContent?.contentPages.map((page, index) => {
            const menuItem = menuItems.find(m => m.navItemKey === page.pageType);
            const { icon: Icon, labelKey, path, navItemKey } = menuItem;
            return (
              navItemIsVisible(navItemKey) && (
                <Item key={index}>
                  <StyledButton
                    exact
                    styles={styleConfig}
                    editing={editingModeActive.toString()}
                    activeClassName="active"
                    to={{ pathname: `/${weddingId}/${path}/` }}
                    onClick={() => closeDrawer()}
                  >
                    <StyledIcon styleConfig={styleConfig} className="icon">
                      <Icon />
                    </StyledIcon>
                    <p>{i18n.t(k[labelKey])}</p>
                  </StyledButton>
                  {editingModeActive && (
                    <VisibilityAndOrderContainer
                      toggleVisibility={toggleVisibility}
                      page={navItemKey}
                      styles={styleConfig}
                      index={index}
                      numberOfPages={weddingConfig.weddingContent.contentPages.length}
                      pageVisibilitiesBeingToggled={pageVisibilitiesBeingToggled}
                      weddingConfig={weddingConfig}
                      moveUp={moveUp}
                      moveDown={moveDown}
                      pageOrdersBeingToggled={pageOrdersBeingToggled}
                    />
                  )}
                </Item>
              )
            );
          })}
        </Container>
      </nav>
    </>
  );
};

export default InnerContentMenu;
