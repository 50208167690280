import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import UserContext from '../../Context/UserContext';
import LanguageContext from '../../Context/LanguageContext';
import GenericStyleContainer from '../../WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../WeddingStyles/DefaultStyles';
import ErrorDialog from '../Dialog/FidiraDialog';
import { ReactComponent as BalloonSvg } from '../../../_assets/svg/heart-balloon.svg';
import breakpoints from '../../breakpoints';

import Username from './Steps/Username';
import Password from './Steps/Password';
import WeddingCode from './Steps/WeddingCode';
import DisplayName from './Steps/DisplayName';
import getCorrectLoginFlow from './Functions/getCorrectLoginFlow';
import LoginFlows from './Constants/LoginFlows';
import Container from './Components/Container';
import DesignElementTopRight from './Components/DesignElementTopRight';
import ForgotPasswordMessage from './Steps/ForgotPasswordMessage';

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  @media (max-width: ${breakpoints.xs}) {
    height: 190px;
  }
`;

const LoginContainer = ({ weddingId, weddingStyle, appPage, appPageId }) => {
  const history = useHistory();
  const { setAuthenticatedUser } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  let currentStepFromUrl;
  const matchForWeddingPageLogin = useRouteMatch('/:weddingId/login/:step');
  const matchForAppPageLogin = useRouteMatch('/:appPage/:appPageId?/app-login/:step');
  currentStepFromUrl = matchForWeddingPageLogin
    ? matchForWeddingPageLogin.params.step
    : matchForAppPageLogin.params.step;
  const [genericLoginError, setGenericLoginError] = useState(false);
  const [wrongCredentialError, setWrongCredentialError] = useState(false);
  const [wrongWeddingCodeError, setWrongWeddingCodeError] = useState(false);
  const [currentLoginStep, setCurrentLoginStep] = useState('enterUsername');
  const [currentLoginFlow, setCurrentLoginFlow] = useState('WEDDING_PAGE_LOGIN_NEW_USER');
  const [loadingUsername, setLoadingUsername] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loginData, setLoginData] = useState({});
  const correctBaseUrlBeforeStep = `/${weddingId || appPage}/${appPageId ? `${appPageId}/` : ''}${
    appPage ? 'app-login' : 'login'
  }/`;

  const onUsernameEntered = async ({ username }) => {
    try {
      setLoadingUsername(true);
      const userResponse = await axios.get(`user-read/`, { headers: { 'data-username': username.toLowerCase() } });
      const user = userResponse.data;
      const correctLoginFlow = getCorrectLoginFlow(user, weddingId);
      setCurrentLoginFlow(correctLoginFlow);
      setLoginData({
        username: username.toLowerCase(),
        displayName: user ? user.displayName : null,
      });
      setLoadingUsername(false);
      history.push(`${correctBaseUrlBeforeStep}${LoginFlows[correctLoginFlow][1]}`);
    } catch (error) {
      console.error(error);
      setGenericLoginError(true);
      setLoadingUsername(false);
    }
  };

  const onDisplayNameEntered = ({ displayName }) => {
    setLoginData(loginData => {
      return { ...loginData, displayName: displayName };
    });
    history.push(`${correctBaseUrlBeforeStep}${LoginFlows[currentLoginFlow][2]}`);
  };

  const onWeddingCodeEntered = ({ weddingCode }) => {
    setLoginData(loginData => {
      return { ...loginData, weddingCode: weddingCode };
    });
    history.push(`${correctBaseUrlBeforeStep}enterPassword`);
  };

  const onForgotPassword = async () => {
    await Auth.forgotPassword(loginData.username, {
      reasonForMail: 'ForgotPassword',
      username: loginData.username,
      displayName: loginData.displayName,
      wedding: weddingId,
    });
  };

  const checkWeddingCode = async () => {
    await axios.post('wedding-check-code/', {
      weddingId: weddingId,
      weddingCode: loginData.weddingCode,
    });
  };

  const onPasswordEntered = async ({ password }) => {
    setLoadingPassword(true);
    let cognitoUser;
    let fidiraUser;
    try {
      switch (currentLoginFlow) {
        case 'WEDDING_PAGE_LOGIN_NEW_USER':
          await checkWeddingCode();
          cognitoUser = await Auth.signUp({
            username: loginData.username,
            password: password,
          });
          fidiraUser = await axios.post(`user-create/`, {
            creationType: 'AS_WEDDING_GUEST',
            sub: cognitoUser.userSub,
            mail: loginData.username,
            weddingId: weddingId,
            displayName: loginData.displayName,
            weddingCode: loginData.weddingCode,
            role: 'GUEST',
            language: currentLanguage,
          });
          await Auth.signIn(loginData.username, password);
          break;
        case 'WEDDING_PAGE_LOGIN_NEW_GUEST_FOR_WEDDING':
          await checkWeddingCode();
          // Why do we signIn() twice? First one is to check password, second one is to get the updated token from cognito after user update
          cognitoUser = await Auth.signIn(loginData.username, password);
          fidiraUser = await axios.patch(`user-update/`, {
            updateType: 'ADD_USER_TO_WEDDING',
            weddingId: weddingId,
            weddingCode: loginData.weddingCode,
            username: loginData.username,
            role: 'GUEST',
          });
          cognitoUser = await Auth.signIn(loginData.username, password);
          break;
        case 'WEDDING_PAGE_LOGIN_EXISTING_GUEST':
          cognitoUser = await Auth.signIn(loginData.username, password);
          fidiraUser = await axios.get(`user-read/`, { headers: { 'data-username': loginData.username } });
          break;
        case 'GENERAL_LOGIN_EXISTING_USER':
          cognitoUser = await Auth.signIn(loginData.username, password);
          fidiraUser = await axios.get(`user-read/`, { headers: { 'data-username': loginData.username } });
          break;
        case 'GENERAL_LOGIN_NEW_USER':
          cognitoUser = await Auth.signUp({
            username: loginData.username,
            password: password,
          });
          fidiraUser = await axios.post(`user-create/`, {
            creationType: 'ON_GENERAL_PAGE',
            sub: cognitoUser.userSub,
            mail: loginData.username,
            displayName: loginData.displayName,
            language: currentLanguage,
          });
          await Auth.signIn(loginData.username, password);
          break;
        default:
          new Error('Invalid Login Flow!');
      }
      setAuthenticatedUser(cognitoUser, fidiraUser.data);
      history.push(`/${weddingId || appPage}${appPageId ? `/${appPageId}` : ''}`);
    } catch (error) {
      console.log(error);
      console.error(error);
      if (error.message === 'Incorrect username or password.') {
        setWrongCredentialError(true);
      } else if (error && error.response && error.response.status === 403) {
        setWrongWeddingCodeError(true);
      } else {
        setGenericLoginError(true);
      }
      setLoadingPassword(false);
    }
  };

  const goToPreviousStep = () => {
    const indexOfCurrentStep = LoginFlows[currentLoginFlow].indexOf(currentLoginStep);
    history.push(`${correctBaseUrlBeforeStep}${LoginFlows[currentLoginFlow][indexOfCurrentStep - 1]}`);
  };

  useEffect(() => {
    setCurrentLoginStep(currentStepFromUrl);
  }, [currentStepFromUrl]);

  useEffect(() => {
    if (currentStepFromUrl !== 'enterUsername' && currentStepFromUrl !== 'forgotPasswordMessage') {
      history.replace(`${correctBaseUrlBeforeStep}enterUsername`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open
      aria-labelledby="Sign in or Register"
      aria-describedby="Sign in or Register for EureHochzeitOnline.com"
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
        <Container className="color-background-white">
          <DialogTitle>{i18n.t(k.LOGIN)}</DialogTitle>
          <DesignElementTopRight weddingStyle={weddingStyle}>
            <BalloonSvg></BalloonSvg>
          </DesignElementTopRight>
          <StyledDialogContent>
            <Username
              visible={currentLoginStep === 'enterUsername'}
              onNext={onUsernameEntered}
              loadingUsername={loadingUsername}
            ></Username>
            <DisplayName
              visible={currentLoginStep === 'enterDisplayName'}
              onNext={onDisplayNameEntered}
              onBack={goToPreviousStep}
            ></DisplayName>
            <WeddingCode
              visible={currentLoginStep === 'enterWeddingCode'}
              onNext={onWeddingCodeEntered}
              onBack={goToPreviousStep}
              displayName={loginData.displayName}
            ></WeddingCode>
            <Password
              visible={currentLoginStep === 'enterPassword'}
              newPassword={
                currentLoginFlow === 'WEDDING_PAGE_LOGIN_NEW_USER' || currentLoginFlow === 'GENERAL_LOGIN_NEW_USER'
              }
              onNext={onPasswordEntered}
              onBack={goToPreviousStep}
              onForgotPassword={onForgotPassword}
              displayName={loginData.displayName}
              loadingPassword={loadingPassword}
              weddingStyle={weddingStyle}
              weddingId={weddingId}
              appPage={appPage}
              appPageId={appPageId}
            ></Password>
            <ForgotPasswordMessage visible={currentLoginStep === 'forgotPasswordMessage'}></ForgotPasswordMessage>
          </StyledDialogContent>
          <ErrorDialog
            open={genericLoginError}
            messageTitle={i18n.t(k.ERROR_GENERAL)}
            messageDescriptionText={i18n.t(k.COULD_NOT_LOGIN)}
            handleClose={() => setGenericLoginError(false)}
          ></ErrorDialog>
          <ErrorDialog
            open={wrongCredentialError}
            messageTitle={i18n.t(k.WRONG_PASSWORD_TITLE)}
            messageDescriptionText={i18n.t(k.WRONG_PASSWORD_TEXT)}
            handleClose={() => setWrongCredentialError(false)}
          ></ErrorDialog>
          <ErrorDialog
            open={wrongWeddingCodeError}
            messageTitle={i18n.t(k.WRONG_CODE_TITLE)}
            messageDescriptionText={i18n.t(k.WRONG_CODE_TEXT)}
            handleClose={() => setWrongWeddingCodeError(false)}
          ></ErrorDialog>
        </Container>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default LoginContainer;
