import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.p`
  font-size: 2vw !important;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 18px;
  margin-top: -12px;
  font-family: ${defaultStyles.blossom.bodyFont} !important;
  color: ${defaultStyles.blossom.colors.secondary.base} !important;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    font-size: 12px !important;
    letter-spacing: 9px;
  }
`;
