import i18n from 'i18next';
import k from './../../../../../i18n/keys';
import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import styled from 'styled-components';

import breakpoints from '../../../../../Shared/breakpoints';
import defaultStyles from '../../../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../../../Shared/WeddingStyles/GenericStyleContainer';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import { ReactComponent as LoveLetter } from '../../../../../_assets/svg/rating-star-empty.svg';
import { useHistory } from 'react-router-dom';

const StyledDialogContent = styled(DialogContent)`
  height: 400px;
  width: 500px;
  max-width: 65vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3,
  p {
    margin: 0px;
    margin-bottom: 12px;
  }
  button {
    background-color: transparent !important;
  }
`;

const IconContainer = styled.div`
  margin: 30px;
  svg {
    height: 100px;
    width: 100px;
    fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite};
  }
  @media (max-width: ${breakpoints.xs}) {
    margin: 10px;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
  button {
    width: 150px;
  }
`;

const StyledH2 = styled.h2`
  margin: 0 0 6px 0;
`;

const NoPackageBoughtHintDialog = ({ show, setShow, restriction }) => {
  const history = useHistory();
  const { weddingConfig, weddingId } = useContext(WeddingContext);

  const handleClose = () => {
    setShow(false);
  };

  const startPayment = async () => {
    history.push(`/${weddingId}/payment`);
  };
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="{messageTitle}"
      aria-describedby="{messageDescriptionText}"
      className="color-background-white"
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingConfig.weddingStyle]}>
        <StyledDialogContent className="color-background-white">
          <IconContainer weddingStyle={weddingConfig.weddingStyle}>
            <LoveLetter></LoveLetter>
          </IconContainer>
          <>
            <StyledH2>{i18n.t(k.LETS_UPGRADE)} </StyledH2>
            <p>{i18n.t(k.MAX_NUMBER_FOR_BASIC_PACKAGE)} </p>
            {restriction === i18n.t(k.PHOTOS) ? (
              <p>{i18n.t(k.UPGRADE_FOR_UNLIMITED_PHOTOS)}</p>
            ) : (
              <p>{i18n.t(k.UPGRADE_FOR_UNLIMITED_MUSIC)}</p>
            )}
          </>
        </StyledDialogContent>
        <StyledDialogActions className="color-background-white">
          <Button onClick={handleClose} color="secondary" variant="contained">
            {i18n.t(k.CANCEL)}
          </Button>
          <Button onClick={startPayment} color="primary" variant="contained">
            {i18n.t(k.UPGRADE)}
          </Button>
        </StyledDialogActions>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default NoPackageBoughtHintDialog;
