import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useContext } from 'react';
import styled from 'styled-components';
import DefaultStyles from '../DefaultStyles';
import EditingContainerForCountdown from '../../../Pages/WeddingPage/Editing/Components/EditingContainer/EditingContainerForCountdown';
import EditingContext from '../../Context/EditingContext';
import breakpoints from '../../breakpoints';

const DAY = 24 * 60 * 60 * 1000;

const Container = styled.div`
  z-index: 100;
  position: fixed;
  top: 100px;
  right: 100px;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 3px;
  transform: rotate(15deg);
  border-color: ${({ weddingStyle }) => DefaultStyles[weddingStyle].colors.primary.onWhite} !important;
  h1,
  h2 {
    margin: -4px;
  }
  @media (max-width: ${breakpoints.md}) {
    top: 80px;
    right: 80px;
    height: 150px;
    width: 150px;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 70px;
    right: 45px;
    height: 90px;
    width: 90px;
    h1,
    h2 {
      margin: -3px;
    }
    h1 {
      font-size: 27px !important;
    }
    h2 {
      font-size: 18px !important;
    }
  }
`;

const Countdown = ({ weddingConfig, weddingStyle }) => {
  const { editingModeActive } = useContext(EditingContext);
  const weddingTimestamp =
    weddingConfig.weddingDateIsExact && weddingConfig.weddingDate
      ? new Date(
          `${weddingConfig.weddingDate.substr(3, 2)}.${weddingConfig.weddingDate.substr(
            0,
            2
          )}.${weddingConfig.weddingDate.substr(6, 4)}`
        ).getTime()
      : null;
  const daysToWedding = weddingTimestamp ? Math.floor((weddingTimestamp + DAY - Date.now()) / DAY) : null;
  return (
    <>
      {((editingModeActive && daysToWedding !== null) || (weddingConfig.showCountdown && daysToWedding !== null)) && (
        <Container weddingStyle={weddingStyle} className="color-background-white">
          <EditingContainerForCountdown>
            {daysToWedding > 1 ? (
              <>
                <h1>
                  <strong>{daysToWedding}</strong>
                </h1>
                <h2>{i18n.t(k.DAYS_TO_GO)}</h2>
              </>
            ) : daysToWedding === 1 ? (
              <>
                <h1>
                  <strong>{daysToWedding}</strong>
                </h1>
                <h2>{i18n.t(k.DAY_TO_GO)}</h2>
              </>
            ) : (
              <h1>
                <strong>{i18n.t(k.TODAY)}</strong>
              </h1>
            )}
          </EditingContainerForCountdown>
        </Container>
      )}
    </>
  );
};

export default Countdown;
