import React from 'react';
import styled from 'styled-components';

import boxShadows from '../../boxShadows';
import breakpoints from '../../breakpoints';

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const DesktopScreen = styled.div`
  width: 450px;
  height: 260px;
  max-width: ${({ lp }) => (lp ? '40vw' : 'auto')}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
  box-shadow: ${boxShadows.z3};
  margin: 12px;
  border: solid 1px;
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    max-width: 80vw;
    max-height: 46vw;
  }
`;
const MobileMockup = styled.div`
  width: 90px;
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/wedding-styles/phone-mock.png);
  position: absolute;
  top: -3px;
  left: -2px;
  border-radius: 14px;
  box-shadow: ${boxShadows.z3};
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    width: 77px;
    height: 155px;
  }
`;
const MobileScreen = styled.div`
  position: absolute;
  top: 120px;
  left: 50px;
  border-radius: 10px;
  width: 87px;
  height: 172px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
  @media (max-width: ${breakpoints.sm}), (max-height: ${breakpoints.xs}) {
    top: 10vw;
    width: 75px;
    height: 149px;
  }
`;

const ThumbnailImageContainer = ({ lp, keyOfStyle }) => {
  return (
    <Container>
      <DesktopScreen lp={lp} image={`/assets/wedding-styles/${keyOfStyle}/desktop-${keyOfStyle}.jpg`}></DesktopScreen>
      {/* ideal: 1600 x 919 in Firefox, then edit and compress to 800 x 460 */}
      <MobileScreen image={`/assets/wedding-styles/${keyOfStyle}/mobile-${keyOfStyle}.jpg`}>
        {/* ideal: 390 x 767 in Firefox, then edit and compress to 305 x 600 */}
        <MobileMockup></MobileMockup>
      </MobileScreen>
    </Container>
  );
};

export default ThumbnailImageContainer;
