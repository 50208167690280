import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { ReactComponent as DeleteSvg } from '../../../../../../_assets/svg/delete-playlist.svg';
import breakpoints from '../../../../../../Shared/breakpoints';

const Container = styled.div`
  width: 70%;
  max-width: 100%;
  margin: 12px auto 6px auto;
`;
const Image = styled.div`
  height: 200px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #e8e8e8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-color: rgba(0, 0, 0, 0.42) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.xs}) {
    height: 170px;
  }
`;

const ButtonContainer = styled.div`
  background-color: #ffffffa8;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 0 0 10px;
  border-top-left-radius: 4px;
`;

const IconButtonContainer = styled.div`
  background-color: #ffffffa8;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3px 10px 0 0;
  border-top-right-radius: 4px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const RequestChangeButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
`;

const RemoveImageButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
  min-width: 30px;
`;

const SelectedImage = ({ imageUrl, onRequestChange, onRemoveImage }) => {
  return (
    <Container>
      <span>{i18n.t(k.SELECTED_IMAGE)}</span>
      <Image imageUrl={imageUrl}>
        {!imageUrl && <span>{i18n.t(k.NO_IMAGE)}</span>}
        <IconButtonContainer>
          <RemoveImageButton onClick={onRemoveImage}>
            <DeleteSvg></DeleteSvg>
          </RemoveImageButton>
        </IconButtonContainer>
        <ButtonContainer>
          <RequestChangeButton onClick={onRequestChange}>{i18n.t(k.OTHER_IMAGE)}</RequestChangeButton>
        </ButtonContainer>
      </Image>
    </Container>
  );
};

export default SelectedImage;
