import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IconButton, CircularProgress } from '@material-ui/core';

import { ReactComponent as DeleteSvg } from '../../../../../../_assets/svg/delete-playlist.svg';
import UserContext from '../../../../../../Shared/Context/UserContext';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';
import hasUserWritePermissionForWedding from '../../../../../../Shared/FunctionUtils/hasUserWritePermissionForWedding';

const OuterContainer = styled.div`
  width: 500px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`;

const Iframe = styled.iframe`
  width: calc(100% - 40px);
  height: 80px;
  background: linear-gradient(rgba(86, 86, 86, 0.6), rgba(0, 0, 0, 0.84));
  border-radius: 12px 0 0 12px;
  &.playlist {
    border-radius: 12px;
  }
`;

const InnerButtonContainer = styled.div`
  width: 40px;
  border-radius: 0 12px 12px 0;
`;

const InnerTextContainer = styled.div`
  width: calc(100% - 40px);
  border-radius: 0 12px 12px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p,
  a {
    font-size: 14px;
    margin: 0;
    text-align: left;
  }
`;

const ButtonLine = styled.div`
  margin: 0;
  padding: 0;
`;

const StyledIconButton = styled(IconButton)`
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  svg {
    height: 20px;
    width: 20px;
  }
`;

const PlaylistHeader = styled.p`
  font-weight: bold;
  text-align: left;
  margin: 24px 0 3px 0;
`;

const PlaylistLink = styled.a`
  font-weight: bold;
  text-align: right;
  font-size: 13px;
  margin: 12px 0;
`;

const Playlist = ({ playlistId, playlistData, refreshingPlaylist, deleteTrack, tracksBeingDeleted }) => {
  const [randomKey, setRandomKey] = useState(null);
  const { fidiraUser } = useContext(UserContext);
  const { weddingId } = useContext(WeddingContext);

  useEffect(() => {
    if (!refreshingPlaylist) {
      setRandomKey(Date.now());
    }
  }, [refreshingPlaylist]);

  return (
    <OuterContainer key={randomKey}>
      <PlaylistHeader>{i18n.t(k.LISTEN_TO_FULL_LIST)}</PlaylistHeader>
      <Iframe
        key={playlistId}
        title={playlistId}
        src={`https://open.spotify.com/embed/playlist/${playlistData.playlistInfo.spotifyId}?nocache=${Date.now()}`}
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        style={{ width: '100%', overflow: 'hidden' }}
        className="playlist"
      ></Iframe>
      <PlaylistLink
        href={`https://open.spotify.com/playlist/${playlistData.playlistInfo.spotifyId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n.t(k.OPEN_IN_SPOTIFY)}
      </PlaylistLink>
      <PlaylistHeader>{i18n.t(k.LISTEN_TO_SINGLE_SONGS)}</PlaylistHeader>
      {playlistData.tracks.map(track => (
        <Container key={track.id}>
          <InnerTextContainer>
            <p>{track.name}</p>
            <p>{track.artists}</p>
            <PlaylistLink
              href={`https://open.spotify.com/track/${track.spotifyId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t(k.OPEN_IN_SPOTIFY)}
            </PlaylistLink>
          </InnerTextContainer>
          <InnerButtonContainer>
            <ButtonLine key={track.id}>
              <StyledIconButton
                onClick={() => deleteTrack(track.id)}
                disabled={
                  tracksBeingDeleted.includes(track.id) ||
                  refreshingPlaylist ||
                  (!track.createdByUser && !hasUserWritePermissionForWedding(fidiraUser, weddingId))
                }
              >
                {!tracksBeingDeleted.includes(track.id) && <DeleteSvg></DeleteSvg>}
                {tracksBeingDeleted.includes(track.id) && <CircularProgress size="15"></CircularProgress>}
              </StyledIconButton>
            </ButtonLine>
          </InnerButtonContainer>
        </Container>
      ))}
    </OuterContainer>
  );
};

export default Playlist;
