import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Container = styled.div`
  position: relative;
  width: 450px;
  max-width: 95vw;
  text-align: left;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  span {
    line-height: 1.2;
  }
`;

const AcceptTermsCheckbox = ({ show, changeAcceptance }) => {
  const label = (
    <span>
      {i18n.t(k.I_AGREE)}{' '}
      <a href="/f/datenschutz/" target="_blank" rel="noopener noreferrer">
        {i18n.t(k.TERMS_AND_CONDITIONS_08)}
      </a>{' '}
      {i18n.t(k.AND)}{' '}
      <a href="/f/nutzungsbedingungen/" target="_blank" rel="noopener noreferrer">
        {i18n.t(k.TERMS_AND_CONDITIONS_00)}
      </a>
    </span>
  );

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    changeAcceptance(checked);
  }, [changeAcceptance, checked]);

  return (
    <Container show={show}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} name="AcceptTermsCheckbox" />}
        label={label}
      />
    </Container>
  );
};

export default AcceptTermsCheckbox;
