import React from 'react';

import LoginContainer from '../../../../Shared/Components/LoginFlow/LoginContainer';

const LoginPage = ({ weddingId, weddingStyle }) => {
  return (
    <>
      <LoginContainer weddingId={weddingId} weddingStyle={weddingStyle}></LoginContainer>
    </>
  );
};

export default LoginPage;
